import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { GoogleConsent } from '../../../shared/entity/consent-google';
import { MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-dialog-options',
  standalone: true,
  imports: [MatSlideToggleModule, FormsModule, MatButtonModule],
  templateUrl: './dialog-options.component.html',
  styleUrl: './dialog-options.component.scss'
})
export class DialogOptionsComponent {

  toggleA: boolean = true;
  toggleB: boolean = true;
  toggleC: boolean = true;
  toggleD: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<DialogOptionsComponent>,

  ) {}


  changeA() {

    this.toggleA = !this.toggleA;

  }

  changeB() {

    this.toggleB = !this.toggleB;

  }

  changeC() {

    this.toggleC = !this.toggleC;

  }

  changeD() {

    this.toggleD = !this.toggleD;
  }

  submit() {

    let gConsent: GoogleConsent = new GoogleConsent();
    if (this.toggleA)
      gConsent.ad_storage = 'granted';
    else
      gConsent.ad_storage = 'denied';

    if (this.toggleB)
      gConsent.ad_user_data  = 'granted';
    else
      gConsent.ad_user_data  = 'denied';

    if (this.toggleC)
      gConsent.ad_personalization  = 'granted';
    else
      gConsent.ad_personalization  = 'denied';

    if (this.toggleD)
      gConsent.analytics_storage  = 'granted';
    else
      gConsent.analytics_storage  = 'denied';

    this.dialogRef.close(gConsent);

  }

}
