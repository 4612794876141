<div class="maincontainer">
  <div class="card">
    <h1 class="errorcode"> YOU ARE BANNED ! </h1>
    <div class="errortext"> You know what you did, and it was wrong</div>
    <div class="errortext"> You have been reported.</div>
    <div class="errortext"> For any complaints, contact our support</div>
    <div class="actions">
      <button mat-raised-button color="primary" (click)="home()">GET OUT OF HERE</button>
    </div>
  </div>
</div>
