import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionService } from '../services/subscription.service';
import { Subscription } from '../shared/entity/subscription';
import { NgIf } from '@angular/common';
import { AccountService } from '../services/account.service';
import { UserService } from '../services/user.service';
import { User } from '../shared/entity/user';

@Component({
  selector: 'app-subscription',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatListModule,
    MatIconModule,
    FormsModule,
    NgIf,
  ],
  templateUrl: './subscription.component.html',
  styleUrl: './subscription.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SubscriptionComponent {
  isAnnual: boolean;
  priceModel: string;
  year_txt: string;
  monthly: Subscription[];
  annualy: Subscription[];
  user: User;

  ui_products: Subscription[];

  constructor(
    private router: Router,
    private subscriptionService: SubscriptionService,
    private accountService: AccountService,
    private userService: UserService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.isAnnual = true;
    this.monthly = [];
    this.annualy = [];
    this.year_txt = 'Bill yearly,';

    //get all the product / prices

    this.subscriptionService.getAllProducts().subscribe((res) => {

      //user and its subs
      this.userService
        .getPerEmail(this.accountService.email)
        .subscribe((user) => {
          this.user = user;

          res.forEach((prod) => {
            if (prod.recurrence == 'MONTHLY') {
              this.monthly.push(prod);
            } else {
              this.annualy.push(prod);
            }
          });

          this.setUiProducts(this.annualy);


        });


    });
  }

  setUiProducts(array: Subscription[]) {
    let tier_1;
    let tier_2;
    let tier_3;

    array.forEach((prod) => {
      if (prod.type.includes('1')) {
        tier_1 = prod;
      } else if (prod.type.includes('2')) {
        tier_2 = prod;
      } else if (prod.type.includes('3')) {
        tier_3 = prod;
      }
    });

    this.ui_products = [];
    this.ui_products.push(tier_1);
    this.ui_products.push(tier_2);
    this.ui_products.push(tier_3);
  }

  priceCompute(amount) {
    if (this.isAnnual) {
      return amount / 12;
    } else {
      return amount;
    }
  }

  priceModelChange() {
    this.isAnnual = !this.isAnnual;

    if (this.isAnnual) {
      this.year_txt = 'Bill yearly,';
      this.setUiProducts(this.annualy);
    } else {
      this.year_txt = '';
      this.setUiProducts(this.monthly);
    }
  }

  subscribe(type) {
    this.router.navigate(['pay', type]);
  }

  checkPurchasePossibility(prod: Subscription) {
    let disable = false;

    if (
      this.user?.data.subscription.remaining_guid ||
      this.user?.data.subscription.future_guid
    ) {
      disable = true;
    }

    if (prod.type == this.user.data.subscription.type) disable = true;

    return disable;
  }
}
