import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { User } from '../shared/entity/user';
import { environment } from '../../environments/environment';
import { Consent } from '../shared/entity/consent';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  apiBaseUrl : string;

  constructor(private http: HttpClient) {
    this.apiBaseUrl =  environment.apiUrl;
  }

  search(typing: string): Observable<User[]>  {
    return this.http.post<User[]>(`${this.apiBaseUrl}/v1/user/search`, typing);
  }

  getAll(): Observable<User[]>  {
    return this.http.get<User[]>(`${this.apiBaseUrl}/v1/user`);
  }

  get(guid:string): Observable<User>  {
    return this.http.get<User>(`${this.apiBaseUrl}/v1/user/`+guid);
  }

  getPerEmail(email:string): Observable<User>  {
    return this.http.get<User>(`${this.apiBaseUrl}/v1/user/email/`+email);
  }

  create(user:User):Observable<User> {
    return this.http.post<User>(`${this.apiBaseUrl}/v1/user`, user);
  }

  updateConsent(consent:Consent):Observable<Consent> {
     return this.http.put<Consent>(`${this.apiBaseUrl}/v1/user/consent`, consent);
}

  delete(id:number):Observable<User> {
    return this.http.delete<User>(`${this.apiBaseUrl}/v1/user/`+id);
  }

  getRolesAvailableForMyRoleLevel():Observable<string[]> {
    return this.http.get<string[]>(`${this.apiBaseUrl}/v1/user/roles`);
  }

  updateNickname(nick: string):Observable<void> {
    return this.http.put<void>(`${this.apiBaseUrl}/v1/user/nickname`, { nickname: nick });
  }

  updateProfilePicture(uri: string):Observable<void> {
    return this.http.put<void>(`${this.apiBaseUrl}/v1/user/pp`, { uri: uri });
  }
}
