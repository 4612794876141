export class References {

  pic_guid?: string; //picture guid in base
  avatar_guid: string;
  enc_r: string;
  enc_p: string;
  action: string;
  target: string; //REFERENCE ou LORA

}
