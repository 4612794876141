<div *ngIf="!loaded" class="spinner-container"><mat-spinner></mat-spinner></div>

<div class="filter">
  <mat-form-field class="searchfield">
    <input matInput type="text" (keyup)="doFilter($event)">
    <mat-label>Search & Filter </mat-label>
    <mat-icon matSuffix>filter_list</mat-icon>
    <mat-hint>Filter applies to all columns</mat-hint>
  </mat-form-field>
  <div class="new">
    <button mat-raised-button [color]="'primary'" (click)="new()">NEW </button>
  </div>
</div>

<table [hidden]="!loaded" mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
  matSortActive="name" matSortDirection="asc" class="mat-elevation-z8">

  <!--  gender Column -->
  <ng-container matColumnDef="gender">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by gender">
      GENDER
    </th>
    <td mat-cell *matCellDef="let element"> {{element.gender}} </td>
  </ng-container>

  <!--  text Column -->
  <ng-container matColumnDef="text">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by text">
      STORY TEXT
    </th>
    <td mat-cell *matCellDef="let element"> {{element.text.slice(0,200)}}... </td>
  </ng-container>


  <!-- action Column  -->
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>
      <span>ACTIONS</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="icons">
        <button mat-icon-button class="action-button" (click)="edit(element)"><mat-icon
            [color]="'primary'" alt="EDIT">search </mat-icon></button>
       <!-- <button mat-icon-button class="action-button" aria-label="delete user"
          (click)="delete(element.guid)"><mat-icon [color]="'primary'" alt="DELETE">delete</mat-icon></button>-->
      </div>
    </td>
  </ng-container>

  <!-- fab Column  -->
  <ng-container matColumnDef="add">
    <th mat-header-cell *matHeaderCellDef>
      <div class="fab">
        <button mat-mini-fab color="accent" aria-label="Add lab" (click)="new()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<div [hidden]="!loaded">
  <mat-paginator [pageSize]="25" [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons aria-label="Select page of samples" class="paginator">
  </mat-paginator>
</div>
