import { Component } from '@angular/core';
import { CustomAuthService } from '../services/custom-auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent {
  constructor(
    private auth: CustomAuthService
  ) {}

  ngOnInit() {

   this.auth.logout();

  }
}
