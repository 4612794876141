<mat-card class="mat-elevation-z0 container">

  <div class="left">
    <img class="image" src="../../assets/img/spot_credits.jpg">
  </div>
  <div class="right">

    <div class="title"> NEED MORE CREATIVE CREDITS ?</div>
    <div class="paragraph"> We allow our users to purchase "spot credits". These credits are additional credits, that will not be reset at the end of your monthly subscription cycle.</div>
    <div class="paragraph"> For instance, imagine your '100 credits' subscription recharges your monthly credits tomorrow and your current balance is 0. </div>
    <div class="paragraph"> If you buy 500 spot credits now without using them, you will have 500+100 credits tomorrow. </div>
    <div class="paragraph"> These spot credits are usually used as an "emergency reserve", when you have a creative workload to finish shortly !  </div>

    <div class="actions">

      @for (offer of spotOffers ; track offer.guid) {

        <div class="action-box">

          <div class="offer-title">
            {{ offer.label }}
          </div>

          <div class="separator"></div>

          <div class="first">
            <div class="text">{{ offer.nb_of_credits }} NZYM credits</div>

          </div>

          <div class="second">
            <mat-icon > payments </mat-icon>
            <div class="text">${{ offer.amount }}</div>
           </div>

           <div class="third">
            <div class="purchase"> <button mat-raised-button color="primary" (click)="purchase(offer.guid)">PURCHASE</button></div>
          </div>

        </div>

      }

    </div>
  </div>
</mat-card>
