import { Component } from '@angular/core';
import { AdminService } from '../services/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Ingredient } from '../shared/entity/ingredient';
import { MatCardModule } from '@angular/material/card';
import { RandomImageService } from '../services/random-image.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgIf } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-ingredient',
  standalone: true,
  imports: [
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatSelectModule,
    NgIf,
  ],
  templateUrl: './ingredient.component.html',
  styleUrl: './ingredient.component.scss',
})
export class IngredientComponent {
  ingredient: Ingredient;
  mode_create: boolean;
  background_img: string;
  form: FormGroup;
  loaded: boolean;

  genders: string[] = ['MALE', 'FEMALE'];
  types: string[] = ['ACTOR', 'SPORT', 'PORN', 'MODEL', 'TV', 'MUSIC', 'OTHER'];
  ethnicities: string[] = ['CAUCASIAN', 'BLACK', 'ASIAN', 'LATINO'];
  rarities: string[] = ['SR', 'SSR', 'UR', 'LR'];

  constructor(
    private adminService: AdminService,
    private router: Router,
    private formBuilder: FormBuilder,
    private randomImageService: RandomImageService,
    private _snackbar: MatSnackBar,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.loaded = false;

    this.route.paramMap.subscribe((params) => {
      this.background_img = this.randomImageService.get();

      if (params.get('ingredient_guid') == 'new') {

        this.mode_create = true;

        this.ingredient = new Ingredient();

        this.form = this.formBuilder.group({
          ingredient_name: ['', Validators.required],
          ingredient_gender: ['MALE', Validators.required],
          ingredient_type: ['ACTOR', Validators.required],
          ingredient_ethnicity: ['CAUCASIAN', Validators.required],
          ingredient_rarity: ['SSR', Validators.required],
          ingredient_active: ['true', Validators.required],
        });

        this.loaded = true;
      } else {
        console.log('edit ! ' + params.get('ingredient_guid'));
        this.mode_create = false;

        this.adminService
          .getIngredient(params.get('ingredient_guid'))
          .subscribe((ingredient) => {
            this.ingredient = ingredient;

            this.form = this.formBuilder.group({
              ingredient_name: [this.ingredient.name, Validators.required],
              ingredient_gender: [this.ingredient.gender, Validators.required],
              ingredient_type: [this.ingredient.type, Validators.required],
              ingredient_ethnicity: [
                this.ingredient.ethnicity,
                Validators.required,
              ],
              ingredient_rarity: [this.ingredient.rarity, Validators.required],
              ingredient_active: [this.ingredient.active, Validators.required],
            });
            this.loaded = true;
          });
      }
    });
  }

  cancel() {
    this._snackbar.open(' Action Cancelled !', '', {
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      duration: 2000,
      panelClass: ['red-snackbar'],
    });
    this.router.navigate(['ingredient-list']);
  }

  submit() {
    if (this.form.valid) {
      this.ingredient.name = this.form.controls.ingredient_name.value;
      this.ingredient.ethnicity = this.form.controls.ingredient_ethnicity.value;
      this.ingredient.gender = this.form.controls.ingredient_gender.value;
      this.ingredient.rarity = this.form.controls.ingredient_rarity.value;
      this.ingredient.type = this.form.controls.ingredient_type.value;
      this.ingredient.active = this.form.controls.ingredient_active.value;

      if (this.mode_create) {

        this.adminService.addIngredient(this.ingredient).subscribe(() => {

          this._snackbar.open('Ingredient created !', '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 2000,
            panelClass: ['green-snackbar'],
          });

          this.router.navigate(['ingredient-list']);
        });


      } else {
        this.adminService.updateIngredient(this.ingredient).subscribe(() => {

          this._snackbar.open('Ingredient updated !', '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 2000,
            panelClass: ['green-snackbar'],
          });

          this.router.navigate(['ingredient-list']);
        });
      }
    }
  }
}
