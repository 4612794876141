<div class="maincontainer">
  <div class="card">
    <h1 class="errorcode"> SESSION EXPIRED  ! </h1>
    <div class="errortext"> Your session has ended !</div>
    <div class="errortext"> For security reasons, please re-log in to  the application</div>
    <div class="actions">
      <button mat-raised-button color="primary" (click)="logoutAndLogin()">RE-LOGIN</button>
      <a mat-raised-button color="warn" href="mailto:support@nzym.io" > CONTACT SUPPORT</a>
    </div>
  </div>
</div>
