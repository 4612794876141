import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CreditService } from '../../../services/credit.service';
import { AccountService } from '../../../services/account.service';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-credit',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, MatCardModule],
  templateUrl: './credit.component.html',
  styleUrl: './credit.component.scss',
})
export class CreditComponent {
  balance: number;
  monthly_credits: number;
  plan: string;

  constructor(
    private creditService: CreditService,
    public accountService: AccountService,
    public router: Router
  ) {}

  ngOnInit() {

      this.creditService.collectCredit().subscribe((info) => {

        this.balance = info?.balance;
        this.monthly_credits = info?.monthly_credits;
        this.plan = info?.plan;
      });

      this.creditService.get().subscribe((info) => {

        this.balance = info?.balance;
        this.monthly_credits = info?.monthly_credits;
        this.plan = info?.plan;
      });
    }

    upgrade() {

      this.router.navigate(['subscription']);

    }

}
