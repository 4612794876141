<mat-card class="mat-elevation-z0 single-card settings-card">
  <mat-cart-header class="mat-card-header form-box-title">
    <mat-card-title> EDIT HISTORY </mat-card-title>
  </mat-cart-header>

  <mat-card-content>
    <div class="container">
      <div class="container-element">
        <!-- -->
        <form [formGroup]="form" class="form-naming">



            <mat-radio-group aria-label="Select an option" formControlName="gender">
              <mat-radio-button labelPosition="before" value="MALE" [checked]='male'
                >MALE</mat-radio-button>
              <mat-radio-button value="FEMALE" [checked]='!male'>FEMALE</mat-radio-button>
            </mat-radio-group>


              <mat-form-field class="form-element">
                <mat-label>HISTORY</mat-label>
                <textarea matInput type="string" formControlName="text" rows="30" cols="150" ></textarea>
              </mat-form-field>

        </form>
        <!-- -->
        <div class="container-button">
          <button mat-raised-button color="primary" (click)="submit()">SUBMIT</button>
          <button mat-raised-button color="warn" (click)="onCancel()">CANCEL</button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
