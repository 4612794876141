<div class="dialog">

  <div class="img-container">
    <div class="image-display">
      <div class="overlay" [class]="tile_overlay.loaded ? 'not-visible' : 'spinner-container'"><mat-spinner ></mat-spinner> </div>
      <div class="overlay" [class]="tile_overlay.loaded ? 'not-visible' : 'chrono-visible'">{{ tile_overlay.sec}}.{{ tile_overlay.decilesec}} s </div>
      <img class="full-image" src="{{ uri }}">
    </div>
    <div class="image-features">
      <div class="button-download">
        <button mat-raised-button color="primary" class="button" (click)="download()">
          DOWNLOAD</button>
      </div>
      <div class="button-edit-in-place">
        <button mat-raised-button color="primary" [disabled]="true" type="submit" class="button" (click)="edit()">INPAINT</button>
      </div>
      <div class="button-regenerate">
        <button mat-raised-button color="primary" type="button" class="button" (click)="regenerate()">REGENERATE</button>
      </div>
    </div>
  </div>

  <div class="image-info">
    <div class="title"> {{ action_text.toUpperCase() }} </div>
    <div class="image-description">
      <div class="subtitle"><h3> DESCRIPTION </h3></div>
      <div class="text"> Your character is in a {{ action_text }} posture, which will be a reference that you can activate to help you in future image generation</div>
      <div class="text"> Ensure that the face and especially the eyes are sufficiently defined. If not, regenerate this image, or inpaint it, to only improve the part which needs modification</div>
    </div>
    <div class="image-size">
      <div class="subtitle"><h3> IMAGE SIZE</h3> </div>
      <div class="text"> 1024x1024 </div>
    </div>
    <div class="image-create-date">
      <div class="subtitle"><h3> CREATION DATE </h3></div>
      <div class="text"> {{ creationDate }} </div>
    </div>
    <div>

    </div>
  </div>

</div>
