import { LiveAnnouncer } from '@angular/cdk/a11y';
import { NgIf } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { AdminService } from '../services/admin.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogEditHistoryComponent } from './dialog-edit-history/dialog-edit-history.component';
import { AvatarStory } from '../shared/entity/avatar-story';

@Component({
  selector: 'app-avatar-stories-list',
  standalone: true,
  imports: [
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    NgIf,
  ],
  templateUrl: './avatar-stories-list.component.html',
  styleUrl: './avatar-stories-list.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AvatarStoriesListComponent {
  dataSource;
  loaded: boolean = false;

  displayedColumns: string[] = ['gender', 'text', 'action'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort | any;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private _snackbar: MatSnackBar,
    public dialog: MatDialog,
    private adminService: AdminService,
    private router: Router
  ) {}

  ngOnInit() {
    this.adminService.getAllAvatarStories().subscribe((stories) => {
      this.dataSource = new MatTableDataSource(stories);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.loaded = true;
    });
  }

  doFilter = (value: any) => {
    this.dataSource.filter = value.target.value.toLocaleLowerCase();
  };

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  new() {
    const dialogRef = this.dialog.open(DialogEditHistoryComponent, {
      data: {
        story: null,
      },
      height: '80%',
      width: '80%',
      maxWidth: '1000px',
      maxHeight: '600px',
    });

    dialogRef.afterClosed().subscribe((story: AvatarStory) => {
      if (null != story) {
        this.adminService.addAvatarStory(story).subscribe(() => {
          this._snackbar.open('Story creaed !', '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 1000,
            panelClass: ['green-snackbar'],
          });
        });
      }
    });
  }

  edit(story: AvatarStory) {

    const dialogRef = this.dialog.open(DialogEditHistoryComponent, {
      data: {
        story: story,
      },
      height: '80%',
      width: '80%',
      maxWidth: '1000px',
      maxHeight: '600px',
    });

    dialogRef.afterClosed().subscribe((story: AvatarStory) => {
      if (null != story) {
        this.adminService.addAvatarStory(story).subscribe(() => {
          this._snackbar.open('Story creaed !', '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 1000,
            panelClass: ['green-snackbar'],
          });
        });
      }
    });

  }
}
