import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { Picture } from '../shared/entity/picture';


@Injectable({
  providedIn: 'root',
})
export class PublicService {
  apiBaseUrl: string;

  constructor(private http: HttpClient) {
    this.apiBaseUrl = environment.apiUrl;
  }

  //**********  PICTURE

  getAllSharedPictures(nbElements: number, lastId: number): Observable<Picture[]>  {
    if (lastId)
      return this.http.get<Picture[]>(`${this.apiBaseUrl}/v1/shared/gallery?nbe=${nbElements}&last=${lastId}`);
    else
      return this.http.get<Picture[]>(`${this.apiBaseUrl}/v1/shared/gallery?nbe=${nbElements}`);
  }


}
