<div class="maincontainer">
  <div class="card">
    <h1 class="errorcode"> DO YOU WANT MORE ? </h1>
    <div class="errortext"> The operation you launched, needed more credits that what you currently have !</div>
    <div class="errortext"> Don't worry, you can still fuel your creativity, by getting new credits immediatly</div>
    <div class="errortext"> You can pick the options, that your prefer hereunder:</div>
    <div class="actions">
      <button mat-raised-button color="primary" (click)="spot()"> <div class="button-text">BUY SPOT CREDITS</div></button>
      <button mat-raised-button color="primary" (click)="upgrade()"> <div class="button-text">UPGRADE MY SUBSCRIPTION</div></button>
      <button mat-raised-button color="warn" (click)="cancel()"> <div class="button-text">NO, THANKS ! </div></button>
    </div>
  </div>
</div>

