<div class="main">

  <div [class]="balance < 20 ? 'action' : 'not-visible'" >
    <button mat-raised-button color="accent" (click)="upgrade()">
      <mat-icon>bolt</mat-icon>UPGRADE </button>
  </div>

<div [class]="balance ? 'finance-container' : 'not-visible'">
  <div class="subscription"> {{ plan }} plan </div>
  <div class="separator"> </div>
<div class="credits">
  <div [class]=" balance > 10 ? 'asset' : 'danger'"> {{ balance }} </div>
  <div [class]=" balance > 10 ? 'sub-max' : 'danger'">&nbsp;/ {{ monthly_credits }} </div>
  <mat-icon [class]=" balance > 10 ? 'coins' : 'coins danger'" >award_star</mat-icon>
</div>
</div>
</div>
