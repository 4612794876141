import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { StatValueDto } from "../shared/entity/stat-value-dto";
import { Picture } from "../shared/entity/picture";

@Injectable({
  providedIn: 'root'
})
export class StatService {

  apiBaseUrl : string;

  constructor(private http: HttpClient) {
    this.apiBaseUrl =  environment.apiUrl;
  }

  getPicturesLast30Days(): Observable<StatValueDto>  {

    return this.http.get<StatValueDto>(`${this.apiBaseUrl}/v1/stat/pictures/last`);
  }

  getPicturesOverall(): Observable<StatValueDto>  {
    return this.http.get<StatValueDto>(`${this.apiBaseUrl}/v1/stat/pictures/alltime`);
  }

  getLastFavorites(nb: number): Observable<Picture[]>  {
    return this.http.get<Picture[]>(`${this.apiBaseUrl}/v1/stat/pictures/favorites?n=${nb}`);
  }

  getCommunityImages(nb: number): Observable<Picture[]>  {
    return this.http.get<Picture[]>(`${this.apiBaseUrl}/v1/stat/pictures/community?n=${nb}`);
  }


  getLastProduced(nb: number): Observable<Picture[]>  {
    return this.http.get<Picture[]>(`${this.apiBaseUrl}/v1/stat/pictures/produced?n=${nb}`);
  }

}
