<div class="container">
  <div class="message">
    Please edit your nickname here:
  </div>
  <div class="price">
    <form [formGroup]="form">
      <mat-form-field class="form-element">
        <mat-label>NICKNAME</mat-label>
        <input matInput type="string" formControlName="nickname">
      </mat-form-field>

    </form>

  </div>
  <div class="actions">
    <button mat-raised-button color="primary" class="ok" (click)="submit()"> OK </button>
    <button mat-raised-button color="warn" class="cancel" (click)="onCancel()"> CANCEL </button>
  </div>
</div>
