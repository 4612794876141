<div class="maincontainer">
  <div class="card">
    <h1 class="errorcode"> YOU GOT US ! </h1>
    <div class="errortext"> Something wrong happened !</div>
    <div class="errortext"> We are very sorry but our engineers have been alerted and are on it !</div>
    <div class="errortext"> But of course, our support will be glad to help you, if need be !</div>
    <div class="actions">
      <button mat-raised-button color="primary" (click)="home()">HOME</button>
      <a mat-raised-button color="warn" href="mailto:support@nzym.io" > CONTACT SUPPORT</a>
    </div>
  </div>
</div>

