<!-- topbar -->
<mat-toolbar color="primary" *ngIf="accountService.isAuthenticated()">
  <button mat-icon-button (click)="drawer.toggle()" class="">
    <mat-icon>menu</mat-icon>
  </button>
  <span class="icon-nzym"><a routerLink='home'><img height="38px" src="../assets/img/nzym_logo_full_white.png">
    </a></span>
  <span class="toolbar-right">
    <app-credit></app-credit>
    <img src="{{accountService.picture}}" class="profile-picture" referrerpolicy="no-referrer"
      [matMenuTriggerFor]="menu">
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="profile()"><mat-icon color="primary">account_circle</mat-icon> MY PROFILE </button>
      <button mat-menu-item class="" aria-label="Logout" routerLink="logout"><mat-icon color="primary">logout</mat-icon>
        LOGOUT </button>
    </mat-menu>
  </span>
</mat-toolbar>


<!-- sidenav -->
<mat-drawer-container class="drawer-container" autosize>
  <mat-drawer #drawer mode="side" [class.mat-elevation-z4]="true" class="app-sidenav">

    <mat-nav-list>
      <div *ngFor="let item of menuItems; let index = index; let isFirst = first;">

        <!-- FIRST ALWAYS TITLE (ONCE)-->
        <mat-list-item *ngIf="isFirst" (click)="drawer.toggle()"> <span class="title"> FEATURES
          </span>
        </mat-list-item>

        <div class="separator" *ngIf="item.rank==6 || item.rank==11"></div>

        <!-- TITLE (ONCE)-->
        <mat-list-item *ngIf="item.rank==6" (click)="drawer.toggle()">
          <span class="title">SUPPORT & INFO
          </span>
        </mat-list-item>

        <a href="{{item.linkUrl}}" target="_blank" class="external-link">
        <mat-list-item *ngIf="item.visible && item.linkUrl" (click)="drawer.toggle()">
          <span class="section">
            <img src="{{item.iconUrl}}" class="menu-icon-png">
           {{ item.label.toUpperCase()}}
          </span>
        </mat-list-item>
        </a>

        <!-- TITLE (ONCE)-->
        <mat-list-item *ngIf="item.rank==11 && admin" (click)="drawer.toggle()">
          <span class="title"> ADMIN FEATURES
        </span>
      </mat-list-item>

        <mat-list-item *ngIf="item.visible && item.link" routerLink="{{item.link}}" (click)="drawer.toggle()">
          <span class="section">
            <mat-icon class="menu-icon" color="primary">{{item.icon}}</mat-icon>
            {{ item.label.toUpperCase()}}
          </span>
        </mat-list-item>

      </div>
    </mat-nav-list>
    <div class="menu-footer">
      <div class="footer-content">
        <div class="version">Version 0.9.1</div>
        <div class="terms"><a href="https://www.nzym.io/tos.html" target="_blank">Terms of Use</a></div>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <!-- <div *ngIf="!displayIfAuth" class="spinner-container"><mat-spinner ></mat-spinner></div>-->
    <router-outlet>
    </router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
