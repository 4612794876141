<div *ngIf="!loaded" class="spinner-container"><mat-spinner></mat-spinner></div>

<div *ngIf="loaded" class="cards">

  <!--  COLUMNS CDK VIRTUAL BUFFERING            -->

  <mat-card class="mat-elevation-z0 single-card pictures-card-column" [style.background]="'url('+background_img+')'">
    <cdk-virtual-scroll-viewport itemSize="40" class="pictures-viewport">
      <mat-card-content [class]="displayColumn ? 'pictures-column' : 'not-visible'" (window:resize)="adaptTable()">
        <div class="pics-container-column" *cdkVirtualFor="let column of sourceFlexColumns; trackBy: columnTrack">
          @for (tile of column; track tile.pic.guid; let idx = $index ) {

          <div class="genbox" [class]="getRatioStyle(tile.pic.img_ratio)" (click)="display(tile)">
            <img [class]="getRatioStyle(tile.pic.img_ratio)" src="{{ tile.pic.thumb_uri }}">
          </div>
          }

        </div>
      </mat-card-content>
    </cdk-virtual-scroll-viewport>
  </mat-card>

</div>
