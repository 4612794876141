<div *ngIf="!loaded" class="spinner-container"><mat-spinner></mat-spinner></div>

<div class="filter">
  <mat-form-field class="searchfield">
    <input matInput type="text" (keyup)="doFilter($event)">
    <mat-label>Search & Filter </mat-label>
    <mat-icon matSuffix>filter_list</mat-icon>
    <mat-hint>Filter applies to all columns</mat-hint>
  </mat-form-field>

</div>

<table [hidden]="!loaded" mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
  matSortActive="name" matSortDirection="asc" class="mat-elevation-z8">

  <!--  time Column -->
  <ng-container matColumnDef="time">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by time">
      TIME
    </th>
    <td mat-cell *matCellDef="let element"> {{element.date}} </td>
  </ng-container>

  <!--  userEmail Column -->
  <ng-container matColumnDef="user_email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by userEmail">
      USER EMAIL
    </th>
    <td mat-cell *matCellDef="let element"> {{element.user_email}} </td>
  </ng-container>

  <!-- level Column -->
  <ng-container matColumnDef="level">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by level">
      CRITICITY LEVEL
    </th>
    <td mat-cell *matCellDef="let element"> {{element.level }} </td>
  </ng-container>

  <!-- ip Column -->
  <ng-container matColumnDef="ip">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ip">
      IP
    </th>
    <td mat-cell *matCellDef="let element"> {{element.ip }} </td>
  </ng-container>

  <!-- raw_prompt Column -->
  <ng-container matColumnDef="raw_prompt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by raw_prompt">
      raw_prompt
    </th>
    <td mat-cell *matCellDef="let element"> {{element.raw_prompt}} </td>
  </ng-container>

  <!-- action Column  -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>
      <span>ACTIONS</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="icons">
        <button mat-icon-button class="action-button" (click)="seePictures(element.user_email)"><mat-icon
            [color]="'primary'" alt="IMAGES">photo_library </mat-icon></button>
        <button mat-icon-button class="action-button" aria-label="open user account"
          (click)="openUser(element.user_email)"><mat-icon [color]="'primary'" alt="USER">account_circle</mat-icon></button>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<div [hidden]="!loaded">
  <mat-paginator [pageSize]="50" [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons aria-label="Select page of samples" class="paginator">
  </mat-paginator>
</div>
