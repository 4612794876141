import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionService } from '../services/subscription.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { UserService } from '../services/user.service';
import { AccountService } from '../services/account.service';
import { Subscription } from '../shared/entity/subscription';
import { User } from '../shared/entity/user';
import { PaymentCardComponent } from '../shared/component/stripe-card/payment-card.component';
import { DatePipe } from '@angular/common';
import { CreditService } from '../services/credit.service';
import { environment } from '../../environments/environment';
import { SpotOffer } from '../shared/entity/spot-offer';
import { SpotBuyService } from '../services/spot-buy.service';

declare let gtag: Function;

@Component({
  selector: 'app-pay-spot',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatListModule,
    MatIconModule,
    FormsModule,
    NgIf,
    PaymentCardComponent,
    MatCheckboxModule,
  ],
  providers: [DatePipe],
  templateUrl: './pay-spot.component.html',
  styleUrl: './pay-spot.component.scss',
})
export class PaySpotComponent {
  readyToDisplay: boolean;
  offer: SpotOffer;
  user: User;

  hasCard: boolean;
  hasCardValid: boolean;
  updateCard: boolean;
  submitDisabled: boolean;
  showSubmitActions: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private accountService: AccountService,
    private userService: UserService,
    private creditService: CreditService,
    private spotBuyService : SpotBuyService,
    public datepipe: DatePipe,
    private _snackbar: MatSnackBar
  ) {

  }

  ngOnInit() {

    this.readyToDisplay = false;
    this.hasCard = false;
    this.hasCardValid = false;
    this.updateCard = false;
   // this.consentChecked = false;
    this.submitDisabled = false;
    this.showSubmitActions = true;

    let guid = this.route.snapshot.params['guid'];

    this.spotBuyService.get(guid).subscribe((offer) => {
      this.offer = offer;

    });

    this.userService
      .getPerEmail(this.accountService.email)
      .subscribe((user) => {
        this.user = user;
        if (this.user.data.cardLast4) {
          this.hasCard = true;
          this.hasCardValid = this.valid(
            this.user.data.cardExpMonth,
            this.user.data.cardExpYear
          );
        } else {
          this.updateCard = true;
          this.submitDisabled = true;
        }

        this.readyToDisplay = true;

      });
  }

  changeCard() {
    this.updateCard = true;
    this.submitDisabled = true;
    this.showSubmitActions = false;
  }

  manageCardFeedback(event) {

    if (event == 'ok') {
      this.updateCard = false;
      this.showSubmitActions = true;

      this.userService
        .getPerEmail(this.accountService.email)
        .subscribe((user) => {
          this.user = user;
          if (this.user.data.cardLast4) {
            this.hasCard = true;
            this.hasCardValid = this.valid(
              this.user.data.cardExpMonth,
              this.user.data.cardExpYear
            );
            this.submitDisabled = false;
          }
        });
    }
  }

  order() {

    this.submitDisabled = true;

    this.spotBuyService.buy(this.offer).subscribe((res) => {

      this._snackbar.open('Successfully purchased '+this.offer.nb_of_credits+' credits !', '', {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 1000,
        panelClass: ['green-snackbar'],
      });

      this.creditService.update();

      const gtmTag = {
        value: res.amount,
        currency: res.currency,
        success: res.success,
      };

      gtag('config', environment.gaId);
      gtag('consent', 'update', this.accountService.consent.google);
      gtag('event', 'pay_spot', gtmTag);

      this.router.navigate(['user-detail', this.user.metadata.guid]);
    });
  }

  valid(month, year): boolean {
    let date: Date = new Date();
    date.setMonth(month + 1);
    date.setFullYear(year);

    let now: Date = new Date();

    if (date >= now) return true;
    else return false;
  }

  sanitizeCardType() {
    if (this.user.data.cardBrand) return this.user.data.cardBrand.toUpperCase();
    else return 'None';
  }

  sanitizeCardNumber() {
    if (this.user.data.cardLast4)
      return 'XXXX-XXXX-XXXX-' + this.user.data.cardLast4.toUpperCase();
    else return 'None';
  }

  sanitizeExpiry() {
    if (this.user.data.cardExpMonth) {
      let month = '';

      if (+this.user.data.cardExpMonth < 10) {
        month = '0' + this.user.data.cardExpMonth;
      }

      return this.user.data.cardExpMonth + '/' + this.user.data.cardExpYear;
    } else return 'None';
  }

  cancel() {
    this.router.navigate(['nzym-studio']);
  }
}
