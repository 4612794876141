import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCard, MatCardModule } from '@angular/material/card';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { Scene } from '../../shared/entity/scene';
import { SceneService } from '../../services/scene.service';


export interface DialogSelectSceneData {}

@Component({
  selector: 'app-dialog-naming',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCard,
    MatProgressSpinnerModule,
    MatChipsModule
  ],
  templateUrl: './dialog-select-scene.component.html',
  styleUrl: './dialog-select-scene.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DialogSelectSceneComponent {
  form: FormGroup;
  title: string;
  scenes: Scene[];
  filteredScenes: Scene[];
  filter: string;

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DialogSelectSceneComponent>,
    public sceneService: SceneService
  ) {
    this.form = this.formBuilder.group({
      filter: ['']
    });
  }

  ngOnInit():void {
    this.title = 'SELECT A SCENE TO INFLUENCE YOUR CREATION';

    this.sceneService.getAll().subscribe((res)=>{

      this.scenes = res;
      this.filteredScenes = this.scenes.slice();

    });
  }

  onChange(input) {

    let filter = input.target.value;

    let filteredArray:Scene[] = [];

    this.scenes.forEach((scene) => {

      if (scene.name.toUpperCase().indexOf(filter.toUpperCase()) >= 0) {

        filteredArray.push(scene);
       }
    })
    this.filteredScenes = filteredArray;

  }

 selectScene(scene) {
    this.dialogRef.close(scene);
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }
}
