<mat-card class="mat-elevation-z0 single-card settings-card">
  <mat-cart-header class="mat-card-header form-box-title">
    <mat-card-title> {{ title }} </mat-card-title>
  </mat-cart-header>
  <mat-card-content>

    <div class="container">
      @for (pic of pictures; track pic) {
      <div class="container-element" (click)="selectPic(pic)">
        <div class="pic-box">
          <img src="{{ pic }}" class="pic-box-image">
        </div>
      </div>
      }
    </div>

  </mat-card-content>
</mat-card>

