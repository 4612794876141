import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule, MatDialog } from '@angular/material/dialog';
import { DialogImageDreamboothComponent } from '../../avatar-face-generator-ref/dialog-image-dreambooth/dialog-image-dreambooth.component';
import { Consent } from '../../shared/entity/consent';
import { GoogleConsent } from '../../shared/entity/consent-google';
import { MatButtonModule } from '@angular/material/button';
import { DialogOptionsComponent } from './dialog-options/dialog-options.component';

interface DialogData {

  consent: Consent;

}

@Component({
  selector: 'app-dialog-consent',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule],
  templateUrl: './dialog-consent.component.html',
  styleUrl: './dialog-consent.component.scss'
})
export class DialogConsentComponent {


  constructor(
    public dialogRef: MatDialogRef<DialogImageDreamboothComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogOptions: MatDialog,
  ) {}


  ngOnInit() {

  }

  onCancel() {

  //  this.dialogRef.close();

  }

  acceptAll() {

    let consent : Consent = new Consent();
    let googleConsent = new GoogleConsent();

    googleConsent.ad_personalization = 'granted';
    googleConsent.ad_storage = 'granted';
    googleConsent.ad_user_data = 'granted';
    googleConsent.analytics_storage = 'granted';

    consent.google = googleConsent;

    this.dialogRef.close(consent);

  }

  options() {

    let optionRef = this.dialogOptions.open(DialogOptionsComponent,  {
      height: '240px',
      width: '550px',
      closeOnNavigation: false,
      disableClose: true,
      position: {
        bottom:'0'

        }
    });

    optionRef.afterClosed().subscribe((gConsent: GoogleConsent) => {

      let consent : Consent = new Consent();
      consent.google = gConsent;

      this.dialogRef.close(consent);

    });

  }

  onlyNecessary() {

    let consent : Consent = new Consent();
    let googleConsent = new GoogleConsent();

    googleConsent.ad_personalization = 'denied';
    googleConsent.ad_storage = 'denied';
    googleConsent.ad_user_data = 'denied';
    googleConsent.analytics_storage = 'denied';

    this.dialogRef.close(consent);
  }

}
