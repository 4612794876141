import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCard } from '@angular/material/card';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AvatarActionTextService } from '../../services/avatar-action-text.service';
import { AvatarService } from '../../services/avatar.service';
import { References } from '../../shared/entity/references';
import { PictureService } from '../../services/picture.service';

export interface DialogData {
  guid: string;
  name: string;
  pic: any;
}

export interface Tile {
  img: string;
  loaded: boolean;
  start?: number;
  sec?: number;
  decilesec?: number;
  action?: string;
}

@Component({
  selector: 'app-dialog-image-action',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCard,
    MatProgressSpinnerModule,
  ],
  templateUrl: './dialog-image-action.component.html',
  styleUrl: './dialog-image-action.component.scss',
})
export class DialogImageActionComponent {
  uri: string;
  creationDate: string;
  action: string;
  tile_overlay: Tile;
  hasBeenChanged:boolean;
  guid: string;
  action_text: string;

  constructor(
    public dialogRef: MatDialogRef<DialogImageActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private avatarService: AvatarService,
    private avatarTextActionService: AvatarActionTextService,
    private pictureService: PictureService
  ) {}

  ngOnInit() {

    this.hasBeenChanged = false;
    this.action = this.data.pic.action;
    this.uri = this.data.pic.img;
    this.guid = this.data.guid;
    this.creationDate = new Date().toISOString();
    this.action_text = this.avatarTextActionService.getText(this.action);

    //transparent pixel
    this.tile_overlay = {
      img: '../../../assets/img/1x1-gray.png',
      loaded: true,
      start: new Date().getTime(),
      sec: 0,
      decilesec: 0,
      action: this.action,
    };

    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this.onCancel();
      }
    });

    this.dialogRef.backdropClick().subscribe((event) => {
      this.onCancel();
    });
  }

  onCancel(): void {
    if (this.hasBeenChanged) {

      let tile = {
        img : this.uri,
        action : this.action
      };

      this.dialogRef.close(tile);
    }
  }

  download(): void {

    this.pictureService.downloadImage(this.uri);
  }

  edit(): void {

  }

  initOverlay(tile: Tile) {
    tile.loaded = false;
    tile.sec = 0;
    tile.decilesec = 0;
    tile.start = new Date().getTime();
  }

  regenerate(): void {

    this.initOverlay(this.tile_overlay);
    this.chrono(this.tile_overlay);

    let references = new References();
    //references.enc_p = sessionStorage.getItem('gen.init.enc_p');
    //references.enc_r = sessionStorage.getItem('gen.init.enc_r');
    references.action = this.action;
    references.avatar_guid = this.guid;
    references.target = 'LORA';

    this.avatarService.regenDreamboothSinglePosture(references).subscribe((pic) => {

      this.tile_overlay.start = 1000000000000000000000000000000;
      //assign the value coming from the server
      this.uri = pic.thumb_uri;
      this.tile_overlay.loaded = true;
      this.hasBeenChanged = true;
    });
  }

  //************** HELPER

  chrono(tile) {
    // Update the chornometer every 1/100 second
    var x = setInterval(function () {
      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = now - tile.start;

      // Time calculations for days, hours, minutes and seconds
      tile.sec = Math.floor(distance / 1000);
      tile.decilesec = Math.floor((distance % 1000) / 10);

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
      }
    }, 10);
  }
}
