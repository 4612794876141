import { Component } from '@angular/core';
import { CustomAuthService } from '../services/custom-auth.service';
import { AccountService } from '../services/account.service';
import { compileNgModule } from '@angular/compiler';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { CookieService } from 'ngx-cookie-service';
import { User } from '@auth0/auth0-angular';

//import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-login-button',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  constructor(
    private accountService: AccountService,
    private userService: UserService,
    private cookieService: CookieService,
    private router: Router,
    private auth: CustomAuthService
  ) {}


  ngOnInit() {

    this.auth.signIn();

  }

}
