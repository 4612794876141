<mat-card class="mat-elevation-z0 single-card fav-card">
  <mat-cart-header class="mat-card-header form-box-title">
    <mat-card-title><span class="card-title"> COMMUNITY WALL </span></mat-card-title>
    <mat-card-title><span class="card-subtitle"> Get inspired by images & prompts </span></mat-card-title>
  </mat-cart-header>

  <mat-card-content class="setting-content" [style.background]="'url('+background_img+')'">

    <div class="spinner" *ngIf="!loaded"> <mat-spinner [diameter]="100"></mat-spinner></div>

    <div class="gallery" *ngIf="loaded">
      <cdk-virtual-scroll-viewport itemSize="40" class="gallery-viewport">

        <div class="gallery-content">
          <!-- ***-->
          @for (item of items ; track item.guid; let idx = $index) {
          <div class="image">
            <img src="{{ item.thumb_uri || item.img_uri }}" (click)="open(idx)">

            <div class="overlay-actions">
              <div class="image-creator">
                <img class="image-profile" src="{{ item.creator_img_uri }}" />
                <div class="image-creator"> {{ item.creator_nickname }}</div>
              </div>
            </div>
          </div>
          }
          <!-- ***-->
        </div>
      </cdk-virtual-scroll-viewport>
    </div>

  </mat-card-content>
</mat-card>
