import { LiveAnnouncer } from '@angular/cdk/a11y';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  Inject,
  LOCALE_ID,
  ViewChild,
} from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { Router } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, AsyncPipe, formatDate } from '@angular/common';
import { User } from '../shared/entity/user';
import { UserService } from '../services/user.service';

export interface UserElement {
  user_guid?: string;
  created_at?: string;
  email?: string;
  family_name?: string;
  given_name?: string;
  last_ip?: string;
  last_login?: string;
  logins_count?: number;
  name?: string;
  role?: string;
  nickname?: string;
  picture?: string;
  updated_at?: string;
  nb_of_avatars?:number;
}

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  standalone: true,
  imports: [
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatInputModule,
    MatChipsModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatCardModule,
    RouterModule,
    MatProgressSpinnerModule,
    NgIf,
    AsyncPipe,
  ]
})
export class UserListComponent {
  //displayedColumns: string[] = ['id', 'fullname', 'username','role', 'creationDate', 'active', 'action', 'add'];
  displayedColumns: string[] = [
    'picture',
    'email',
    'fullname',
    'role',
    'created_at',
    'last_login',
    'nb_of_avatars',
    'logins_count',
    'action',
    'add',
  ];

  ELEMENT_DATA: UserElement[];
  dataSource;
  loaded: boolean = false;
  totalAdmins = 0;
  totalUsers = 0;
  nbActive = 0;
  nbInactive = 0;

  constructor(
    private userService: UserService,
    private _liveAnnouncer: LiveAnnouncer,
    private router: Router,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.ELEMENT_DATA = [];
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.userService.getAll().subscribe((res: User[]) => {

       res.forEach((val) => {
        var crea = new Date(0);
        crea.setUTCSeconds(val.metadata.creationEpoch);

        var upda = new Date(0);
        upda.setUTCSeconds(val.metadata.modificationEpoch);

        var last = new Date(0);
        last.setUTCSeconds(new Date(val.data.last_login).getTime() / 1000);

        let user: UserElement = {};
        user.user_guid = val.metadata.guid;
        user.created_at = formatDate(
          crea,
          'YYYY-MM-dd',
          this.locale
        ).toString();
        user.email = val.data.email;
        user.family_name = val.data.family_name;
        user.given_name = val.data.given_name;
        if (val.data.last_login)
          user.last_login = formatDate(
            last,
            'YYYY-MM-dd',
            this.locale
          ).toString();
        else user.last_login = '-';
        user.last_ip = val.data.last_ip;
        user.logins_count = val.data.logins_count || 0;
        user.name = val.data.name;
        user.role = val.data.roles[0];
        user.nickname = val.data.nickname;
        user.picture = val.data.picture || '../../assets/img/user.png';
        user.updated_at = formatDate(
          upda,
          'YYYY-MM-dd',
          this.locale
        ).toString();

        if (val.data.active) this.nbActive++;
        else if (!val.data.active) this.nbInactive++;

        if (
          val.data.roles.includes('ADMIN') ||
          val.data.roles.includes('STAFF') ||
          val.data.roles.includes('CUSTOMER_ADMIN')
        )
          this.totalAdmins++;
        else this.totalUsers++;

        user.nb_of_avatars = val.data.nb_of_avatars;

        this.ELEMENT_DATA.push(user);
      });

      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.loaded = true;
    });
  }

  doFilter = (value: any) => {
    this.dataSource.filter = value.target.value.toLocaleLowerCase();
  };

  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  new() {
    this.router.navigate(['user-detail/new']);
  }

  delete(id: number) {
    this.userService.delete(id).subscribe(() => {
      window.location.reload();
    });
  }

  seePictures(guid: string) {

      this.router.navigate(['user-images', guid]);

  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.

    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
}
