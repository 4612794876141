import { CUSTOM_ELEMENTS_SCHEMA, Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgIf } from '@angular/common';
import { AvatarStory } from '../../shared/entity/avatar-story';
import { MatRadioModule } from '@angular/material/radio';

export interface DialogData {
  story: AvatarStory;
}

@Component({
  selector: 'app-edit-avatar',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCardModule,
    NgIf,
  ],
  templateUrl: './dialog-edit-history.component.html',
  styleUrl: './dialog-edit-history.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DialogEditHistoryComponent {
  form: FormGroup;
  male: boolean;
  story: AvatarStory;
  create_mode: boolean;

  constructor(
    public dialogRef: MatDialogRef<DialogEditHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.story = this.data.story;

    //console.log(this.story);

    if (null == this.story) {
      this.create_mode = true;
      this.male = true;
      this.story = new AvatarStory();
      this.story.gender = 'MALE';
      this.story.text = '';
    } else {
      this.create_mode = false;

      if (this.story.gender == 'MALE') {
        this.male = true;
      } else {
        this.male = false;
      }
    }

    this.form = this.formBuilder.group({
      gender: [this.story.gender, Validators.required],
      text: [this.story.text, Validators.required],
    });
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }

  submit() {
    if (this.form.valid) {
      this.story.text = this.form.controls.text.value;
      this.story.gender = this.form.controls.gender.value;
    }

    this.dialogRef.close(this.story);
  }
}
