<mat-card class="mat-elevation-z0 single-card settings-card">
  <mat-cart-header class="mat-card-header form-box-title">
    <mat-card-title> {{ title }} </mat-card-title>
  </mat-cart-header>
  <mat-card-content>

    <div class="container">
      @for (hairstyle of hairstyles; track hairstyle.id) {
      <div class="container-element" (click)="selectHairstyle(hairstyle)">
        <div class="hairstyle-box">
          <img src="{{ hairstyle.img_url}}" class="hairstyle-box-pic">

            <div class="hairstyle-creds">
            {{ hairstyle.gender}} #{{ hairstyle.id}}
          </div>

        </div>
      </div>
      }
    </div>

  </mat-card-content>
</mat-card>

