<div class="maincontainer">
  <div class="card">
    <h1 class="errorcode"> PAGE NOT FOUND ! </h1>
    <div class="errortext"> Hey Indie, you end up in an unknown page...</div>
    <div class="errortext"> We are very sorry but do not worry, you can stop your exploration of this mysterious page !</div>
    <div class="errortext"> Maybe one day, there will be a treasure here !</div>
    <div class="actions">
      <button mat-raised-button color="primary" (click)="home()">HOME</button>
    </div>
  </div>
</div>

