import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { Picture } from '../../shared/entity/picture';
import { MatIconModule } from '@angular/material/icon';


export interface DialogData {
  original: Picture,
  pics: Picture[],
  start_index: number
}

@Component({
  selector: 'app-dialog-custom-lightbox',
  standalone: true,
  imports: [
    MatIconModule
    ],
  templateUrl: './dialog-custom-lightbox.component.html',
  styleUrl: './dialog-custom-lightbox.component.scss',
})
export class DialogCustomLightboxComponent {

  index: number;
  original: Picture;
  inpaintedPics: Picture[];
  text : string[];

  keyPressed = (e) => {

    if (e.isComposing || e.code === 'ArrowLeft') {
      this.goLeft();
    }
    if (e.isComposing || e.code === 'ArrowRight') {
      this.goRight();
    }
    e.stopPropagation();
  }



  constructor(
    public dialogRef: MatDialogRef<DialogCustomLightboxComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {

    this.index = 0;

  }

  ngOnInit() {

      this.index = this.data.start_index;
      this.original = this.data.original;
      this.inpaintedPics = this.data.pics.slice();
      this.text = ['ORIGINAL', 'PROPOSAL #1', 'PROPOSAL #2'];

      this.inpaintedPics.unshift(this.original);

       }

  ngAfterViewInit() {

    var _this = this;

    document.addEventListener(
      'keydown', _this.keyPressed
    );

  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.keyPressed);

  }

  onCancel(): void {
      this.dialogRef.close(null);
  }

  goLeft() {
    if (this.index > 0)
      this.index--;
  }

  goRight() {
    if (this.index < this.inpaintedPics.length-1)
      this.index++;
  }

}
