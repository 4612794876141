import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Avatar } from '../../shared/entity/avatar';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

export interface DialogData {
  text: string;
  avatar: Avatar;
}

@Component({
  selector: 'app-dialog-review-price',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSlideToggleModule
  ],
  templateUrl: './dialog-review-price.component.html',
  styleUrl: './dialog-review-price.component.scss'
})
export class DialogReviewPriceComponent {

  text: string;
  avatar: Avatar;
  form : FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogReviewPriceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.text = this.data.text;
    this.avatar = this.data.avatar;

    this.form = this.formBuilder.group({
      sales_price: [this.avatar.data.sales_price, [Validators.required, Validators.min(0)]],
      exclusive: [this.avatar.data.exclusive, [Validators.required]],
      max_sales: [this.avatar.data.max_sales, [Validators.required, Validators.min(0)]],
    });
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }

  submit() {
    if (this.form.valid) {
      this.avatar.data.sales_price = this.form.controls.sales_price.value;
      this.avatar.data.exclusive = this.form.controls.exclusive.value;
      this.avatar.data.max_sales = this.form.controls.max_sales.value;
      this.avatar.data.on_sale = true;
      this.dialogRef.close(this.avatar);
  }

  }

}
