<mat-card class="mat-elevation-z0 single-card gallery-card">
  <mat-cart-header class="mat-card-header form-box-title">
    <mat-card-title><span class="card-title"> AVATAR GALLERY </span></mat-card-title>
    <mat-card-title><span class="card-subtitle"> AVAILABLE FOR YOU TO CREATE </span></mat-card-title>
  </mat-cart-header>

  <mat-card-content class="setting-content" [style.background]="'url('+background_img+')'" >

    <div class="commands">

      <mat-form-field class="filter">
        <mat-label>Filter by name</mat-label>
        <input matInput placeholder="Katharine..." [(ngModel)]="filterField" (keyup)="filter($event)">
      </mat-form-field>

      <div class="tag-filter">

        <div class="filter-element align">
          <div class="title">FILTER BY TYPE</div>
        </div>

        <div class="filter-element">
          <div class="element-texts">
            <div class="element-name" [class]=" filterFreeToggle ? 'free-use' : 'deactivated'"  (click)="filterFree()"> FREE USE FOR ALL</div>
            <div class="element-check"></div>
          </div>
          <div class="element-description"> </div>
        </div>
        <div class="filter-element">
          <div class="element-texts">
            <div class="element-name" [class]=" filterPrivateToggle ? 'private' : 'deactivated'" (click)="filterPrivate()"> <span *ngIf="!accountService.isAdmin()">YOUR </span> <span *ngIf="accountService.isAdmin()">ALL PRIVATE </span> CREATIONS </div>
            <div class="element-check"> </div>
          </div>
          <div class="element-description">  </div>

        </div>
        <div class="filter-element">
          <div class="element-texts">
            <div class="element-name" [class]=" filterSaleToggle ? 'on-sale' : 'deactivated'" (click)="filterSale()"> NOW ON SALE </div>
            <div class="element-check"></div>
          </div>
          <div class="element-description">  </div>
        </div>
        <div class="filter-element" *ngIf="!accountService.isAdmin()">
          <div class="element-texts">
            <div class="element-name" [class]=" filterPurchasedToggle ? 'purchased' : 'deactivated'" (click)="filterPurchased()"> YOUR ACQUISITIONS </div>
            <div class="element-check"></div>
          </div>
          <div class="element-description">  </div>
        </div>
      </div>
    </div>

    <div class="interlign"></div>

    <div class="gallery">
      <cdk-virtual-scroll-viewport itemSize="40" class="gallery-viewport">

        <div class="gallery-content">
          <!-- ***-->
          @for (avatar of filteredAvatarsFinal ; track avatar.metadata.guid; let idx = $index) {


   <!--       public : {{ avatar?.data.public_access }} <br>
            on_sale :  {{ avatar?.data.on_sale }}<br>
            onwed: {{ avatar?.data.is_owned_by_user }} <br>
-->
          <div class="image" (click)="openProfile(idx)">
            <div class="overlay-status"
              [class]="avatar?.data.on_sale ? ( avatar?.data.is_owned_by_user ? ( avatar?.data.link_origin=='CREATION' ? 'status-private' : 'status-purchased' ): 'status-sale' ): avatar?.data.public_access ? 'status-public' : 'status-private'">
              {{ avatarStatus(avatar) }}
            </div>
            <div class="overlay-price" *ngIf="avatar?.data.on_sale && !avatar?.data.is_owned_by_user">
              {{ avatar?.data.sales_price }} credits
            </div>
            <div class="overlay-actions" *ngIf="avatar?.data.on_sale && !avatar?.data.is_owned_by_user">
              <mat-icon matTooltip="Buy and use it as your exclusive avatar" (click)="buy($event, idx)"
                [matTooltipPosition]="'right'">shopping_cart</mat-icon>
            </div>
            <div class="overlay-info">
              {{ avatar?.data.given_name.toUpperCase() }} {{ avatar?.data.family_name.toUpperCase() }}
            </div>
            <img src="{{ avatar?.data.marketing_profile_picture_thumb_uri }}">
          </div>
          }
          <!-- ***-->
        </div>
      </cdk-virtual-scroll-viewport>
    </div>

  </mat-card-content>
</mat-card>
