import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-subscription',
  templateUrl: './error-subscription.component.html',
  styleUrls: ['./error-subscription.component.scss'],
  standalone: true,
  imports: [ MatButtonModule ]
})
export class ErrorSubscriptionComponent {

  constructor(
    private router: Router,
  ) {
  }

    home() {
      this.router.navigate(['home']);
    }

    support() {
      this.router.navigate(['support']);
    }

}
