import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import { AccountService } from '../services/account.service';
import { CreditService } from '../services/credit.service';
import { Balance } from '../shared/entity/balance';
import { StatService } from '../services/stat.service';
import { Picture } from '../shared/entity/picture';
import { MatDialog } from '@angular/material/dialog';
import { DialogCommunityImageHomeComponent  } from './dialog-community-image/dialog-community-image-home.component';
import { AvatarService } from '../services/avatar.service';
import { Avatar } from '../shared/entity/avatar';
import { Router } from '@angular/router';
import { CommunityPicture } from '../shared/entity/community-picture';
import { DialogImageHomeComponent } from './dialog-image/dialog-image-home.component';
import { UserService } from '../services/user.service';
import { User } from '../shared/entity/user';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent {

  displayHome = false;
  balance: Balance;
  picLast30: string;
  picOverall: string;
  pushedAvatars : Avatar[];

  lastProduced: Picture[];
  lastFavorites: Picture[];
  lastCommunities : Picture[];

  constructor(
    private accountService: AccountService,
    private avatarService: AvatarService,
    private creditService: CreditService,
    private userService: UserService,
    private statService: StatService,
    private router: Router,
    public dialog: MatDialog,
  ) {

  }

  ngAfterViewInit() {

  }


  ngOnInit() {

    let nbImages = 4;
    let nbAvatars = 4;

    if (window.innerWidth <= 860) {
      nbImages = 4;
      nbAvatars = 4;
    } else if (window.innerWidth <= 1150) {
      nbImages = 6;
      nbAvatars = 6;
    } else if (window.innerWidth <= 1420) {
      nbImages = 8;
      nbAvatars = 8;
    } else if (window.innerWidth <= 1700) {
      nbImages = 10;
      nbAvatars = 10;
    } else if (window.innerWidth <= 1980) {
      nbImages = 12;
      nbAvatars = 12;
    } else if (window.innerWidth <= 2250) {
      nbImages = 14;
      nbAvatars = 14;
    } else {
      nbImages = 28;
      nbAvatars = 8;
    }

//    console.log("pouet");
//    console.log(this.accountService.consent);

    //*********************  ban live

    this.userService.getPerEmail(this.accountService.email).subscribe({
      next: (user: User) => {

        //console.log(user);

        if (user.data.banned == true) {
          this.accountService.logout(true);
          this.router.navigate(['ban']);
        }
      },
      error: (error) => {
        this.accountService.logout(false);
      },
    });

    //*********************


    if (this.accountService.isAuthenticated()) {
      this.displayHome=true;
    }

    this.creditService?.get().subscribe( (balance)=> {

      this.balance = balance;

    });

    this.statService.getPicturesLast30Days().subscribe((dto) => {

      this.picLast30 = dto.value;

    });

    this.statService.getPicturesOverall().subscribe((dto) => {

      this.picOverall = dto.value;

    });

    this.statService.getCommunityImages(nbImages).subscribe((dtos) => {

      this.lastCommunities = dtos;

    });

      /*
    this.statService.getLastProduced(nbImages).subscribe((dtos) => {

      this.lastProduced = dtos;

    });


    this.statService.getLastFavorites(nbImages).subscribe((dtos) => {

      this.lastFavorites = dtos;

    });
*/

    this.avatarService.getAvailableAvatars(nbAvatars).subscribe((avatars) => {
      this.pushedAvatars = avatars;
    })

  }

  avatarStatus(avatar: Avatar) {
    if (avatar.data.public_access) {
      return 'FREE USE';
    } else {
      if (avatar.data.on_sale && !avatar.data.is_owned_by_user) {
        return 'ON SALE';
      } else {
        if (avatar.data.link_origin == 'PURCHASE') return 'PURCHASED';
        else {
        if (this.accountService.isAdmin()) {
            return 'CUSTOM CREATION';
        } else
            return 'YOUR CREATION';
      }
      }
    }
  }


  openAvatar(guid:string) {
    this.router.navigate(['/avatar-profile/'+guid]);
  }

  studio() {
    this.router.navigate(['/nzym-studio']);
  }

  community() {
    this.router.navigate(['/community']);
  }

  openCommunityImage(image: CommunityPicture, index: number) {

    let dataConfig = {
      data: { name: 'IMAGE',  images: this.lastCommunities, index: index },
      maxWidth: '95vw',
      maxHeight: '99vh',
      autoFocus: false,
    }

    this.dialog.open(DialogCommunityImageHomeComponent, dataConfig);

  }

  openProductedImage(image: Picture, index: number) {

    let dataConfig = {
      data: { name: 'IMAGE',  images: this.lastProduced, index: index },
      maxHeight: '98vh',
      minWidth: '400px',
      maxWidth: '1024px',
      autoFocus: false,
    }

    this.dialog.open(DialogImageHomeComponent, dataConfig);

  }

}


