import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
import { Consent } from '../shared/entity/consent';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root',
})
export class AccountService {
  //********** SOURCE JWT
  idtoken: string;
  email: string;
  email_verified: boolean;
  iss: string;
  aud: string;
  iat: number;
  exp: number;
  sub: string;
  sid: string;
  scp: string[];  //scope for roles

  //*********** SOURCE NZYM
  picture: string;
  guid: string;
  consent: Consent;

  constructor(
    private jwtHelper: JwtHelperService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    let jwt = localStorage.getItem('id_token');
    //console.log(jwt);
  }

  isAuthenticated(): boolean {
    if (this.idtoken) {
      return true;
    } else {
      return false;
    }
  }

  hasRoleAssigned(): boolean {
    if (null != this.scp && null!=this.scp[0]) {
      return true;
    } else {
      return false;
    }
  }

  isAdmin(): boolean {

    //console.log('hasRole='+ this.hasRoleAssigned());
    //console.log('isAdmin= '+this.scp);

    if (null != this.scp && null!=this.scp[0] && this.scp[0]=='ADMIN') {
      return true;
    } else {
      return false;
    }
  }


  setIdToken(jwt: string) {
    this.idtoken = jwt;
    this.email = this.jwtHelper.decodeToken(jwt).email;
    this.email_verified = this.jwtHelper.decodeToken(jwt).email_verified;
    this.iss = this.jwtHelper.decodeToken(jwt).iss;
    this.aud = this.jwtHelper.decodeToken(jwt).aud;
    this.exp = this.jwtHelper.decodeToken(jwt).exp;
    this.sub = this.jwtHelper.decodeToken(jwt).sub;
    this.sid = this.jwtHelper.decodeToken(jwt).sid;
    this.scp = this.jwtHelper.decodeToken(jwt).scp;

  }

  setPicture(picture: string) {
    this.picture = picture;
  }

  setGuid(guid: string) {
    this.guid = guid;
  }

  setConsent(consent: Consent) {
    this.consent = consent;
  }

  logout(bye:boolean) {
    this.idtoken = null;
    this.email = null;
    this.email_verified = null;
    this.iss = null;
    this.aud = null;
    this.exp = null;
    this.sub = null;
    this.sid = null;
    this.scp = null;

    this.cookieService.deleteAll();

    this.cookieService.deleteAll( "*", environment.nzymDomain, null,  'Lax');
    this.cookieService.delete("JSESSIONID", environment.nzymDomain);

    //console.log(this.cookieService.getAll());
    localStorage.setItem('id_token', "");
    localStorage.removeItem('id_token');

    if (bye)
      sessionStorage.setItem('bye', 'true');
  }
}
