import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../environments/environment';
import { PaymentMethod } from '../shared/entity/payment-method';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  apiBaseUrl : string;

  constructor(private http: HttpClient) {
    this.apiBaseUrl =  environment.apiUrl;
  }

  getSetupIntent(): Observable<any> {
    return this.http.get<any>( `${this.apiBaseUrl}/v1/payment/setup`);

  }

  sendNewPaymentMethod(pm: string) : Observable<void> {

    let newPm = new PaymentMethod();
    newPm.payment_method = pm;

    return this.http.post<void>( `${this.apiBaseUrl}/v1/payment/method`, newPm);

  }
}
