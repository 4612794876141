import { LiveAnnouncer } from '@angular/cdk/a11y';
import { NgIf, formatDate } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  Inject,
  LOCALE_ID,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router, RouterModule } from '@angular/router';
import { AvatarService } from '../services/avatar.service';
import { Avatar } from '../shared/entity/avatar';
import { AccountService } from '../services/account.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface AvatarElement {
  guid?: string;
  img_uri?: string;
  public_access?: boolean;
  on_sale?:boolean;
  has_been_sold_at_least_once?:boolean;
  family_name?: string;
  given_name?: string;
  age?: number;
  ethnicity?: string;
  hair_color?: string;
  hair_style?: string;
  eye_color?: string;
  active?: boolean;
  in_creation?:boolean;
  in_regeneration?:boolean;
}

@Component({
  selector: 'app-avatar-list',
  standalone: true,
  imports: [
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatChipsModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatDividerModule,
    MatCardModule,
    RouterModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    NgIf,
  ],
  templateUrl: './avatar-list.component.html',
  styleUrl: './avatar-list.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AvatarListComponent {
  displayedColumns: string[] = [
    'image',
    'name',
    'publicAccess',
    'age',
    'ethnicity',
    'eye',
    'active',
    'on_sale',
    'sold',
    'action',
    'add',
  ];

  dataSource;
  loaded: boolean = false;
  ELEMENT_DATA: AvatarElement[];
  role: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort | any;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private _snackbar: MatSnackBar,
    private avatarService: AvatarService,
    private accountService: AccountService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.avatarService.getAll().subscribe((list) => {

      this.ELEMENT_DATA = this.mapDtoToTableElements(list);
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.loaded = true;
    });

    this.role = this.accountService.scp[0];

  }

  canEdit(element: AvatarElement) {

    let isAdmin = this.isAdmin();

    if (isAdmin) {
      return true;
    } else {
      if (element.public_access) {
        return false;
      } else {
        //a non-admin can only edit his own custom avatars
        return true;
      }
    }

  }

  isAdmin() {
    if (this.role=='ADMIN' || this.role=='STAFF') {
        return true;
    }

    return false;
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {

    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  doFilter = (value: any) => {
    this.dataSource.filter = value.target.value.toLocaleLowerCase();
  };

  new() {
    this.router.navigate(['avatar-face-generator-init']);
  }

  activeNumber() {
    let nb: number = 0;
    for (let element of this.ELEMENT_DATA) {
      if (element.active) nb++;
    }
    return nb;
  }

  inactiveNumber() {
    let nb: number = 0;
    for (let element of this.ELEMENT_DATA) {
      if (!element.active) nb++;
    }
    return nb;
  }

  active(element: AvatarElement) {

    this.avatarService.active(element.guid).subscribe((res) => {
       element.active = !element.active;
    });
  }

  regen(element: AvatarElement) {

    element.in_regeneration = true;

    this._snackbar.open('Regen in process !', '', {
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      duration: 2000,
      panelClass: ['green-snackbar'],
    });

    this.avatarService.regenScenes(element.guid).subscribe(()=>{

      this.reloadList();

      this._snackbar.open('Regen '+element.given_name+' '+element.family_name+' done !', '', {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 5000,
        panelClass: ['green-snackbar'],
      });

    });

  }

  generateProfilePicture(element: AvatarElement) {

    this._snackbar.open('Profile Picture in regen !', '', {
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      duration: 2000,
      panelClass: ['green-snackbar'],
    });

    this.avatarService.regenProfilePicture(element.guid).subscribe(()=>{

      this.reloadList();

      this._snackbar.open('Profile Picture '+element.given_name+' '+element.family_name+' done !', '', {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 5000,
        panelClass: ['green-snackbar'],
      });

    });

  }

  dreambooth(element: AvatarElement) {

    element.in_regeneration = true;

    this._snackbar.open('Dreambooth in process !', '', {
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      duration: 2000,
      panelClass: ['green-snackbar'],
    });

    this.avatarService.regenDreamboothAllPostures(element.guid).subscribe(()=>{

      this.reloadList();

      this._snackbar.open('Dreambooth '+element.given_name+' '+element.family_name+' launched in background !', '', {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 5000,
        panelClass: ['green-snackbar'],
      });

    });

  }

  delete(element) {
    this.avatarService.delete(element.guid).subscribe(()=>{
      let index = this.dataSource.data.indexOf(element);
      let removedElementsArray =  this.dataSource.data.splice(index, 1);
      this.dataSource._updateChangeSubscription();

    });
  }

  publicText(access: boolean) {

    if (access) return 'PUBLIC';
    else return 'CUSTOM';
  }

  adaptDisplay(str) {
    if (str) return str.replaceAll('_', ' ');
  }

  reloadList() {

    this.avatarService.getAll().subscribe((list) => {

      this.ELEMENT_DATA = this.mapDtoToTableElements(list);
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;

    });

  }

  mapDtoToTableElements(array: Avatar[]) {
    let result: AvatarElement[] = [];

    array.forEach((element) => {

      let oe: AvatarElement = {};
      oe.guid = element.metadata.guid;
      oe.active = element.data.active;
      oe.on_sale = element.data.on_sale;
      oe.has_been_sold_at_least_once = element.data.has_been_sold_at_least_once;
      oe.age = element.data.age;
      oe.ethnicity = element.data.ethnicity;
      oe.eye_color = element.data.eye_color;
      oe.family_name = element.data.family_name;
      oe.given_name = element.data.given_name;
      oe.hair_color = element.data.hair_color;
      oe.hair_style = element.data.hair_style;
      oe.img_uri = element.data.marketing_profile_picture_thumb_uri;
      oe.public_access = element.data.public_access;
      oe.in_creation = element.data.in_creation;
      oe.in_regeneration = element.data.in_regeneration;

      result.push(oe);
    });

    return result;
  }
}
