import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCard, MatCardModule } from '@angular/material/card';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { Scene } from '../../shared/entity/scene';
import { SceneService } from '../../services/scene.service';


export interface DialogSelectSceneData {}

@Component({
  selector: 'app-dialog-naming',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCard,
    MatProgressSpinnerModule,
    MatChipsModule
  ],
  templateUrl: './dialog-select-profile-picture.component.html',
  styleUrl: './dialog-select-profile-picture.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DialogSelectProfilePictureComponent {
  form: FormGroup;
  title: string;
  pictures = [];

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DialogSelectProfilePictureComponent>,
    public sceneService: SceneService
  ) {
  }

  ngOnInit():void {
    this.title = 'SELECT A PROFILE PIC';

    let maxNumberOfPp = 169;


    for ( let i = 1; i <= maxNumberOfPp; i++) {

      this.pictures.push('../../assets/img/user/'+i+'.jpg');

    }
  }

  selectPic(pic) {
    this.dialogRef.close(pic);
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }
}
