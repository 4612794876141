import { NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AccountService } from '../../../services/account.service';
import { AvatarService } from '../../../services/avatar.service';
import { CreditService } from '../../../services/credit.service';
import { UserService } from '../../../services/user.service';
import { Avatar } from '../../entity/avatar';
import { User } from '../../entity/user';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface DialogData {

  avatar: Avatar;

}

@Component({
  selector: 'app-dialog-need-biscuit',
  standalone: true,
  imports: [MatButtonModule, NgIf],
  templateUrl: './dialog-purchase-avatar.component.html',
  styleUrl: './dialog-purchase-avatar.component.scss'
})
export class DialogPurchaseAvatarComponent {

  user : User;
  avatar: Avatar;
  transactionPossible: boolean;
  hideSpot: boolean;
  loaded: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private router: Router,
    private accountService: AccountService,
    private userService: UserService,
    private creditService: CreditService,
    private avatarService : AvatarService,
    private _snackbar: MatSnackBar,
    public dialogRef: MatDialogRef<DialogPurchaseAvatarComponent>,
  ) {}

  ngOnDestroy() {
  }

  ngOnInit() {

    this.loaded = false;

    this.avatar = this.data.avatar;

//    console.log(this.avatar);

    this.creditService.get().subscribe((info) => {
     this.transactionPossible = info.balance >= this.avatar.data.sales_price;
    });

    //user and its subs
    this.userService.getPerEmail(this.accountService.email).subscribe((user) => {

      this.user = user;

  //    console.log(user);

      if (this.user?.data.subscription && this.user?.data.subscription.type=='FREE')
        this.hideSpot = true;
      else
      this.hideSpot = false;

      this.loaded = true;
    });

  }

  purchase() {

    this.avatarService.buy(this.avatar.metadata.guid).subscribe( {
      next: (res) => {

        this.creditService.update();

        this._snackbar.open('Avatar purchased !', '', {
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
          duration: 2000,
          panelClass: ['green-snackbar'],
        });

        this.dialogRef.close(true);

      },
      error: (err) => {

        this.dialogRef.close(false);

        this._snackbar.open('Avatar not purchased !', '', {
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
          duration: 2000,
          panelClass: ['red-snackbar'],
        });
      }
    });
  }

  upgradeSub() {
    this.dialogRef.close();
    this.router.navigate(['subscription']);
  }

  buySpotCredits() {
    this.dialogRef.close();
    this.router.navigate(['buy-spot-credits']);
  }

  onCancel() {
    this.dialogRef.close(false);
  }


}
