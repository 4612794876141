import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bye',
  templateUrl: './bye.component.html',
  styleUrls: ['./bye.component.scss'],
  standalone: true,
  imports: [ MatButtonModule ]
})
export class ByeComponent {

  constructor(
    private router: Router,
  ) {

    sessionStorage.removeItem('bye');
  }

    home() {
      this.router.navigate(['home']);
    }

}

