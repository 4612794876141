import { ImagePrompt } from "./image-prompt";
import { Picture } from "./picture";

export class InpaintRequest {

  source_pic_guid: string;
  base64_mask: string;
  add_prompt: string;
  improve_mode: boolean;
  img_prompts: ImagePrompt[];

}
