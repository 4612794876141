import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { Abuse } from '../shared/entity/abuse';
import { Ingredient } from '../shared/entity/ingredient';
import { Picture } from '../shared/entity/picture';
import { AvatarStory } from '../shared/entity/avatar-story';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  apiBaseUrl: string;

  constructor(private http: HttpClient) {
    this.apiBaseUrl = environment.apiUrl;
  }

  //**********  PICTURE
  getAllPictures( guid: string, nbElements: number, lastId: number): Observable<Picture[]>  {
    if (lastId)
      return this.http.get<Picture[]>(`${this.apiBaseUrl}/v1/admin/picture/for/${guid}?nbe=${nbElements}&last=${lastId}`);
    else
      return this.http.get<Picture[]>(`${this.apiBaseUrl}/v1/admin/picture/for/${guid}?nbe=${nbElements}`);
  }

  getAllSharedPictures(nbElements: number, lastId: number): Observable<Picture[]>  {
    if (lastId)
      return this.http.get<Picture[]>(`${this.apiBaseUrl}/v1/public/picture?nbe=${nbElements}&last=${lastId}`);
    else
      return this.http.get<Picture[]>(`${this.apiBaseUrl}/v1/public/picture?nbe=${nbElements}`);
  }

  //************ ABUSE

  getAllAbuses(): Observable<Abuse[]> {
    return this.http.get<Abuse[]>(`${this.apiBaseUrl}/v1/admin/abuse`);
  }

  getAbuse(guid: string): Observable<Abuse> {
    return this.http.get<Abuse>(
      `${this.apiBaseUrl}/v1/admin/abuse/for/${guid}`
    );
  }

  //************ INGREDIENT

  getAllIngredients(): Observable<Ingredient[]> {
    return this.http.get<Ingredient[]>(
      `${this.apiBaseUrl}/v1/admin/ingredient`
    );
  }

  getIngredient(guid: string): Observable<Ingredient> {
    return this.http.get<Ingredient>(
      `${this.apiBaseUrl}/v1/admin/ingredient/${guid}`
    );
  }

  updateIngredient(ingredient: Ingredient): Observable<void> {
    return this.http.put<void>(
      `${this.apiBaseUrl}/v1/admin/ingredient`,
      ingredient
    );
  }

  deleteIngredient(guid: string): Observable<void> {

    //console.log("url= "+`${this.apiBaseUrl}/v1/admin/ingredient/${guid}`);

    return this.http.delete<void>(
      `${this.apiBaseUrl}/v1/admin/ingredient/${guid}`
    );
  }

  addIngredient(ingredient: Ingredient): Observable<Ingredient> {
    return this.http.post<Ingredient>(
      `${this.apiBaseUrl}/v1/admin/ingredient`,
      ingredient
    );
  }

  //************ AVATAR STORY

  getAllAvatarStories(): Observable<AvatarStory[]> {
    return this.http.get<AvatarStory[]>(
      `${this.apiBaseUrl}/v1/admin/avatar-story`
    );
  }

  getAvatarStory(guid: string): Observable<AvatarStory> {
    return this.http.get<AvatarStory>(
      `${this.apiBaseUrl}/v1/admin/avatar-story/${guid}`
    );
  }

  updateAvatarStory(story: AvatarStory): Observable<void> {
    return this.http.put<void>(
      `${this.apiBaseUrl}/v1/admin/avatar-story`,
      story
    );
  }

  deleteAvatarStory(guid: string): Observable<void> {
    return this.http.delete<void>(
      `${this.apiBaseUrl}/v1/admin/avatar-story/${guid}`
    );
  }

  addAvatarStory(story: AvatarStory): Observable<AvatarStory> {
    return this.http.post<AvatarStory>(
      `${this.apiBaseUrl}/v1/admin/avatar-story`,
      story
    );
  }
}
