import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import {

  MatDialog,

} from '@angular/material/dialog';
import { Picture } from '../shared/entity/picture';
import { FavoriteService } from '../services/favorite.service';
import { DialogFavoriteComponent } from './dialog-favorite/dialog-favorite.component';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogLabelComponent } from './dialog-label/dialog-label.component';
import { MatChipGrid, MatChipsModule } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PictureService } from '../services/picture.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Favorite } from '../shared/entity/favorite';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatCardModule } from '@angular/material/card';
import { RandomImageService } from '../services/random-image.service';
import { NgIf } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

interface Item {
  pic?: Picture;
  selected?: boolean;
}

@Component({
  selector: 'app-favorites',
  standalone: true,
  imports: [
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatChipGrid,
    MatChipsModule,
    MatTooltipModule,
    MatCardModule,
    MatProgressSpinner,
    NgIf
  ],
  templateUrl: './favorites.component.html',
  styleUrl: './favorites.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FavoritesComponent {
  items: Item[];
  filteredItems: Item[];
  title: string;
  filterField: string;
  background_img: string;
  loaded: boolean;

  constructor(
    private favoriteService: FavoriteService,
    private pictureService: PictureService,
    public dialog: MatDialog,
    private _snackbar: MatSnackBar,
    private randomImageService: RandomImageService
  ) {
    this.items = [];
  }

  ngOnInit() {

    this.loaded = false;

    this.background_img = this.randomImageService.get();

    this.favoriteService.getAll().subscribe((pictures) => {

      pictures.forEach((picture) => {
        let item: Item = {};
        item.pic = picture;
        item.selected = false;
        this.items.push(item);
      });

      this.filteredItems = this.items.slice();
      this.loaded = true;
    });
  }

  filter(e) {
    //console.log(e);
    this.filteredItems = [];

    if (this.filterField && this.filterField.trim().length > 2) {
      //console.log('tokenize');

      //tokenize
      let tokens: string[] = this.filterField.split(' ');

      tokens.forEach((token) => {
        if (token != '' && token.length > 2) {
          for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].pic?.tags) {
              this.items[i].pic?.tags.forEach((tag) => {
                if (tag.indexOf(token) >= 0) {
                  if (!this.alreadyFiltered(this.items[i])) {
                    this.filteredItems.push(this.items[i]);
                  }
                }
              });
            }
          }
        }
      });
    } else {
     // console.log('all');
      this.filteredItems = this.items;
    }
  }

  alreadyFiltered(item: Item) {
    let already = false;

    this.filteredItems.forEach((f) => {
      if (f.pic.id === item.pic.id) {
        already = true;
      }
    });

    return already;
  }

  open(index: number) {
    let dataConfig = {
      data: { name: 'IMAGE', items: this.filteredItems, index: index },
      //height: '90%',
      //width: '90%',
      //maxHeight: '90vh',
      maxWidth: '100%',
      maxHeight: '100%',
      autoFocus: false,
    };

    const dialogRef = this.dialog.open(DialogFavoriteComponent, dataConfig);
  }

  downloads() {
    let guids: string[] = [];

    this.items.forEach((item) => {
      if (item.selected) {
        guids.push(item.pic.guid);
      }
    });

    let reqGuids = { guids: guids };

    this.favoriteService.downloadZip(reqGuids).subscribe((blob) => {
      // Create a link element
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'nzym-package-' + new Date().getTime() + '.zip'; // Set the download filename

      // Append link to the body, click it, and then remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  addChip(tag: string) {
    if (this.filterField) this.filterField += ' ' + tag;
    else this.filterField = tag;

    this.filter('test');
  }

  labels() {
    let dataConfig = {
      data: { title: 'ADD TAGS TO ALL SELECTED PICS' },
      width: '500px',
      maxHeight: '600px',
      autoFocus: false,
    };

    let atLeastOneSelected = false;

    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].selected) {
        atLeastOneSelected = true;
        break;
      }
    }

    if (atLeastOneSelected) {
      const dialogRef = this.dialog.open(DialogLabelComponent, dataConfig);

      dialogRef.afterClosed().subscribe((result) => {
        let pics: Picture[] = [];

        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].selected) {
            if (this.items[i].pic.tags && this.items[i].pic.tags.length > 0) {
              this.items[i].pic.tags = this.items[i].pic.tags.concat(result);
            } else {
              this.items[i].pic.tags = result;
            }
            pics.push(this.items[i].pic);
          }
        }

        this.pictureService.updateTags(pics).subscribe(() => {
          this._snackbar.open('Tags updated !', '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 1000,
            panelClass: ['green-snackbar'],
          });
        });
      });
    } else {
      this._snackbar.open('Please select at least one picture ', '', {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 1000,
        panelClass: ['red-snackbar'],
      });
    }
  }

  singleDownload(index: number) {
    this.pictureService
      .downloadImage(this.items[index].pic.img_uri)
      .subscribe((blob) => {
        // Create a link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.type = "image/jpeg";
        link.download = 'nzym-img-' + new Date().getTime() + '.jpg'; // Set the download filename

        // Append link to the body, click it, and then remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }

  unfav(index: number) {
    let fav: Favorite = new Favorite();

    fav.img_guid = this.items[index].pic.guid;

    this.favoriteService.remove(fav).subscribe(() => {
      this.items.splice(index, 1);
      this.filter('a');

      this._snackbar.open('Favorite removed !', '', {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 1000,
        panelClass: ['green-snackbar'],
      });
    });
  }

  deselectAll() {
    this.items.forEach((item) => {
      item.selected = false;
    });

    this.filterField = '';
    this.filter('a');
  }

  selectAll() {
    this.filteredItems.forEach((item) => {
      item.selected = true;
    });
  }

  singleTagEdit(index: number) {
    let dataConfig = {
      data: {
        title: 'ADD TAGS FOR SINGLE PICTURE',
        tags: this.items[index].pic.tags,
      },
      width: '500px',
      maxHeight: '600px',
      autoFocus: false,
    };

    const dialogRef = this.dialog.open(DialogLabelComponent, dataConfig);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let pics: Picture[] = [];

        this.filteredItems[index].pic.tags = result;
        pics.push(this.items[index].pic);

        this.pictureService.updateTags(pics).subscribe(() => {
          this._snackbar.open('Tags updated !', '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 1000,
            panelClass: ['green-snackbar'],
          });
        });
      }
    });
  }
}
