<div>
  <mat-card class="card">
    <mat-card-header class="header">
      <mat-card-title class="card-title"> </mat-card-title>
      <mat-card-subtitle class="card-subtitle "> </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>

      <app-payment-card (output)="manageCardFeedback($event)"></app-payment-card>

    </mat-card-content>
  </mat-card>
</div>
