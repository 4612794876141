import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bye',
  templateUrl: './ban.component.html',
  styleUrls: ['./ban.component.scss'],
  standalone: true,
  imports: [ MatButtonModule ]
})
export class BanComponent {

  constructor(
    private router: Router,
  ) {


  }

    home() {
      this.router.navigate(['logout']);
    }

}
