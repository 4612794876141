import { LiveAnnouncer } from '@angular/cdk/a11y';
import { NgIf, formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { AdminService } from '../services/admin.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-abuse-list',
  standalone: true,
  imports: [
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
  ],
  templateUrl: './abuse-list.component.html',
  styleUrl: './abuse-list.component.scss',
})
export class AbuseListComponent {
  dataSource;
  loaded: boolean = false;

  displayedColumns: string[] = [
    'time',
    'user_email',
    'level',
    'ip',
    'raw_prompt',
    'actions',
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort | any;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private userService: UserService,
    private adminService: AdminService,
    private router: Router,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  ngOnInit() {
    this.loaded = false;

    this.adminService.getAllAbuses().subscribe((abuses) => {

      //conversion epoch to date
      abuses.forEach((abuse)=> {
        var crea = new Date(0);
        crea.setUTCSeconds(abuse.time);
        abuse.date = formatDate(
          crea,
          'dd-MM-YYYY HH:mm',
          this.locale
        ).toString();
      });


      this.dataSource = new MatTableDataSource(abuses);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.loaded = true;
    });
  }

  doFilter = (value: any) => {
    this.dataSource.filter = value.target.value.toLocaleLowerCase();
  };

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  openUser(email: string) {

    this.userService.getPerEmail(email).subscribe((user) => {

      let guid = user.metadata.guid;

      this.router.navigate(['user-detail', guid]);

    })

  }

  seePictures(email: string) {
    this.userService.getPerEmail(email).subscribe((user) => {

      let guid = user.metadata.guid;

      this.router.navigate(['user-images', guid]);

    })

  }
}
