<div class="container">
  <div class="message">
    <div class="text">{{ text }}</div>
    <div class="text">{{ text2 }}</div>
  </div>
  <div class="actions">
    <button mat-raised-button color="primary" class="ok" (click)="submit()"> YES ! </button>
    <button mat-raised-button color="warn" class="cancel" (click)="onCancel()"> NO </button>
  </div>
</div>
