import { LiveAnnouncer } from '@angular/cdk/a11y';
import { NgIf } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { AdminService } from '../services/admin.service';
import { MatChipsModule } from '@angular/material/chips';

@Component({
  selector: 'app-ingredient-list',
  standalone: true,
  imports: [
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    NgIf,
  ],
  templateUrl: './ingredient-list.component.html',
  styleUrl: './ingredient-list.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class IngredientListComponent {
  dataSource;
  loaded: boolean = false;

  displayedColumns: string[] = [
    'name',
    'ethnicity',
    'gender',
    'type',
    'rarity',
    'active',
    'action',
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort | any;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private _snackbar: MatSnackBar,
    private adminService: AdminService,
    private router: Router
  ) {}

  ngOnInit() {
    this.adminService.getAllIngredients().subscribe((ingredients) => {
      this.dataSource = new MatTableDataSource(ingredients);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.loaded = true;
    });
  }

  doFilter = (value: any) => {
    this.dataSource.filter = value.target.value.toLocaleLowerCase();
  };

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  active(element) {

    element.active = !element.active;

    this.adminService.updateIngredient(element).subscribe(() => {

      this._snackbar.open('Ingredient updated !', '', {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 2000,
        panelClass: ['green-snackbar'],
      });

      this.adminService.getAllIngredients().subscribe((ingredients) => {
        this.dataSource = new MatTableDataSource(ingredients);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.loaded = true;
      });

    });

  }

  delete(guid: string) {

    this.adminService.deleteIngredient(guid).subscribe(()=> {

      this.loaded = false;


      this.adminService.getAllIngredients().subscribe((ingredients) => {
        this.dataSource = new MatTableDataSource(ingredients);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.loaded = true;

        this._snackbar.open('Ingredient deleted !', '', {
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
          duration: 2000,
          panelClass: ['green-snackbar'],
        });

      });

    });

  }

  new() {
    this.router.navigate(['ingredient', 'new']);
  }

  edit(guid: string) {

    this.router.navigate(['ingredient', guid]);
  }
}
