import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipEditedEvent, MatChipGrid, MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

export interface DialogData {

}

export interface Tag {
  name: string;
}

@Component({
  selector: 'app-dialog-custom-zoom',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatIconModule,
    MatDialogClose,
    MatLabel,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatChipGrid,
    MatChipsModule
  ],
  templateUrl: './dialog-label.component.html',
  styleUrl: './dialog-label.component.scss',
})
export class DialogLabelComponent {

  data: any;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  tags: Tag[] = [];

  original;

  constructor(
    public dialogRef: MatDialogRef<DialogLabelComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data_in: DialogData
  ) {

    this.data = data_in;
    this.data?.tags?.forEach((tag)=> {

      let t : Tag = { name: tag };
      this.tags.push(t);

    })
  }

  ngOnInit() {


  }


  submit() {

    let result = [];

      this.tags.forEach((tag) => {

        result.push(tag.name);

      })

      this.dialogRef.close(result);

  }

  onCancel(): void {
      this.dialogRef.close(null);
  }


  addTag(event: MatChipInputEvent): void {
    const value = (event.value?.toLowerCase() || '').trim();

    // Add our tag
    if (value) {

      for (let i=0;i< this.tags.length; i++) {
        if (this.tags[i].name==value.toLowerCase()) {
          event.chipInput!.clear();
          return;
        }
      }

      this.tags.push({name: value.toLowerCase()});
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  removeTag(tag: Tag): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);

      //this.announcer.announce(`Removed ${tag}`);
    }
  }

  editTag(tag: Tag, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove tag if it no longer has a name
    if (!value) {
      this.removeTag(tag);
      return;
    }

    // Edit existing tag
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags[index].name = value.toLowerCase();
    }
  }

}
