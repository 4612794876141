import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { CustomAuthService } from '../services/custom-auth.service';
import { AccountService } from '../services/account.service';

@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.scss'],
  standalone: true,
  imports: [ MatButtonModule ]
})
export class SessionExpiredComponent {

  constructor(
    private router: Router,
    private accountService : AccountService,
    private customAuthService: CustomAuthService
  ) {
  }

  logoutAndLogin() {
     this.customAuthService.expired();
     // sessionStorage.removeItem('bye');
     //this.accountService.logout(false);
     //this.router.navigate(['home']);
    }

    support() {
      this.router.navigate(['support']);

    }

}
