<div class="dialog" >
  <div [class]= "inprocess ? 'overlay-inprocess' : 'not-visible'">
    <mat-spinner [diameter]="20" [color]="'warn'"></mat-spinner>
    <div class="generation"> generation in progress ! </div>
  </div>

  <div [class]="horizontal_format ? 'img-container-horizontal' : 'img-container-vertical'">
    <div [class]="horizontal_format ? 'image-display-horizontal' : 'image-display-vertical'">
      <div [class]="img_overlay ? 'overlay' : 'not-visible'" #overlayEdit>

        <div class="image-features">
          <div class="section ">
            <!--            <div class="section-head">ACTION</div>-->

          </div>

          <div class="section">
            <!--        <div class="section-head"> GENERATE</div> -->
            <div class="section-body">
              <div class="button">
                <button mat-button color="white" class="nzym-icon-button"
                  matTooltip="[S] Make that picture disappear from your flow" [matTooltipPosition]="'right'" (click)="disappear()">
                  <mat-icon>
                    delete
                  </mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div [class]="pic.favorite ? 'fav-overlay' : 'not-visible'" #favOverlayEdit > <mat-icon>favorite</mat-icon></div>
       <img class="full-image" src="{{ pic.img_uri }}" #imgEdit (load)="onLoadImage()">
    </div>

  </div>
