import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCard } from '@angular/material/card';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AvatarActionTextService } from '../../services/avatar-action-text.service';
import { AvatarService } from '../../services/avatar.service';
import { References } from '../../shared/entity/references';
import { PictureService } from '../../services/picture.service';

export interface DialogData {
  guid: string;
  name: string;
  pic: any;
}

export interface Tile {
  imgThumb: string;
  imgFull: string;
  loaded: boolean;
  start?: number;
  sec?: number;
  decilesec?: number;
  action?: string;
}

@Component({
  selector: 'app-dialog-image-dreambooth-profile',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCard,
    MatProgressSpinnerModule,
  ],
  templateUrl: './dialog-image-dreambooth-profile.component.html',
  styleUrl: './dialog-image-dreambooth-profile.component.scss',
})
export class DialogImageDreamboothProfileComponent {
  thumbUri: string;
  fullUri: string;
  creationDate: string;
  action: string;
  tile_overlay: Tile;
  hasBeenChanged:boolean;
  //id: number;
  guid: string;
  action_text: string;

  constructor(
    public dialogRef: MatDialogRef<DialogImageDreamboothProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private avatarService: AvatarService,
    private avatarTextActionService: AvatarActionTextService,
    private pictureService: PictureService
  ) {}

  ngOnInit() {

    this.hasBeenChanged = false;
    this.action = this.data.pic.action;
    this.thumbUri = this.data.pic.imgThumb;
    this.fullUri = this.data.pic.imgFull;
    this.guid = this.data.guid;
    this.creationDate = new Date().toISOString();
    this.action_text = this.avatarTextActionService.getText(this.action);

    //transparent pixel
    this.tile_overlay = {
      imgThumb: '../../../assets/img/1x1-gray.png',
      imgFull: '../../../assets/img/1x1-gray.png',
      loaded: true,
      start: new Date().getTime(),
      sec: 0,
      decilesec: 0,
      action: this.action,
    };

    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this.onCancel();
      }
    });

    this.dialogRef.backdropClick().subscribe((event) => {
      this.onCancel();
    });
  }

  onCancel(): void {

    if (this.hasBeenChanged) {

      let tile = {
        imgThumb: this.thumbUri,
        imgFull: this.fullUri,
        action : this.action
      };

      this.dialogRef.close(tile);
    }
  }

  download(): void {
    this.pictureService.downloadImage(this.fullUri).subscribe((blob) => {
      // Create a link element
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.type = "image/jpeg";
      link.download = 'nzym-img-' + (new Date().getTime()) + '.jpg'; // Set the download filename

      // Append link to the body, click it, and then remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  edit(): void {

  }

  initOverlay(tile: Tile) {
    tile.loaded = false;
    tile.sec = 0;
    tile.decilesec = 0;
    tile.start = new Date().getTime();
  }


}
