export class Ingredient {

  guid: string;
  name: string;
  ethnicity: string;
  gender: string;
  rarity: string;
  type: string;
  active: boolean;
}
