<div class="dialog">
  <div [class]="horizontal_format ? 'img-container-horizontal' : 'img-container-vertical'">
    <div [class]="horizontal_format ? 'image-display-horizontal' : 'image-display-vertical'">
      <div [class]="img_overlay ? 'overlay' : 'not-visible'" #overlayEdit>

        <div class="image-features">
          <div class="section ">
            <!--            <div class="section-head">ACTION</div>-->
            <div class="section-body">
              <div class="button">
                <button mat-button color="white" class="nzym-icon-button" (click)="download()" matTooltip="[D] Download"
                  [matTooltipPosition]="'right'">
                  <mat-icon>download</mat-icon></button>
              </div>
            </div>
          </div>

        </div>
      </div>
       <img class="full-image" src="{{ items[index].pic.img_uri }}" #imgEdit (load)="onLoadImage()">
    </div>

  </div>
