import { Location, AsyncPipe, NgFor, NgIf } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { User } from '../shared/entity/user';
import { environment } from '../../environments/environment';
import { AccountService } from '../services/account.service';
import { PaymentCardComponent } from '../shared/component/stripe-card/payment-card.component';
import { SubscriptionService } from '../services/subscription.service';
import { Subscription } from '../shared/entity/subscription';
import { MatDialog } from '@angular/material/dialog';
import { DialogChangeCardComponent } from './change-card/change-card.component';
import { DialogFutureSubComponent } from './dialog-future-sub/dialog-future-sub.component';
import { DialogCancelSubComponent } from './dialog-cancel-sub/dialog-cancel-sub.component';
import { MatIconModule } from '@angular/material/icon';
import { DialogNicknameComponent } from './dialog-nickname/dialog-nickname.component';
import { DialogSelectProfilePictureComponent } from './dialog-select-profile-picture/dialog-select-profile-picture.component';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCardModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    AsyncPipe,
    MatAutocompleteModule,
    PaymentCardComponent,
    MatIconModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UserDetailComponent {
  user: User | undefined;
  title: string | undefined;
  subtitle: string | undefined;
  form: FormGroup;
  loaded: boolean = false;
  pictureUrl: string = environment.frontUrl + '/assets/img/user.png';
  availableRoles: string[] = [];
  adminRight: boolean;
  remainingSub: Subscription;
  remainingText: string;
  subDisabled: boolean;
  chgCardDisabled: boolean;
  invoicesDisabled: boolean;
  cancelDisabled: boolean;

  constructor(
    public dialog: MatDialog,
    private userService: UserService,
    private accountService: AccountService,
    private subscriptionService: SubscriptionService,
    private router: Router,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private location: Location
  ) {}

  ngOnInit() {
    // First get the user id from the current route
    const routeParams = this.route.snapshot.paramMap;
    const guid = routeParams.get('user_guid');

    this.adminRight = this.accountService.isAdmin();

    // Find the product that correspond with the id provided in route.
    this.userService.get(guid).subscribe((data: User) => {
      //console.log('User data:');
      console.log(data);

      this.user = data;

      this.subtitle = 'Profile Information';
      this.pictureUrl = this.user.data.picture;
      this.loaded = true;

      this.cancelDisabled = !this.user.data.subscription.active;

      if (
        this.user.data.subscription.type &&
        this.user.data.subscription.type == 'FREE'
      ) {
        this.cancelDisabled = true;
      }

      this.subDisabled = false;

      //ADMIN CAN NOT DO THIS FOR THEMSELVES AND/OR FOR OTHERS HERE
      if (this.adminRight) {
        this.subDisabled = true;
        this.chgCardDisabled = true;
        this.invoicesDisabled = true;
        this.cancelDisabled = true;
      }

      if (this.user.data.subscription.remaining_guid) {
        //it has extra credits from a previous sub

        //TODO ADMIN READ FOR REMAINING OF A USER

        if (this.accountService.isAdmin()) {

          this.subscriptionService.getRemainingForUser(this.user.metadata.guid).subscribe((extraSub) => {
            this.remainingSub = extraSub;
            this.remainingText =
              ' + ' + extraSub.monthly_credits + ' from previous engagement';
            this.subDisabled = true;
          });

        } else {
          this.subscriptionService.getRemaining().subscribe((extraSub) => {
            this.remainingSub = extraSub;
            this.remainingText =
              ' + ' + extraSub.monthly_credits + ' from previous engagement';
            this.subDisabled = true;
          });
        }
      }
    });
  }

  renewal() {
    if (this.user.data.subscription.active) return 'ACTIVE';
    else return 'CANCELLED';
  }

  invoices() {
    this.router.navigate(['invoices']);
  }

  sanitizeCardType() {
    if (this.user.data.cardBrand) return this.user.data.cardBrand.toUpperCase();
    else return 'None';
  }

  sanitizeCardNumber() {
    if (this.user.data.cardLast4)
      return 'XXXX-XXXX-XXXX-' + this.user.data.cardLast4.toUpperCase();
    else return 'None';
  }

  sanitizeExpiry() {
    if (this.user.data.cardExpMonth) {
      let month = '';

      if (+this.user.data.cardExpMonth < 10) {
        month = '0' + this.user.data.cardExpMonth;
      } else month = this.user.data.cardExpMonth;

      return month + '/' + this.user.data.cardExpYear;
    } else return 'None';
  }

  onSubmit() {
    this._snackBar.open('User updated !', '', {
      duration: 2000,
      horizontalPosition: 'right',
      panelClass: ['green-snackbar'],
    });
  }

  changeCard() {
    const dialogRef = this.dialog.open(DialogChangeCardComponent, {
      width: '80%',
      maxWidth: '600px',
      maxHeight: '400px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._snackBar.open('Card updated !', '', {
          duration: 2000,
          horizontalPosition: 'right',
          panelClass: ['green-snackbar'],
        });

        //refresh user
        this.userService
          .get(this.user.metadata.guid)
          .subscribe((data: User) => {
            this.user = data;
          });
      }
    });
  }

  cancelSub() {
    const dialogRef = this.dialog.open(DialogCancelSubComponent, {
      width: '500px',
      maxHeight: '350px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.subscriptionService.cancelSub().subscribe(() => {
          this._snackBar.open('Subscription(s) will be terminated !', '', {
            duration: 2000,
            horizontalPosition: 'right',
            panelClass: ['green-snackbar'],
          });

          this.router.navigate(['home']);
        });
      }
    });
  }

  nextBillingDate() {
    if (this.user.data.subscription.type == 'FREE') {
      return ' No Subscription ';
    } else {
      let utcSeconds;

      if (this.user.data.subscription.recurrence == 'ANNUAL') {
        utcSeconds = this.user.data.subscription.annual_date_cycle;
      } else {
        utcSeconds = this.user.data.subscription.next_date_cycle;
      }

      var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
      d.setUTCSeconds(utcSeconds);

      return (
        d.getUTCFullYear() +
        '/' +
        (d.getUTCMonth() + 1) +
        '/' +
        d.getUTCDate() +
        ' ' +
        d.getUTCHours() +
        ':' +
        d.getUTCMinutes() +
        ' (UTC Time)'
      );
    }
  }

  dateNextReload() {
    if (this.user.data.subscription.type == 'FREE') {
      return ' No Subscription ';
    } else {
      var utcSeconds = this.user.data.subscription.next_date_cycle;
      var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
      d.setUTCSeconds(utcSeconds);

      return (
        d.getUTCFullYear() +
        '/' +
        (d.getUTCMonth() + 1) +
        '/' +
        d.getUTCDate() +
        ' ' +
        d.getUTCHours() +
        ':' +
        d.getUTCMinutes() +
        ' (UTC Time)'
      );
    }
  }

  getRecurrence() {

    if (this.user.data.subscription.type == 'FREE') {
      return ' No Subscription ';
    } else
      return this.user.data.subscription.recurrence;

  }

  futureSub() {
    if (this.user.data.subscription.future_guid) {
      if (this.accountService.isAdmin()) {
        //admin has no future but beed to see the one of a user during user-detail-review

        //console.log("IS ADMIN !");

        this.subscriptionService
          .adminGet(this.user.data.subscription.future_guid)
          .subscribe((future) => {
            //console.log(future);

            const dialogRef = this.dialog.open(DialogFutureSubComponent, {
              data: { sub: future },
              width: '500px',
              maxHeight: '350px',
            });

            dialogRef.afterClosed().subscribe((result) => {});
          });
      } else {
        //standard use case for the user connected
        this.subscriptionService.getFuture().subscribe((sub) => {
          //console.log(sub);

          //console.log("IS NOT ADMIN !");

          const dialogRef = this.dialog.open(DialogFutureSubComponent, {
            data: { sub: sub },
            width: '500px',
            maxHeight: '350px',
          });

          dialogRef.afterClosed().subscribe((result) => {});
        });
      }
    } else {
      //console.log("no future sub to display !");
    }
  }

  subscription() {
    this.router.navigate(['subscription']);
  }

  displayFn(str: string) {
    return str.replaceAll('_', ' ');
  }

  back() {
    this.location.back();
  }

  editNickname() {

    //console.log(this.user.data.nickname);

    const dialogRef = this.dialog.open(DialogNicknameComponent, {
      data: { nickname: this.user.data.nickname },
      height: '50%',
      width: '50%',
      maxWidth: '330px',
      maxHeight: '230px',
    });

    dialogRef.afterClosed().subscribe((result: string) => {

//      console.log(result);

      //could be undefined
      if (result) {

        this.userService.updateNickname(result).subscribe(()=> {

          this.user.data.nickname = result;

          this._snackBar.open('Nickname updated !', '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 1000,
            panelClass: ['green-snackbar'],
          });


        });
      }
    });
  }

  openPPChoice() {

    const dialogRef = this.dialog.open(DialogSelectProfilePictureComponent, {
      height: '80%',
      width: '80%',
      panelClass: 'custom-container',
    });

    dialogRef.afterClosed().subscribe((uri) => {
      //true means profile pic has been updated
      if (uri) {

        this.userService.updateProfilePicture(uri).subscribe(()=> {

          this.user.data.picture = uri;
          this.pictureUrl = uri;
          this.accountService.picture = uri;

          this._snackBar.open('Nickname updated !', '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 1000,
            panelClass: ['green-snackbar'],
          });

        });

      }
    });


  }

}
