<div class="dialog">
  <div [class]="horizontal_format ? 'img-container-horizontal' : 'img-container-vertical'">
    <div [class]="horizontal_format ? 'image-display-horizontal' : 'image-display-vertical'">
      <div [class]="img_overlay ? 'overlay' : 'not-visible'" #overlayEdit>

        <div class="image-features">
          <div class="section ">
            <!--            <div class="section-head">ACTION</div>-->
            <div class="section-body">
              <div class="button">
                <button mat-button color="white" class="nzym-icon-button" (click)="download()" matTooltip="[D] Download"
                  [matTooltipPosition]="'right'">
                  <mat-icon>download</mat-icon></button>
              </div>
              <div class="button">
                <button mat-button [disabled]='true' color="white" class="nzym-icon-button" matTooltip="[F] Favorite"
                  [matTooltipPosition]="'right'" >
                  <mat-icon>
                    heart_plus
                  </mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="section">
            <!--            <div class="section-head"> IMPROVE</div>-->
            <div class="section-body">

              <div class="button">
                <button mat-button  [disabled]='true' color="white" class="nzym-icon-button"
                  matTooltip="[I] Fix problem with AI" [matTooltipPosition]="'right'" matTooltipClass="tooltip">
                  <mat-icon>build</mat-icon></button>
              </div>
              <div class="button">
                <button mat-button [disabled]='true' color="white" class="nzym-icon-button"
                  matTooltip="[Z] Zoom out (x1) - Standard" [matTooltipPosition]="'right'">
                  <mat-icon>zoom_out_map</mat-icon> </button>
              </div>
             </div>
          </div>
          <div class="section">
            <!--      <div class="section-head"> POST TREAT</div> -->
            <div class="section-body">
              <div class="button">
                <button mat-button [disabled]='true' color="white" class="nzym-icon-button"
                  matTooltip="[U] Upscale the resolution x2" [matTooltipPosition]="'right'" >
                  <mat-icon>filter_2</mat-icon>
                </button>
              </div>
              <div class="button">
                <button mat-button [disabled]='true' color="white" class="nzym-icon-button"
                  matTooltip="[T] Upscale the resolution x4" [matTooltipPosition]="'right'" >
                  <mat-icon>filter_4</mat-icon>
                </button>
              </div>
            </div>
          </div>


          <div class="section">
            <!--        <div class="section-head"> GENERATE</div> -->
            <div class="section-body">
              <div class="button">
                <button mat-button [disabled]='true' color="white" class="nzym-icon-button"
                  matTooltip="[V] Generate a variant of this exact picture" [matTooltipPosition]="'right'" >
                  <mat-icon>
                    frame_reload
                  </mat-icon>
                </button>
              </div>
              <div class="button">
                <button mat-button [disabled]='true' color="white" class="nzym-icon-button"
                  matTooltip="[R] Generate a new image with the same prompt" [matTooltipPosition]="'right'">
                  <mat-icon>replay</mat-icon> </button>
              </div>

            </div>
          </div>
          <div class="section">
            <!--        <div class="section-head"> GENERATE</div> -->
            <div class="section-body">
              <div class="button">
                <button mat-button [disabled]='true' color="white" class="nzym-icon-button"
                  matTooltip="[S] Make that picture disappear from your flow" [matTooltipPosition]="'right'" >
                  <mat-icon>
                    delete
                  </mat-icon>
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
       <img class="full-image" src="{{ pics[index].img_uri }}" #imgEdit (load)="onLoadImage()">
    </div>

  </div>
