<div class="options">

  <div class="text"> These settings allow us to improve our service, but you can customize the following options: </div>
  <div class="checks">
    <div class="check"> <mat-slide-toggle labelPosition="before" [(ngModel)]="toggleA" [color]="'primary'" (toggleChange)="changeA()"> I accept my data to be stored in Google Ads platform  </mat-slide-toggle></div>
    <div class="check"><mat-slide-toggle labelPosition="before" [(ngModel)]="toggleB" [color]="'primary'" (toggleChange)="changeB()"> I accept my data (email only) can be used for advertising purposes</mat-slide-toggle></div>
    <div class="check"><mat-slide-toggle labelPosition="before" [(ngModel)]="toggleC" [color]="'primary'" (toggleChange)="changeC()"> I accept my data can be used for personnalized marketing</mat-slide-toggle></div>
    <div class="check"><mat-slide-toggle labelPosition="before" [(ngModel)]="toggleD" [color]="'primary'" (toggleChange)="changeD()"> I accept my actions on the site to be stored in Google Analytics platform</mat-slide-toggle></div>
  </div>
  <button mat-raised-button color="primary" (click)="submit()"> OK</button>
</div>
