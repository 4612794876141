import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PaymentCardComponent } from '../../shared/component/stripe-card/payment-card.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-change-card',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    PaymentCardComponent,
  ],
  templateUrl: './change-card.component.html',
  styleUrl: './change-card.component.scss'
})
export class DialogChangeCardComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogChangeCardComponent>,
  ) {

  }

  manageCardFeedback(event) {
    this.dialogRef.close(event);
  }

}
