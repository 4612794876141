import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, timeout } from 'rxjs';

import { environment } from '../../environments/environment';
import { Scene } from '../shared/entity/scene';


@Injectable({
  providedIn: 'root'
})
export class SceneService {

  apiBaseUrl : string;

  constructor(private http: HttpClient) {
    this.apiBaseUrl =  environment.apiUrl;
  }

  getAll(): Observable<Scene[]>  {
    return this.http.get<Scene[]>(`${this.apiBaseUrl}/v1/scene`);
  }

  get(id:string): Observable<Scene>  {
    return this.http.get<Scene>(`${this.apiBaseUrl}/v1/scene/`+id);
  }

}
