import {
  Component,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  AfterViewInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  NgForm,
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
  FormsModule,
  ReactiveFormsModule,
  FormGroup,
} from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { loadStripe } from '@stripe/stripe-js';
import { PaymentService } from '../../../services/payment.service';
import { MatFormField } from '@angular/material/form-field';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';

declare var Stripe;

@Component({
  selector: 'app-payment-card',
  templateUrl: './payment-card.component.html',
  styleUrls: ['./payment-card.component.scss'],
  imports: [
    MatFormField,
    MatButton,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    MatInputModule,
  ],
  standalone: true,
})
export class PaymentCardComponent implements AfterViewInit, OnDestroy {
  @ViewChild('cardInfo', { static: false }) cardInfo: ElementRef;

  form: FormGroup = this._formBuilder.group({
    cardholder: ['', Validators.required],
  });

  isDisabled: boolean = false;
  card: any;
  payment: any;
  client_secret: string;
  elements: any;
  cardHandler = this.onChange.bind(this);
  error: string;
  stripe: any;

  @Input() newAdmin = false;
  @Output() output: EventEmitter<any>;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private paymentService: PaymentService,
    private _snackbar: MatSnackBar
  ) {
    this.output = new EventEmitter<any>();
  }

  ngOnInit() {
    this.isDisabled = false;
  }

  ngAfterViewInit(): void {
    const appearance = { theme: 'night' };

    let loadLib = async () => {
      await loadStripe(environment.stripePublicKey);
    };

    loadLib().then(() => {
      this.stripe = Stripe(environment.stripePublicKey);
      this.setupFullPaymentExperience();
    });
  }

  setupFullPaymentExperience() {
    let self = this;

    const appearance = {
      theme: 'night',
      labels: 'floating',
      variables: {
        colorPrimary: '#039be5',
        colorBackground: '#424242',
        colorText: '#f6f6f6',
        colorDanger: '#d32f2f',
        fontFamily: 'Ideal Sans, system-ui, sans-serif',
        spacingUnit: '2px',
        borderRadius: '4px',
        // See all possible variables below
      },
    };

    this.paymentService.getSetupIntent().subscribe((res) => {

      self.client_secret = res.client_secret;
      self.elements = this.stripe.elements({
        clientSecret: self.client_secret,
        appearance: appearance,
        loader: 'always',
      });

      //************ SETUP FRONT ELEMENT FOR CREDIT CARD ONLY ( NEED FOR CLIENT SECRET)

      this.payment = self.elements.create('payment', {
        layout: 'tabs',
      });

      //************
      this.payment.mount(this.cardInfo.nativeElement);
      //this.card.addEventListener('change', this.cardHandler);
    });
  }

  setupCreditCardOnly() {
    //************ SETUP FRONT ELEMENT FOR CREDIT CARD ONLY (NO NEED FOR CLIENT SECRET)
    this.card = this.stripe.elements().create('card', {
      disableLink: true,
      hidePostalCode: true,
      iconStyle: 'solid',
      style: {
        style: {
          base: {
            iconColor: '#c4f0ff',
            color: '#fff',
            fontWeight: '500',
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
              color: '#fce883',
            },
            '::placeholder': {
              color: '#87BBFD',
            },
          },
          invalid: {
            iconColor: '#FFC7EE',
            color: '#FFC7EE',
          },
        },
      },
    });
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
  }

  ngOnDestroy() {
    //this.card.removeEventListener('change', this.cardHandler);
    this.payment.destroy();
    this.isDisabled = false;
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = '';
    }
    this.cd.detectChanges();
  }

  cancel() {
    this.isDisabled = false;
    this.output.emit('ok');
  }

  async addCard() {
    var self = this;
    this.isDisabled = true;
    const elements = this.elements;
    const secret_param = this.client_secret;

    const result = await elements.submit();

    this.stripe
      .confirmSetup({
        elements: elements,
        clientSecret: secret_param,
        confirmParams: {
          return_url: 'https://adn.nzym/io',
        },
        // Uncomment below if you only want redirect for redirect-based payments
        redirect: 'if_required',
      })
      .then((res) => {

        if (result.error) {
          // Inform the customer that there was an error.

          this._snackbar.open('Invalid card !', '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 1000,
            panelClass: ['red-snackbar'],
          });

          self.output.emit('ko');
          self.isDisabled = false;
        } else {

          let pmGuid = res.setupIntent.payment_method;

          //shoot PM to NZYM for customer creation / update
          self.paymentService.sendNewPaymentMethod(pmGuid).subscribe(() => {
            self.output.emit('ok');
            self.isDisabled = false;
          });
        }
      });
  }
}
