import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Subscription } from '../shared/entity/subscription';
import { SpotOffer } from '../shared/entity/spot-offer';


@Injectable({
  providedIn: 'root'
})
export class SpotBuyService {

  apiBaseUrl : string;

  constructor(private http: HttpClient) {
    this.apiBaseUrl =  environment.apiUrl;
  }

  get(guid:string): Observable<SpotOffer>  {
    return this.http.get<SpotOffer>(`${this.apiBaseUrl}/v1/spot/`+guid);
  }

  getAllSpotOffers() : Observable<SpotOffer[]> {
    return this.http.get<SpotOffer[]>(`${this.apiBaseUrl}/v1/spot/all`);
  }

  buy(spot:SpotOffer) : Observable<SpotOffer> {
    return this.http.post<SpotOffer>(`${this.apiBaseUrl}/v1/spot`, spot);
  }

}
