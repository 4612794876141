<mat-card class="mat-elevation-z0 single-card settings-card">
  <mat-cart-header class="mat-card-header form-box-title">
    <mat-card-title> {{ title }} </mat-card-title>
  </mat-cart-header>
  <mat-card-content>

    <div class="container">
      <div class="container-element">
        <form [formGroup]="form" class="form-naming">
          <mat-form-field class="form-element">
            <input matInput type="string" placeholder="Filter (Optional)" (input)="onChange($event)"
              formControlName="filter">
          </mat-form-field>[
        </form>
      </div>
    </div>


    <div class="container">
      @for (avatar of filteredAvatars; track avatar.metadata.guid) {
      <div class="container-element" (click)="selectAvatar(avatar)">

        <div class="avatar-box">
          <div class="avatar-creds">
          {{ avatar.data.given_name}} {{ avatar.data.family_name}}
        </div>
          <img src="{{ avatar.data.marketing_profile_picture_thumb_uri}}" class="avatar-box-pic">
          <div class="avatar-access">
            <mat-chip [disabled]=false [color]="avatar.data.public_access ? 'primary':'warn'" highlighted> {{ publicness(avatar) }}
            </mat-chip>
        </div>

        </div>
      </div>
      }
    </div>


  </mat-card-content>
</mat-card>

