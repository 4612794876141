import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { environment } from '../../environments/environment';
import { HairStyle } from '../shared/entity/hairstyle';

@Injectable({
  providedIn: 'root'
})
export class HairStyleService {

   apiBaseUrl : string;

  constructor(private http: HttpClient) {
    this.apiBaseUrl =  environment.apiUrl;
  }

  getAll(): Observable<HairStyle[]>  {

    return this.http.get<HairStyle[]>(`${this.apiBaseUrl}/v1/hairstyle`);

  }

}
