import { CUSTOM_ELEMENTS_SCHEMA, Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule, MatIconButton } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCard, MatCardModule } from '@angular/material/card';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { AvatarData } from '../../shared/entity/avatar-data';
import { Avatar } from '../../shared/entity/avatar';
import { MatSliderModule } from '@angular/material/slider';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { OperationsCostService } from '../../services/operations-cost';

export interface DialogData {
  guid: string;
  imgUri: string;
  avatar: Avatar;
}

@Component({
  selector: 'app-dialog-naming',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCard,
    MatCardModule,
    MatSliderModule,
    MatSelectModule,
    MatIconModule
  ],
  templateUrl: './dialog-naming.component.html',
  styleUrl: './dialog-naming.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DialogNamingComponent {
  form: FormGroup;
  cost: number ;
  title: string;
  portrait_image: string;
  guid: string; //pictureGuid
  temp_avatar: Avatar;
  minAge: number;
  maxAge: number;
  bloodTypes: string[] = ['A', 'B', 'AB', 'O'];
  zodiacSigns: string[] = [
    'Capricorn',
    'Aquarius',
    'Pisces',
    'Aries',
    'Taurus',
    'Gemini',
    'Cancer',
    'Leo',
    'Virgo',
    'Libra',
    'Scorpio',
    'Sagittarius',
  ];
  tempers: string[] = [
    'short', // Easily angered
    'hotheaded', // Becoming angry quickly and intensely
    'irascible', // Easily angered
    'volatile', // Likely to erupt suddenly and violently
    'cantankerous', // Bad-tempered and argumentative, especially in an old person
    'sulky', // Silently bad-tempered
    'spirited', // Full of life and energy
    'passionate', // Having strong feelings and beliefs
    'feisty', // Having a lively and energetic spirit, especially when defending oneself
    'steady', // Not easily upset
    'even-tempered', // Not easily angered
    'mercurial', // Likely to change quickly
    'high-strung', // Easily excited or nervous

    // Additional adjectives
    'impetuous', // Acting or done without forethought
    'placid', // Calm and peaceful
    'stoic', // Showing little or no emotion
    'combustible', // Likely to become angry suddenly
    'choleric', // Easily angered and bad-tempered (used in old-fashioned personality typing)
    'phlegmatic', // Calm and unexcitable (used in old-fashioned personality typing)
    'forgiving', // Able to forgive others easily
    'resentful', // Feeling bitterness or indignation
    'vindictive', // Desiring revenge
    'assertive', // Stating one's needs or beliefs confidently
    'abrasive', // Harsh or unpleasant in manner
    'agreeable', // Willing to agree with others
    'relenting', // Giving way to pressure or persuasion
    'adamant', // Firmly refusing to change one's mind
    'impervious', // Not affected by something
  ];

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    public operationsCost: OperationsCostService,
    public dialogRef: MatDialogRef<DialogNamingComponent>
  ) {}

  ngOnInit(): void {
    this.title = 'FINE TUNE YOUR AVATAR';
    this.portrait_image = this.data.imgUri; //sessionStorage.getItem('gen.init.img');
    this.guid = this.data.guid;
    this.temp_avatar = this.data.avatar;
    this.minAge = Math.round(this.temp_avatar.data.age / 10) * 10;
    this.maxAge = this.minAge + 9;
    this.cost = this.operationsCost.cost_full_avatar_generation;

    let fake_firstname = this.getRandomFemaleFirstname();
    let fake_lastname = this.getRandomFamilyname();

    if (this.temp_avatar.data.gender == 'male') {
      fake_firstname = this.getRandomMaleFirstname();
    }

    this.form = this.formBuilder.group({
      family_name: [fake_lastname, Validators.required],
      given_name: [fake_firstname, Validators.required],
      marketing_age: [this.minAge + 5, Validators.required],
      marketing_height: ['175', Validators.required],
      marketing_hair_color: [
        this.capitalizeFirstLetter(this.temp_avatar.data.hair_color),
        Validators.required,
      ],
      marketing_eye_color: [
        this.capitalizeFirstLetter(this.temp_avatar.data.eye_color),
        Validators.required,
      ],
      marketing_zodiac_sign: ['Aquarius', Validators.required],
      marketing_blood_type: ['A', Validators.required],
      marketing_temper: ['spirited', Validators.required],
      marketing_history: [
        "Jean wasn't born under the spotlight. He traded a predictable life for a one-way bus ticket to the city of dreams. With a worn suitcase full of ambition and a heart brimming with talent (be it singing, acting, or something else entirely), he left behind the familiar comfort for the exhilarating unknown, determined to turn his passion for stardom into reality.",
        Validators.required,
      ],
    });

  }

  submit() {
    let avatar: Avatar = new Avatar();
    let avatarData: AvatarData = new AvatarData();

    avatar.data = avatarData;
    avatar.updateType = 'PUBLIC_PROFILE_TEXTS';
    avatarData.family_name = this.form.controls.family_name.value;
    avatarData.given_name = this.form.controls.given_name.value;
    avatarData.marketing_age = this.form.controls.marketing_age.value;
    avatarData.marketing_height = this.form.controls.marketing_height.value;
    avatarData.marketing_hair_color =
      this.form.controls.marketing_hair_color.value;
    avatarData.marketing_eye_color =
      this.form.controls.marketing_eye_color.value;
    avatarData.marketing_zodiac_sign =
      this.form.controls.marketing_zodiac_sign.value;
    avatarData.marketing_blood_type =
      this.form.controls.marketing_blood_type.value;
    avatarData.marketing_temper = this.form.controls.marketing_temper.value;
    avatarData.marketing_history = this.form.controls.marketing_history.value;

    //avatarData.img_uri =  this.portrait_image;
    avatarData.img_guid = this.guid;

    if (this.form.valid) {
      this.dialogRef.close(avatar);
    }
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }

  formatAge(value) {
    return value;
  }

  formatSize(value) {
    return value / 100 + 'm';
  }

  getRandomFemaleFirstname() {
    let max = this.femaleNames.length;
    let min = 0;

    // range is between 0 (inclusive) and length (exclusive)

    let random = Math.floor(Math.random() * (max - min + 1)) + min;

    return this.femaleNames[random];
  }

  getRandomMaleFirstname() {
    let max = this.maleNames.length;
    let min = 0;

    // range is between 0 (inclusive) and length (exclusive)

    let random = Math.floor(Math.random() * (max - min + 1)) + min;

    return this.maleNames[random];
  }

  getRandomFamilyname() {
    let max = this.familyNames.length;
    let min = 0;

    // range is between 0 (inclusive) and length (exclusive)

    let random = Math.floor(Math.random() * (max - min + 1)) + min;

    return this.familyNames[random];
  }

  capitalizeFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1);
  }

  familyNames = [
    'Smith', 'Johnson', 'Williams', 'Brown', 'Jones', 'Garcia', 'Miller', 'Davis', 'Rodriguez', 'Martinez',
    'Hernandez', 'Lopez', 'Gonzalez', 'Wilson', 'Anderson', 'Thomas', 'Taylor', 'Moore', 'Jackson', 'Martin',
    'Lee', 'Perez', 'Thompson', 'White', 'Harris', 'Sanchez', 'Clark', 'Ramirez', 'Lewis', 'Robinson',
    'Walker', 'Young', 'Allen', 'King', 'Wright', 'Scott', 'Torres', 'Nguyen', 'Hill', 'Flores',
    'Green', 'Adams', 'Nelson', 'Baker', 'Hall', 'Rivera', 'Campbell', 'Mitchell', 'Carter', 'Roberts',
    'Gomez', 'Phillips', 'Evans', 'Turner', 'Diaz', 'Parker', 'Cruz', 'Edwards', 'Collins', 'Reyes',
    'Stewart', 'Morris', 'Morales', 'Murphy', 'Cook', 'Rogers', 'Gutierrez', 'Ortiz', 'Morgan', 'Cooper',
    'Peterson', 'Bailey', 'Reed', 'Kelly', 'Howard', 'Ramos', 'Kim', 'Cox', 'Ward', 'Richardson',
    'Watson', 'Brooks', 'Chavez', 'Wood', 'James', 'Bennett', 'Gray', 'Mendoza', 'Ruiz', 'Hughes',
    'Price', 'Alvarez', 'Castillo', 'Sanders', 'Patel', 'Myers', 'Long', 'Ross', 'Foster', 'Jimenez',
    'Powell', 'Jenkins', 'Perry', 'Russell', 'Sullivan', 'Bell', 'Coleman', 'Butler', 'Henderson', 'Barnes',
    'Gonzales', 'Fisher', 'Vasquez', 'Simmons', 'Romero', 'Jordan', 'Patterson', 'Alexander', 'Hamilton', 'Graham',
    'Reynolds', 'Griffin', 'Wallace', 'Moreno', 'West', 'Cole', 'Hayes', 'Bryant', 'Herrera', 'Gibson',
    'Ellis', 'Tran', 'Medina', 'Aguilar', 'Stevens', 'Murray', 'Ford', 'Castro', 'Marshall', 'Owens',
    'Harrison', 'Fernandez', 'McDonald', 'Woods', 'Washington', 'Kennedy', 'Wells', 'Vargas', 'Henry', 'Chen',
    'Freeman', 'Webb', 'Tucker', 'Guzman', 'Burns', 'Crawford', 'Olson', 'Simpson', 'Porter', 'Hunter',
    'Gordon', 'Mendez', 'Silva', 'Shaw', 'Snyder', 'Mason', 'Dixon', 'Muñoz', 'Hunt', 'Hicks',
    'Holmes', 'Palmer', 'Wagner', 'Black', 'Robertson', 'Boyd', 'Rose', 'Stone', 'Salazar', 'Fox',
    'Warren', 'Mills', 'Meyer', 'Rice', 'Schmidt', 'Garza', 'Daniels', 'Ferguson', 'Nichols', 'Stephens',
    'Soto', 'Weaver', 'Ryan', 'Gardner', 'Payne', 'Grant', 'Dunn', 'Kelley', 'Spencer', 'Hawkins',
    'Arnold', 'Pierce', 'Vazquez', 'Hansen', 'Peters', 'Santos', 'Hart', 'Bradley', 'Knight', 'Elliott',
    'Cunningham', 'Duncan', 'Armstrong', 'Hudson', 'Carroll', 'Lane', 'Riley', 'Andrews', 'Alvarado', 'Ray',
    'Delgado', 'Berry', 'Perkins', 'Hoffman', 'Johnston', 'Matthews', 'Pena', 'Richards', 'Contreras', 'Willis',
    'Carpenter', 'Lawrence', 'Sandoval', 'Guerrero', 'George', 'Chapman', 'Rios', 'Estrada', 'Ortega', 'Watkins',
    'Greene', 'Nunez', 'Wheeler', 'Valdez', 'Harper', 'Burke', 'Larson', 'Santiago', 'Maldonado', 'Morrison',
    'Franklin', 'Carlson', 'Austin', 'Dominguez', 'Carr', 'Lawson', 'Jacobs', 'O\'Brien', 'Lynch', 'Singh',
    'Vega', 'Bishop', 'Montgomery', 'Oliver', 'Jensen', 'Harvey', 'Williamson', 'Gilbert', 'Dean', 'Sims',
    'Espinoza', 'Howell', 'Li', 'Wong', 'Reid', 'Hanson', 'Le', 'McCoy', 'Garrett', 'Burton',
    'Fuller', 'Wang', 'Weber', 'Welch', 'Rojas', 'Lucas', 'Marquez', 'Fields', 'Park', 'Yang',
    'Little', 'Banks', 'Padilla', 'Day', 'Walsh', 'Bowman', 'Schultz', 'Luna', 'Fowler', 'Mejia',
    'Davidson', 'Acosta', 'Brewer', 'May', 'Holland', 'Juarez', 'Newman', 'Pearson', 'Curtis', 'Cortez',
    'Douglas', 'Schneider', 'Joseph', 'Barrett', 'Navarro', 'Figueroa', 'Keller', 'Avila', 'Wade', 'Molina',
    'Stanley', 'Hopkins', 'Campos', 'Barnett', 'Bates', 'Chambers', 'Caldwell', 'Beck', 'Lambert', 'Miranda',
    'Byrd', 'Craig', 'Ayala', 'Lowe', 'Frazier', 'Powers', 'Neal', 'Leonard', 'Gregory', 'Carrillo',
    'Sutton', 'Fleming', 'Rhodes', 'Shelton', 'Schwartz', 'Norris', 'Jennings', 'Watts', 'Duran', 'Walters',
    'Cohen', 'McDaniel', 'Moran', 'Parks', 'Steele', 'Vaughn', 'Becker', 'Holt', 'DeLeon', 'Barker',
    'Terry', 'Hale', 'Leon', 'Hail', 'Benson', 'Haynes', 'Horton', 'Miles', 'Lyons', 'Pham',
    'Graves', 'Bush', 'Thornton', 'Wolfe', 'Warner', 'Cabrera', 'McKinney', 'Mann', 'Zimmerman', 'Dawson',
    'Lara', 'Fletcher', 'Page', 'Macias', 'Love', 'Robles', 'Cervantes', 'Solis', 'Erickson', 'Reeves',
    'Chang', 'Klein', 'Gallagher', 'Fry', 'Liu', 'Espinosa', 'Ross', 'Preston', 'Byrd', 'Medrano',
    'Shaffer', 'Tanner', 'Hurst', 'Greer', 'Tapia', 'Wade', 'Rivas', 'Shaw', 'Armstrong', 'Christian',
    'Frost', 'Pineda', 'Davenport', 'Henry', 'Ho', 'Gamble', 'King', 'Dillon', 'Hyde', 'Gillespie',
    'Fuentes', 'Gilmore', 'Nash', 'Potts', 'Clay', 'Cameron', 'Barajas', 'Galvan', 'Hodge', 'Barrera',
    'Escobar', 'Cameron', 'Chen', 'Leach', 'Zamora', 'Humphrey', 'Pace', 'Randall', 'Crosby', 'Dickerson',
    'Mack', 'Moyer', 'Petersen', 'Woodward', 'Han', 'Thorne', 'Nielsen', 'Solomon', 'Swanson', 'Martens',
    'Barrera', 'Singh', 'Yu', 'McClain', 'Cruz', 'McDowell', 'Bean', 'Barr', 'Floyd', 'Mueller',
    'Frost', 'Hardy', 'McBride', 'Watts', 'Beck', 'Ayala', 'Goodman', 'Macias', 'Rivera', 'Simon',
    'Craig', 'Walker', 'Schroeder', 'Mendez', 'Koch', 'Allison', 'Wu', 'Parks', 'Hawkins', 'Salas',
    'McGee', 'Barnett', 'Mayer', 'Berg', 'Walters', 'Lam', 'Tran', 'Whitehead', 'Gibbs', 'Nielsen',
    'Curtis', 'Freeman', 'Doyle', 'Hess', 'Horne', 'Fitzgerald', 'Romero', 'McKinney', 'Gaines', 'Carson',
    'Rios', 'Chan', 'Vincent', 'Payne', 'Pacheco', 'Dominguez', 'Horne', 'Joyce', 'Weber', 'Lyons',
    'Simons', 'Stephens', 'Maldonado', 'Bruce', 'Bradshaw', 'Roth', 'Lamb', 'Hinton', 'Hendricks', 'Adkins',
    'Gould', 'Garner', 'Harmon', 'Weaver', 'Manning', 'Anthony', 'Figueroa', 'Hopper', 'Newton', 'Collins',
    'Ramsey', 'Goodwin', 'Becker', 'Gonzales', 'Ramos', 'Herrera', 'Ryan', 'Schmidt', 'Harper', 'Rivas',
    'Stevens', 'Hoffman', 'Bradley', 'Holmes', 'Hawkins', 'Lopez', 'Reid', 'Flynn', 'Warner', 'McGuire',
    'Hardy', 'Stevenson', 'Pollard', 'Hanson', 'Frost', 'Burgess', 'Wall', 'Ho', 'Ryan', 'Santos',
    'Jacobson', 'Sloan', 'Foster', 'Manning', 'Wallace', 'Sullivan', 'Brady', 'Perry', 'Farrell', 'Morris',
    'Williamson', 'Watson', 'Brock', 'Santana', 'Flynn', 'Hale', 'Fisher', 'Quinn', 'Kline', 'Cooper',
    'Higgins', 'Soto', 'Austin', 'Mendez', 'Ford', 'Jordan', 'O\'Neill', 'Gray', 'Riley', 'Hayes',
    'Adams', 'Rios', 'Thomas', 'Yates', 'Reed', 'Mills', 'Barnes', 'Collier', 'Ramos', 'Joseph',
    'Black', 'Guzman', 'Washington', 'Fleming', 'Turner', 'Wood', 'Conway', 'Moran', 'Barrett', 'Waters',
    'Richardson', 'Atkins', 'Powers', 'Curtis', 'Sharp', 'Doyle', 'Jensen', 'Moss', 'Luna', 'Cruz',
    'Burton', 'Manning', 'Rice', 'Cruz', 'Pena', 'Thornton', 'Kelly', 'Carter', 'Phillips', 'George',
    'Castillo', 'Nash', 'Stephens', 'Khan', 'Sparks', 'Roberts', 'Williams', 'Lawson', 'Vega', 'Hardin',
    'Sanders', 'Neal', 'Larson', 'Park', 'Hamilton', 'Blake', 'Stanton', 'Hunt', 'Parker', 'Fitzpatrick',
    'Thomas', 'Casey', 'Alvarez', 'Raymond', 'Spencer', 'Evans', 'Cruz', 'Perry', 'Davidson', 'Wilson',
    'Cooke', 'Keller', 'Reid', 'Griffith', 'Reed', 'Manning', 'Morales', 'Mendoza', 'Bates', 'Jones',
    'Price', 'Dean', 'Murphy', 'Roberts', 'Cox', 'Cooke', 'Butler', 'Hart', 'Hale', 'Bell',
    'Hale', 'Jordan', 'Garcia', 'Russell', 'Ross', 'Turner', 'Koch', 'Weber', 'Rice', 'Day',
    'Farmer', 'Stokes', 'Brown', 'Weber', 'Watson', 'Barnett', 'Hale', 'Matthews', 'Sanders', 'Rice',
    'Cameron', 'Chavez', 'Schmidt', 'Spencer', 'Nash', 'Tran', 'Carlson', 'Sullivan', 'Martinez', 'Palmer',
    'Casey', 'Campbell', 'Long', 'Oliver', 'Pearson', 'Ramos', 'Shaw', 'Davidson', 'Palmer', 'Cruz',
    'Ferguson', 'Cook', 'Cox', 'Torres', 'Young', 'Campbell', 'Lawson', 'Butler', 'Garrett', 'Long',
    'Sims', 'Bates', 'Hughes', 'Rodgers', 'Cook', 'Franklin', 'Mason', 'Mack', 'Gomez', 'Cooke',
    'Phillips', 'Reid', 'Dunn', 'Hoffman', 'Harris', 'Howard', 'Cook', 'Scott', 'George', 'Taylor',
    'Thompson', 'Lewis', 'Ramos', 'Wright', 'Morrison', 'Martin', 'Russell', 'Collins', 'Brown', 'Green',
    'Fisher', 'Harrison', 'Sanders', 'Jones', 'Hughes', 'Garcia', 'Ramos', 'Torres', 'Dunn', 'Phillips',
    'Ramos', 'Nelson', 'Campbell', 'Cruz', 'Jones', 'Reid', 'Parker', 'Hale', 'Bennett', 'Cole',
    'Ross', 'Richardson', 'Smith', 'Green', 'Jenkins', 'Morris', 'Miller', 'Hill', 'Rivera', 'Hughes',
    'Phillips', 'Howard', 'Baker', 'Clark', 'Wood', 'Williams', 'Cooper', 'Hughes', 'Foster', 'Brown',
    'Richardson', 'Morris', 'King', 'Jones', 'Watson', 'Martinez', 'Turner', 'Gonzalez', 'Nelson', 'Miller'
];


 femaleNames = [
  'Emma', 'Olivia', 'Ava', 'Isabella', 'Sophia', 'Mia', 'Charlotte', 'Amelia', 'Evelyn', 'Abigail',
  'Harper', 'Emily', 'Elizabeth', 'Avery', 'Sofia', 'Ella', 'Madison', 'Scarlett', 'Victoria', 'Aria',
  'Grace', 'Chloe', 'Camila', 'Penelope', 'Riley', 'Layla', 'Lillian', 'Nora', 'Zoey', 'Mila',
  'Aubrey', 'Hannah', 'Lily', 'Addison', 'Eleanor', 'Natalie', 'Luna', 'Savannah', 'Brooklyn', 'Leah',
  'Zoe', 'Stella', 'Hazel', 'Ellie', 'Paisley', 'Audrey', 'Skylar', 'Violet', 'Claire', 'Bella',
  'Aurora', 'Lucy', 'Anna', 'Samantha', 'Caroline', 'Genesis', 'Aaliyah', 'Kennedy', 'Kinsley', 'Allison',
  'Maya', 'Sarah', 'Madelyn', 'Adeline', 'Alexa', 'Ariana', 'Elena', 'Gabriella', 'Naomi', 'Alice',
  'Sadie', 'Hailey', 'Eva', 'Emilia', 'Autumn', 'Quinn', 'Nevaeh', 'Piper', 'Ruby', 'Serenity',
  'Willow', 'Everly', 'Cora', 'Kaylee', 'Lydia', 'Aubree', 'Arianna', 'Eliana', 'Peyton', 'Melanie',
  'Gianna', 'Isabelle', 'Julia', 'Valentina', 'Nova', 'Clara', 'Vivian', 'Reagan', 'Mackenzie', 'Madeline',
  'Brianna', 'Bailey', 'Destiny', 'Rylee', 'Katherine', 'Sophie', 'Josephine', 'Ivy', 'Liliana', 'Jade',
  'Maria', 'Taylor', 'Hadley', 'Kylie', 'Emery', 'Adalynn', 'Natalia', 'Annabelle', 'Faith', 'Alexandra',
  'Ximena', 'Ashley', 'Brielle', 'Ryleigh', 'Jocelyn', 'Kimberly', 'Andrea', 'Valeria', 'Arabella', 'Mary',
  'Athena', 'Lila', 'Margaret', 'Alyssa', 'Juliana', 'Eden', 'Melody', 'Isla', 'Brooke', 'Trinity',
  'Payton', 'Eliza', 'Londyn', 'Laila', 'Daniela', 'Jordyn', 'Teagan', 'Alaina', 'Collins', 'Gracie',
  'Adalyn', 'Brooke', 'Journee', 'Julianna', 'Sloane', 'Catherine', 'Lyla', 'Sydney', 'Genevieve', 'Norah',
  'Rosalie', 'Emerson', 'Reese', 'Mckenna', 'Finley', 'Daleyza', 'Arabella', 'Rachel', 'Summer', 'Malia',
  'Joanna', 'Marley', 'Kennedi', 'Daisy', 'Harmony', 'Adelyn', 'Freya', 'Giselle', 'Lauren', 'Amy',
  'Sienna', 'Fiona', 'Ophelia', 'Charlie', 'Alana', 'Juniper', 'Maddison', 'Madilyn', 'Gemma', 'Blake',
  'Noelle', 'Anastasia', 'Alayna', 'Ana', 'Leila', 'Mckenna', 'Maeve', 'Amara', 'Mya', 'Mariana',
  'Vivienne', 'Angela', 'Dakota', 'Georgia', 'Callie', 'Elaina', 'Lola', 'Juliette', 'Rosie', 'Regina',
  'Adaline', 'Aurora', 'Sage', 'Lyric', 'Olive', 'Celeste', 'Meadow', 'Kenzie', 'Camille', 'Maggie',
  'Gracelyn', 'Journee', 'Tessa', 'Miriam', 'Mikayla', 'Gwendolyn', 'Nina', 'Brynlee', 'Blakely', 'Carmen',
  'Evie', 'Emory', 'Brinley', 'Zara', 'Abby', 'Amber', 'Esmeralda', 'Tatum', 'Dahlia', 'Mckenna',
  'Makayla', 'Nyla', 'Ana', 'Malia', 'Adelynn', 'Alivia', 'Brooklynn', 'Charlee', 'Makenna', 'Eloise',
  'Ada', 'Elise', 'Lilah', 'Adelaide', 'Athena', 'Kate', 'Ruth', 'Amiyah', 'Jordan', 'Selena',
  'Nyla', 'Adriana', 'Evangeline', 'Demi', 'Sienna', 'Briella', 'Ariella', 'Annie', 'Catalina', 'Ayla',
  'Sabrina', 'Nayeli', 'Phoenix', 'Gemma', 'Mckenzie', 'Talia', 'Gia', 'Winter', 'Nina', 'Izabella',
  'Raegan', 'Harlow', 'Heaven', 'Dylan', 'Thea', 'Kali', 'Ainsley', 'Alani', 'Aitana', 'Zariah',
  'Elsie', 'Renata', 'Camilla', 'Viviana', 'Briar', 'Willa', 'Kenzie', 'Alessandra', 'Edith', 'Kaylani',
  'Brielle', 'Noa', 'Celine', 'Zelda', 'Alejandra', 'Samara', 'Rory', 'Lennon', 'Lyra', 'Milani',
  'Madelynn', 'Hattie', 'Nadia', 'Saige', 'Holly', 'Mabel', 'Aubrie', 'Malia', 'Nyla', 'Avianna',
  'Jessica', 'Elsa', 'Frankie', 'Lucille', 'Armani', 'Paloma', 'Journi', 'April', 'Jolene', 'Alma',
  'Zariyah', 'Anaya', 'Sarai', 'Monroe', 'Charleigh', 'Dorothy', 'Fernanda', 'Amora', 'Elianna', 'Cecilia',
  'Aisha', 'Emelia', 'Mariam', 'Freya', 'Zaylee', 'Colette', 'Angelina', 'Mira', 'Liv', 'Chelsea',
  'Skyler', 'Aniyah', 'Jayda', 'Annalise', 'Hayley', 'Elaine', 'Elianna', 'Mercy', 'Penny', 'Kira',
  'Bianca', 'Everleigh', 'Laney', 'Raya', 'Charli', 'Malaysia', 'Raelynn', 'Katalina', 'Antonella', 'Kalani',
  'Mylah', 'Chaya', 'Henley', 'Beatrice', 'Livia', 'Lorelei', 'Madisyn', 'Maliyah', 'Aileen', 'Helena',
  'Gwendolyn', 'Camille', 'Sloane', 'Emmy', 'Zaria', 'Cassandra', 'Winter', 'Elora', 'Julieta', 'Frances',
  'Mavis', 'Louisa', 'Hallie', 'Briana', 'Eve', 'Journee', 'Michaela', 'Lyric', 'Francesca', 'Danna',
  'Meredith', 'Averie', 'Maliyah', 'Raelyn', 'Kelly', 'Hope', 'Noelle', 'Esme', 'Aurelia', 'Ivanna',
  'Elora', 'Avah', 'Amelie', 'Celeste', 'Kinslee', 'Estella', 'Jayleen', 'Marlee', 'Galilea', 'Dayana',
  'Lilian', 'Annabella', 'Reina', 'Lailah', 'Tatiana', 'Keira', 'Jolie', 'Belle', 'Nola', 'Aliana',
  'Zora', 'Ashlynn', 'Kadence', 'Miah', 'Naya', 'Amaris', 'Juliet', 'Rebekah', 'Landry', 'Aviana',
  'Lennox', 'Mara', 'Jayda', 'Alaya', 'Opal', 'Emmalyn', 'Macy', 'Elodie', 'Adley', 'Logan',
  'Kamila', 'Elianna', 'Addilyn', 'Azalea', 'Briana', 'Jaelyn', 'Lylah', 'Amelie', 'Alyson', 'Malaya',
  'Kynlee', 'Amina', 'Emmy', 'Karsyn', 'Nathalie', 'Oaklynn', 'Rosalyn', 'Scarlette', 'Kenzie', 'Galilea',
  'Aviana', 'Kira', 'Camryn', 'Lainey', 'Jaliyah', 'Mara', 'Freyja', 'Madelynn', 'Laney', 'Annalise',
  'Lorelai', 'Kensley', 'Zahra', 'Clarissa', 'Elliot', 'Justice', 'Annika', 'Kailani', 'Karter', 'Maisie',
  'Leona', 'Zaniyah', 'Oaklee', 'Paola', 'Navy', 'Roselyn', 'Maliyah', 'Alena', 'Lucia', 'Zariah',
  'Nala', 'Myla', 'Maci', 'Amaris', 'Angie', 'Jaycee', 'Rory', 'Megan', 'Celine', 'Lorelai',
  'Kayleigh', 'Malaysia', 'Adelina', 'Kenna', 'Hadleigh', 'Renata', 'Laney', 'Julissa', 'Mara', 'Annabel',
  'Averie', 'Emmeline', 'Remington', 'Jayleen', 'Paige', 'Emory', 'Charleigh', 'Elliot', 'Julissa', 'Leighton',
  'Mallory', 'Aitana', 'Elora', 'Kenna', 'Aliza', 'Esme', 'Lina', 'Annalee', 'Mikaela', 'Marlee',
  'Addilynn', 'Kynlee', 'Kaylie', 'Frida', 'Greta', 'Vienna', 'Aubriella', 'Amelie', 'Jaylee', 'Karsyn',
  'Avah', 'Bria', 'Alyvia', 'Jaylah', 'Zariyah', 'Rosalyn', 'Jessie', 'Alisson', 'Amaia', 'Ariadne',
  'Addisyn', 'Mylah', 'Noor', 'Elliott', 'Katalina', 'Zola', 'Ellianna', 'Mara', 'Cecelia', 'Winnie',
  'Jovie', 'Flora', 'Milena', 'Corinne', 'Kehlani', 'Baylee', 'Lennon', 'Amalia', 'Vada', 'Paloma',
  'Livia', 'Nina', 'Heidi', 'Mae', 'Fernanda', 'Briana', 'Sky', 'Lana', 'Nola', 'Kairi',
  'Harmoni', 'Keira', 'Valery', 'Elina', 'Jaelyn', 'Elaine', 'Katalina', 'Elyse', 'Aubrielle', 'Coraline',
  'Braelyn', 'Nalani', 'Leslie', 'Nola', 'Ari', 'Oakley', 'Rylan', 'Emerie', 'Alisson', 'Evelynn',
  'Naya', 'Galilea', 'Liv', 'Emory', 'Rowan', 'Anahi', 'Serena', 'Louisa', 'Alma', 'Mina',
  'Veda', 'Addisyn', 'Aila', 'Ivory', 'Addyson', 'Zelda', 'Rayna', 'Noemi', 'Mylah', 'Milana',
  'Paulina', 'Ariadne', 'Holland', 'Malayah', 'Avah', 'Adelina', 'Brylee', 'Kimber', 'Alisson', 'Louisa',
  'Hadlee', 'Mara', 'Royal', 'Alani', 'Lexie', 'Aubriella', 'Kamryn', 'Kaylani', 'Charleigh', 'Lyra',
  'Nala', 'Mae', 'Lina', 'Emmie', 'Katalina', 'Averie', 'Iyla', 'Collins', 'Belen', 'Aubriella',
  'Maliyah', 'Scout', 'Zaylee', 'Collins', 'Kenley', 'Giavanna', 'Halo', 'Oaklyn', 'Ariyah', 'Aurelia',
  'Naya', 'Kaylynn', 'Callie', 'Sky', 'Allyson', 'Fallon', 'Aliza', 'Julissa', 'Lorelei', 'Veda',
  'Allyson', 'Azariah', 'Milana', 'Keilani', 'Marianna', 'Janae', 'Whitley', 'Kai', 'Lainey', 'Zaylee',
  'Ensley', 'Amayah', 'Vienna', 'Milena', 'Ellison', 'Palmer', 'Cleo', 'Kora', 'Zelda', 'Malani',
  'Emilee', 'Alannah', 'Julieta', 'Lillie', 'Lilyana', 'Capri', 'Veda', 'Zariyah', 'Blaire', 'Kamiyah',
  'Kaia', 'Ensley', 'Mavis', 'Corinne', 'Alannah', 'Karter', 'Zahra', 'Halo', 'Belen', 'Rylan',
  'Kimberly', 'Marianna', 'Vada', 'Annika', 'Sariyah', 'Vienna', 'Willa', 'Sky', 'Analia', 'Estella',
  'Lina', 'Belen', 'Braylee', 'Saige', 'Hadleigh', 'Galilea', 'Zora', 'Amayah', 'Aubriella', 'Rylan',
  'Oaklee', 'Rhea', 'Kai', 'Kenley', 'Sariyah', 'Kynlee', 'Zora', 'Estella', 'Aiyana', 'Camilla',
  'Coraline', 'Amayah', 'Bexley', 'Leanna', 'Kai', 'Noa', 'Milena', 'Aliya', 'Paityn', 'Aila',
  'Ari', 'Julieta', 'Nathalie', 'Analia', 'Winnie', 'Lacey', 'Keira', 'Evelynn', 'Belen', 'Milana'
];
  maleNames = [
  'Liam', 'Noah', 'William', 'James', 'Oliver', 'Benjamin', 'Elijah', 'Lucas', 'Mason', 'Logan',
  'Alexander', 'Ethan', 'Jacob', 'Michael', 'Daniel', 'Henry', 'Jackson', 'Sebastian', 'Aiden', 'Matthew',
  'Samuel', 'David', 'Joseph', 'Carter', 'Owen', 'Wyatt', 'John', 'Jack', 'Luke', 'Jayden',
  'Dylan', 'Grayson', 'Levi', 'Isaac', 'Gabriel', 'Julian', 'Mateo', 'Anthony', 'Jaxon', 'Lincoln',
  'Joshua', 'Christopher', 'Andrew', 'Theodore', 'Caleb', 'Ryan', 'Asher', 'Nathan', 'Thomas', 'Leo',
  'Isaiah', 'Charles', 'Josiah', 'Hudson', 'Christian', 'Hunter', 'Connor', 'Eli', 'Ezra', 'Aaron',
  'Landon', 'Adrian', 'Jonathan', 'Nolan', 'Jeremiah', 'Easton', 'Elias', 'Colton', 'Cameron', 'Carson',
  'Robert', 'Angel', 'Maverick', 'Nicholas', 'Dominic', 'Jaxson', 'Greyson', 'Adam', 'Ian', 'Austin',
  'Santiago', 'Jordan', 'Cooper', 'Brayden', 'Roman', 'Evan', 'Ezekiel', 'Xavier', 'Jose', 'Jace',
  'Jameson', 'Leonardo', 'Bryson', 'Axel', 'Everett', 'Parker', 'Kayden', 'Miles', 'Sawyer', 'Jason',
  'Declan', 'Weston', 'Micah', 'Ayden', 'Wesley', 'Luca', 'Vincent', 'Damian', 'Zachary', 'Silas',
  'Gavin', 'Chase', 'Kai', 'Emmett', 'Harrison', 'Nathaniel', 'Kingston', 'Cole', 'Tyler', 'Bennett',
  'Bentley', 'Ryker', 'Tristan', 'Brandon', 'Kevin', 'Luis', 'George', 'Ashton', 'Rowan', 'Braxton',
  'Ryder', 'Gael', 'Ivan', 'Diego', 'Maxwell', 'Max', 'Carlos', 'Kaiden', 'Juan', 'Maddox',
  'Justin', 'Waylon', 'Calvin', 'Giovanni', 'Jonah', 'Abel', 'Jayce', 'Jesus', 'Amir', 'King',
  'Beau', 'Camden', 'Alex', 'Jasper', 'Malachi', 'Brody', 'Jude', 'Blake', 'Emmanuel', 'Eric',
  'Brooks', 'Elliott', 'Antonio', 'Abraham', 'Timothy', 'Finn', 'Rhett', 'Elliot', 'Edward', 'August',
  'Xander', 'Alan', 'Dean', 'Lorenzo', 'Bryce', 'Karter', 'Victor', 'Milo', 'Miguel', 'Hayden',
  'Graham', 'Grant', 'Zion', 'Tucker', 'Jesse', 'Zayden', 'Joel', 'Richard', 'Patrick', 'Emiliano',
  'Avery', 'Nicolas', 'Brantley', 'Dawson', 'Myles', 'Matteo', 'River', 'Steven', 'Thiago', 'Zane',
  'Matias', 'Judah', 'Messiah', 'Jeremy', 'Preston', 'Oscar', 'Kaleb', 'Alejandro', 'Marcus', 'Mark',
  'Peter', 'Maximus', 'Barrett', 'Jax', 'Andres', 'Holden', 'Legend', 'Charlie', 'Knox', 'Kaden',
  'Paxton', 'Kyrie', 'Kyle', 'Griffin', 'Josue', 'Kenneth', 'Beckett', 'Enzo', 'Adriel', 'Arthur',
  'Felix', 'Bryan', 'Lukas', 'Paul', 'Brian', 'Colt', 'Caden', 'Leon', 'Archer', 'Omar',
  'Israel', 'Aidan', 'Theo', 'Javier', 'Remington', 'Jaden', 'Bradley', 'Emilio', 'Colin', 'Riley',
  'Cayden', 'Phoenix', 'Clayton', 'Simon', 'Ace', 'Nash', 'Derek', 'Rafael', 'Zander', 'Brady',
  'Jorge', 'Jake', 'Louis', 'Damien', 'Karson', 'Walker', 'Maximiliano', 'Amari', 'Sean', 'Chance',
  'Walter', 'Martin', 'Finley', 'Andre', 'Tobias', 'Cash', 'Corbin', 'Arlo', 'Iker', 'Erick',
  'Emerson', 'Gunner', 'Cody', 'Stephen', 'Francisco', 'Killian', 'Dallas', 'Reid', 'Manuel', 'Lane',
  'Atlas', 'Ronan', 'Jensen', 'Keegan', 'Kash', 'Nico', 'Beckham', 'Cesar', 'Ellis', 'Jaylen',
  'Titus', 'Travis', 'Jett', 'Ricardo', 'Bodhi', 'Gideon', 'Jaiden', 'Fernando', 'Mario', 'Conor',
  'Caiden', 'Dakota', 'Makai', 'Major', 'Luca', 'Ronan', 'Kairo', 'Kason', 'Joaquin', 'Clark',
  'Ali', 'Fabian', 'Ibrahim', 'Zayn', 'Kamdyn', 'Ridge', 'Keenan', 'Armando', 'Finnegan', 'Talon',
  'Zeke', 'Rory', 'Anderson', 'Gannon', 'Huxley', 'Caspian', 'Dominick', 'Kyson', 'Elian', 'Lawson',
  'Callan', 'Koa', 'Soren', 'Stanley', 'Samson', 'Cassius', 'Miller', 'Donald', 'Wells', 'Boden',
  'Madden', 'Flynn', 'Crew', 'Jericho', 'Kylan', 'Langston', 'Marlon', 'Alec', 'Mohammed', 'Jasiah',
  'Ronin', 'Bowen', 'Krew', 'Mohamed', 'Tony', 'Hank', 'Keanu', 'Finnley', 'Kaiser', 'Zyaire',
  'Alaric', 'Rayden', 'Axl', 'Stetson', 'Jerry', 'Rocco', 'Aziel', 'Odin', 'Ares', 'Roy',
  'Reed', 'Leonel', 'Carmelo', 'Hugh', 'Saul', 'Scott', 'Finnick', 'Morgan', 'Rayan', 'Kingsley',
  'Ridge', 'Bentlee', 'Bishop', 'Atlas', 'Rowen', 'Kendrick', 'Joziah', 'Bo', 'Marvin', 'Sage',
  'Frederick', 'Harvey', 'Moses', 'Royce', 'Quentin', 'Harlan', 'Kamari', 'Zaire', 'Ameer', 'Demetrius',
  'Kellen', 'Brycen', 'Ricky', 'Fox', 'Trace', 'Briar', 'Graysen', 'Kace', 'Desmond', 'Wade',
  'Sam', 'Dustin', 'Orlando', 'Cairo', 'Braylon', 'Dillon', 'Kannon', 'Jonas', 'Princeton', 'Rex',
  'Darius', 'Drake', 'Khalid', 'Duke', 'Kye', 'Nikolai', 'Westley', 'Eithan', 'Maxton', 'Colson',
  'Ahmed', 'Saul', 'Ford', 'Matthias', 'Ramon', 'Justice', 'Noe', 'Dennis', 'Alden', 'Trent',
  'Hassan', 'Mohammad', 'Baker', 'Saint', 'Jaxx', 'Rene', 'Douglas', 'Larry', 'Ramon', 'Uriel',
  'Jayson', 'Jaime', 'Scott', 'Lawrence', 'Alvin', 'Darren', 'Brendan', 'Sterling', 'Wayne', 'Dax',
  'Emory', 'Jon', 'Winston', 'Zechariah', 'Albert', 'Neil', 'Apollo', 'Lionel', 'Jefferson', 'Conner',
  'Fletcher', 'Isaias', 'Jamison', 'Brock', 'Leandro', 'Dominik', 'Marshall', 'Kaysen', 'Alfredo', 'Jalen',
  'Maurice', 'Jerry', 'Neil', 'Chaim', 'Brennan', 'Case', 'Leonard', 'Jalen', 'Alessandro', 'Ruben',
  'Moshe', 'Nasir', 'Salvador', 'Alfonso', 'Kian', 'Rhys', 'Marcos', 'Mathew', 'Bruce', 'Noel',
  'Kelvin', 'Johan', 'Gianni', 'Santino', 'Magnus', 'Tate', 'Bruno', 'Edison', 'Arjun', 'Zaid',
  'Boone', 'Royce', 'Koda', 'Azariah', 'Skyler', 'Colter', 'Cullen', 'Jedidiah', 'Kellan', 'Lucian',
  'Valentin', 'Reese', 'Marcelo', 'Allan', 'Ray', 'Rocky', 'Sutton', 'Talon', 'Jensen', 'Kase',
  'Giovani', 'Hamza', 'Jagger', 'Tripp', 'Kohen', 'Byron', 'Harlan', 'Kylan', 'Kristian', 'Jaxen',
  'Malakai', 'Dane', 'Boden', 'Jabari', 'Canaan', 'Chad', 'Alistair', 'Tommy', 'Guillermo', 'Johnny',
  'Darren', 'Fisher', 'Dario', 'Braden', 'Arian', 'Kristopher', 'Toby', 'Mayson', 'Oakley', 'Lionel',
  'Branson', 'Tomas', 'Henrik', 'Darian', 'Jacoby', 'Billy', 'Gustavo', 'Max', 'Ty', 'Otis',
  'Quincy', 'Porter', 'Seth', 'Jared', 'Rodney', 'Leroy', 'Cruz', 'Asa', 'Jadon', 'Kane',
  'Hugo', 'Mitchell', 'Brett', 'Cody', 'Edwin', 'Jamari', 'Cyrus', 'Terry', 'Lawson', 'Russell',
  'Aron', 'Adonis', 'Rafael', 'Cory', 'Davion', 'Trent', 'Johan', 'Nixon', 'Rashad', 'Jeffrey',
  'Zion', 'Kellen', 'Maximus', 'Keaton', 'Sullivan', 'Beckham', 'Melvin', 'Shaun', 'Jayceon', 'Braiden',
  'Stefan', 'Reece', 'Zaid', 'Jadiel', 'Kyler', 'Turner', 'Bodie', 'Kendall', 'Sincere', 'Jacobi',
  'Fletcher', 'Branson', 'Pablo', 'Damon', 'Gage', 'Alvin', 'Tate', 'Harvey', 'Santino', 'Bruno',
  'Luciano', 'Payton', 'Cairo', 'Rocco', 'Landen', 'Dominick', 'Deacon', 'Tadeo', 'Nasir', 'Mathias',
  'Jayson', 'Frederick', 'Dax', 'Quentin', 'Raiden', 'Emanuel', 'Zaiden', 'Remy', 'Amos', 'Marlon',
  'Skylar', 'Roland', 'Koda', 'Chaim', 'Ricky', 'Mitchell', 'Dillon', 'Lee', 'Sterling', 'Dennis',
  'Kingsley', 'Jagger', 'Darius', 'Jamir', 'Emmitt', 'Colby', 'Alden', 'Gianni', 'Reuben', 'Rocky',
  'Frankie', 'Finnegan', 'Royce', 'Armando', 'Makai', 'Alessandro', 'Casey', 'Tomas', 'Shiloh', 'Kylo',
  'Jonas', 'Riggs', 'Hank', 'Weston', 'Raul', 'Kendrick', 'Madden', 'Knox', 'Bowen', 'Talon',
  'Ramon', 'Cason', 'Jovanni', 'Yusuf', 'Jadiel', 'Maurice', 'Draven', 'Asher', 'Braxton', 'Izaiah',
  'Jaylin', 'Jase', 'Ander', 'Alaric', 'Kody', 'Kyler', 'Willie', 'Jad', 'Samir', 'Wilder',
  'Jaxxon', 'Misael', 'Ridge', 'Jairo', 'Santos', 'Shawn', 'Zavier', 'Lucian', 'Will', 'Freddy',
  'Ray', 'Zaire', 'Dariel', 'Tyrell', 'Caspian', 'Harold', 'Tyrone', 'Jadon', 'Yahir', 'Salem',
  'Ford', 'Axton', 'Kase', 'Crew', 'Trey', 'Raylan', 'Rylan', 'Flynn', 'Royce', 'Tripp',
  'Ishaan', 'Azrael', 'Wes', 'Fisher', 'Darren', 'Dillon', 'Johnny', 'Gannon', 'Rene', 'Kaiser',
  'Mekhi', 'Darwin', 'Dimitri', 'Dion', 'Finnian', 'Krew', 'Benicio', 'Kylan', 'Soren', 'Bjorn',
  'Malakai', 'Jaxen', 'Jovany', 'Case', 'Tommy', 'Amias', 'Dax', 'Judson', 'Elon', 'Grady',
  'Reese', 'Baylor', 'Braydon', 'Max', 'Langston', 'Callum', 'Gustavo', 'Jaziel', 'Leonidas', 'Cael',
  'Ellis', 'Yosef', 'Yousef', 'Brentley', 'Bodhi', 'Corban', 'Zeke', 'Xzavier', 'Niko', 'Canaan',
  'Atlas', 'Ezequiel', 'Ledger', 'Urijah', 'Fox', 'Niklaus', 'Lyric', 'Isaias', 'Ramses', 'Thatcher'
];

}
