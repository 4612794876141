import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AccountService } from '../../services/account.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private accountService: AccountService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {


    // add auth header with jwt if account is logged in and request is to the api url
    const isLoggedIn = this.accountService.idtoken;
    const isApiUrl = request.url.startsWith(environment.apiUrl);

    if (isLoggedIn && isApiUrl) {
     //   console.log('intercept1:' + this.accountService?.idtoken);
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.accountService?.idtoken}`,
          },
        });
    } else {
      //console.log('intercept3:' + this.accountService?.idtoken);
      /*request = request.clone({
        setHeaders: {
          Authorization: ''  // <----- GCS takes that as an attempt to authenticate, then returns 401 !
        },
      });
      */
      request = request.clone({
        headers: request.headers.delete('Authorization')
      });

    }

    return next.handle(request);
  }
}
