  <div class="main">

    <table class="setup">
      <tr class="line_1">
        <td class="lateral"></td>
        <td class="central">

<!--
          <mat-checkbox
          class="example-margin"
          [(ngModel)]="top_checked"
          [(indeterminate)]="top_indeterminate"
          [labelPosition]="top_labelPosition"
          [disabled]="top_disabled">
          TOP </mat-checkbox>
      <input [disabled]='!top_checked' matInput type="text" [(ngModel)]="top_value" class="inputfield">
-->

      <div class="element-name" [class]=" expandTopToggle ? 'expand' : 'deactivated'"  (click)="expandTop()" matToolTip="EXPAND TOPWARD"> <mat-icon class="icon">arrow_upward</mat-icon> </div>

        </td>
        <td class="lateral"></td>
      </tr>
      <tr class="line_2">
        <td class="lateral">

    <!--      <mat-checkbox
          class="example-margin"
          [(ngModel)]="left_checked"
          [(indeterminate)]="left_indeterminate"
          [labelPosition]="left_labelPosition"
          [disabled]="left_disabled">
          LEFT
      </mat-checkbox><input [disabled]='!left_checked' matInput type="text" [(ngModel)]="left_value" class="inputfield">
    -->

      <div class="element-name" [class]=" expandLeftToggle ? 'expand' : 'deactivated'"  (click)="expandLeft()">    <mat-icon class="icon">arrow_backward</mat-icon> </div>
    </td>

    <!-- "../../../../assets/img/zoom_portrait.jpg" -->
        <td class="central"><img src="{{ pic }}"></td>
        <td class="lateral">


       <!--
          <mat-checkbox
          class="example-margin"
          [(ngModel)]="right_checked"
          [(indeterminate)]="right_indeterminate"
          [labelPosition]="right_labelPosition"
          [disabled]="right_disabled">
          RIGHT
      </mat-checkbox>
    <input [disabled]='!right_checked' matInput type="text" [(ngModel)]="right_value" class="inputfield"></td>
  -->

  <div class="element-name" [class]=" expandRightToggle ? 'expand' : 'deactivated'"  (click)="expandRight()"> <mat-icon class="icon">arrow_forward</mat-icon> </div>

      </tr>
      <tr class="line_3">
        <td class="lateral"></td>
        <td class="central">

          <!--
          <mat-checkbox
          class="example-margin"
          [(ngModel)]="bottom_checked"
          [(indeterminate)]="bottom_indeterminate"
          [labelPosition]="bottom_labelPosition"
          [disabled]="bottom_disabled">
          BOTTOM
      </mat-checkbox>
       <input [disabled]='!bottom_checked' matInput type="text" [(ngModel)]="bottom_value" class="inputfield">
    -->

    <div class="element-name" [class]=" expandBottomToggle ? 'expand' : 'deactivated'"  (click)="expandBottom()"> <mat-icon class="icon">arrow_downward</mat-icon> </div>
        </td>
        <td class="lateral"></td>
      </tr>

    </table>
    <div class="footer">
      <button mat-raised-button color="primary" type="button" (click)="onSubmit()">


        <div class="button-text">

          <span class="action-text"> ZOOM OUT !</span>
          <div class="amount">
          <div class="cost">{{ cost_zoomout }} </div>
          <div class="container-icon">
            <mat-icon class="icon"> award_star</mat-icon>
          </div>
          </div>
        </div>




      </button>

    </div>
  </div>

