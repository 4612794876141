<div class="maincontainer">
  <div class="card">
    <h1 class="errorcode"> PLEASE CHECK YOUR MAILBOX ! </h1>
    <div class="errortext"> We need you to activate your account, for security reasons </div>
    <div class="errortext"> Please check your emails and click on the activation link</div>
    <div class="errortext"> Then, you will be ready to roooooock'n roooll !</div>
    <div class="actions">
      <button mat-raised-button color="primary" (click)="relog()"> I ACTIVATED IT !</button>
    </div>
  </div>
</div>
