import { Location, AsyncPipe, NgFor, NgIf } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { Router } from '@angular/router';
import { AvatarService } from '../services/avatar.service';
import { Avatar } from '../shared/entity/avatar';
import { AvatarData } from '../shared/entity/avatar-data';
import { MatDialog } from '@angular/material/dialog';
import { References } from '../shared/entity/references';
import { DialogNamingComponent } from './dialog-naming/dialog-naming.component';
import { MatIconModule } from '@angular/material/icon';
import { CreditService } from '../services/credit.service';
import { Observable, map, startWith } from 'rxjs';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { HairStyleService } from '../services/hairstyle.service';
import { HairStyle } from '../shared/entity/hairstyle';
import { DialogHairstyleInitComponent } from './dialog-hairstyle/dialog-hairstyle.component';
import { OperationsCostService } from '../services/operations-cost';
import { DialogNeedBiscuitComponent } from '../shared/component/dialog-need-biscuit/dialog-need-biscuit.component';
import { PictureService } from '../services/picture.service';

export interface Tile {
  img: string;
  loaded: boolean;
  guid: string; //pictureGuid in base
  start?: number;
  sec?: number;
  decilesec?: number;
  //  enc_r?: string;
  enc_p?: string;
}

@Component({
  selector: 'app-avatar-face-generator-init',
  templateUrl: './avatar-face-generator-init.component.html',
  styleUrl: './avatar-face-generator-init.component.scss',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCardModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    AsyncPipe,
    MatAutocompleteModule,
    MatRadioModule,
    MatChipsModule,
    MatSliderModule,
    MatIconModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AvatarFaceGeneratorInitComponent {
  avatar: Avatar | undefined;
  title: string | undefined;
  subtitle: string | undefined;
  form: FormGroup;
  loaded: boolean = false;
  tile: Tile;
  //processed_tile: Tile;
  thumbs: Tile[] = [];
  inprocess: boolean;
  generateLabel: string;
  gen_done: boolean;
  nationalities: string[];
  filteredOptions: Observable<String[]>;
  cost_preview: number;
  cost_full_avatar_generation: number;
  intervalId;
  counterDone:number=0;

  //pure display UI
  pronoun: string;
  tile_img_src: string;
  hairstyle_image: string;

  constructor(
    public dialog: MatDialog,
    private avatarService: AvatarService,
    private formBuilder: FormBuilder,
    private router: Router,
    private hairStyleService: HairStyleService,
    private creditService: CreditService,
    private pictureService: PictureService,
    public operationsCost: OperationsCostService,
    private _snackbar: MatSnackBar
  ) {}

  private _filter(name: string): String[] {
    const filterValue = name.toLowerCase();
    return this.nationalities.filter((nat) =>
      nat.toLowerCase().includes(filterValue)
    );
  }

  ngOnInit() {
    this.cost_preview = 4 * this.operationsCost.cost_simple;
    this.cost_full_avatar_generation =
      this.operationsCost.cost_full_avatar_generation;

    //setting pure UI
    this.pronoun = 'HIM';
    this.tile_img_src = '../../assets/img/portrait_male.jpg';
    this.hairstyle_image = '/img/hairstyle/choose.webp';

    this.nationalities = this.setupNationalities();

    this.form = this.formBuilder.group({
      gender: ['', Validators.required],
      nationality: ['', [Validators.required, isCorrectNationality]],
      ethnicity: ['', Validators.required],
      hair_color: ['', Validators.required],
      hair_style: ['', Validators.required],
      eye_color: ['', Validators.required],
      age: ['', Validators.required],
      //      artist_name: ['', Validators.required],
      //      family_name: ['', Validators.required],
      //      given_name: ['', Validators.required]
    });

    this.filteredOptions = this.form.controls.nationality.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value;
        return value
          ? this._filter(name as string)
          : this.nationalities.slice();
      })
    );

    this.title = 'GENERATE YOUR AVATAR';
    this.subtitle = 'SETTINGS';
    this.inprocess = false;
    this.gen_done = false;
    this.generateLabel = 'GENERATE FACES';

    this.initThumbs();

    this.avatar = new Avatar();
    this.avatar.data = new AvatarData();
    this.avatar.data.gender = 'male';
    this.avatar.data.age = 20;
    this.avatar.data.active = true;

    //not initiated by default even if displayed
    this.form.controls.gender.setValue(this.avatar.data.gender);
    this.form.controls.age.setValue(this.avatar.data.age);

    this.hairStyleService.getAll().subscribe((res) => {
      this.loaded = true;
    });

    //REFRESH PENDING PICTURES ONLY very 1.8s
    this.intervalId = setInterval(() => {
      //console.log('In !');
      //this.counterDone = 0;

      this.thumbs.forEach((thumb) => {

        if (thumb.loaded == false) {

          if (thumb.guid!=null) {

            this.pictureService.get(thumb.guid).subscribe((newPic) => {
              if (newPic.img_uri != null) {
                thumb.img = newPic.img_uri;
                thumb.loaded = true;
                this.counterDone++;

              }

              if (this.counterDone==4) {
                this.tile = this.thumbs[0];
                this.inprocess=false;
                this.counterDone = 0;
              }

            });
          }
        }
      });
    }, 1800);
  }

  ngOnDestroy() {
    //console.log("Destroy !");
    clearInterval(this.intervalId);
  }

  changeGenderSelected(event) {
    if (event.value == 'male') {
      this.pronoun = 'HIM';
      this.tile_img_src = '../../assets/img/portrait_male.jpg';
    } else {
      this.pronoun = 'HER';
      this.tile_img_src = '../../assets/img/portrait_female.jpg';
    }

    if (!this.tile.loaded) this.tile.img = this.tile_img_src;
  }

  isHairBlond() {
    if (this.form.controls.hair_color.value == 'blond') return true;
    else return false;
  }

  isHairBrown() {
    if (this.form.controls.hair_color.value == 'brown') return true;
    else return false;
  }

  isHairBlack() {
    if (this.form.controls.hair_color.value == 'black') return true;
    else return false;
  }

  isHairRed() {
    if (this.form.controls.hair_color.value == 'red') return true;
    else return false;
  }

  isHairBlue() {
    if (this.form.controls.hair_color.value == 'blue') return true;
    else return false;
  }

  isHairPurple() {
    if (this.form.controls.hair_color.value == 'purple') return true;
    else return false;
  }

  isHairWhite() {
    if (this.form.controls.hair_color.value == 'white') return true;
    else return false;
  }

  isEyeBlue() {
    if (this.form.controls.eye_color.value == 'blue') return true;
    else return false;
  }

  isEyeBrown() {
    if (this.form.controls.eye_color.value == 'brown') return true;
    else return false;
  }

  isEyeGreen() {
    if (this.form.controls.eye_color.value == 'green') return true;
    else return false;
  }

  openHairstyle() {
    const dialogRef = this.dialog.open(DialogHairstyleInitComponent, {
      data: { gender: this.form.controls.gender.value },
      width: '80%',
      maxWidth: '1000px',
      maxHeight: '800px',
    });

    dialogRef.afterClosed().subscribe((result: HairStyle) => {
      this.hairstyle_image = result.img_url;
      this.form.controls.hair_style.setValue(result.reference);
    });
  }

  changeEthnicitySelected(value) {
    if (!value.source._selected) {
      this.form.controls.ethnicity.setValue(null);
    } else {
      this.form.controls.ethnicity.setValue(value.source._value);
    }
  }

  changeHairColorSelected(value) {
    this.form.controls.hair_color.setValue(value);
  }

  changeHairStyleSelected(value) {
    if (!value.source._selected) {
      this.form.controls.hair_style.setValue(null);
    } else {
      this.form.controls.hair_style.setValue(value.source._value);
    }
  }

  changeEyeColorSelected(value) {
    this.form.controls.eye_color.setValue(value);
  }

  /*chrono(tile) {
    //console.log('start chronometer !');
    tile.start = new Date().getTime();

    this.processed_tile = tile;

    // Update the chornometer every 1/100 second
    var x = setInterval(function () {
      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = now - tile.start;

      // Time calculations for days, hours, minutes and seconds
      tile.sec = Math.floor(distance / 1000);
      tile.decilesec = Math.floor((distance % 1000) / 10);

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        tile.start = new Date().getTime();
        tile.sec = 0;
        tile.decilesec = 0;
        //  console.log('stop chronometer !');
      }
    }, 10);
  }
*/

  newAvatar(count) {
    this.avatarService.initiate(this.avatar).subscribe((res) => {
      this.creditService.update();

      //assign the value coming from the server

      let t_uri = '';

      if (count == 0) t_uri = '../../assets/img/trans-one.png';
      else if (count == 1) t_uri = '../../assets/img/trans-two.png';
      else if (count == 2) t_uri = '../../assets/img/trans-three.png';
      else if (count == 3) t_uri = '../../assets/img/trans-four.png';

      this.thumbs[count] = {
        img: t_uri,
        guid: res[0].guid,
        loaded: false,
        //     start: 1000000000000000000000000000000,
        //     decilesec: 0,
        //     sec: 0,
        //        enc_r: res[0].enc_r,
        enc_p: res[0].enc_p,
      };

      //if (count == 0) this.tile = this.thumbs[0];

      //console.log(count);
      //console.log(this.thumbs.length);

      if (count == 3) {
        this.generateLabel = 'RETRY !';
        //console.log(this.thumbs);
        //this.inprocess = false;
        //console.log('************ END !');
        //console.log(this.thumbs);
      }
      count++;
      if (count < 4) {
        //this.thumbs[count].start = new Date().getTime();
        //this.processed_tile = this.thumbs[count];
        //this.chrono(this.thumbs[count]);
        this.newAvatar(count);
      }
    });
  }

  initThumbs() {
    this.tile = {
      img: this.tile_img_src,
      guid: null,
      //  img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkKAcAAH0AecUT9IwAAAAASUVORK5CYII=', //black transparent
      loaded: false,
      //   sec: 0,
      //   decilesec: 0,
    };

    this.thumbs = [];

    let tile_a = {
      img: '../../assets/img/trans-one.png',
      guid: null,
      //  img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkKAcAAH0AecUT9IwAAAAASUVORK5CYII=', //black transparent
      loaded: false,
      //  sec: 0,
      //  decilesec: 0,
    };

    this.thumbs.push(tile_a);

    let tile_b = {
      img: '../../assets/img/trans-two.png',
      guid: null,
      //  img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkKAcAAH0AecUT9IwAAAAASUVORK5CYII=', //black transparent
      loaded: false,
      //   sec: 0,
      //   decilesec: 0,
    };

    this.thumbs.push(tile_b);

    let tile_c = {
      img: '../../assets/img/trans-three.png',
      guid: null,
      //  img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkKAcAAH0AecUT9IwAAAAASUVORK5CYII=', //black transparent
      loaded: false,
      // sec: 0,
      // decilesec: 0,
    };

    this.thumbs.push(tile_c);

    let tile_d = {
      img: '../../assets/img/trans-four.png',
      guid: null,
      //  img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkKAcAAH0AecUT9IwAAAAASUVORK5CYII=', //black transparent
      loaded: false,
      //  sec: 0,
      //  decilesec: 0,
    };

    this.thumbs.push(tile_d);
  }

  initiate() {
    //TODO CHECK CREDITS
    //**************************

    this.creditService.get().subscribe((info) => {
      let possible: boolean = info.balance >= this.cost_preview; //cost of 4 image preview

      if (possible) {
        //LAUNCH !
        this.launchInitiate();
      } else {
        this.dialog.open(DialogNeedBiscuitComponent, {
          width: '600px',
          disableClose: true,
          autoFocus: false,
        });
      }
    });
    //****************************
  }

  launchInitiate() {
    this.initThumbs();

    if (!this.gen_done) this.gen_done = true;

    this.inprocess = true;
    this.mapFormValueToDto(this.form.value, 1);
    // this.tile.img= 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkKAcAAH0AecUT9IwAAAAASUVORK5CYII=', //black transparent
    //this.chrono(this.tile);

    let count = 0;

    this.newAvatar(count);
  }

  openDialog(): void {
    // sessionStorage.setItem('gen.init.img', this.tile.img);
    // sessionStorage.setItem('gen.init.enc_r', this.tile.enc_r);
    sessionStorage.setItem('gen.init.enc_p', this.tile.enc_p);

    const dialogRef = this.dialog.open(DialogNamingComponent, {
      data: {
        guid: this.tile.guid,
        imgUri: this.tile.img,
        avatar: this.avatar,
      },
      width: '80%',
      maxWidth: '1000px',
      maxHeight: '800px',
    });

    dialogRef.afterClosed().subscribe((result: Avatar) => {
      //true means profile has been updated
      if (result) {
        this.save(result);
      }
    });
  }

  save(avatarEdited: Avatar) {
    //**************************

    this.creditService.get().subscribe((info) => {
      let possible: boolean = info.balance >= this.cost_full_avatar_generation;

      //console.log("info.balance ? "+info.balance);
      //console.log(" this.cost ? "+ this.cost_full_avatar_generation);
      //console.log("save possible ? "+possible);

      if (possible) {
        //LAUNCH !
        this.launchGenerateAvatar(avatarEdited);
      } else {
        this.dialog.open(DialogNeedBiscuitComponent, {
          width: '600px',
          disableClose: true,
          autoFocus: false,
        });
      }
    });
    //****************************
  }

  launchGenerateAvatar(avatarEdited: Avatar) {
    let references = new References();
    references.pic_guid = this.tile.guid;
    references.enc_p = this.tile.enc_p;
    references.action = 'PORTRAIT_FRONT';

    this.avatarService.create(references).subscribe((res) => {
      res.data.family_name = avatarEdited.data.family_name;
      res.data.given_name = avatarEdited.data.given_name;

      res.data.in_creation = true;

      res.data.marketing_age = avatarEdited.data.marketing_age;
      res.data.marketing_hair_color = avatarEdited.data.marketing_hair_color;
      res.data.marketing_height = avatarEdited.data.marketing_height;
      res.data.marketing_eye_color = avatarEdited.data.marketing_eye_color;
      res.data.marketing_zodiac_sign = avatarEdited.data.marketing_zodiac_sign;
      res.data.marketing_blood_type = avatarEdited.data.marketing_blood_type;
      res.data.marketing_temper = avatarEdited.data.marketing_temper;
      res.data.marketing_history = avatarEdited.data.marketing_history;

      //res.data.img_uri = this.tile.img;
      res.data.img_guid = this.tile.guid;

      res.data.marketing_profile_picture_uri = this.tile.guid;

      res.updateType = 'PUBLIC_PROFILE_TEXTS';

      this.avatarService.update(res).subscribe((r) => {
        //launch automated pictures gen for avatar in background
        this.avatarService.backgroundGeneration(res.metadata.guid).subscribe({
          next: (res) => {
            //console.log('generation running !');
            this.creditService.update();
          },
          error: (res) => {
            // console.log('error in the avatar generation process !');
          },
        });

        this.router.navigate([
          '/avatar-wait-for-generation',
          res.metadata.guid,
        ]);
      });
    });
  }

  formatAge(value) {
    if (value == 20) return '20yo';
    else if (value == 30) return '30yo';
    else if (value == 40) return '40yo';
    else if (value == 50) return '50yo';
    else return '60yo';
  }

  mapFormValueToDto(value, nb_image) {
    if (!this.avatar?.data) {
      this.avatar = new Avatar();
      this.avatar.data = new AvatarData();
    }
    this.avatar.data.gender = value.gender;
    this.avatar.data.nationality = value.nationality;
    this.avatar.data.artist_name = value.artist_name;
    this.avatar.data.family_name = value.family_name;
    this.avatar.data.given_name = value.given_name;
    this.avatar.data.ethnicity = value.ethnicity;
    this.avatar.data.hair_color = value.hair_color;
    this.avatar.data.hair_style = value.hair_style;
    this.avatar.data.eye_color = value.eye_color;
    this.avatar.data.age = value.age;
    this.avatar.data.nb_image = nb_image;
    this.avatar.data.active = value.active;

    //NAME NOT NEEDED FOR DATABASE (id managed) - the field is only for display
    //this.avatar.data.managerId = Number.parseInt(value.managerId); //contains the id value
  }

  setMain(value) {
    if (this.thumbs[value].loaded) this.tile = this.thumbs[value];
  }

  displayFn(str: string) {
    return str.replaceAll('_', ' ');
  }

  setupNationalities() {
    return nationalities();
  }
}

export function isCorrectNationality(control: AbstractControl) {
  const controlValue = control.value;

  if (nationalities().indexOf(controlValue) > -1) {
    // input is valid
    return null;
  }

  // input is not valid, we can return any object. In our case we return
  // an object with the adult property set to false
  return {
    isCorrectNationality: false,
  };
}

export function nationalities() {
  return [
    'Afghan',
    'Albanian',
    'Algerian',
    'American',
    'Andorran',
    'Angolan',
    'Antiguans',
    'Argentine',
    'Armenian',
    'Australian',
    'Austrian',
    'Azerbaijani',
    'Bahamian',
    'Bahraini',
    'Bangladeshi',
    'Barbadian',
    'Barbudans',
    'Batswana',
    'Belarusian',
    'Belgian',
    'Belizean',
    'Beninese',
    'Bhutanese',
    'Bolivian',
    'Bosnian',
    'Brazilian',
    'British',
    'Bruneian',
    'Bulgarian',
    'Burkinabe',
    'Burmese',
    'Burundian',
    'Cambodian',
    'Cameroonian',
    'Canadian',
    'Cape Verdean',
    'Central African',
    'Chadian',
    'Chilean',
    'Chinese',
    'Colombian',
    'Comoran',
    'Congolese',
    'Costa Rican',
    'Croatian',
    'Cuban',
    'Cypriot',
    'Czech',
    'Danish',
    'Djibouti',
    'Dominican',
    'Dutch',
    'East Timorese',
    'Ecuadorean',
    'Egyptian',
    'Emirati',
    'Equatorial Guinean',
    'Eritrean',
    'Estonian',
    'Ethiopian',
    'Fijian',
    'Filipino',
    'Finnish',
    'French',
    'Gabonese',
    'Gambian',
    'Georgian',
    'German',
    'Ghanaian',
    'Greek',
    'Grenadian',
    'Guatemalan',
    'Guinea-Bissauan',
    'Guinean',
    'Guyanese',
    'Haitian',
    'Herzegovinian',
    'Honduran',
    'Hungarian',
    'Icelander',
    'Indian',
    'Indonesian',
    'Iranian',
    'Iraqi',
    'Irish',
    'Israeli',
    'Italian',
    'Ivorian',
    'Jamaican',
    'Japanese',
    'Jordanian',
    'Kazakhstani',
    'Kenyan',
    'Kittian and Nevisian',
    'Kuwaiti',
    'Kyrgyz',
    'Laotian',
    'Latvian',
    'Lebanese',
    'Liberian',
    'Libyan',
    'Liechtensteiner',
    'Lithuanian',
    'Luxembourger',
    'Macedonian',
    'Malagasy',
    'Malawian',
    'Malaysian',
    'Maldivan',
    'Malian',
    'Maltese',
    'Marshallese',
    'Mauritanian',
    'Mauritian',
    'Mexican',
    'Micronesian',
    'Moldovan',
    'Monacan',
    'Mongolian',
    'Montenegrin',
    'Moroccan',
    'Mosotho',
    'Motswana',
    'Mozambican',
    'Namibian',
    'Nauruan',
    'Nepalese',
    'New Zealander',
    'Nicaraguan',
    'Nigerian',
    'Nigerien',
    'North Korean',
    'Northern Irish',
    'Norwegian',
    'Omani',
    'Pakistani',
    'Palauan',
    'Palestinian',
    'Panamanian',
    'Papua New Guinean',
    'Paraguayan',
    'Peruvian',
    'Polish',
    'Portuguese',
    'Qatari',
    'Romanian',
    'Russian',
    'Rwandan',
    'Saint Lucian',
    'Salvadoran',
    'Samoan',
    'San Marinese',
    'Sao Tomean',
    'Saudi',
    'Scottish',
    'Senegalese',
    'Serbian',
    'Seychellois',
    'Sierra Leonean',
    'Singaporean',
    'Slovakian',
    'Slovenian',
    'Solomon Islander',
    'Somali',
    'South African',
    'South Korean',
    'Spanish',
    'Sri Lankan',
    'Sudanese',
    'Surinamer',
    'Swazi',
    'Swedish',
    'Swiss',
    'Syrian',
    'Taiwanese',
    'Tajik',
    'Tanzanian',
    'Thai',
    'Togolese',
    'Tongan',
    'Trinidadian or Tobagonian',
    'Tunisian',
    'Turkish',
    'Tuvaluan',
    'Ugandan',
    'Ukrainian',
    'Uruguayan',
    'Uzbekistani',
    'Vanuatuan',
    'Venezuelan',
    'Vietnamese',
    'Welsh',
    'Yemenite',
    'Zambian',
    'Zimbabwean',
  ];
}
