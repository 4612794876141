import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

export interface DialogData {

}

@Component({
  selector: 'app-dialog-custom-zoom',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatIconModule,
    MatDialogClose,
  ],
  templateUrl: './dialog-custom-info.component.html',
  styleUrl: './dialog-custom-info.component.scss',
})
export class DialogCustomInfoComponent {

  data: any;

  constructor(
    public dialogRef: MatDialogRef<DialogCustomInfoComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data_in: DialogData
  ) {

    this.data = data_in;
  }

  ngOnInit() {


  }


  onCancel(): void {
      this.dialogRef.close(null);
  }

}
