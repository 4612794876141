<div class="maincontainer">
  <div class="card">
    <h1 class="errorcode"> Bye bye ! </h1>
    <div class="errortext"> We hope you enjoy your creative session !</div>
    <div class="errortext"> Can't wait to see you again !</div>
    <div class="actions">
      <button mat-raised-button color="primary" (click)="home()">HOME</button>
    </div>
  </div>
</div>
