<mat-card class="mat-elevation-z0 single-card ingredient-card">
  <mat-card-header class="mat-card-header form-box-title">
    <mat-card-title><span class="card-title"> INGREDIENT SETTINGS </span></mat-card-title>
    <mat-card-title><span class="card-subtitle"> </span></mat-card-title>
  </mat-card-header>

  <mat-card-content class="setting-content" [style.background]="'url('+background_img+')'">

    <div *ngIf="loaded">

    <form [formGroup]="form" class="form" >

      <div class="table-field">

        <div class="form-element">
          <mat-form-field class="form-element">
            <mat-label>NAME</mat-label>
            <input matInput type="string" formControlName="ingredient_name">
          </mat-form-field>
        </div>

        <div class="form-element">
          <mat-form-field class="form-element">
            <mat-label>GENDER</mat-label>
            <mat-select formControlName="ingredient_gender">
              @for (gender of genders; track gender) {
              <mat-option [value]="gender">{{ gender.toUpperCase() }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-element">
          <mat-form-field class="form-element">
            <mat-label>TYPE</mat-label>
            <mat-select formControlName="ingredient_type">
              @for (type of types; track type) {
              <mat-option [value]="type">{{ type.toUpperCase() }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-element">
          <mat-form-field class="form-element">
            <mat-label>ETHNICITY</mat-label>
            <mat-select formControlName="ingredient_ethnicity">
              @for (ethnicity of ethnicities; track ethnicity) {
              <mat-option [value]="ethnicity">{{ ethnicity.toUpperCase() }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-element">
          <mat-form-field class="form-element">
            <mat-label>RARITY</mat-label>
            <mat-select formControlName="ingredient_rarity">
              @for (rarity of rarities; track rarity) {
              <mat-option [value]="rarity">{{ rarity.toUpperCase() }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-element">
            <mat-label class="active">ACTIVE</mat-label>
            <mat-slide-toggle [color]="'primary'" formControlName="ingredient_active">
            </mat-slide-toggle>
        </div>



      </div>

      <div class="container-buttons">
        <div class="button-container-element">
          <button mat-raised-button color="primary" type="submit" class="button" [disabled]="!form.valid"
            (click)="submit()"> SAVE </button>
        </div>

        <div class="button-container-element">
          <button mat-raised-button color="accent" type="button" class="button" (click)="cancel()">CANCEL</button>
        </div>

      </div>
    </form>
  </div>

  </mat-card-content>
</mat-card>
