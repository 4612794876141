<div class="dialog">
  <div [class]="inprocess ? 'overlay-inprocess' : 'not-visible'">
    <mat-spinner [diameter]="20" [color]="'warn'"></mat-spinner>
    <div class="generation"> generation in progress ! </div>
  </div>

  <div [class]="horizontal_format ? 'img-container-horizontal' : 'img-container-vertical'">
    <div [class]="horizontal_format ? 'image-display-horizontal' : 'image-display-vertical'">
      <div [class]="img_overlay ? 'overlay' : 'not-visible'" #overlayEdit>

        <div class="image-features">
          <div class="section ">
            <!--            <div class="section-head">ACTION</div>-->
            <div class="section-body">
              <div class="button">
                <button mat-button color="white" class="nzym-icon-button" (click)="download()" matTooltip="[D] Download"
                  [matTooltipPosition]="'right'">
                  <mat-icon>download</mat-icon></button>
              </div>
              <div class="button">
                <button mat-button color="white" class="nzym-icon-button" matTooltip="[F] Favorite"
                  [matTooltipPosition]="'right'" (click)="addFavorite()">
                  <mat-icon>
                    heart_plus
                  </mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="section">
            <!--            <div class="section-head"> IMPROVE</div>-->
            <div class="section-body">

              <div class="button">
                <button mat-button color="white" class="nzym-icon-button" (click)="inpaint()" [disabled]="inprocess"
                  matTooltip="[I] Fix problem with AI" [matTooltipPosition]="'right'" matTooltipClass="tooltip">
                  <mat-icon>build</mat-icon></button>
              </div>
              <div class="button">
                <button mat-button color="white" class="nzym-icon-button" (click)="zoomOut()" [disabled]="inprocess"
                  matTooltip="[Z] Zoom out (x1) - {{ operationsCost.cost_zoomout}} cred" [matTooltipPosition]="'right'">
                  <mat-icon>zoom_out_map</mat-icon> </button>
              </div>
              <div class="button">
                <button mat-button color="white" class="nzym-icon-button" (click)="customZoomOut()"
                  [disabled]="inprocess" matTooltip="[E] Custom Zoom out - {{ operationsCost.cost_zoomout}} cred"
                  [matTooltipPosition]="'right'">
                  <mat-icon>instant_mix</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="section">
            <!--      <div class="section-head"> POST TREAT</div> -->
            <div class="section-body">
              <div class="button">
                <button mat-button color="white" class="nzym-icon-button" [disabled]="inprocess"
                  matTooltip="[U] Upscale x2 - {{ operationsCost.cost_upscale2}} cred" [matTooltipPosition]="'right'"
                  (click)="upscale2()">
                  <mat-icon>filter_2</mat-icon>
                </button>
              </div>
              <!--<div class="button">
                <button mat-button color="white" class="nzym-icon-button" [disabled]="inprocess"
                  matTooltip="[T] Upscale x4 - {{ operationsCost.cost_upscale2}} cred" [matTooltipPosition]="'right'"
                  (click)="upscale4()">
                  <mat-icon>filter_4</mat-icon>
                </button>
              </div>-->
            </div>
          </div>


          <div class="section">
            <!--        <div class="section-head"> GENERATE</div> -->
            <div class="section-body">
              <div class="button">
                <button mat-button color="white" class="nzym-icon-button" [disabled]="inprocess"
                  matTooltip="[V] Generate a variant  - {{ operationsCost.cost_vary }} cred"
                  [matTooltipPosition]="'right'" (click)="vary()">
                  <mat-icon>
                    frame_reload
                  </mat-icon>
                </button>
              </div>
              <div class="button">
                <button mat-button color="white" class="nzym-icon-button" (click)="regenerate()" [disabled]="inprocess"
                  matTooltip="[R] Regenerate prompt - {{ operationsCost.cost_simple}} cred"
                  [matTooltipPosition]="'right'">
                  <mat-icon>replay</mat-icon> </button>
              </div>

            </div>
          </div>
          <div class="section">
            <!--        <div class="section-head"> GENERATE</div> -->
            <div class="section-body">
              <div class="button">
                <button mat-button color="white" class="nzym-icon-button"
                  matTooltip="[S] Make that picture disappear from your flow" [matTooltipPosition]="'right'"
                  (click)="disappear()">
                  <mat-icon>
                    delete
                  </mat-icon>
                </button>
              </div>
               <div class="button">
                <button mat-button color="white" class="nzym-icon-button"
                  matTooltip="[X] Share that picture " [matTooltipPosition]="'right'" (click)="share()">
                  <mat-icon>
                    share
                  </mat-icon>
                </button>
              </div>
              <div class="button">
                <button mat-button color="white" class="nzym-icon-button"
                  matTooltip="[H] Information about this picture" [matTooltipPosition]="'right'" (click)="info()">
                  <mat-icon>
                    info
                  </mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div [class]="pic.favorite ? 'fav-overlay' : 'not-visible'" #favOverlayEdit> <mat-icon matTooltip="Personal Favourite">favorite</mat-icon></div>
      <div [class]="pic.public_shared ? 'public-overlay' : 'not-visible'" #publicOverlayEdit> <mat-icon matTooltip="Shared to community">share</mat-icon></div>

      <div class="navigation-overlay">
        <div [class]="index > 0 ? 'arrow-overlay' : 'not-visible' " (click)="goLeft()" matTooltip="[shortcut] You can use the left arrow on your keyboard" [matTooltipPosition]="'above'"> <mat-icon>arrow_back</mat-icon></div>
        <div [class]="index < tiles.length-1  ? 'arrow-overlay' : 'not-visible'" (click)="goRight()" matTooltip="[shortcut] You can use the right arrow on your keyboard" [matTooltipPosition]="'above'"> <mat-icon>arrow_forward</mat-icon></div>
      </div>
      <img class="full-image" src="{{ pic.img_uri }}" #imgEdit (load)="onLoadImage()">
    </div>

  </div>
