<div *ngIf="readyToDisplay" class="main">
  <mat-card class="card">
    <mat-card-header class="header">
      <mat-card-title class="card-title"> SUBSCRIPTION ORDER</mat-card-title>
      <mat-card-subtitle class="card-subtitle "> Nzym Subscription {{ prod?.label }} </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="order">
        <div class="order-title"> Please review the subscription you chose to order: </div>
        <div class="order-text">
          <div class="sub-review">
            <div class="sub-attributes">
              <div class="sub-attribute">
                <div class="sub-profile-attribute"> ENGAGEMENT PERIOD  </div>
                <div class="sub-attribute-value" *ngIf="prod?.recurrence=='MONTHLY'"> {{ prod?.recurrence }}, stop whenever   </div>
                <div class="sub-attribute-value" *ngIf="prod?.recurrence!='MONTHLY'"> {{ prod?.recurrence }}, 12 months until {{ endDate }}  </div>
              </div>

              <div class="sub-attribute">
                <div class="sub-profile-attribute"> MONTHLY CREDITS TO USE </div>
                <div class="sub-attribute-value"> {{ prod?.monthly_credits }} credits </div>
              </div>

              <div class="sub-attribute">
                <div class="sub-profile-attribute"> SUBSCRIPTION PRICE  </div>
                <div class="sub-attribute-value" *ngIf="prod?.recurrence=='MONTHLY'"><div> €{{ prod?.amount }}/month </div>  </div>
                <div class="sub-attribute-value" *ngIf="prod?.recurrence!='MONTHLY'"> <div>€{{ prod?.amount }}, equivalent to €{{ prod?.amount/12 }} /month </div> </div>
              </div>
              </div>
              <div class="sub-action">
                <div class="discount" *ngIf="prod?.recurrence!='MONTHLY'"> 20% DISCOUNT</div>
                <button mat-raised-button color="primary" (click)="changeSubscription()"> MODIFY SUBSCRIPTION</button>
              </div>
          </div>
        </div>
      </div>
      <div class="payment-card">

        <div [class]=" hasCard && hasCardValid && !updateCard ? 'card-known' : 'not-visible'">
          <div class="card-intro-text"> You already use this card to purchase Nzym products and we propose to charge it
            by default:</div>
          <div class="card-review">
            <div class="card-attributes">
              <div class="card-attribute">
                <div class="card-profile-attribute">CARD TYPE </div>
                <div class="card-attribute-value">{{ sanitizeCardType() }}</div>
              </div>
              <div class="card-attribute">
                <div class="card-profile-attribute"> CARD # </div>
                <div class="card-attribute-value"> {{ sanitizeCardNumber() }}</div>
              </div>
              <div class="card-attribute">
                <div class="card-profile-attribute"> EXPIRY </div>
                <div class="card-attribute-value">{{ sanitizeExpiry() }}</div>
              </div>
            </div>
            <div class="card-action">
              <button mat-raised-button color="primary" (click)="changeCard()"> CHANGE PAYMENT CARD</button>

            </div>
          </div>

        </div>
        <div [class]=" !hasCard || !hasCardValid || updateCard ? 'card-new' : 'not-visible'">
          <div class="card-new-text"> Please enter the credit card information to proceed with the order:</div>
          <app-payment-card (output)="manageCardFeedback($event)"></app-payment-card>
        </div>
        <div class="information"> Nzym does not store / hold any of your bank information. All data is managed securely by our partner Stripe, leader of online transactions</div>
      </div>
      <div class="finalize">
        <div class="buttons" *ngIf="showSubmitActions">
          <button mat-raised-button color="primary" [disabled]="submitDisabled" (click)="order()">SUBMIT</button>
          <button mat-raised-button color="warn" (click)="cancel()">CANCEL</button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <div class="footer"><img src="../../assets/img/powered_by_stripe.png" class="logo-stripe"> </div>
</div>
