
<div class="title"><h3> {{ data.title }} </h3></div>
<div class="main">
   <div class="tags">
    <mat-form-field class="chip-list">
      <mat-label>Image tags</mat-label>
      <mat-chip-grid #chipGrid aria-label="Enter tags">
        @for (tag of tags; track tag) {
          <mat-chip-row
            (removed)="removeTag(tag)"
            [editable]="true"
            (edited)="editTag(tag, $event)"
            [aria-description]="'press enter to edit ' + tag.name">
            {{tag.name}}
            <button matChipRemove [attr.aria-label]="'remove ' + tag.name">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        }
        <input placeholder="Add tags..."
               [matChipInputFor]="chipGrid"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur"
               (matChipInputTokenEnd)="addTag($event)"/>
      </mat-chip-grid>
    </mat-form-field>
  </div>


  <div class="buttons">
    <button mat-raised-button color="primary" class="button" (click)="submit()"> ADD</button>
    <button mat-raised-button color="warn" class="button" (click)="onCancel()"> CANCEL</button>
  </div>
</div>

