<div *ngIf="!loaded" class="spinner-container"><mat-spinner></mat-spinner></div>

<div *ngIf="loaded">
  <div class="cards">
    <div class="single-card">
        <img src="../../assets/img/portrait.png">
      <span class="feature-title">AVAILABLE AVATARS</span>
    </div>

    <mat-card class="mat-elevation-z0 single-card">
      <mat-cart-header class="mat-card-header">
        <mat-card-title><span class="card-title">#AVATARS </span></mat-card-title>
        <mat-card-title><span class="card-subtitle"> Synthesis </span></mat-card-title>
      </mat-cart-header>
      <mat-card-content class="metric">
        <p>
          {{ ELEMENT_DATA.length }}
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card class="mat-elevation-z0 single-card">
      <mat-cart-header class="mat-card-header">
        <mat-card-title><span class="card-title">#ACTIVE </span></mat-card-title>
        <mat-card-title><span class="card-subtitle">Synthesis</span></mat-card-title>
      </mat-cart-header>
      <mat-card-content class="metric">
        <p>
          {{ activeNumber() }}
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card class="mat-elevation-z0 single-card">
      <mat-cart-header class="mat-card-header">
        <mat-card-title><span class="card-title">#INACTIVE</span></mat-card-title>
        <mat-card-title><span class="card-subtitle">Synthesis</span></mat-card-title>
      </mat-cart-header>
      <mat-card-content class="metric">
        <p>
          {{ inactiveNumber() }}
        </p>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="filter">
    <mat-form-field class="searchfield">
      <input matInput type="text" (keyup)="doFilter($event)" placeholder="">
      <mat-label>Search & Filter </mat-label>
      <mat-icon matSuffix>filter_list</mat-icon>
      <mat-hint>Filter applies to all columns</mat-hint>
    </mat-form-field>
  </div>
</div>

<table [hidden]="!loaded" mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
  class="mat-elevation-z8">

  <!-- id Column -->
  <ng-container matColumnDef="image">
    <th mat-header-cell *matHeaderCellDef  class="header">
      PIC
    </th>
    <td mat-cell *matCellDef="let element">
      <a [routerLink]="['/avatar-face-generator-ref',element.guid]">
        <img class="thumb" src="{{element.img_uri}}">
      </a>
    </td>
  </ng-container>

  <!-- publicAccess Column -->
  <ng-container matColumnDef="publicAccess">
    <th mat-header-cell *matHeaderCellDef  >
      ACCESS
    </th>
    <td mat-cell *matCellDef="let element">
      <mat-chip [color]="(element.public_access)?'primary':'warn'" highlighted > {{ publicText(element.public_access) }}</mat-chip>
    </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
      NAME
    </th>
    <td mat-cell *matCellDef="let element"> {{element.given_name}} {{element.family_name}} </td>
  </ng-container>

  <!-- age Column -->
  <ng-container matColumnDef="age">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by age">
      AGE
    </th>
    <td mat-cell *matCellDef="let element"> {{element.age}} </td>
  </ng-container>

  <!-- ethnicity Column -->
  <ng-container matColumnDef="ethnicity">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ethnicity">
      TYPE
    </th>
    <td mat-cell *matCellDef="let element"> {{element.ethnicity}} </td>
  </ng-container>

  <!-- eye Column -->
  <ng-container matColumnDef="eye">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by eye">
      EYE COLOR
    </th>
    <td mat-cell *matCellDef="let element"> {{element.eye_color}}  </td>
  </ng-container>

  <!-- on_sale Column  -->
  <ng-container matColumnDef="on_sale">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by onSale">
      ON SALE
    </th>
    <td mat-cell *matCellDef="let element" >
      <mat-chip [color]="(element.on_sale)?'primary':'warn'" highlighted > {{element.on_sale}} </mat-chip>
    </td>
  </ng-container>


    <!-- sold Column  -->
    <ng-container matColumnDef="sold">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by sold">
        SOLD AT LEAST ONCE ?
      </th>
      <td mat-cell *matCellDef="let element" >
        <mat-chip [color]="(element.sold)?'primary':'warn'" highlighted > {{element.has_been_sold_at_least_once}} </mat-chip>
      </td>
    </ng-container>

  <!-- active Column  -->
  <ng-container matColumnDef="active">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by active">
      ACTIVE ?
    </th>
    <td mat-cell *matCellDef="let element" >
      <mat-chip [color]="(element.active)?'primary':'warn'" highlighted (click)="active(element)"> {{element.active}} </mat-chip>
    </td>
  </ng-container>

  <!-- action Column  -->
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>
      <span>ACTIONS</span>
    </th>
    <td mat-cell *matCellDef="let element">

      <div class="icons">
        <div
        [class]=" ( element.in_creation || element.in_regeneration ) ?   'visible' : 'not-visible' ">
        <div class="spinner">
          <mat-spinner [diameter]="20"></mat-spinner>
        </div>
      </div>
        <a  class="visible" [routerLink]="['/avatar-face-generator-ref',element.guid]" matTooltip="Edit Avatar"><mat-icon [color]="'primary'" alt="EDIT" >edit</mat-icon></a>
        <div class="visible" matTooltip="Regen Profile Picture image"><mat-icon [color]="'primary'" alt="Profile Picture" (click)="generateProfilePicture(element)" >account_circle</mat-icon></div>
        <div class="visible" matTooltip="Regen all Marketing images"><mat-icon [color]="'primary'" alt="Regen" (click)="regen(element)" >recycling</mat-icon></div>
        <div class="visible" matTooltip="Regen all Dreambooth images"><mat-icon [color]="'primary'" alt="Regen" (click)="dreambooth(element)" >web_stories</mat-icon></div>
        <mat-icon class="visible"  [color]="'primary'" alt="DELETE" (click)="delete(element)" matTooltip="Delete Avatar">delete</mat-icon>
      </div>
    </td>
  </ng-container>

  <!-- fab Column  -->
  <ng-container matColumnDef="add">
    <th mat-header-cell *matHeaderCellDef>
      <div class="fab">
        <button mat-mini-fab color="accent" aria-label="Add sensor" (click)="new()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
    </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<div [hidden]="!loaded">
  <mat-paginator [pageSize]="50" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons
    aria-label="Select page of samples" class="paginator">
  </mat-paginator>
</div>

