<div class="cards" *ngIf="displayHome">

  <mat-card class="mat-elevation-z0 single-card">
    <mat-card-header class="mat-card-header">
      <mat-card-title><span class="card-title">#CREDITS </span></mat-card-title>
      <mat-card-title><span class="card-subtitle"></span></mat-card-title>
    </mat-card-header>
    <mat-card-content class="metric">
      <p>
        {{ balance?.balance }}
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card class="mat-elevation-z0 single-card">
    <mat-card-header class="mat-card-header">
      <mat-card-title><span class="card-title">#IMAGES </span></mat-card-title>
      <mat-card-title><span class="card-subtitle">Last 30 days</span></mat-card-title>
    </mat-card-header>
    <mat-card-content class="metric">
      <p>
        {{ picLast30 }}
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card class="mat-elevation-z0 single-card">
    <mat-card-header class="mat-card-header">
      <mat-card-title><span class="card-title">#IMAGES </span></mat-card-title>
      <mat-card-title><span class="card-subtitle">All time</span></mat-card-title>
    </mat-card-header>
    <mat-card-content class="metric">
      <p>
        {{ picOverall }}
      </p>
    </mat-card-content>
  </mat-card>

  <!--
  <mat-card class="mat-elevation-z0 image-card">
    <mat-card-header class="mat-card-header">
      <mat-card-title><span class="card-title">NZYM News </span></mat-card-title>
      <mat-card-title><span class="card-subtitle"></span></mat-card-title>
    </mat-card-header>


   <mat-card-content class="text">
      <p>
      <h4>NZYM Levels Up AI Image Generation!</h4>
      <p>Get ready to create even more stunning visuals with our new features:</p>
      <p>
        <img src="../../assets/img/breaking_news.png" class="news">
        - Enhanced Style Control: Precisely control generation through powerful presets<br>
        - Improved Text-to-Image Accuracy: Generate images closer to your vision than ever<br>
        - Reference Characters: Incorporate avatar with state-of-the-art consistency <br>
        - Plus, learn from our new YouTube tutorials and get excited for animation coming soon!
      </p>
    </mat-card-content>

  </mat-card>
    -->
  <mat-card class="mat-elevation-z0 image-card">
    <mat-card-header class="mat-card-header header-text">
      <div class="header-titles">
        <mat-card-title><span class="card-title">DISCOVER AVATARS </span></mat-card-title>
        <mat-card-title><span class="card-subtitle">And boost your creativity !</span></mat-card-title>
      </div>
      <button mat-raised-button color="accent" class="header-button" (click)="studio()">
        <div class="text-button" > GO TO THE STUDIO </div>
      </button>
    </mat-card-header>
    <mat-card-content>
      <div class="images">

        @for (avatar of pushedAvatars; track avatar.metadata.guid) {
        <div class="avatar" (click)="openAvatar(avatar.metadata.guid)">
          <div class="overlay-status"
            [class]="avatar?.data.on_sale ? ( avatar?.data.is_owned_by_user ? ( avatar?.data.link_origin=='CREATION' ? 'status-private' : 'status-purchased' ): 'status-sale' ): avatar?.data.public_access ? 'status-public' : 'status-private'">
            {{ avatarStatus(avatar) }}
          </div>

          <div class="overlay-info">
            {{ avatar?.data.given_name.toUpperCase() }} {{ avatar?.data.family_name.toUpperCase() }}
          </div>
          <img src="{{ avatar.data.marketing_profile_picture_thumb_uri}}">
        </div>
        }
      </div>
      <div class="go-gallery" [routerLink]="'/avatar-gallery'"> <mat-icon class="icon">arrow_forward</mat-icon>
        <div class="text">See the whole collection </div>
      </div>
    </mat-card-content>
  </mat-card>

  <!--
   <mat-card class="mat-elevation-z0 image-card" *ngIf="lastProduced && lastProduced.length > 0">
    <mat-card-header class="mat-card-header">
      <mat-card-title><span class="card-title">YOUR LAST GENERATIONS </span></mat-card-title>
      <mat-card-title><span class="card-subtitle">Your last images</span></mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="images">

        @for (image of lastProduced; track image.guid; let idx = $index) {
        <div class="image" (click)="openProductedImage(image, idx)">
          <img src="{{ image.thumb_uri || image.img_uri}}">
        </div>
        }

      </div>

    </mat-card-content>
  </mat-card>
-->
  <mat-card class="mat-elevation-z0 image-card" *ngIf="lastCommunities && lastCommunities.length > 0">
    <mat-card-header class="mat-card-header header-text">
      <div class="header-titles">
        <mat-card-title><span class="card-title">COMMUNITY IMAGES - Creativity by our Nzymers</span></mat-card-title>
        <mat-card-title><span class="card-subtitle">Share & get inspiration for your own prompts</span></mat-card-title>
      </div>
      <button mat-raised-button color="accent" class="header-button" (click)="community()">
        <div class="text-button" > GO TO COMMUNITY WALL </div>
      </button>
    </mat-card-header>
    <mat-card-content>
      <div class="images">

        @for (image of lastCommunities; track image.guid; let idx = $index) {
        <div class="image" (click)="openCommunityImage(image, idx)">
          <img src="{{ image.thumb_uri || image.img_uri}}">
          <div class="overlay-actions">
            <div class="image-creator">
              <img class="image-profile" src="{{ image.creator_img_uri }}" />
              <div class="image-creator"> {{ image.creator_nickname }}</div>
            </div>
          </div>
        </div>

        }
      </div>
    </mat-card-content>
  </mat-card>

</div>
