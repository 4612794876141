import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Avatar } from '../../shared/entity/avatar';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

export interface DialogData {
  nickname: string;
}

@Component({
  selector: 'app-dialog-review-price',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSlideToggleModule
  ],
  templateUrl: './dialog-nickname.component.html',
  styleUrl: './dialog-nickname.component.scss'
})
export class DialogNicknameComponent {

  form : FormGroup;
  nickname : string;


  constructor(
    public dialogRef: MatDialogRef<DialogNicknameComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {

    this.nickname = this.data.nickname;

    this.form = this.formBuilder.group({
      nickname: [this.nickname, [Validators.required]]
    });
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }

  submit() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.controls.nickname.value);
  }

  }

}
