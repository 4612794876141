import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCard } from '@angular/material/card';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PictureService } from '../../services/picture.service';
import { Picture } from '../../shared/entity/picture';
import { ActionMessage } from '../../shared/entity/action-message';
import { ActionCode } from '../../shared/enum/action-code';
import { DialogCustomZoomComponent } from './custom-zoom-out/dialog-custom-zoom.component';
import { ZoomRequest } from '../../shared/entity/zoom-request';
import { Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FavoriteService } from '../../services/favorite.service';
import { Favorite } from '../../shared/entity/favorite';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgIf } from '@angular/common';
import { UpscaleRequest } from '../../shared/entity/upscale-request';
import { ScaleFactor } from '../../shared/enum/scale-factor';
import { VaryRequest } from '../../shared/entity/vary-request';
import { DialogCustomInfoComponent } from './custom-info/dialog-custom-info.component';
import { OperationsCostService } from '../../services/operations-cost';
import { DialogShareComponent } from './dialog-share/dialog-share.component';

export interface DialogData {

  name: string;
  index: number;
  tiles: Tile[];
  inprocess: boolean;
}

export interface Tile {
  pic: Picture;
  loaded: boolean;
  start?: number;
  sec?: number;
  decilesec?: number;
}

@Component({
  selector: 'app-dialog-image-action',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCard,
    MatProgressSpinnerModule,
    MatIconModule,
    MatTooltipModule,
    NgIf,
  ],
  templateUrl: './dialog-image-action.component.html',
  styleUrl: './dialog-image-action.component.scss',
})
export class DialogImageActionComponent {

  @ViewChild('imgEdit', { static: true }) imgEdit: ElementRef;
  @ViewChild('overlayEdit', { static: true }) overlayEdit: ElementRef;

  pic: Picture;
  creationDate: string;
  img_overlay: boolean;
  hasBeenChanged: boolean;
  id: number;
  horizontal_format: boolean; // setup the style
  realx: number;
  realy: number;
  index: number;
  tiles:Tile[];
  inprocess: boolean;

  finalMsg: ActionMessage;

  keyPressed = (e) => {

    //careful AZERTY vs QWERTY---> not working with e.code so we use the e.keyCode
    //except for arrows (I dont know why, but it does not work properly) for arrows

    if (e.isComposing || e.code === 'ArrowLeft') {
        this.goLeft();
    }
    if (e.isComposing || e.code === 'ArrowRight') {
        this.goRight();
    }
    if (e.isComposing || e.keyCode === 68) {
        this.download();
    }
    if (e.isComposing || e.keyCode === 70) {
        this.addFavorite();
    }
    if (e.isComposing || e.keyCode === 73) {
      if (!this.inprocess)
        this.inpaint();
    }
    if (e.isComposing || e.keyCode === 90) {
      if (!this.inprocess)
        this.zoomOut();
    }
    if (e.isComposing || e.keyCode === 69) {
      if (!this.inprocess)
        this.customZoomOut();
    }
    if (e.isComposing || e.keyCode === 85) {
      if (!this.inprocess)
        this.upscale2();
    }
    if (e.isComposing || e.keyCode === 84) {
      if (!this.inprocess)
        this.upscale4();
    }
    if (e.isComposing || e.keyCode === 86) {
      if (!this.inprocess)
        this.vary();
    }
    if (e.isComposing || e.keyCode === 82) {
      if (!this.inprocess)
        this.regenerate();
    }
    if (e.isComposing || e.keyCode === 83) {
        this.disappear();
    }
    if (e.isComposing || e.keyCode === 88) {
      this.share();
    }
    if (e.isComposing ||e.keyCode === 72) {
      this.info();
    }
    e.stopPropagation();
  }


  mouseMove = (e) => {
    if (this.img_overlay == false) this.img_overlay = true;
  };

  mouseOut = (e) => {
    if (this.img_overlay == true) this.img_overlay = false;
  };

  imgTouchStart = (e) => {
   //if (this.img_overlay == false) this.img_overlay = true;
   this.img_overlay = true;
   //e.stopPropagation();
   //e.preventDefault();
  };

  overlayTouchStart = (e) => {
    //if (this.img_overlay == false) this.img_overlay = true;
   // this.img_overlay = false;
    //e.stopPropagation();
    e.preventDefault();
   };

  constructor(
    public dialogRef: MatDialogRef<DialogImageActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private router: Router,
    public dialog: MatDialog,
    private pictureService: PictureService,
    private favoriteService: FavoriteService,
    private _snackbar: MatSnackBar,
    public operationsCost : OperationsCostService
  ) {}

  ngOnDestroy() {

    document.removeEventListener('keydown', this.keyPressed);

  }

  ngAfterViewInit() {
    var _this = this;
    document.addEventListener(
      'keydown', _this.keyPressed
    );
  }


  ngOnInit() {

    this.index = this.data.index;
    this.tiles = this.data.tiles;
    this.inprocess = this.data.inprocess;


    this.hasBeenChanged = false;
    this.pic = this.tiles[this.index].pic;
    this.creationDate = new Date().toISOString();
    this.horizontal_format = false;

    this.setFormatForCss();

    this.finalMsg = new ActionMessage();
    this.finalMsg.tiles = this.tiles;

   /* this.imgEdit.nativeElement.addEventListener(
      'mouseover',
      this.mouseMove,
      false
    );

    this.overlayEdit.nativeElement.addEventListener(
      'mouseout',
      this.mouseOut,
      false
    );

    this.imgEdit.nativeElement.addEventListener(
      'touchstart',
      this.imgTouchStart,
      false
    );

    this.overlayEdit.nativeElement.addEventListener(
      'touchstart',
      this.overlayTouchStart,
      false
    );
*/
    //init overlay
    this.img_overlay = true;

    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this.onCancel();
      }
    });

    this.dialogRef.backdropClick().subscribe((event) => {
      this.onCancel();
    });
  }

  onLoadImage() {
    this.realx = this.imgEdit.nativeElement.naturalWidth;
    this.realy = this.imgEdit.nativeElement.naturalHeight;

    //this.calcLeft = (this.imgEdit.nativeElement.clientWidth - 24*2)+'px';
  }

  setFormatForCss() {
    if (
      this.pic.img_ratio == 'RATIO_5_3' ||
      this.pic.img_ratio == 'RATIO_5_2'
    ) {
      this.horizontal_format = true;
    } else this.horizontal_format = false;
  }

  zoomOut(): void {


    //msg.img_guid = this.pic.guid;
    this.finalMsg.action_code = ActionCode.ZOOM_OUT;
    this.finalMsg.zoom_req = new ZoomRequest();
    this.finalMsg.zoom_req.img_guid = this.pic.guid;

    //CLOSE AND ASK MAIN PAGE TO GENERATE A NEW ONE WITH THE SAME PROMPT
    this.dialogRef.close(this.finalMsg);
  }

  customZoomOut() {
    const dialogRef = this.dialog.open(DialogCustomZoomComponent, {
      data: { img_uri: this.pic.thumb_uri },
      width: '400px',
      height: '380px',
      panelClass: "{'border-radius':'16px'}"
    });

    dialogRef.afterClosed().subscribe((customZoomParams: ZoomRequest) => {

      if (customZoomParams) {

        this.finalMsg.action_code = ActionCode.ZOOM_OUT;
        customZoomParams.img_guid = this.pic.guid;
        this.finalMsg.zoom_req = customZoomParams;

        this.dialogRef.close(this.finalMsg);
      }
    });
  }

  addFavorite() {
    let fav: Favorite = new Favorite();
    fav.img_guid = this.pic.guid;
    let isAlreadyFav = this.pic.favorite;

    if (isAlreadyFav) {
      //Case remove from fav

      this.favoriteService.remove(fav).subscribe((result) => {
        if (result.done) {
          this.pic.favorite = false;

          this._snackbar.open('Removed from favourites !', '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 1000,
            panelClass: ['green-snackbar'],
          });
        } else {
          this._snackbar.open('Not removed, it was not a fav !', '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 1000,
            panelClass: ['red-snackbar'],
          });
        }
      });
    } else {
      //Case add to Favorite
      this.favoriteService.add(fav).subscribe((result) => {
        if (result.done) {
          this.pic.favorite = true;

          this._snackbar.open('Added to favourites !', '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 1000,
            panelClass: ['green-snackbar'],
          });
        } else {
          this._snackbar.open('Already a fav !', '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 1000,
            panelClass: ['red-snackbar'],
          });
        }
      });
    }
  }

  upscale2(): void {

    this.finalMsg.action_code = ActionCode.UPSCALE;
    this.finalMsg.upscale_req = new UpscaleRequest();
    this.finalMsg.upscale_req.img_guid = this.pic.guid;
    this.finalMsg.upscale_req.factor = ScaleFactor.TWO;

    //CLOSE AND ASK MAIN PAGE TO LAUNCH AN UPSCALE PROCESS
    this.dialogRef.close(this.finalMsg);
  }

  upscale4(): void {

    this.finalMsg.action_code = ActionCode.UPSCALE;
    this.finalMsg.upscale_req = new UpscaleRequest();
    this.finalMsg.upscale_req.img_guid = this.pic.guid;
    this.finalMsg.upscale_req.factor = ScaleFactor.FOUR;

    //CLOSE AND ASK MAIN PAGE TO LAUNCH AN UPSCALE PROCESS
    this.dialogRef.close(this.finalMsg);
  }

  vary(): void {

    this.finalMsg.action_code = ActionCode.VARY;
    this.finalMsg.vary_req = new VaryRequest();
    this.finalMsg.vary_req.img_guid = this.pic.guid;

    //CLOSE AND ASK MAIN PAGE TO LAUNCH A VARY PROCESS
    this.dialogRef.close(this.finalMsg);
  }

  onCancel(): void {

    this.dialogRef.close(this.finalMsg);
  }

  download(): void {

    this.pictureService.downloadImage(this.pic.img_uri).subscribe((blob) => {
      // Create a link element
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.type = "image/jpeg";
      link.download = 'nzym-img-' + (new Date().getTime()) + '.jpg'; // Set the download filename

      // Append link to the body, click it, and then remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  inpaint(): void {
    this.dialogRef.close(null);

    this.router.navigate(['/inpaint', this.pic.guid]);
  }

  getSource() {
    if (this.pic.gen_type == 'SDXL_IMG_GENERATOR') {
      return 'Standard prompt generation';
    } else if ( this.pic.gen_type == 'SDXL_PROFILE_PICTURE_AVATAR'
      || this.pic.gen_type == 'SDXL_PROFILE_PICTURE_AVATAR'
      || this.pic.gen_type == 'SDXL_SCENE_AVATAR'
      || this.pic.gen_type == 'SDXL_DREAMBOOTH_AVATAR'
      || this.pic.gen_type == 'SDXL_PREVIEW_AVATAR'
    ) {
      return 'Avatar generation';
    } else if (this.pic.gen_type == 'SDXL_IMG_INPAINT') {
      return 'Inpaint/Fix generation';
    } else if (this.pic.gen_type == 'SDXL_IMG_OUTPAINT') {
      return 'Zoom Out generation';
    } else if (this.pic.gen_type == 'SDXL_IMG_UPSCALE') {
      return 'Upscale generation';
    } else if (this.pic.gen_type == 'SDXL_IMG_VARY') {
      return 'Variant generation';
    } else return 'Unknown';
  }

  info() {
    const dialogRef = this.dialog.open(DialogCustomInfoComponent, {
      data: { realx: this.realx, realy: this.realy, source: this.getSource(), prompt: this.pic.raw_user_prompt },
      height: '350px',
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(() => {

    });
  }

  disappear() {

    this.pictureService.disappear(this.pic.guid).subscribe(()=> {

//      let msg = new ActionMessage();

//      msg.img_guid = this.pic.guid;
//      msg.action_code = ActionCode.DELETE;

    //CLOSE AND remove picture from the table
    //this.dialogRef.close(msg);

      this.tiles.splice(this.index,1);
      if (this.index < this.tiles.length-1) {
        //nothing
      } else if (this.index > 0)
        this.index--;

      //else nothing :)

      this.pic = this.tiles[this.index]?.pic;

      this._snackbar.open('Image deleted !', '', {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 800,
        panelClass: ['green-snackbar'],
      });


    });

  }


  share() : void {

    //console.log("parent url ="+this.pic.img_uri);
    //share picture to all and to social network ?

    this.pictureService.sharePictureToAll(this.pic.guid).subscribe(() => {

      this.pic.public_shared = !this.pic.public_shared;

      if (this.pic.public_shared) {

      this._snackbar.open('Image shared to the community !', '', {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 800,
        panelClass: ['green-snackbar'],
      });
    } else {

      this._snackbar.open('Image unshared  !', '', {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 800,
        panelClass: ['red-snackbar'],
      });


    }

    });

  }

  regenerate(): void {

    this.finalMsg.img_guid = this.pic.guid;
    this.finalMsg.action_code = ActionCode.SAME_AGAIN;

    //CLOSE AND ASK MAIN PAGE TO GENERATE A NEW ONE WITH THE SAME PROMPT
    this.dialogRef.close(this.finalMsg);
  }


  goLeft() {

    console.log("click left");

    if (this.index > 0)  {
      this.index--;
      this.pic = this.tiles[this.index].pic;
    }
  }

  goRight() {

    console.log("click right");

    if (this.index < this.tiles.length-1) {
      this.index++;
      this.pic = this.tiles[this.index].pic;
    }

  }

}
