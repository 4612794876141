import { AsyncPipe, Location, NgFor, NgIf } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AvatarService } from '../services/avatar.service';
import { DialogImageActionComponent } from './dialog-image-action/dialog-image-action.component';
import { Avatar } from '../shared/entity/avatar';
import { DialogImageDreamboothComponent } from './dialog-image-dreambooth/dialog-image-dreambooth.component';
import { CreditService } from '../services/credit.service';
import { DialogEditAvatarComponent } from './dialog-edit-avatar/dialog-edit-avatar.component';
import { MatIconModule } from '@angular/material/icon';
import { FileDragNDropDirective } from '../shared/directive/file-drag-n-drop.directive ';
import { MarketingImage } from '../shared/entity/marketing-image';
import { DialogWarningSoldComponent } from './dialog-warning-sold/dialog-warning-sold.component';
import { DialogReviewPriceComponent } from './dialog-review-price/dialog-review-price.component';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DialogEditHistoryComponent } from '../avatar-stories-list/dialog-edit-history/dialog-edit-history.component';

export interface Tile {
  imgThumb: string,
  imgFull: string,
  loaded: boolean;
  start?: number;
  sec?: number;
  decilesec?: number;
  action?: string;
}

@Component({
  selector: 'app-avatar-face-generator-ref',
  templateUrl: './avatar-face-generator-ref.component.html',
  styleUrl: './avatar-face-generator-ref.component.scss',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatCardModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    AsyncPipe,
    MatAutocompleteModule,
    MatRadioModule,
    MatChipsModule,
    MatSliderModule,
    MatIconModule,
    FileDragNDropDirective,
    MatTooltipModule,
    NgIf,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AvatarFaceGeneratorRefComponent {
  avatar: Avatar | undefined;
  avatarOwners: string[];
  loaded: boolean = false;
  dreamboothAvailable: boolean = false;
  refAvailable: boolean = false;
  form: FormGroup;

  disableGenProfilePicture: boolean;
  disableGenMarketing: boolean;
  disableGenDreambooth: boolean;

  tiles: Tile[] = [];
  dreamboothTiles: Tile[] = [];

  constructor(
    public dialog: MatDialog,
    private avatarService: AvatarService,
    private route: ActivatedRoute,
    private location: Location,
    private _snackbar: MatSnackBar,
    private creditService: CreditService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loaded = true;

    //activation of the action button for regenerations
    this.disableGenProfilePicture = false;
    this.disableGenMarketing = false;
    this.disableGenDreambooth = false;

    this.route.paramMap.subscribe((params) => {
      //console.log(params);
      //console.log(params.get('avatar_guid'));
      //console.log('In init of REF');

      this.avatarService.get(params.get('avatar_guid')).subscribe((avatar) => {
        this.avatar = avatar;

        //        console.log(this.avatar);

        this.setGenButtons();

        //console.log('init form !');

        this.form = this.formBuilder.group({
          public_access: [
            this.avatar.data.public_access,
            [Validators.required],
          ],
          on_sale: [this.avatar.data.on_sale, [Validators.required]],
          active: [this.avatar.data.active, [Validators.required]],
        });

        /*   if (avatar.data.ref_pics) {
          // console.log(Object.keys(avatar.data.ref_pics));

          let keys = Object.keys(avatar.data.ref_pics);
          let count = 0;

          keys.forEach((key) => {
            this.addCase(avatar.data.ref_pics[key], key);
            if (count == 0) {
              //only once !
              this.refAvailable = true;
              count++;
            }
          });
        }
*/
        if (avatar.data.lora_pics) {
          // console.log(Object.keys(avatar.data.lora_pics));

          let keys = Object.keys(avatar.data.lora_pics);
          let count = 0;

          keys.forEach((key) => {
            this.addDreamboothCase(avatar.data.lora_pics[key].thumb_uri, avatar.data.lora_pics[key].img_uri, key);
            if (count == 0) {
              //only once !
              this.dreamboothAvailable = true;
              count++;
            }
          });
        }

        //emails / account
        this.avatarService
          .getOwners(this.avatar.metadata.guid)
          .subscribe((owners) => {
            //console.log("owners:");
            //console.log(owners);
            this.avatarOwners = owners;
          });
      });
    });
  }

  /*
  addCase(picUri: string, pic_action: string) {
    let tile = {
      img: picUri,
      loaded: true,
      start: new Date().getTime(),
      sec: 0,
      decilesec: 0,
      action: pic_action,
    };
    this.tiles.unshift(tile);
  }
  */

  addDreamboothCase(picThumnUri: string, picFullUri: string, pic_action: string) {
    let tile = {
      imgThumb: picThumnUri,
      imgFull: picFullUri,
      loaded: true,
      start: new Date().getTime(),
      sec: 0,
      decilesec: 0,
      action: pic_action,
    };
    this.dreamboothTiles.unshift(tile);
  }

  generateProfilePicture() {
    this.avatar.data.in_regeneration = true;
    this.setGenButtons();

    this.avatarService
      .regenProfilePicture(this.avatar.metadata.guid)
      .subscribe(() => {
        this._snackbar.open(
          'Regen ' +
            this.avatar.data.given_name +
            ' ' +
            this.avatar.data.family_name +
            ' done !',
          '',
          {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 5000,
            panelClass: ['green-snackbar'],
          }
        );

        this.avatarService.get(this.avatar.metadata.guid).subscribe((av) => {
          this.avatar = av;
          this.setGenButtons();
        });
      });
  }

  generateMarketingPictures() {
    this.avatar.data.in_regeneration = true;
    this.setGenButtons();

    this._snackbar.open('Regen in process !', '', {
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      duration: 2000,
      panelClass: ['green-snackbar'],
    });

    this.avatarService.regenScenes(this.avatar.metadata.guid).subscribe(() => {
      this.avatarService.get(this.avatar.metadata.guid).subscribe((av) => {
        this.avatar = av;
        this.setGenButtons();
      });

      this._snackbar.open(
        'Regen ' +
          this.avatar.data.given_name +
          ' ' +
          this.avatar.data.family_name +
          ' done !',
        '',
        {
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
          duration: 5000,
          panelClass: ['green-snackbar'],
        }
      );
    });
  }

  generateDreamboothPictures() {
    this.avatar.data.in_regeneration = true;
    this.setGenButtons();

    this._snackbar.open('Dreambooth in process !', '', {
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      duration: 2000,
      panelClass: ['green-snackbar'],
    });

    //
    this.avatarService
      .regenDreamboothAllPostures(this.avatar.metadata.guid)
      .subscribe(() => {
        this.avatarService.get(this.avatar.metadata.guid).subscribe((av) => {
          this.avatar = av;
          this.setGenButtons();
        });

        this._snackbar.open(
          'Dreambooth ' +
            this.avatar.data.given_name +
            ' ' +
            this.avatar.data.family_name +
            ' launched in background !',
          '',
          {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 5000,
            panelClass: ['green-snackbar'],
          }
        );
      });
  }

  openDreamboothDialog(tile): void {
    const dialogRef = this.dialog.open(DialogImageDreamboothComponent, {
      data: { name: 'IMAGE', pic: tile, guid: this.avatar.metadata.guid },
      height: '80%',
      width: '80%',
      maxWidth: '1000px',
      maxHeight: '600px',
    });

    dialogRef.afterClosed().subscribe((result: Tile) => {
      //this is a tile
      //we have to write the img in the tiles array, looking for the reference action

      //could be undefined
      if (result) {
        for (let i = 0; i < this.dreamboothTiles.length; i++) {
          if (this.dreamboothTiles[i].action == result.action) {
            this.dreamboothTiles[i].imgThumb = result.imgThumb;
            this.dreamboothTiles[i].imgFull = result.imgFull;
            break;
          }
        }
      }
    });
  }

  openDialog(tile): void {
    const dialogRef = this.dialog.open(DialogImageActionComponent, {
      data: { name: 'IMAGE', pic: tile, guid: this.avatar.metadata.guid },
      height: '80%',
      width: '80%',
      maxWidth: '1000px',
      maxHeight: '600px',
    });

    dialogRef.afterClosed().subscribe((result: Tile) => {
      //this is a tile
      //we have to write the img in the tiles array, looking for the reference action

      //could be undefined
      if (result) {
        for (let i = 0; i < this.tiles.length; i++) {
          if (this.tiles[i].action == result.action) {
            this.tiles[i].imgThumb = result.imgThumb;
            this.tiles[i].imgFull = result.imgFull;
            break;
          }
        }
      }
    });
  }

  editMarketingText() {
    const dialogRef = this.dialog.open(DialogEditAvatarComponent, {
      data: { avatar: this.avatar },
      width: '90%',
      maxWidth: '1000px',
      maxHeight: '600px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result: Avatar) => {
      if (result) {
        result.updateType = 'PUBLIC_PROFILE_TEXTS';

        this.avatarService.update(result).subscribe((res) => {
          this.avatar = result;

          this._snackbar.open('Avatar updated !', '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 1000,
            panelClass: ['green-snackbar'],
          });
        });
      } else {
        this._snackbar.open('Avatar not updated !', '', {
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
          duration: 1000,
          panelClass: ['red-snackbar'],
        });
      }
    });
  }

  refreshToggles() {
    this.form.controls.public_access.setValue(this.avatar.data.public_access);
    this.form.controls.on_sale.setValue(this.avatar.data.on_sale);
    this.form.controls.active.setValue(this.avatar.data.active);
  }

  changePublicAccess() {
    // is it owned by someone ?
    // if yes, it can not be switch public
    // if not, is it on sale ?
    // if yes, switch the sale off and nullify the price
    // if not, continue
    // switch it public
    // update the local avatar

    //update avatar on server
    //if ok, then update ava

    let nbOwner = this.avatarOwners.length;

    //check if there is an owner (paid or custom create) --> based on subscription
    if (nbOwner > 0) {
      this._snackbar.open('Avatar has owners, can not become public !', '', {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 2000,
        panelClass: ['red-snackbar'],
      });

      this.avatarService.get(this.avatar.metadata.guid).subscribe((avatar) => {
        this.avatar = avatar;
        this.refreshToggles();
      });
    } else {
      //is it on sale ?
      if (this.avatar.data.on_sale) {
        //if yes switch the sale off and nullify price

        this.avatar.data.on_sale = false;
        this.avatar.data.sold_out = false;
        this.avatar.data.exclusive = false;
        this.avatar.data.max_sales = 0;
        this.avatar.data.sales_price = 0;

        this.avatar.updateType = 'SALES_INFO';
        //update sale info
        this.avatarService.update(this.avatar).subscribe({
          next: (res) => {

            //then switch public access
            this.avatarService
              .publicAccess(this.avatar.metadata.guid)
              .subscribe(() => {
                this._snackbar.open('Avatar updated !', '', {
                  horizontalPosition: 'right',
                  verticalPosition: 'bottom',
                  duration: 1000,
                  panelClass: ['green-snackbar'],
                });

                this.avatarService
                  .get(this.avatar.metadata.guid)
                  .subscribe((avatar) => {
                    this.avatar = avatar;
                    this.refreshToggles();
                  });
              });
          },
          error: (err) => {
            this._snackbar.open('Avatar not updated !', '', {
              horizontalPosition: 'right',
              verticalPosition: 'bottom',
              duration: 1000,
              panelClass: ['red-snackbar'],
            });
          },
        });
      } else {
        //then switch public access
        this.avatarService
          .publicAccess(this.avatar.metadata.guid)
          .subscribe(() => {
            this._snackbar.open('Avatar updated !', '', {
              horizontalPosition: 'right',
              verticalPosition: 'bottom',
              duration: 1000,
              panelClass: ['green-snackbar'],
            });

            //update local avatar

            this.avatarService
              .get(this.avatar.metadata.guid)
              .subscribe((avatar) => {
                this.avatar = avatar;
                this.refreshToggles();
              });
          });
      }
    }
  }

  changeSaleStatus() {

    //console.log(this.avatar);
    //console.log(this.avatarOwners);

    if (!this.avatar.data.on_sale) {
      // CASE 1: it was not on sale, the admin activates the sale status to ON

      // is it owned ?
      // --> if so, NOGO
      // --> else
      //    --> inform the admin that the price+data needs to be set (open popup ?)
      //    --> if price is set in the popup, then update the server
      //    --> if avatar is "public", switch it off
      //    --> if ok, update the local avatar
      //    --> set the public access to false

      let nbOwner = this.avatarOwners.length;

      //check if there is an owner without any sales , it means it is private
      if (nbOwner == 1 && this.avatar.data.has_been_sold_at_least_once==false) {
        this._snackbar.open(
          'Avatar has been created by a user, can not be switched to "on sale" !',
          '',
          {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 2000,
            panelClass: ['red-snackbar'],
          }
        );

        this.avatarService
          .get(this.avatar.metadata.guid)
          .subscribe((avatar) => {
            this.avatar = avatar;
            this.refreshToggles();
          });
      } else {
        const dialogRef = this.dialog.open(DialogReviewPriceComponent, {
          data: {
            text: 'Please set/confirm sales data :',
            avatar: this.avatar,
          },
          width: '400px',
          disableClose: true,
        });

        dialogRef.afterClosed().subscribe((result: Avatar) => {

          //console.log("CASE 1");
          //console.log(result);

          if (result) {
            this.avatar = result;
            this.avatar.updateType = 'SALES_INFO';
            //update sale status
            this.avatarService.update(this.avatar).subscribe({
              next: (res) => {

                //if the avatar was public, set it to private
                if (
                  this.avatar.data.on_sale &&
                  this.avatar.data.public_access
                ) {
                  this.avatarService
                    .publicAccess(this.avatar.metadata.guid)
                    .subscribe(() => {
                      //refresh user
                      this.avatarService
                        .get(this.avatar.metadata.guid)
                        .subscribe((avatar) => {
                          this.avatar = avatar;
                          this.refreshToggles();

                          this._snackbar.open('Avatar updated !', '', {
                            horizontalPosition: 'right',
                            verticalPosition: 'bottom',
                            duration: 1000,
                            panelClass: ['green-snackbar'],
                          });
                        });
                    });
                } else {
                  //and update the local avatar
                  this.avatarService
                    .get(this.avatar.metadata.guid)
                    .subscribe((avatar) => {
                      this.avatar = avatar;
                      this.refreshToggles();

                      this._snackbar.open('Avatar updated !', '', {
                        horizontalPosition: 'right',
                        verticalPosition: 'bottom',
                        duration: 1000,
                        panelClass: ['green-snackbar'],
                      });
                    });
                }
              },
              error: (err) => {
                this._snackbar.open('Avatar not updated !', '', {
                  horizontalPosition: 'right',
                  verticalPosition: 'bottom',
                  duration: 1000,
                  panelClass: ['red-snackbar'],
                });
              },
            });
          } else {
            this._snackbar.open('Avatar not updated !', '', {
              horizontalPosition: 'right',
              verticalPosition: 'bottom',
              duration: 2000,
              panelClass: ['red-snackbar'],
            });

            this.refreshToggles();
          }
        });
      }
    } else {
      //CASE2 : it was on sale, the user deactivates the sale status to OFF
      // --> update the avatar on server
      // --> if ok update the avatar locally
      //      --> set the price to null
      //      --> set the sale status to false

      //if yes switch the sale off and nullify price

      this.avatar.data.on_sale = false;
      this.avatar.data.sold_out = false;
      this.avatar.data.exclusive = false;
      this.avatar.data.max_sales = 0;
      this.avatar.data.sales_price = 0;

      this.avatar.updateType = 'SALES_INFO';

      //update sale info
      this.avatarService.update(this.avatar).subscribe({
        next: (res) => {

          this._snackbar.open('Avatar updated !', '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 1000,
            panelClass: ['green-snackbar'],
          });

          this.refreshToggles();
        },
        error: (err) => {
          this._snackbar.open('Avatar not updated !', '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 1000,
            panelClass: ['red-snackbar'],
          });
        },
      });
    }
  }

  changeActive() {
    //CASE 1: the status is public
    // --> update avatar on server
    // --> if ok, then update avatar locally

    if (this.avatar.data.public_access) {
      this.avatarService.active(this.avatar.metadata.guid).subscribe({
        next: (res) => {
          this._snackbar.open('Avatar updated !', '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 1000,
            panelClass: ['green-snackbar'],
          });

          this.avatar.data.active = !this.avatar.data.active;
          this.refreshToggles();
        },
        error: (err) => {
          this._snackbar.open('Avatar not updated !', '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 1000,
            panelClass: ['red-snackbar'],
          });
        }
      });
    } else {
      //CASE 2: the status is private, if it is not already sold
      // --> update avatar on server
      // --> if ok, then update avatar locally

      if (!this.avatar.data.has_been_sold_at_least_once) {
        this.avatarService.active(this.avatar.metadata.guid).subscribe({
          next: (res) => {
            this._snackbar.open('Avatar updated !', '', {
              horizontalPosition: 'right',
              verticalPosition: 'bottom',
              duration: 1000,
              panelClass: ['green-snackbar'],
            });

            this.avatar.data.active = !this.avatar.data.active;
            this.refreshToggles();
          },
          error: (err) => {
            this._snackbar.open('Avatar not updated !', '', {
              horizontalPosition: 'right',
              verticalPosition: 'bottom',
              duration: 1000,
              panelClass: ['red-snackbar'],
            });
          },
        });
      } else {
        //CASE 3: the status is private and the avatar is sold to some users
        // --> opens a pop up to validate the decision !
        // --> if yes, update the server
        // --> if ok, update the avatar locally

        const dialogRef = this.dialog.open(DialogWarningSoldComponent, {
          data: {
            text: 'This Avatar wqas acquired by some user(s). Are you sure you want to deactivate it ?',
          },
          width: '400px',
          disableClose: true,
        });

        dialogRef.afterClosed().subscribe((result: boolean) => {
          if (result) {
            let self = this;

            this.avatarService.active(this.avatar.metadata.guid).subscribe({
              next: (res) => {
                self._snackbar.open('Avatar updated !', '', {
                  horizontalPosition: 'right',
                  verticalPosition: 'bottom',
                  duration: 1000,
                  panelClass: ['green-snackbar'],
                });

                self.avatar.data.active = !this.avatar.data.active;
                this.refreshToggles();
              },
              error: (err) => {
                this._snackbar.open('Avatar not updated - Server error !', '', {
                  horizontalPosition: 'right',
                  verticalPosition: 'bottom',
                  duration: 1000,
                  panelClass: ['red-snackbar'],
                });
              },
            });
          } else {
            this._snackbar.open('Avatar not updated !', '', {
              horizontalPosition: 'right',
              verticalPosition: 'bottom',
              duration: 1000,
              panelClass: ['red-snackbar'],
            });
            this.refreshToggles();
          }
        });
      }
    }
  }

  //drag and drop
  onImageChange(pFileList: File[], type: string, idx: number) {
    let temp_files: File[] = Object.keys(pFileList).map(
      (key) => pFileList[key]
    );

    this.file2Base64(temp_files[0]).then((value) => {
      //console.log(value);

      let image: MarketingImage = new MarketingImage();

      image.base64 = value.slice('data:image/png;base64,'.length + 1); //remove the signature

      //console.log(image.base64);

      image.type = type;
      image.rank = idx;
      image.guid = this.avatar.metadata.guid;

      if (type == 'PROFILE') {
        image.caption = 'THUMBNAIL';
      } else if (type == 'SCENE') {
        if (idx == 0) image.caption = 'MAIN SCENE';
        else if (idx == 1) image.caption = 'COSPLAY';
        else if (idx == 2) image.caption = 'BEACH';
        else if (idx == 3) image.caption = 'ACTION';
        else if (idx == 4) image.caption = 'SPORT';
      } else if (type == 'PORTRAIT') {
        //PORTRAIT
        image.caption = 'PORTRAIT';
      }

      this.avatarService
        .createMarketingImage(image)
        .subscribe((avatar: Avatar) => {
          this.avatar = avatar;
        });
    });
  }

  file2Base64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      let reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString() || '');
      reader.onerror = (error) => reject(error);
    });
  };

  //if click to choose image
  displayEvent(event, type: string, idx: number) {
    let pFileList: File[] = event.target.files;
    this.onImageChange(pFileList, type, idx);
  }

  displayFn(str: string) {
    return str.replaceAll('_', ' ');
  }

  setGenButtons() {
    if (this.avatar.data.in_creation || this.avatar.data.in_regeneration) {
      this.disableGenProfilePicture = true;
      this.disableGenMarketing = true;
      this.disableGenDreambooth = true;
    } else {
      this.disableGenProfilePicture = false;
      this.disableGenMarketing = false;
      this.disableGenDreambooth = false;
    }
  }

  cancel() {
    this.location.back();
  }

  changeRandomStory() {

    this.avatarService.randomizeAvatarStory(this.avatar.metadata.guid).subscribe((avatar)=> {
      this.avatar = avatar;
    })



  }
}
