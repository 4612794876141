import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { Balance } from '../shared/entity/balance';
import { Favorite } from '../shared/entity/favorite';
import { Picture } from '../shared/entity/picture';

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {

   apiBaseUrl : string;

  constructor(private http: HttpClient) {
    this.apiBaseUrl =  environment.apiUrl;
  }

  add(fav: Favorite): Observable<Favorite>  {

    return this.http.post<Favorite>(`${this.apiBaseUrl}/v1/favorite`, fav);

  }

  remove(fav: Favorite): Observable<Favorite> {

    return this.http.delete<Favorite>(`${this.apiBaseUrl}/v1/favorite?guid=${fav.img_guid}`);
  }

  getAll():Observable<Picture[]> {
    return this.http.get<Picture[]>(`${this.apiBaseUrl}/v1/favorite`);
  }


  downloadZip(guids):Observable<Blob> {
    return this.http.post(`${this.apiBaseUrl}/v1/favorite/downloads`, guids, { responseType: 'blob' });
  }


}
