import { ImagePrompt } from "./image-prompt";

export class PictureRequest {

  raw_user_prompt: string;
  avatar_guid: string;
  hair_style: string;
  scene_guid: string;
  scene_influence_level: number;
  gen_type: string;
//  img_prompt_base64: string;
  img_prompts: ImagePrompt[];
  nb_images: number;
  img_ratio: string;
  styles: string[];
  creativity: string;

  //FRONT ONLY TO COMMUNICATE BETWEEN MODALS
  action: string;  //ZOOM_OUT, SAME_AGAIN

}
