<div class="container" >

  <mat-card class="mat-elevation-z0 box">
    <mat-card-header class="header">
      <mat-card-title class="card-title">GENERATION RUNNING ! </mat-card-title>
      <mat-card-title class="card-subtitle"></mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
    <div class="image">
      <img class="round" src="{{ picture }}">
    </div>
    <div class="text">
      <div class="avatar-name"> {{ avatar?.data.given_name.toUpperCase() }} {{ avatar?.data.family_name.toUpperCase() }}
      </div>
      <div class="avatar-wait-text">
        <mat-progress-bar
          [color]="'primary'"
          [mode]="'determinate'"
          [value]="value"
          [bufferValue]="value">
      </mat-progress-bar>
      </div>
      <div class="avatar-wait-text">
        Your avatar is being generated !</div>
      <div class="avatar-wait-text">
        {{ avatar?.data.given_name.toUpperCase() }} will be available for new generation shortly.
      </div>
      <div class="avatar-wait-text">
        You will be able to generate pictures with scenes and your brand new avatar.
      </div>
        <div class="avatar-wait-text">
        Have fun with NZYM & {{ avatar?.data.given_name }} !
      </div>
    </div>
   <!-- <div class="buttons">
      <div class="button">
        <button mat-raised-button color="primary" (click)="move()"> OK </button>
      </div>
    </div> -->
  </mat-card-content>
  </mat-card>

</div>
