<div *ngIf="loaded" class="cards">

  <mat-card class="mat-elevation-z0 single-card settings-card">
    <mat-cart-header class="mat-card-header ">
      <mat-card-title>
        <div class="card-title"> AVATAR SETTINGS </div>
      </mat-card-title>
      <!--<mat-card-title><span class="card-subtitle">  </span></mat-card-title>-->
    </mat-cart-header>
    <mat-card-content>
      <div class="container">
        <div class="thumbnail">

          <div class="file-drop-container">
            <div class="dropzone" fileDragDrop (filesChangeEmiter)="onImageChange($event, 'PROFILE', 0)">

              <img src='{{avatar?.data.marketing_profile_picture_thumb_uri}}' class="round-profile">


              <div class="text-wrapper">
                <div class="centered">
                  <input type="file" name="file" id="fileDropRef0" (change)="displayEvent($event,'PROFILE', 0)">
                  <label for="fileDropRef0">
                    <div class="caption">
                      <mat-icon>edit_square</mat-icon>
                    </div>
                  </label>
                </div>

              </div>
            </div>
          </div>

        </div>
        <div class="avatar-name"> {{ avatar?.data.given_name?.toUpperCase() }} {{
          avatar?.data.family_name?.toUpperCase()
          }}
        </div>

        <div class="action-buttons">
          <div class="edit">
            <button mat-raised-button color="accent" type="submit" class="button" [disabled]="disableGenProfilePicture"
              matTooltip="Regenerate Profile picture" (click)="generateProfilePicture()"> <mat-icon
                class="icon-button">account_circle</mat-icon></button>
          </div>
          <div class="edit">
            <button mat-raised-button color="accent" type="submit" class="button" [disabled]="disableGenMarketing"
              matTooltip="Regenerate new Scene & Portrait pictures " (click)="generateMarketingPictures()"> <mat-icon
                class="icon-button">recycling</mat-icon></button>
          </div>
          <div class="edit">
            <button mat-raised-button color="accent" type="submit" class="button" [disabled]="disableGenDreambooth"
              matTooltip="Regenerate new Dreambooth pictures " (click)="generateDreamboothPictures()"> <mat-icon
                class="icon-button">web_stories</mat-icon></button>
          </div>
        </div>
        <div
          [class]=" (disableGenProfilePicture || disableGenMarketing || disableGenDreambooth) ? 'spinner-container' :  'not-visible'">
          <div class="spinner">
            <mat-spinner [diameter]="25"></mat-spinner>
          </div>
          <div class="progress-text">
            Gen. in Progress !
          </div>
        </div>
      </div>
      <div class="container">

        <div class="profile-info">

          <div class="info-element">
            <div class="element-title">AGE</div>
            <div class="element-content">{{ avatar?.data.marketing_age }}</div>
          </div>
          <div class="info-element">
            <div class="element-title">HEIGHT</div>
            <div class="element-content">{{ avatar?.data.marketing_height }} cm</div>
          </div>
          <div class="info-element">
            <div class="element-title">HAIR COLOR</div>
            <div class="element-content">{{ avatar?.data.marketing_hair_color?.toUpperCase() }}</div>
          </div>
          <div class="info-element">
            <div class="element-title">EYE COLOR</div>
            <div class="element-content">{{ avatar?.data.marketing_eye_color?.toUpperCase() }}</div>
          </div>
          <div class="info-element">
            <div class="element-title">ZODIAC SIGN</div>
            <div class="element-content">{{ avatar?.data.marketing_zodiac_sign?.toUpperCase() }}</div>
          </div>
          <div class="info-element">
            <div class="element-title">BLOOD TYPE</div>
            <div class="element-content">{{ avatar?.data.marketing_blood_type?.toUpperCase() }}</div>
          </div>
          <div class="info-element">
            <div class="element-title">TEMPER</div>
            <div class="element-content">{{ avatar?.data.marketing_temper?.toUpperCase() }}</div>
          </div>
          <div class="info-element" *ngIf="avatar?.data.on_sale">
            <div class="element-title">PRICE</div>
            <div class="element-content">{{ avatar?.data.sales_price }} credits</div>
          </div>
          <div class="info-element" *ngIf="avatar?.data.on_sale">
            <div class="element-title">MAX LIMIT SALES</div>
            <div class="element-content">{{ avatar?.data.max_sales }} </div>
          </div>
          <div class="info-element" *ngIf="avatar?.data.on_sale">
            <div class="element-title">EXCLUSIVE SALES</div>
            <div class="element-content">{{ avatar?.data.exclusive }} </div>
          </div>
          <div class="info-element" *ngIf="avatar?.data.on_sale">
            <div class="element-title">SOLD OUT </div>
            <div class="element-content">{{ avatar?.data.sold_out }} </div>
          </div>
          <div class="info-element" *ngIf="avatar?.data.on_sale">
            <div class="element-title"> #SALES </div>
            <div class="element-content">TODO </div>
          </div>

          <div class="info-button">
            <button mat-raised-button color="primary" (click)="editMarketingText()"> EDIT
              {{avatar?.data.given_name?.toUpperCase() }} </button>
          </div>
        </div>

        <form *ngIf="form" [formGroup]="form">
          <div class="actions">

            <div class="public">
              <div class="label">Public avatar</div>
              <div class="toggle">

                <mat-slide-toggle [color]="'primary'" formControlName="public_access" (change)="changePublicAccess()">
                </mat-slide-toggle>

              </div>
            </div>
            <div class="sale">
              <div class="label">Avatar for sale</div>
              <div class="toggle">

                <mat-slide-toggle [color]="'primary'" formControlName="on_sale"
                  (change)="changeSaleStatus()"></mat-slide-toggle>

              </div>
            </div>
            <div class="active">
              <div class="label">Active</div>
              <div class="toggle">

                <mat-slide-toggle [color]="'primary'" formControlName="active"
                  (change)="changeActive()"></mat-slide-toggle>

              </div>
            </div>


            <div class="sold-to">
              <div class="label">Owned by</div>
                <div class="owners">
              @for (owner of avatarOwners; track owner) {
              <div class="owner">{{ owner }}</div>
              }
            </div>

            </div>
          </div>
        </form>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="content-zone">
    <mat-card class="mat-elevation-z0 single-card admin-card">
      <mat-cart-header class="mat-card-header form-box-title">
          <mat-card-title>
            <table width="100%">
              <td width="33%"> </td>
              <td width="33%">PROFILE SCENES & PORTRAITS
              </td>
              <td width="33%" class="header-button">
                <button mat-raised-button color="accent" type="submit" class="button" [disabled]="disableGenMarketing"
                  (click)="generateMarketingPictures()"> <mat-icon>recycling</mat-icon> REGEN. ALL SCENES
                  </button>
              </td>
            </table>
          </mat-card-title>
        <!--<mat-card-title><span class="card-subtitle">  </span></mat-card-title>-->
      </mat-cart-header>

      <mat-card-content>
        <div class="boxes">
          <div class="left-box">

            <div class="history-text">
              <div class="history-title">HISTORY</div>
              <div class="history-content">{{ avatar?.data.marketing_history }} </div>
              <div class="caption" (click)="changeRandomStory()">
                <div class="scene-caption">CHANGE STORY</div>
                <mat-icon>edit_square</mat-icon>
              </div>
            </div>
            <div class="main-scene">
              <div class="scene">

                <div class="file-drop-container">
                  <div class="dropzone" fileDragDrop (filesChangeEmiter)="onImageChange($event, 'SCENE', 0)">

                    <div class="scene-image"><img src='{{ avatar?.data.marketing_main_scene_uri }}' class="image">
                    </div>

                    <div class="caption">
                      <div class="text-wrapper">
                        <div class="centered">
                          <input type="file" name="file" id="fileDropRef0" (change)="displayEvent($event,'SCENE', 0)">
                          <label for="fileDropRef0">
                            <div class="caption" >
                              <div class="scene-caption">MAIN SCENE</div>
                              <mat-icon>edit_square</mat-icon>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="right-box">

            <div class="scenes-title"> {{avatar?.data.given_name?.toUpperCase() }}'S SCENES ( Best 400x600 - ratio 2:3 )
            </div>
            <div class="box-scenes">

              <div class="scene">

                <div class="file-drop-container">
                  <div class="dropzone" fileDragDrop (filesChangeEmiter)="onImageChange($event, 'SCENE', 1)">

                    <div class="scene-image"><img src='{{ avatar?.data.marketing_scene_one_uri }}' class="image">
                    </div>

                    <div class="caption">
                      <div class="text-wrapper">
                        <div class="centered">
                          <input type="file" name="file" id="fileDropRef1" (change)="displayEvent($event,'SCENE', 1)">
                          <label for="fileDropRef1">
                            <div class="caption">
                              <div class="scene-caption">COSPLAY</div>
                              <mat-icon>edit_square</mat-icon>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="scene">

                <div class="file-drop-container">
                  <div class="dropzone" fileDragDrop (filesChangeEmiter)="onImageChange($event, 'SCENE', 2)">

                    <div class="scene-image"><img src='{{ avatar?.data.marketing_scene_two_uri }}' class="image">
                    </div>

                    <div class="caption">
                      <div class="text-wrapper">
                        <div class="centered">
                          <input type="file" name="file" id="fileDropRef2" (change)="displayEvent($event,'SCENE', 2)">
                          <label for="fileDropRef2">
                            <div class="caption">
                              <div class="scene-caption">BEACH</div>
                              <mat-icon>edit_square</mat-icon>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>




              <div class="scene">

                <div class="file-drop-container">
                  <div class="dropzone" fileDragDrop (filesChangeEmiter)="onImageChange($event, 'SCENE', 3)">

                    <div class="scene-image"><img src='{{ avatar?.data.marketing_scene_three_uri }}'
                        class="image">
                    </div>

                    <div class="caption">
                      <div class="text-wrapper">
                        <div class="centered">
                          <input type="file" name="file" id="fileDropRef3" (change)="displayEvent($event,'SCENE', 3)">
                          <label for="fileDropRef3">
                            <div class="caption">
                              <div class="scene-caption">ACTION</div>
                              <mat-icon>edit_square</mat-icon>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="scene">


                <div class="file-drop-container">
                  <div class="dropzone" fileDragDrop (filesChangeEmiter)="onImageChange($event, 'SCENE', 4)">

                    <div class="scene-image"><img src='{{ avatar?.data.marketing_scene_four_uri }}' class="image">
                    </div>


                    <div class="caption">
                      <div class="text-wrapper">
                        <div class="centered">
                          <input type="file" name="file" id="fileDropRef4" (change)="displayEvent($event,'SCENE', 4)">
                          <label for="fileDropRef4">
                            <div class="caption">
                              <div class="scene-caption">SPORT</div>
                              <mat-icon>edit_square</mat-icon>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="portraits-title"> {{avatar?.data.given_name?.toUpperCase() }}'S PORTRAITS ( Best 400x400 - ratio
              1:1 )</div>

            <div class="box-portraits">

              <div class="portrait">

                <div class="file-drop-container">
                  <div class="dropzone" fileDragDrop (filesChangeEmiter)="onImageChange($event, 'PORTRAIT', 1)">

                    <div class="portrait-image"><img src='{{ avatar?.data.marketing_portrait_one_uri }}'
                        class="image">
                    </div>

                    <div class="caption">
                      <div class="text-wrapper">
                        <div class="centered">
                          <input type="file" name="file" id="fileDropRef5"
                            (change)="displayEvent($event,'PORTRAIT', 1)">
                          <label for="fileDropRef5">
                            <div class="caption">
                              <div class="portrait-caption">PORTRAIT</div>
                              <mat-icon>edit_square</mat-icon>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="portrait">

                <div class="file-drop-container">
                  <div class="dropzone" fileDragDrop (filesChangeEmiter)="onImageChange($event, 'PORTRAIT', 2)">

                    <div class="portrait-image"><img src='{{ avatar?.data.marketing_portrait_two_uri }}'
                        class="image">
                    </div>

                    <div class="caption">
                      <div class="text-wrapper">
                        <div class="centered">
                          <input type="file" name="file" id="fileDropRef6"
                            (change)="displayEvent($event,'PORTRAIT', 2)">
                          <label for="fileDropRef6">
                            <div class="caption">
                              <div class="portrait-caption">PORTRAIT</div>
                              <mat-icon>edit_square</mat-icon>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="portrait">

                <div class="file-drop-container">
                  <div class="dropzone" fileDragDrop (filesChangeEmiter)="onImageChange($event, 'PORTRAIT', 3)">

                    <div class="portrait-image"><img src='{{ avatar?.data.marketing_portrait_three_uri }}'
                        class="image"> </div>

                    <div class="caption">
                      <div class="text-wrapper">
                        <div class="centered">
                          <input type="file" name="file" id="fileDropRef7"
                            (change)="displayEvent($event,'PORTRAIT', 3)">
                          <label for="fileDropRef7">
                            <div class="caption">
                              <div class="portrait-caption">PORTRAIT</div>
                              <mat-icon>edit_square</mat-icon>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="portrait">

                <div class="file-drop-container">
                  <div class="dropzone" fileDragDrop (filesChangeEmiter)="onImageChange($event, 'PORTRAIT', 4)">

                    <div class="portrait-image"><img src='{{ avatar?.data.marketing_portrait_four_uri }}'
                        class="image">
                    </div>

                    <div class="caption">
                      <div class="text-wrapper">
                        <div class="centered">
                          <input type="file" name="file" id="fileDropRef8"
                            (change)="displayEvent($event,'PORTRAIT', 4)">
                          <label for="fileDropRef8">
                            <div class="caption">
                              <div class="portrait-caption">PORTRAIT</div>
                              <mat-icon>edit_square</mat-icon>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="mat-elevation-z0 single-card pictures-card">
      <mat-cart-header *ngIf='dreamboothAvailable' class="mat-card-header form-box-title">
        <mat-card-title>
          <table width="100%">
            <td width="33%"> </td>
            <td width="33%">CUSTOM TRAINING SET
            </td>
            <td width="33%" class="header-button">
              <button mat-raised-button color="accent" type="submit" class="button" [disabled]="disableGenDreambooth"
                (click)="generateDreamboothPictures()"> <mat-icon>web_stories</mat-icon> REGEN.
                ALL</button>
            </td>
          </table>
        </mat-card-title>
      </mat-cart-header>
      <mat-card-content *ngIf='dreamboothAvailable' class="pictures">
        <div class="intro">
          <div class="intro-text"> The following pictures are the automated "Dreambooth pictures" for {{
            avatar?.data?.given_name }}</div>
          <div class="intro-text"> It means that they are/were used to train a specific AI model to generate consistant generations </div>
          <div class="intro-text"> Consider them as your specific AI dataset for {{ avatar?.data.given_name }} generative
            pictures </div>
        </div>
        <div class="pics-container">
          @for (dbTile of dreamboothTiles; track dbTile.action) {
          <div class="genbox" (click)="openDreamboothDialog(dbTile)">
            <div class="genbox-overlay" [class]="dbTile.loaded ? 'not-visible' : 'spinner-container'">
              <mat-spinner></mat-spinner>
            </div>

            <div class="genbox-overlay" [class]="dbTile.loaded ? 'not-visible' : 'chrono-visible'">{{ dbTile.sec}}.{{
              dbTile.decilesec}} s </div>
            <img width="100%" src="{{dbTile.imgThumb}}" class="image">
          </div>

          }
        </div>
      </mat-card-content>
    </mat-card>

  </div>
</div>
<!--<mat-cart-header *ngIf='refAvailable' class="mat-card-header form-box-title">
      <mat-card-title>
        <table width="100%">
          <td width="33%"> </td>
          <td width="33%">STANDARD POSTURES
          </td>
          <td width="33%" class="header-button">
            <button mat-raised-button color="accent" type="submit" class="button" [disabled]=""
              (click)="generateActionPostures()">REGEN.
              ALL</button>
          </td>
        </table>
      </mat-card-title>
    </mat-cart-header>
    <mat-card-content *ngIf='refAvailable' class="pictures">


      <div class="intro">
        <div class="intro-title"> </div>
        <div class="intro-text"> The following pictures are the automated "Reference pictures" for your avatar </div>
        <div class="intro-text"> You will be able to use these pictures, as model for future generations in scene, if
          need be </div>
        <div class="intro-text">Consider them as a "palette" of postures for your future image generation with {{
          avatar?.data?.given_name }}, if you want to </div>
      </div>
      <div class="pics-container">
        @for (tile of tiles; track tile) {
        <div class="genbox" (click)="openDialog(tile)">
          <div class="genbox-overlay" [class]="tile.loaded ? 'not-visible' : 'spinner-container'">
            <mat-spinner></mat-spinner>
          </div>
          <div class="genbox-overlay" [class]="tile.loaded ? 'not-visible' : 'chrono-visible'">{{ tile.sec}}.{{
            tile.decilesec}} s </div>
          <img width="100%" src="{{tile.img}}">
        </div>

        }

      </div>
    </mat-card-content> -->
