import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CustomAuthService } from '../../services/custom-auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private _snackbar: MatSnackBar, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        this._snackbar.open('Houston, we had a problem !', '', {
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
          duration: 2000,
          panelClass: ['red-snackbar'],
        });

        //TODO SNACKBARS FOR ERRORS

        if (err)
          switch (err.status) {
            case 204:
              this.router.navigate(['avatar-not-on-sale']);
              break;
            case 401:
                this.router.navigate(['forbidden']);
                break;
            case 402:
              this.router.navigate(['no-credit']);
              break;
            case 403:
              this.router.navigate(['forbidden']);
              break;
            case 404:
              this.router.navigate(['error']);
              break;
            case 406:
              this.router.navigate(['error-subscription']);
              break;
            case 412:
              this.router.navigate(['session-expired']);
              break;
            case 417:
              this.router.navigate(['error-subscription']);
              break;
            case 500:
              this.router.navigate(['error']);
              break;
            case 502:
              this.router.navigate(['error']);
              break;
            default:
              this.router.navigate(['home']);
              break;
          }

        const error = err.error?.message || err.statusText;

        return throwError(() => new Error(err));
      })
    );
  }
}
