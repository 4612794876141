export class VaryRequest {
  img_guid: string;
  mixing_prompt_toggle: boolean;
  mixing_prompt: string;

  constructor() {
    this.img_guid = "";
    this.mixing_prompt_toggle = false;
    this.mixing_prompt = "";
  }
}
