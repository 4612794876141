import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RandomImageService {


  image_names : string[];
  apiBaseUrl : string = '../../assets/img/background/';

  constructor(private http: HttpClient) {
    this.image_names =
    [
      'anime.webp',
      'anime2.webp',
      'car.webp',
      'comics.webp',
      'indian.webp',
      'killer.webp',
      'land.webp',
      'landscape.webp',
      'portrait.webp',
      'space.webp',
      'term.webp',
      'woman.webp',
    ]
  }

  get(): string  {

    let min = 0;
    let max = this.image_names.length-1;
    let rand = Math.floor(Math.random() * (max - min + 1)) + min;

    return this.apiBaseUrl+this.image_names[rand];

  }

}
