<div class="invoices">

<mat-form-field>
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Type a date for instance " #input>
</mat-form-field>

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>

    <!-- Start Column -->
    <ng-container matColumnDef="period_start">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> PERIOD START </th>
      <td mat-cell *matCellDef="let row"> {{row.period_start}} </td>
    </ng-container>

    <!-- End Column -->
    <ng-container matColumnDef="period_end">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> PERIOD END </th>
      <td mat-cell *matCellDef="let row"> {{row.period_end}} </td>
    </ng-container>

    <!-- invoice Column -->
    <ng-container matColumnDef="invoice_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> INVOICE DATE </th>
      <td mat-cell *matCellDef="let row"> {{row.invoice_date}} </td>
    </ng-container>

     <!-- label Column -->
     <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> LABEL </th>
      <td mat-cell *matCellDef="let row"> {{row.label}} </td>
    </ng-container>

    <!-- charge Column -->
    <ng-container matColumnDef="charge_amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> AMOUNT </th>
      <td mat-cell *matCellDef="let row"> ${{row.charge_amount}} </td>
    </ng-container>

    <!-- paid Column -->
    <ng-container matColumnDef="paid">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> PAID </th>
      <td mat-cell *matCellDef="let row"> {{row.paid}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10" aria-label="Select page of invoices"></mat-paginator>
</div>

</div>
