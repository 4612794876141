export class Picture {

  id?: number;
  guid?: string;
  img_uri?: string;
  thumb_uri?:string;
  raw_user_prompt?: string;
  avatar_guid?: string;
  hair_style?:string;
  gen_type?: string;
//  img_prompt_base64?: string;
  creator_email?: string;
  img_ratio?: string;
  favorite?:boolean;
  tags?: string[];
  creativity?:string;
  public_shared?:boolean;
  enc_p?: string;
  enc_r?: string;

}
