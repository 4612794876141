<mat-card class="mat-elevation-z0 avatar-card ">
  <mat-cart-header class="mat-card-header">
    <mat-card-title><span class="card-title"> AVATAR PROFILE </span></mat-card-title>
    <mat-card-title><span class="card-subtitle"> INFORMATION </span></mat-card-title>
  </mat-cart-header>
  <mat-card-content class="content">

    <div class="topline">

      <div class="narrative">
        <div class="narrative-element">
          <div class="narrative-element-title"> </div>
          <div class="narrative-element-content">
            <div class="intro">
              <div class="portrait"><img src="{{ avatar?.data.marketing_profile_picture_thumb_uri}}" class="thumbnail"></div>
              <div class="fullname">{{ avatar?.data.given_name?.toUpperCase() }} {{ avatar?.data.family_name?.toUpperCase() }} </div>
              <div class="purchase" *ngIf="avatar?.data.on_sale && !avatar?.data.is_owned_by_user">
                <button mat-raised-button color="primary" class="purchase-button" [disabled]="accountService.isAdmin()" (click)="purchase()">
                  <div class="text-button">
                  <div class="text">PURCHASE {{ avatar?.data.given_name?.toUpperCase() }}</div>
                  <div class="text">({{ avatar?.data.sales_price }} credits)</div>
                  </div>

                </button>
              </div>
              <div class="purchase" *ngIf="avatar?.data.public_access">
                <button mat-raised-button color="primary" class="purchase-button" (click)="use()"> TRY {{ avatar?.data.given_name?.toUpperCase() }} </button>
              </div>
              <div class="purchase" *ngIf="avatar?.data.is_owned_by_user">
                <button mat-raised-button color="primary" class="purchase-button" (click)="use()"> USE {{ avatar?.data.given_name?.toUpperCase() }} </button>
              </div>
            </div>
          </div>
        </div>
        <div class="narrative-element">
          <div class="narrative-element-title"> CREDENTIALS</div>
          <div class="narrative-element-content">

            <div class="identity">
              <div class="identity-element-title"> Age</div>
              <div class="identity-element-content"> {{ avatar?.data.marketing_age }} </div>
            </div>
            <div class="identity">
              <div class="identity-element-title"> Height</div>
              <div class="identity-element-content"> {{ avatar?.data.marketing_height }} cm</div>
            </div>
            <div class="identity">
              <div class="identity-element-title"> Natural hair color</div>
              <div class="identity-element-content"> {{ avatar?.data.marketing_hair_color?.toUpperCase() }} </div>
            </div>
            <div class="identity">
              <div class="identity-element-title"> Eye color</div>
              <div class="identity-element-content"> {{ avatar?.data.marketing_eye_color?.toUpperCase() }} </div>
            </div>
            <div class="identity">
              <div class="identity-element-title"> Zodiac Sign </div>
              <div class="identity-element-content"> {{ avatar?.data.marketing_zodiac_sign?.toUpperCase() }} </div>
            </div>
            <div class="identity">
              <div class="identity-element-title"> Blood type </div>
              <div class="identity-element-content"> {{ avatar?.data.marketing_blood_type?.toUpperCase() }} </div>
            </div>
            <div class="identity">
              <div class="identity-element-title"> Temper </div>
              <div class="identity-element-content"> {{ avatar?.data.marketing_temper?.toUpperCase() }} </div>
            </div>

          </div>
        </div>
        <div class="narrative-element">
          <div class="narrative-element-title"> HISTORY</div>
          <div class="narrative-element-content story">{{ avatar?.data.marketing_history }}
          </div>
        </div>
        <div class="narrative-element">
          <div class="narrative-element-title"  *ngIf="avatar?.data.on_sale"> TERMS & CONDITIONS </div>
          <div class="narrative-element-title"  *ngIf="!avatar?.data.on_sale"> PROPERTY & USAGE </div>
          <div class="narrative-element-content" *ngIf="avatar?.data.on_sale"> PRICE: {{ avatar?.data.sales_price }} credits </div>

          <div class="narrative-element-content" *ngIf="avatar?.data.on_sale && !avatar?.data.is_owned_by_user"> You can buy {{ avatar?.data.given_name }} and create scenes with it FOR LIFE</div>
          <div class="narrative-element-content" *ngIf="avatar?.data.on_sale && !avatar?.data.is_owned_by_user"> {{ avatar?.data.given_name }} becomes available in your NZYM Studio immediatly</div>
          <div class="narrative-element-content" *ngIf="avatar?.data.is_owned_by_user"> {{ avatar?.data.given_name }} is one of your avatars</div>

        </div>
      </div>
      <div class="big-photo">
        <img src="{{ avatar?.data.marketing_main_scene_uri}}" class="image">
      </div>
    </div>

    <div class="middleline">

      <div class="gallery">
        <div class="title"> {{ avatar?.data.given_name?.toUpperCase() }}'S SCENE EXAMPLES </div>
        <div class="photo-line">
          <div class="scene">
            <div class="scene-image"><img src="{{ avatar?.data.marketing_scene_one_uri }}" class="image"></div>
            <div class="scene-title">Cosplay</div>
          </div>
          <div class="scene">
            <div class="scene-image"><img src="{{ avatar?.data.marketing_scene_two_uri }}" class="image"></div>
            <div class="scene-title">Beach</div>
          </div>
          <div class="scene">
            <div class="scene-image"><img src="{{ avatar?.data.marketing_scene_three_uri }}" class="image"></div>
            <div class="scene-title">Action</div>
          </div>
          <div class="scene">
            <div class="scene-image"><img src="{{ avatar?.data.marketing_scene_four_uri }}" class="image"></div>
            <div class="scene-title">Sport</div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottomline">
      <div class="gallery">
        <div class="title"> {{ avatar?.data.given_name?.toUpperCase() }}'S PORTRAITS </div>
        <div class="photo-line">
          <div class="scene">
            <div class="scene-image"><img src="{{ avatar?.data.marketing_portrait_one_uri }}" class="image"></div>
            <div class="scene-title">PORTRAIT </div>
          </div>
          <div class="scene">
            <div class="scene-image"><img src="{{ avatar?.data.marketing_portrait_two_uri }}" class="image"></div>
            <div class="scene-title">PORTRAIT </div>
          </div>
          <div class="scene">
            <div class="scene-image"><img src="{{ avatar?.data.marketing_portrait_three_uri }}" class="image"></div>
            <div class="scene-title">PORTRAIT </div>
          </div>
          <div class="scene">
            <div class="scene-image"><img src="{{ avatar?.data.marketing_portrait_four_uri }}" class="image"></div>
            <div class="scene-title">PORTRAIT </div>
          </div>
        </div>
      </div>
    </div>

  </mat-card-content>



</mat-card>

<mat-card class="mat-elevation-z0 pictures-card">
  <mat-cart-header *ngIf='dreamboothAvailable' class="mat-card-header form-box-title">
    <mat-card-title>
      <table width="100%">
        <td width="33%"> </td>
        <td width="33%"> AVATAR SPECIFIC CONSISTENCY SET
        </td>
        <td width="33%" class="header-button">
          <button mat-raised-button *ngIf="admin" color="accent" class="purchase-button" (click)="downloads()"> GET ALL </button>
        </td>
      </table>
    </mat-card-title>
  </mat-cart-header>
  <mat-card-content *ngIf="dreamboothAvailable" class="pictures">
    <div class="intro">
      <div class="intro-text"> The following pictures are the automated "Dreambooth pictures" for {{
        avatar?.data?.given_name }}</div>
      <div class="intro-text"> It means that they are/were used to train a specific AI model to generate consistant generations </div>
      <div class="intro-text"> Consider them as your specific AI dataset for {{ avatar?.data.given_name }} generative
        pictures </div>
    </div>
    <div class="pics-container">
      @for (dbTile of dreamboothTiles; track dbTile.action) {
      <div class="genbox" (click)="openDreamboothDialog(dbTile)">
        <img width="100%" src="{{dbTile.imgThumb}}" class="image">
      </div>

      }
    </div>
  </mat-card-content>
</mat-card>
