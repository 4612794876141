import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCard } from '@angular/material/card';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PictureService } from '../../services/picture.service';
import { Picture } from '../../shared/entity/picture';
import { ActionMessage } from '../../shared/entity/action-message';
import { Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FavoriteService } from '../../services/favorite.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgIf } from '@angular/common';
import { OperationsCostService } from '../../services/operations-cost';

export interface DialogData {

  name: string;
  index: number;
  tiles: Tile[];
  inprocess: boolean;
}

export interface Tile {
  pic: Picture;
  loaded: boolean;
  start?: number;
  sec?: number;
  decilesec?: number;
}

@Component({
  selector: 'app-dialog-image-action',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCard,
    MatProgressSpinnerModule,
    MatIconModule,
    MatTooltipModule,
    NgIf,
  ],
  templateUrl: './dialog-image-action.component.html',
  styleUrl: './dialog-image-action.component.scss',
})
export class DialogImageActionComponent {

  @ViewChild('imgEdit', { static: true }) imgEdit: ElementRef;
  
  pic: Picture;
  creationDate: string;
  img_overlay: boolean;
  hasBeenChanged: boolean;
  id: number;
  horizontal_format: boolean; // setup the style
  realx: number;
  realy: number;
  index: number;
  tiles:Tile[];
  inprocess: boolean;

  finalMsg: ActionMessage;

  constructor(
    public dialogRef: MatDialogRef<DialogImageActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private router: Router,
    public dialog: MatDialog,
    private pictureService: PictureService,
    private favoriteService: FavoriteService,
    private _snackbar: MatSnackBar,
    public operationsCost : OperationsCostService
  ) {}

  ngOnDestroy() {
  }

  ngAfterViewInit() {

  }


  ngOnInit() {

    this.index = this.data.index;
    this.tiles = this.data.tiles;
    this.inprocess = this.data.inprocess;


    this.hasBeenChanged = false;
    this.pic = this.tiles[this.index].pic;
    this.creationDate = new Date().toISOString();
    this.horizontal_format = false;

    this.setFormatForCss();

    this.finalMsg = new ActionMessage();
    this.finalMsg.tiles = this.tiles;

    //init overlay
    this.img_overlay = true;

    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this.onCancel();
      }
    });

    this.dialogRef.backdropClick().subscribe((event) => {
      this.onCancel();
    });
  }

  onLoadImage() {
    this.realx = this.imgEdit.nativeElement.naturalWidth;
    this.realy = this.imgEdit.nativeElement.naturalHeight;

    //this.calcLeft = (this.imgEdit.nativeElement.clientWidth - 24*2)+'px';
  }

  setFormatForCss() {
    if (
      this.pic.img_ratio == 'RATIO_5_3' ||
      this.pic.img_ratio == 'RATIO_5_2'
    ) {
      this.horizontal_format = true;
    } else this.horizontal_format = false;
  }


  onCancel(): void {

    this.dialogRef.close(this.finalMsg);
  }

  getSource() {
    if (this.pic.gen_type == 'SDXL_IMG_GENERATOR') {
      return 'Standard prompt generation';
    } else if ( this.pic.gen_type == 'SDXL_PROFILE_PICTURE_AVATAR'
      || this.pic.gen_type == 'SDXL_PROFILE_PICTURE_AVATAR'
      || this.pic.gen_type == 'SDXL_SCENE_AVATAR'
      || this.pic.gen_type == 'SDXL_DREAMBOOTH_AVATAR'
      || this.pic.gen_type == 'SDXL_PREVIEW_AVATAR'
    ) {
      return 'Avatar generation';
    } else if (this.pic.gen_type == 'SDXL_IMG_INPAINT') {
      return 'Inpaint/Fix generation';
    } else if (this.pic.gen_type == 'SDXL_IMG_OUTPAINT') {
      return 'Zoom Out generation';
    } else if (this.pic.gen_type == 'SDXL_IMG_UPSCALE') {
      return 'Upscale generation';
    } else if (this.pic.gen_type == 'SDXL_IMG_VARY') {
      return 'Variant generation';
    } else return 'Unknown';
  }

  disappear() {

    this.pictureService.disappear(this.pic.guid).subscribe(()=> {

//      let msg = new ActionMessage();

//      msg.img_guid = this.pic.guid;
//      msg.action_code = ActionCode.DELETE;

    //CLOSE AND remove picture from the table
    //this.dialogRef.close(msg);

      this.tiles.splice(this.index,1);
      if (this.index < this.tiles.length-1) {
        //nothing
      } else if (this.index > 0)
        this.index--;

      //else nothing :)

      this.pic = this.tiles[this.index]?.pic;

      this._snackbar.open('Image deleted !', '', {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 800,
        panelClass: ['green-snackbar'],
      });


    });

  }

  goLeft() {
    if (this.index > 0)  {
      this.index--;
      this.pic = this.tiles[this.index].pic;
    }
  }

  goRight() {
    if (this.index < this.tiles.length-1) {
      this.index++;
      this.pic = this.tiles[this.index].pic;
    }

  }

}
