import { Component } from '@angular/core';

@Component({
  selector: 'app-no-role',
  templateUrl: './no-role.component.html',
  styleUrls: ['./no-role.component.scss']
})
export class NoRoleComponent {

}
