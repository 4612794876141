import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AccountService } from '../services/account.service';

@Injectable({
  providedIn: 'root'
})
export class CustomAuthService {

  loginBaseUrl : string;

  constructor(private accountService:AccountService,private http: HttpClient) {
    this.loginBaseUrl =  environment.apiUrl;
  }

  signIn()  {
   // console.log("signIn url:"+this.loginBaseUrl+"/v1/login");

    window.location.href = this.loginBaseUrl+"/v1/login";

    /*return this.http.get<any>(`${this.loginBaseUrl}/v1/login`).subscribe((res)=>{
      console.log(res);
    });
*/
  }

  callback(code: string, state:string) {

    //console.log("CALLBACK:" + `${this.loginBaseUrl}/v1/callback?code=`+code+"&state="+state);

    return `${this.loginBaseUrl}/v1/callback?code=`+code+"&state="+state;

  }

  getSignInUrl():string {

   // console.log("SIGN IN:" + `${this.loginBaseUrl}/v1/login`);

    return `${this.loginBaseUrl}/v1/login`;
  }

  logout() {

    //console.log('logout() !');

    const urlRequest = this.loginBaseUrl + '/v1/logout';

    //console.log("kill token and cookie");
    this.accountService.logout(true);

    //console.log("call logout on app server !");

    this.http.get<string>(urlRequest).subscribe((res)=>{

      //console.log('call '+res[0]);
      window.location.href = res[0];

    });
  }

  expired() {

    //console.log('logout() !');

    const urlRequest = this.loginBaseUrl + '/v1/expired';

    //console.log("kill token and cookie");
    this.accountService.logout(false);

    //console.log("call logout on app server !");

    this.http.get<string>(urlRequest).subscribe((res)=>{

      window.location.href = res[0];

    });
  }

}
