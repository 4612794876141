import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';

@Component({
  selector: 'app-no-credit',
  templateUrl: './no-credit.component.html',
  styleUrls: ['./no-credit.component.scss'],
  standalone: true,
  imports: [ MatButtonModule ]
})
export class NoCreditComponent {

  constructor(
    private router: Router,
  ) {
  }

    spot() {
      this.router.navigate(['buy-spot-credits']);
    }

    upgrade() {
      this.router.navigate(['subscription']);
    }

    cancel() {
      this.router.navigate(['home']);
    }

}
