import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AvatarActionTextService {
  constructor() {}

  getText(action: string): string {
    let text = '';

    switch (action) {
      case 'PORTRAIT_FRONT':
        text = 'front close-up portrait';
        break;
      case 'PORTRAIT_SIDE':
        text = 'side close-up portrait';
        break;
      case 'PORTRAIT_ORIENTED':
        text = 'tilted close-up portrait';
        break;
      case 'PORTRAIT_BACK':
        text = 'back close-up portrait';
        break;
      case 'HALF_FRONT':
        text = 'half body front portrait';
        break;
      case 'HALF_SIDE':
        text = 'half body side portrait';
        break;
      case 'HALF_ORIENTED':
        text = 'half body tilted portrait';
        break;
      case 'HALF_BACK':
        text = 'back half body portrait';
        break;
      case 'STANDING_FRONT':
        text = 'full body front portrait';
        break;
      case 'STANDING_SIDE':
        text = 'full body side portrait';
        break;
      case 'STANDING_ORIENTED':
        text = 'full body tilted portrait';
        break;
      case 'STANDING_BACK':
        text = 'full body back portrait';
        break;
      case 'ACTION_DANCE':
        text = 'dancing';
        break;
      case 'ACTION_DANCE2':
        text = 'dancing';
        break;
      case 'ACTION_RUNNING':
        text = 'running';
        break;
      case 'ACTION_RUNNING2':
        text = 'running';
        break;
      case 'ACTION_SITTING':
        text = 'sitting';
        break;
      case 'ACTION_SITTING2':
        text = 'sitting';
        break;
      case 'ACTION_JUMP':
        text = 'jumping';
        break;
      // LORA cases
      case 'LORA_PORTRAIT_FRONT_NEUTRAL':
        text = 'neutral front portrait';
        break;
      case 'LORA_PORTRAIT_FRONT_SMILING':
        text = 'smiling front portrait';
        break;
      case 'LORA_PORTRAIT_FRONT_OPEN_MOUTH':
        text = 'opened mouth front portrait';
        break;
      case 'LORA_PORTRAIT_FRONT_LOOKING_UP':
        text = 'looking up front portrait';
        break;
      case 'LORA_PORTRAIT_FRONT_LOOKING_DOWN':
        text = 'looking down front portrait';
        break;
      case 'LORA_PORTRAIT_FRONT_LOOKING_SIDE':
        text = 'looking side front portrait';
        break;
      case 'LORA_PORTRAIT_FRONT_HAPPY':
        text = 'happy front portrait';
        break;
      case 'LORA_PORTRAIT_FRONT_SAD':
        text = 'sad front portrait';
        break;
      case 'LORA_PORTRAIT_FRONT_CLOSED_EYES':
        text = 'closed eyes front portrait';
        break;
      case 'LORA_PORTRAIT_SIDE_NEUTRAL':
        text = 'neutral side portrait';
        break;
      case 'LORA_PORTRAIT_SIDE_SMILING':
        text = 'smiling side portrait';
        break;
      case 'LORA_PORTRAIT_SIDE_OPEN_MOUTH':
        text = 'opened mouth side portrait';
        break;
      case 'LORA_PORTRAIT_SIDE_LOOKING_UP':
        text = 'looking up side portrait';
        break;
      case 'LORA_PORTRAIT_SIDE_LOOKING_DOWN':
        text = 'looking down side portrait';
        break;
      case 'LORA_PORTRAIT_SIDE_HAPPY':
        text = 'happy side portrait';
        break;
      case 'LORA_PORTRAIT_SIDE_SAD':
        text = 'sad side portrait';
        break;
      case 'LORA_PORTRAIT_SIDE_CLOSED_EYES':
        text = 'closed eyes side portrait';
        break;
      case 'LORA_PORTRAIT_ORIENTED_NEUTRAL':
        text = 'neutral tilted portrait';
        break;
      case 'LORA_PORTRAIT_ORIENTED_SMILING':
        text = 'smiling tilted portrait';
        break;
      case 'LORA_PORTRAIT_ORIENTED_OPEN_MOUTH':
        text = 'open mouth tilted portrait';
        break;
      case 'LORA_PORTRAIT_ORIENTED_LOOKING_UP':
        text = 'looking up tilted portrait';
        break;
      case 'LORA_PORTRAIT_ORIENTED_LOOKING_DOWN':
        text = 'looking down tilted portrait';
        break;
      case 'LORA_PORTRAIT_ORIENTED_HAPPY':
        text = 'happy tilted portrait';
        break;
      case 'LORA_PORTRAIT_ORIENTED_SAD':
        text = 'sad tilted portrait';
        break;
      case 'LORA_PORTRAIT_ORIENTED_CLOSED_EYES':
        text = 'closed eyes tilted portrait';
        break;
      case 'LORA_HALF_FRONT_NEUTRAL':
        text = 'neutral half front portrait';
        break;
      case 'LORA_HALF_FRONT_SMILING':
        text = 'smiling half front portrait';
        break;
      case 'LORA_HALF_FRONT_OPEN_MOUTH':
        text = 'opened mouth half front portrait';
        break;
      case 'LORA_HALF_FRONT_LOOKING_UP':
        text = 'looking up half front portrait';
        break;
      case 'LORA_HALF_FRONT_LOOKING_DOWN':
        text = 'looking down half front portrait';
        break;
      case 'LORA_HALF_FRONT_LOOKING_SIDE':
        text = 'looking side half front portrait';
        break;
      case 'LORA_HALF_FRONT_HAPPY':
        text = 'happy half front portrait';
        break;
      case 'LORA_HALF_FRONT_SAD':
        text = 'sad half front portrait';
        break;
      case 'LORA_HALF_FRONT_CLOSED_EYES':
        text = 'closed eyes half front portrait';
        break;
      case 'LORA_HALF_SIDE_NEUTRAL':
        text = 'neutral half side portrait';
        break;
      case 'LORA_HALF_SIDE_SMILING':
        text = 'smiling half side portrait';
        break;
      case 'LORA_HALF_SIDE_OPEN_MOUTH':
        text = 'open mouth half side portrait';
        break;
      case 'LORA_HALF_SIDE_LOOKING_UP':
        text = 'looking up half side portrait';
        break;
      case 'LORA_HALF_SIDE_LOOKING_DOWN':
        text = 'looking down half side portrait';
        break;
      case 'LORA_HALF_SIDE_HAPPY':
        text = 'happy half side portrait';
        break;
      case 'LORA_HALF_SIDE_SAD':
        text = 'sad half side portrait';
        break;
      case 'LORA_HALF_SIDE_CLOSED_EYES':
        text = 'closed eyes half side portrait';
        break;
      case 'LORA_HALF_ORIENTED_NEUTRAL':
        text = 'neutral half tilted portrait';
        break;
      case 'LORA_HALF_ORIENTED_SMILING':
        text = 'smiling half tilted portrait';
        break;
      case 'LORA_HALF_ORIENTED_OPEN_MOUTH':
        text = 'open mouth half tilted portrait';
        break;
      case 'LORA_HALF_ORIENTED_LOOKING_UP':
        text = 'looking up half tilted portrait';
        break;
      case 'LORA_HALF_ORIENTED_LOOKING_DOWN':
        text = 'looking down half tilted portrait';
        break;
      case 'LORA_HALF_ORIENTED_HAPPY':
        text = 'happy half tilted portrait';
        break;
      case 'LORA_HALF_ORIENTED_SAD':
        text = 'sad half tilted portrait';
        break;
      case 'LORA_HALF_ORIENTED_CLOSED_EYES':
        text = 'closed eyes half tilted portrait';
        break;
      default:
    }
    return text;
  }
}
