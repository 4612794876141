<div *ngIf="!loaded" class="spinner-container"><mat-spinner></mat-spinner></div>

<div *ngIf="loaded" class="cards">
  <mat-card class="mat-elevation-z0 single-card settings-card">
    <mat-cart-header class="mat-card-header form-box-title">
      <mat-card-title><span class="card-title"> {{ title.toUpperCase() }} </span></mat-card-title>
      <mat-card-title><span class="card-subtitle"> {{ subtitle }} </span></mat-card-title>
    </mat-cart-header>
    <mat-card-content class="setting-content">
      <div class="stickyto">
        <form [formGroup]="form">
          <div class="container">

            <div class="container-element">
              <mat-form-field>
                <mat-label>YOUR PROMPT</mat-label>
                <textarea class="prompt" matInput placeholder="a photo of donuts..." maxlength="400"
                  formControlName="prompt"></textarea>
              </mat-form-field>
            </div>

            <div class="examples">
              <div class="example-title"> Prompt examples: </div>
              <mat-chip (click)="changePrompt(promptTag1.prompt)"> {{ promptTag1.tag }}
              </mat-chip>
              <mat-chip (click)="changePrompt(promptTag2.prompt)"> {{ promptTag2.tag }}
              </mat-chip>
              <mat-chip (click)="changePrompt(promptTag3.prompt)"> {{ promptTag3.tag }}
              </mat-chip>
            </div>

            <div class="container-element">
              <mat-accordion>
                <mat-expansion-panel (opened)="creativityOpenState = true" (closed)="creativityOpenState = false"
                  class="parameter">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="label">
                      <mat-icon class="label-icon">palette</mat-icon>
                      <div class="label-text">AI CREATIVITY:
                        {{ creativityTrad(this.form.controls.creativity.value) }}
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-slider min="0" max="3" step="1" [showTickMarks]="true" [displayWith]="creativityTrad" discrete
                    class="image-slider" id="sliderCreativity">
                    <input matSliderThumb formControlName="creativity" value="2">
                  </mat-slider>
                  <mat-icon class="info-icon" matTooltip="{{ creativityToolTipMessage() }}">info</mat-icon>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="container-element">
              <mat-accordion>
                <mat-expansion-panel (opened)="formatOpenState = true" (closed)="formatOpenState = false"
                  class="parameter">

                  <mat-expansion-panel-header>
                    <mat-panel-title class="label">
                      <mat-icon class="label-icon">photo_size_select_large</mat-icon>
                      <div class="label-text">FORMAT: <mat-icon class="icon-ratio">{{
                          iconFormat(form.controls.ratio.value) }}</mat-icon> {{
                        adaptFormat(form.controls.ratio.value)}}
                      </div>
                      <div class="instagram" matTooltip="Instagram format"><img class="insta-icon"
                          src="../../assets/img/insta.svg"
                          *ngIf=" form.controls.ratio.value=='RATIO_1_1' || form.controls.ratio.value=='RATIO_4_5' || form.controls.ratio.value=='RATIO_191_1' ">
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <mat-chip-listbox class="parameter" aria-label="Format" [selectable]="true" [multiple]="false">
                    <mat-chip-option value="RATIO_1_1" (selectionChange)="changeFormatSelected($event)" selected="true">
                      1:1
                    </mat-chip-option>
                    <mat-chip-option value="RATIO_4_5" (selectionChange)="changeFormatSelected($event)"> 4:5
                    </mat-chip-option>
                    <mat-chip-option value="RATIO_191_1" (selectionChange)="changeFormatSelected($event)"> 1.91:1
                    </mat-chip-option>
                    <mat-chip-option value="RATIO_3_5" (selectionChange)="changeFormatSelected($event)"> 3:5
                    </mat-chip-option>
                    <mat-chip-option value="RATIO_7_9" (selectionChange)="changeFormatSelected($event)"> 7:9
                    </mat-chip-option>
                    <mat-chip-option value="RATIO_5_3" (selectionChange)="changeFormatSelected($event)"> 5:3
                    </mat-chip-option>
                    <mat-chip-option value="RATIO_5_2" (selectionChange)="changeFormatSelected($event)"> 5:2
                    </mat-chip-option>
                    <mat-chip-option value="RATIO_2_3" (selectionChange)="changeFormatSelected($event)"> 2:3
                    </mat-chip-option>
                    <mat-chip-option value="RATIO_4_3" (selectionChange)="changeFormatSelected($event)"> 4:3
                    </mat-chip-option>

                  </mat-chip-listbox>

                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="container-element">
              <mat-accordion>
                <mat-expansion-panel (opened)="stylesOpenState = true" (closed)="stylesOpenState = false" expanded="stylesOpenState"
                  class="parameter">

                  <mat-expansion-panel-header>
                    <mat-panel-title class="label">
                      <mat-icon class="label-icon">style</mat-icon>
                      <div class="label-text">CUSTOM STYLES: {{ styles.length }}</div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <mat-chip-listbox class="parameter" aria-label="Format" [selectable]="true" [multiple]="true">
                    <mat-chip-option value="ANIME" (selectionChange)="changeStyleSelection($event)">
                      ANIME
                    </mat-chip-option>
                    <mat-chip-option value="CYBERPUNK" (selectionChange)="changeStyleSelection($event)"> CYBERPUNK
                    </mat-chip-option>
                    <mat-chip-option value="COMIC" (selectionChange)="changeStyleSelection($event)"> COMIC
                    </mat-chip-option>
                    <mat-chip-option value="DARK" (selectionChange)="changeStyleSelection($event)"> DARK
                    </mat-chip-option>
                    <mat-chip-option value="DIGITAL_ART" (selectionChange)="changeStyleSelection($event)"> DIGITAL ART
                    </mat-chip-option>
                    <mat-chip-option value="FASHION" (selectionChange)="changeStyleSelection($event)"> FASHION
                    </mat-chip-option>
                    <mat-chip-option value="GOTHIC" (selectionChange)="changeStyleSelection($event)"> GOTHIC
                    </mat-chip-option>
                    <mat-chip-option value="HEROIC_FANTASY" (selectionChange)="changeStyleSelection($event)"> HEROIC
                      FANTASY
                    </mat-chip-option>
                    <mat-chip-option value="MASTERPIECE" (selectionChange)="changeStyleSelection($event)"> MASTERPIECE
                    </mat-chip-option>
                    <mat-chip-option value="PHOTOREALISM" (selectionChange)="changeStyleSelection($event)" selected> PHOTOREALISM
                    </mat-chip-option>
                    <mat-chip-option value="ROBOTICS" (selectionChange)="changeStyleSelection($event)"> ROBOTICS
                    </mat-chip-option>
                    <mat-chip-option value="STEAMPUNK" (selectionChange)="changeStyleSelection($event)"> STEAMPUNK
                    </mat-chip-option>
                  </mat-chip-listbox>

                </mat-expansion-panel>
              </mat-accordion>
            </div>


            <div class="container-element">
              <mat-accordion>
                <mat-expansion-panel (opened)="numberOpenState = true" (closed)="numberOpenState = false"
                  class="parameter">

                  <mat-expansion-panel-header>
                    <mat-panel-title class="label">
                      <mat-icon class="label-icon">collections</mat-icon>
                      <div class="label-text">NUMBER OF IMAGES: {{ imageNb(form.controls.nb_of_images.value) }}</div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <mat-slider min="0" max="12" step="4" [showTickMarks]="true" [displayWith]="imageNb" discrete
                    class="image-slider" (input)="onChangeImageNumber($event)" id="sliderImages">
                    <input matSliderThumb formControlName="nb_of_images" value="0">
                  </mat-slider>
                </mat-expansion-panel>
              </mat-accordion>
            </div>


            <div class="container-element">
              <mat-accordion>
                <mat-expansion-panel (opened)="isAvatarAddedExpanded = true" (closed)="isAvatarAddedExpanded = false"
                  [(expanded)]="isAvatarAddedExpanded" class="parameter">

                  <mat-expansion-panel-header>
                    <mat-panel-title class="label">
                      <mat-icon class="label-icon">assignment_ind</mat-icon>
                      <div class="label-text">AVATAR: {{this.avatarAdded?.data.given_name }}
                        {{this.avatarAdded?.data.family_name }}</div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <div class="avatar-option">
                    <div class="selection-avatar">
                      <div class="avatar">
                        <!--<div><img src="{{ this.avatarAdded?.data.marketing_profile_picture_thumb_uri }}" class="avatar-img"> </div>-->
                        <div><img src="{{ avatar_image }}" class="avatar-img"> </div>
                      </div>
                      <div class="avatar-buttons">
                        <button mat-raised-button color="primary" class="button" (click)="selectAvatar()"> {{
                          changeAvatarAddButtonLabel() }}
                        </button>
                        <button *ngIf='this.avatarAdded' mat-raised-button color="warn" class="button"
                          (click)="cancelAvatar()"> REMOVE
                        </button>
                      </div>
                    </div>
                    <div class="selection-hairstyle" *ngIf="avatarAdded">
                      <div class="hairstyle-image"> <img src="{{ hairstyle_image }}" class="format"></div>
                      <div class="hairstyle-action"><button mat-raised-button color="primary"
                          (click)="openHairstyle()">HAIRSTYLE PICKER</button></div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="container-element">
              <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                  class="parameter">

                  <mat-expansion-panel-header>
                    <mat-panel-title class="label">
                      <mat-icon class="label-icon">follow_the_signs</mat-icon>
                      <div class="label-text">INFLUENCE SCENE: {{ sceneAdded?.name }} </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <div class="scene-option">
                    <div class="scene" *ngIf='this.sceneAdded'>
                      <div><img src="{{ sceneAdded?.thumb_uri }}" class="scene-img"></div>
                      <div class="scene-name"> {{this.sceneAdded?.name }}
                      </div>
                    </div>
                    <div class="scene-commands">
                      <div class="scene-buttons">
                        <button mat-raised-button color="primary" class="button" (click)="selectScene()"> {{
                          changeSceneAddButtonLabel() }}
                        </button>
                        <button *ngIf='sceneAdded' mat-raised-button color="warn" class="button"
                          (click)="cancelScene()"> REMOVE
                        </button>
                      </div>
                      <div *ngIf='sceneAdded' class="scene-slider">
                        Strength:
                        <mat-slider min="0" max="1" step="0.1" [showTickMarks]="false" [displayWith]="influence"
                          discrete class="slider">
                          <input matSliderThumb formControlName="influence_level" value="0.5">
                        </mat-slider>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>



            <div class="container-element">
              <mat-accordion>
                <mat-expansion-panel (opened)="ipromptOpenState = true" (closed)="ipromptOpenState = false"
                  class="parameter">

                  <mat-expansion-panel-header>
                    <mat-panel-title class="label">
                      <mat-icon class="label-icon">photo_frame</mat-icon>
                      <div class="label-text">IMAGE PROMPT: {{ getImagePromptMessage() }} </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <div class="file-drop-container">

                    <div class="dropzone" fileDragDrop (filesChangeEmiter)="onImageSwapFileChange($event)">
                      <div class="text-wrapper">
                        <div class="centered">
                          <input type="file" name="file" id="fileDropRef" (change)="displayEvent($event)" multiple>
                          <div class="textLink"><label for="fileDropRef"><mat-icon class="icon">upload</mat-icon> Drop
                              files or click here ! </label></div>
                        </div>
                      </div>
                    </div>

                    <div class="files">
                      <mat-list class="lines">

                        @for ( element of imagePrompts; track element; let idx=$index) {
                        <mat-list-item class="line">
                          <div class="image">
                            <div class="overlay-iprompt" (click)="deleteFile(idx)"><mat-icon
                                class="remove-iprompt">delete</mat-icon></div>
                            <img src="{{ element.base64 }}" class="thumb">
                          </div>
                          <div class="commands">
                            <div class="command">
                              <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [value]="element.type">
                                <mat-button-toggle value="IMAGE_PROMPT" [checked]="(element.type=='IMAGE_PROMPT')"
                                  (click)="setImagePromptType(idx,'IMAGE_PROMPT' )">PROMPT</mat-button-toggle>
                                <mat-button-toggle value="FACE_SWAP" [checked]="(element.type=='FACE_SWAP')"
                                  (click)="setImagePromptType(idx,'FACE_SWAP' )">FACESWAP</mat-button-toggle>
                              </mat-button-toggle-group>
                            </div>
                            <div class="command">
                              Strength:
                              <mat-slider min="0" max="1" step="0.1" [showTickMarks]="false" [displayWith]="influence"
                                discrete class="slider">
                                <input matSliderThumb [value]="element.strength"
                                  (valueChange)="setImagePromptStrength(idx,$event)">
                              </mat-slider>
                            </div>
                          </div>
                        </mat-list-item>
                        }

                      </mat-list>
                    </div>


                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
          <div class="container-button">
            <button mat-raised-button color="primary" type="submit" class="button"
              [disabled]="(!form.valid || inprocess)" (click)="generate()">

              <div class="button-text">

                <span class="action-text"> {{ this.generateLabel }} {{
                  imageNb(form.controls.nb_of_images.value) }} images</span>
                <div class="amount">
                  <div class="cost">{{ cost }} </div>
                  <div class="container-icon">
                    <mat-icon class="icon"> award_star</mat-icon>
                  </div>
                </div>
              </div>

            </button>

          </div>
        </form>

      </div>

    </mat-card-content>
  </mat-card>

  <!--  COLUMNS CDK VIRTUAL BUFFERING            -->

  <mat-card class="mat-elevation-z0 single-card pictures-card-column" [style.background]="'url('+background_img+')'">
    <cdk-virtual-scroll-viewport itemSize="40" class="pictures-viewport">
      <mat-card-content [class]="displayColumn ? 'pictures-column' : 'not-visible'" (window:resize)="adaptTable()">
        <div class="pics-container-column" *cdkVirtualFor="let column of sourceFlexColumns; trackBy: columnTrack">
          @for (tile of column; track tile.pic.guid; let idx = $index ) {

          <div class="genbox" [class]="getRatioStyle(tile.pic.img_ratio)" (click)="display(tile)">
           
            <div class="genbox-overlay" [class]="tile.loaded ? 'not-visible' : 'spinner-container'">
              <mat-spinner></mat-spinner>
            </div>
           <!-- <div class="genbox-overlay" [class]="tile.loaded ? 'not-visible' : 'chrono-visible'">{{ tile.sec}}.{{
              tile.decilesec}} s </div>-->
            <div class="fav-overlay" [class]="tile.pic.favorite ? 'fav-overlay' : 'not-visible'">
              <mat-icon class="nzym-fav">favorite</mat-icon>
            </div>
            <img [class]="getRatioStyle(tile.pic.img_ratio)" src="{{ tile.pic.thumb_uri || tile.pic.img_uri }}">

          </div>
          }

        </div>
      </mat-card-content>
    </cdk-virtual-scroll-viewport>
  </mat-card>

</div>
