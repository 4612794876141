import { Injectable } from '@angular/core';
import { PromptTag } from '../shared/entity/prompt-tag';

@Injectable({
  providedIn: 'root',
})
export class PromptService {

  prompts: Map<string, string>;

  constructor() {

    this.prompts = new Map<string, string>()

    this.prompts.set('Anime','an anime character with large, detailed eyes and distinctive Japanese anime aesthetics');
    this.prompts.set('Funny ads','a caricature for a social media campaign promoting tomatoes. Include exaggerated, humorous depictions of cutting tomatoes, highlighting the taste');
    this.prompts.set('Urban art','aerial view of a mural design for a building wall in NYC. Include hip-hop dance themes in the mural');
    this.prompts.set('Cartoon','a semi-realistic cartoon character with intricate details, blending elements of realism with a cartoon aesthetic');
    this.prompts.set('Pixel art','a pixel art character, reminiscent of old-school video games, with limited colors and a blocky, pixelated design');
    this.prompts.set('Illustration','a whimsical cartoon character suitable for children’s book illustrations, characterized by soft lines and pastel colors');
    this.prompts.set('Art deco','a surreal character in the style of Art Deco, known for its geometric shapes, bold colors, and elegant, vintage feel');
    this.prompts.set('Cartoon','a character reminiscent of the classic, retro cartoon era, featuring exaggerated movements and playful antics');
    this.prompts.set('Caricature','a famous historical figure into a lovable cartoon character, depicting them in humorous situations or reimagining their achievements with a playful twist');
    this.prompts.set('Gaming','a thematic color palette for a RTS game. I want the colors to evoke a sense of futuristic era');
    this.prompts.set('Gaming','designs for house props for a role-playing game. Include detailed illustrations of a Medieval Japanese Ninja');
    this.prompts.set('Product','a realistic product mockup for handbag, which will allow customers to envision the product before making a purchase');
    this.prompts.set('Social Media','an attention-grabbing graphic for a social post on toys');
    this.prompts.set('Banner','an email banner for an newsletter sharing cute cats');
    this.prompts.set('Logo','a logo for a business of thai resturant');
    this.prompts.set('Youtube','an image for a video thumbnail on the theme of cybersecurity');
    this.prompts.set('Blog','an image that will complement a blog post on the topic of climate change');
    this.prompts.set('Website','a hero image for a website on electric cars');
    this.prompts.set('Brochure','a visually appealing brochure showcasing the benefits of sport coach, targeting seniors');
    this.prompts.set('Event Poster','a vibrant poster promoting an upcoming event hosted by my company that provide aerial drones featuring copters and cloud with a view of landscape');
    this.prompts.set('Google ads','a Google Ads banner to highlight hand bags. Use images of expensive jewelry, and make sure the design grabs attention');
    this.prompts.set('Poster','a graphic for a poster on summer holidays. Make sure to incorporate detailed drawings of plam trees and the ocean');
    this.prompts.set('Travel blog','a photo of sunset casting a warm glow on the ancient ruins of Inca civilization');
    this.prompts.set('Travel blog','a photo showcasing the atmosphere of Paris, at night');
    this.prompts.set('Online retail','a photo showcasing sneakers in action, with red and green colors');
    this.prompts.set('Corporate','a photo of an executive team in a strategic retreat setting, fostering a sense of unity and vision');
    this.prompts.set('Lifestyle','a stunning photo capturing the essence of a high-fashion runway show, featuring the latest couture');
    this.prompts.set('Event Management','a visual of dynamic keynote speakers captivating the audience at a conference');
    this.prompts.set('Wellness','an image visualizing serene yoga poses in natural settings, promoting mental and physical well-being');
    this.prompts.set('Bio','a showcase farmers harvesting organic produce for a healthy and sustainable lifestyle');
    this.prompts.set('Meditation','a photo of individuals finding tranquility in a meditation retreat setting');
    this.prompts.set('Food','a mouthwatering image of vegetables being cooked, showcasing the blend of colors and textures');
    this.prompts.set('Workout','a motivational image of an individual engaged in intense workout routines, highlighting their determination and perseverance to achieve their fitness goals');
    this.prompts.set('Product','a visually stunning image of earrings, showcasing their elegance and luxury against contrasting backgrounds');
    this.prompts.set('Product','a studio-quality photo of a jacket, showcasing it from multiple angles, highlighting the quality of the cotton');
    this.prompts.set('Bedtime','a warm visual with soft lighting, cozy setting, and a touch of magic on the theme of faeries,tailored for a bedtime story');
    this.prompts.set('Wisdom','a abstract and thought-provoking visual that resonate with the philosophical concept of  wisdom');
    this.prompts.set('Mandala','mandalas incorporating vibrant colors and unique patterns that will inspire feelings of happiness and well-being');
    this.prompts.set('Emotion','an abstract artwork exploring the depths of human emotion, using color, form, and texture to convey feelings of joy');

  }

  getPrompt(): PromptTag {

    let randomKey = this.getRandomKey();
    let prompt = this.prompts.get(randomKey);

    let response : PromptTag = new PromptTag();
    response.tag = randomKey;
    response.prompt = prompt;

    return response;

  }

  getRandomKey() {
    let keys = Array.from(this.prompts.keys());
    return keys[Math.floor(Math.random() * keys.length)];
  }
}
