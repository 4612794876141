<div class="card-form">
  <form [formGroup]="form" (ngSubmit)="addCard()" class="checkout">
    <div class="form-row">
      <div id="card-info" #cardInfo></div>
    </div>
  </form>
</div>
<div class="buttons" >
  <button id="card-button" mat-raised-button color="primary" [disabled]="isDisabled" (click)='addCard()'>SET CARD</button>
  <button id="card-button" mat-raised-button color="#32a852" (click)='cancel()'>BACK</button>
</div>
