<div *ngIf="!loaded" class="spinner-container"><mat-spinner></mat-spinner></div>

<div *ngIf="loaded" class="cards">
  <mat-card class="mat-elevation-z0 single-card">
    <mat-cart-header class="mat-card-header form-box-title">
      <mat-card-title><span class="card-title"> {{ user.data.nickname.toUpperCase() }} </span></mat-card-title>
      <mat-card-title><span class="card-subtitle"> {{ subtitle }} </span></mat-card-title>
    </mat-cart-header>
    <mat-card-content class="card-content">
      <div class="container">
        <div class="container-element">
          <div class="column-left">
            <div class="picture-profile-bloc">
              <img src="{{ pictureUrl }}" class="picture-profile" referrerpolicy="no-referrer">
              <button mat-icon-button class="picture-edit" (click)="openPPChoice()"> <mat-icon class="icon-size">edit</mat-icon></button>
            </div>
            <div class="attributes">
              <div class="attribute">
                <div class="profile-attribute">LOGIN/EMAIL</div>
                <div class="attribute-value">{{ user.data.email }}</div>
              </div>
              <div class="attribute">
                <div class="profile-attribute" *ngIf="null!=user.data.family_name">FAMILY NAME</div>
                <div class="attribute-value">{{ user.data.family_name }}</div>
              </div>
              <div class="attribute">
                <div class="profile-attribute" *ngIf="null!=user.data.given_name">FIRSTNAME</div>
                <div class="attribute-value">{{ user.data.given_name }}</div>
              </div>
              <div class="attribute">
                <div class="profile-attribute" *ngIf="null!=user.data.nickname">NICKNAME</div>
                <div class="attribute-value">

                  <div class="nickname-cell">
                    <div class="nickname">{{ user.data.nickname }}</div>
                    <button mat-icon-button color="white" (click)="editNickname()" matTooltip="Edit Nickname"
                      [matTooltipPosition]="'right'">
                      <mat-icon class="icon-size">edit</mat-icon></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-element">
          <div class="column-right">

            <div class="sections">
              <div class="section">
                <div class="section-title"> SUBSCRIPTION</div>
                <div class="section-content">
                  <div class="attribute">
                    <div class="profile-attribute">TYPE </div>
                    <div class="attribute-value">{{ user.data.subscription.label }}</div>
                  </div>
                  <div class="attribute">
                    <div class="profile-attribute">ENGAGEMENT <span *ngIf="null!=user.data.subscription.future_guid"
                        class="chip" (click)="futureSub()"> NEXT SUB </span></div>
                    <div class="attribute-value">{{ getRecurrence() }}</div>
                  </div>
                  <div class="attribute">
                    <div class="profile-attribute">MONTHLY CREDITS INCLUDED </div>
                    <div class="attribute-value">{{ user.data.subscription.monthly_credits }} {{ remainingText }} </div>
                  </div>
                  <div class="attribute">
                    <div class="profile-attribute"> NEXT CREDIT RECHARGE DATE </div>
                    <div class="attribute-value">{{ dateNextReload() }} {{ remainingText }} </div>
                  </div>
                  <div class="attribute">
                    <div class="profile-attribute"> NEXT BILLING DATE </div>
                    <div class="attribute-value">{{ nextBillingDate() }} {{ remainingText }} </div>
                  </div>
                  <div class="attribute">
                    <div class="profile-attribute"> AUTOMATIC RENEWAL </div>
                    <div class="attribute-value">{{ renewal() }} </div>
                  </div>
                </div>
              </div>
              <div class="section">
                <div class="section-title"> PAYMENT INFO</div>
                <div class="section-content">
                  <div class="attribute">
                    <div class="profile-attribute">CARD TYPE </div>
                    <div class="attribute-value">{{ sanitizeCardType() }}</div>
                  </div>
                  <div class="attribute">
                    <div class="profile-attribute"> CARD # </div>
                    <div class="attribute-value"> {{ sanitizeCardNumber() }}</div>
                  </div>
                  <div class="attribute">
                    <div class="profile-attribute"> EXPIRY </div>
                    <div class="attribute-value">{{ sanitizeExpiry() }}</div>
                  </div>
                  <!-- <app-payment-card></app-payment-card>-->
                </div>
              </div>

            </div>

          </div>
        </div>

      </div>
      <div class="container">
        <div class="container-element">
          <div class="container-button">
            <button mat-raised-button color="primary" type="submit" class="button" [disabled]="subDisabled"
              (click)="subscription()">CHANGE SUBSCRIPTION</button>
            <button mat-raised-button color="primary" type="submit" class="button" [disabled]="cancelDisabled"
              (click)="cancelSub()">CANCEL SUBSCRIPTION</button>
            <button mat-raised-button color="primary" type="submit" class="button" [disabled]="chgCardDisabled"
              (click)="changeCard()">CHANGE CARD</button>
            <button mat-raised-button color="primary" type="submit" class="button" [disabled]="invoicesDisabled"
              (click)="invoices()">INVOICES</button>
            <button mat-raised-button color="accent" type="button" class="button" (click)="back()">BACK</button>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
