<div *ngIf="!loaded" class="spinner-container"><mat-spinner></mat-spinner></div>

<div *ngIf="loaded" class="cards">
  <mat-card class="mat-elevation-z0 single-card settings-card">
    <mat-cart-header class="mat-card-header form-box-title">
      <mat-card-title><span class="card-title"> INPAINT EDITOR </span></mat-card-title>
      <mat-card-title><span class="card-subtitle"> FIX YOUR IMAGES </span></mat-card-title>
    </mat-cart-header>
    <mat-card-content class="setting-content">
      <div class="stickyto">

        <div class="container">
          <div class="container-element ">
            <mat-form-field>
              <mat-label>YOUR PROMPT (Optional)</mat-label>
              <textarea class="prompt" matInput (input)="onPromptChange($event)"></textarea>
            </mat-form-field>
          </div>

          <div class="container-element">
            <mat-accordion>
              <mat-expansion-panel (opened)="modeOpenState = true" (closed)="modeOpenState = false" class="parameter">
                <mat-expansion-panel-header>
                  <mat-panel-title class="label">
                    <mat-icon class="label-icon">format_paint</mat-icon>
                    <div class="label-text"> MODE:  {{ adaptMode() }}
                    </div>

                  </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-chip-listbox class="parameter" aria-label="Format" [selectable]="true" [multiple]="false">
                  <mat-chip-option value="IMPROVE" (selectionChange)="changeModeSelected($event)" selected="true">
                    <mat-icon class="icon-ratio"> healing</mat-icon> FIX
                  </mat-chip-option>
                  <mat-chip-option value="REPLACE" (selectionChange)="changeModeSelected($event)"><mat-icon class="icon-ratio">sync</mat-icon> REPLACE
                  </mat-chip-option>
                </mat-chip-listbox>

              </mat-expansion-panel>
            </mat-accordion>
          </div>


          <div class="container-element">
            <mat-accordion>
              <mat-expansion-panel [disabled]='false' (opened)="zoomOpenState = true" (closed)="zoomOpenState = false" class="parameter">
                <mat-expansion-panel-header>
                  <mat-panel-title class="label">
                    <mat-icon class="label-icon">collections</mat-icon>
                    <div class="label-text">ZOOM LEVEL: {{ zoomLevel(zoom_level) }}% </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-slider min="0.25" max="1" step="0.01" [showTickMarks]="true" [displayWith]="zoomLevel" discrete
                  class="image-slider">
                  <input matSliderThumb [(ngModel)]="zoom_level" (input)="zoomDraw()">
                </mat-slider>
              </mat-expansion-panel>
            </mat-accordion>
          </div>

          <div class="container-element">
            <mat-accordion>
              <mat-expansion-panel (opened)="toolOpenState = true" (closed)="toolOpenState = false" class="parameter">

                <mat-expansion-panel-header>
                  <mat-panel-title class="label">
                    <mat-icon class="label-icon">brush</mat-icon>
                    <div class="label-text"> ACTIVE TOOL : {{ penColor }} </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-chip-listbox class="parameter" aria-label="Format" [selectable]="true" [multiple]="false">
                  <mat-chip-option (selectionChange)="activePen($event)" selected="true">
                    Pen
                  </mat-chip-option>
                  <mat-chip-option (selectionChange)="activeEraser($event)"> Erase
                  </mat-chip-option>
                </mat-chip-listbox>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <div>

          </div>

          <div class="container-element">
            <mat-accordion>
              <mat-expansion-panel (opened)="brushOpenState = true" (closed)="brushOpenState = false" class="parameter">
                <mat-expansion-panel-header>
                  <mat-panel-title class="label">
                    <mat-icon class="label-icon">brush</mat-icon>
                    <div class="label-text"> BRUSH SIZE : {{ this.lineWidth }} pixels</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-slider min="0" max="80" step="5" [showTickMarks]="true" [displayWith]="brushSize" discrete
                  class="image-slider">
                  <input matSliderThumb [(value)]="lineWidth" value="0">
                </mat-slider>
              </mat-expansion-panel>
            </mat-accordion>
          </div>

          <div class="container-element">
            <mat-accordion>
              <mat-expansion-panel [disabled]="true" (opened)="numberOpenState = true" (closed)="numberOpenState = false" class="parameter">
                <mat-expansion-panel-header>
                  <mat-panel-title class="label">
                    <mat-icon class="label-icon">collections</mat-icon>
                    <div class="label-text">NUMBER OF IMAGES: {{ nb_of_images }} </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-slider min="1" max="4" step="1" [showTickMarks]="true" [displayWith]="imageNb" discrete class="image-slider">
                  <input matSliderThumb [(ngModel)]="nb_of_images">
                </mat-slider>
              </mat-expansion-panel>
            </mat-accordion>
          </div>



          <div class="container-element">
            <mat-accordion>
              <mat-expansion-panel (opened)="swapOpenState = true" (closed)="swapOpenState = false"
                class="parameter">
                <mat-expansion-panel-header>
                  <mat-panel-title class="label">
                    <mat-icon class="label-icon">photo_frame</mat-icon>
                    <div class="label-text">FACE SWAP</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="file-drop-container">

                <div class="input-image">
                  <div class="dropzone" fileDragDrop (filesChangeEmiter)="onImageSwapFileChange($event)">
                    <div class="text-wrapper">
                      <div class="centered">
                        <input type="file" name="file" id="fileDropRef" (change)="displayEvent($event)" multiple>
                        <div class="textLink"><label for="fileDropRef"> <div><mat-icon class="icon">upload</mat-icon></div><div> CUSTOM</div> </label></div>
                      </div>
                    </div>
                  </div>

                  <div class="avatarzone" (click)="selectAvatarImage()">
                    <div class="text-wrapper">
                      <div class="centered">
                          <div class="avatarLink" ><div><mat-icon class="icon">assignment_ind</mat-icon></div><div> AVATAR</div> </div>
                      </div>
                    </div>
                  </div>
                  </div>

                  <div class="files">
                    <mat-list class="lines">

                      @for ( element of img_prompts; track element.temp_id; let idx=$index) {

                      <mat-list-item class="line">
                        <div class="image">
                          <div class="overlay-iprompt" (click)="deleteFile(idx)"><mat-icon class="remove-iprompt">delete</mat-icon></div>
                          <img src="{{ element.base64 }}" class="thumb" >
                        </div>
                        <div class="commands">
                           <div class="command">
                            Strenght:
                            <mat-slider min="0" max="1" step="0.1" [showTickMarks]="false" [displayWith]="influence"
                              discrete class="slider">
                              <input matSliderThumb [value]="element.strength" (valueChange)="setFaceSwapStrenght(idx,$event)" >
                            </mat-slider>
                          </div>
                        </div>
                      </mat-list-item>
                      }

                    </mat-list>
                  </div>


                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>

        </div>
        <div class="container-button">
          <button [disabled]='!image_has_been_touched' mat-raised-button color="primary" type="submit" class="button" (click)="inpaint()" >

            <div class="button-text">
              <span class="action-text">
                INPAINT {{ this.nb_of_images }} images
                </span>
              <div class="amount">
              <div class="cost">{{ cost }} </div>
              <div class="container-icon">
                <mat-icon class="icon"> award_star</mat-icon>
              </div>
              </div>
            </div>
          </button>
        </div>

      </div>

    </mat-card-content>
  </mat-card>

  <mat-card class="mat-elevation-z0 single-card pictures-card-column" >
    <mat-card-content class="main-container"  >

        <div class="editor" #editor_container>
          <canvas #background class="background-layer"></canvas>
          <canvas #mask class="mask-layer" ></canvas>
        </div>

    </mat-card-content>
  </mat-card>

</div>
