import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { AccountService } from '../../../services/account.service';
import { User } from '../../entity/user';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-dialog-need-biscuit',
  standalone: true,
  imports: [MatButtonModule, NgIf],
  templateUrl: './dialog-need-biscuit.component.html',
  styleUrl: './dialog-need-biscuit.component.scss'
})
export class DialogNeedBiscuitComponent {

  user : User;
  hideSpot: boolean = true;
  loaded: boolean = false;

  constructor(
    private router: Router,
    private accountService: AccountService,
    private userService: UserService,
    public dialogRef: MatDialogRef<DialogNeedBiscuitComponent>,
  ) {}

  ngOnDestroy() {
  }

  ngOnInit() {

    //user and its subs
        this.userService.getPerEmail(this.accountService.email).subscribe((user) => {

          this.user = user;

         // console.log(user);

          if (this.user?.data.subscription && this.user?.data.subscription.type=='FREE')
            this.hideSpot = true;
          else
          this.hideSpot = false;

          this.loaded = true;
        });
  }

  upgradeSub() {
    this.dialogRef.close();
    this.router.navigate(['subscription']);
  }

  buySpotCredits() {
    this.dialogRef.close();
    this.router.navigate(['buy-spot-credits']);
  }

  onCancel() {
    this.dialogRef.close();
  }

}
