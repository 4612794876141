import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Picture } from '../shared/entity/picture';
import { PictureRequest } from '../shared/entity/picture-request';
import { ActionMessage } from '../shared/entity/action-message';
import { InpaintRequest } from '../shared/entity/inpaint-request';
import { ImagePrompt } from '../shared/entity/image-prompt';

@Injectable({
  providedIn: 'root'
})
export class PictureService {

  apiBaseUrl : string;

  constructor(private http: HttpClient) {
    this.apiBaseUrl =  environment.apiUrl;
  }

  //based on user id (session)
  getAllGenerated(): Observable<Picture[]>  {
    return this.http.get<Picture[]>(`${this.apiBaseUrl}/v1/picture`);
  }

  getPicturePerPage( nbElements: number, lastId: number): Observable<Picture[]>  {
    if (lastId)
      return this.http.get<Picture[]>(`${this.apiBaseUrl}/v1/picture?nbe=${nbElements}&last=${lastId}`);
    else
      return this.http.get<Picture[]>(`${this.apiBaseUrl}/v1/picture?nbe=${nbElements}`);
  }

  get(guid:string): Observable<Picture>  {
    return this.http.get<Picture>(`${this.apiBaseUrl}/v1/picture/`+guid);
  }

  generate(pic:PictureRequest): Observable<Picture[]>  {
     return this.http.post<Picture[]>(`${this.apiBaseUrl}/v1/picture`, pic);
  }

  zoomOut(msg:ActionMessage): Observable<Picture> {
    return this.http.post<Picture>(`${this.apiBaseUrl}/v1/picture/zoom-out`, msg.zoom_req);
  }

  upscale(msg:ActionMessage): Observable<Picture> {
    return this.http.post<Picture>(`${this.apiBaseUrl}/v1/picture/upscale`, msg.upscale_req);
  }

  vary(msg:ActionMessage): Observable<Picture> {
    return this.http.post<Picture>(`${this.apiBaseUrl}/v1/picture/vary`, msg.vary_req);
  }

  inpaint(source_pic_guid:string, base64Mask: string, add_prompt:string, improve_mode: boolean, img_prompts:ImagePrompt[]): Observable<Picture> {

    let inpaintReq = new InpaintRequest();
    inpaintReq.source_pic_guid = source_pic_guid;
    inpaintReq.base64_mask = base64Mask.slice("data:image/png;base64,".length);
    inpaintReq.add_prompt = add_prompt;
    inpaintReq.improve_mode = improve_mode;
    inpaintReq.img_prompts = img_prompts;

    return this.http.post<Picture>(`${this.apiBaseUrl}/v1/picture/inpaint`, inpaintReq);

  }
/*
  downloadImage(url:string) {
    this.http.get(url, { responseType: 'blob' }).subscribe(blob => {
        // Create a link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'nzym-img-'+(new Date().getTime)+'.jpg'; // Set the download filename

        // Append link to the body, click it, and then remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, error => {
        console.error('Error downloading the image: ', error);
    });
  }
*/

downloadImage(url:string):Observable<Blob> {

  const headerDict = {
    'Accept': 'image/jpeg',
  };

  //console.log("url:"+url);

  return this.http.get(url, {
    headers: new HttpHeaders(headerDict),
    responseType: 'blob'
  } );
}

disappear(guid:string):Observable<void> {
  return this.http.delete<void>(`${this.apiBaseUrl}/v1/picture/`+guid);
}

updateTags(pictures: Picture[]):Observable<void> {
   return this.http.put<void>(`${this.apiBaseUrl}/v1/picture/tags`, pictures);
}

sharePictureToAll(guid:string):Observable<void> {
  return this.http.get<void>(`${this.apiBaseUrl}/v1/picture/share/`+guid);
}

}
