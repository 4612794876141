import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  gender: string;
}

@Component({
  selector: 'app-dialog-ready',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './dialog-ready.component.html',
  styleUrl: './dialog-ready.component.scss',
})
export class DialogReadyComponent {
  text: string;
  text2: string;

  constructor(
    public dialogRef: MatDialogRef<DialogReadyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  ngOnInit() {

    this.text = "Your Avatar is ready !";
    if (this.data.gender=='male')
      this.text2 = "Would you like to see his profile ?";
    else
       this.text2 = "Would you like to see her profile ?";

  }

  onCancel(): void {
    this.dialogRef.close(false);

  }

  submit() {
    this.dialogRef.close(true);
  }
}
