<mat-card class="mat-elevation-z0 single-card settings-card">
  <mat-cart-header class="mat-card-header form-box-title">
    <mat-card-title> {{ title.toUpperCase() }} </mat-card-title>
  </mat-cart-header>

  <mat-card-content>
    <div class="container">

      <div class="container-element">
        <div class="image">
          <img src='{{portrait_image}}' class="round-profile">
        </div>
      </div>
      <div class="container-element">

        <!-- -->
        <form [formGroup]="form" class="form-naming">


          <div class="table-field">
            <div class="left-column">

              <mat-form-field class="form-element">
                <mat-label>FIRSTNAME</mat-label>
                <input matInput type="string" formControlName="given_name">
              </mat-form-field>

              <mat-form-field class="form-element">
                <mat-label>HAIR COLOR (not editable)</mat-label>
                <input matInput type="string" formControlName="marketing_hair_color" [readonly]="true">
              </mat-form-field>

              <div class="form-element">
                <div class="slider">
                  <mat-label>AGE</mat-label>
                  <mat-slider class="slider-tag" min="{{minAge}}" max="{{maxAge}}" step="1" showTickMarks discrete
                    [displayWith]="formatAge">
                    <input matSliderThumb formControlName="marketing_age">
                  </mat-slider>
                </div>
              </div>

              <div class="form-element">
                <mat-form-field class="form-element">
                  <mat-label>ZODIAC SIGNS</mat-label>
                  <mat-select formControlName="marketing_zodiac_sign">
                    @for (sign of zodiacSigns; track sign) {
                    <mat-option [value]="sign">{{ sign.toUpperCase() }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="form-element">
                <mat-form-field class="form-element">
                  <mat-label>TEMPER</mat-label>
                  <mat-select formControlName="marketing_temper">
                    @for (temper of tempers.sort(); track temper) {
                    <mat-option [value]="temper">{{ temper.toUpperCase() }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>

            </div>
            <div class="middle-column">

              <mat-form-field class="form-element">
                <mat-label>FAMILY NAME</mat-label>
                <input matInput type="string" formControlName="family_name">
              </mat-form-field>

              <mat-form-field class="form-element">
                <mat-label>EYE COLOR (not editable)</mat-label>
                <input matInput type="string" formControlName="marketing_eye_color" [readonly]="true">
              </mat-form-field>

              <div class="form-element">
                <div class="slider">
                  <mat-label>HEIGHT</mat-label>
                  <mat-slider class="slider-tag" min="145" max="210" step="10" showTickMarks discrete
                    [displayWith]="formatSize">
                    <input matSliderThumb formControlName="marketing_height">
                  </mat-slider>
                </div>
              </div>

              <div class="form-element">
                <mat-form-field class="form-element">
                  <mat-label>BLOOD TYPE</mat-label>
                  <mat-select formControlName="marketing_blood_type">
                    @for (blood of bloodTypes; track blood) {
                    <mat-option [value]="blood">{{ blood.toUpperCase() }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>


            </div>

          </div>
          <div class="container-button">
            <div class="button-container-element">
              <div class="inside-button">
                <button mat-raised-button color="primary" type="submit" class="button" [disabled]="!form.valid"
                  (click)="submit()">

                  <div class="button-text">
                    <span class="action-text">
                      CREATE AVATAR
                    </span>
                    <div class="amount">
                      <div class="cost">{{ cost }} </div>
                      <div class="container-icon">
                        <mat-icon class="icon"> award_star</mat-icon>
                      </div>
                    </div>
                  </div>

                </button>

              </div>
            </div>

            <div class="button-container-element">
              <button mat-raised-button color="accent" type="button" class="button" (click)="onCancel()">CANCEL</button>
            </div>

          </div>
        </form>
        <!-- -->


      </div>
    </div>

  </mat-card-content>
</mat-card>
