import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { Balance } from '../shared/entity/balance';

@Injectable({
  providedIn: 'root'
})
export class CreditService {

  private readonly creditChange$ = new Subject<Balance>();

  apiBaseUrl : string;

  constructor(private http: HttpClient) {
    this.apiBaseUrl =  environment.apiUrl;
  }

  get(): Observable<Balance>  {

    return this.http.get<Balance>(`${this.apiBaseUrl}/v1/credit`);

  }

  update():void {

      this.get().subscribe((res)=> {
        this.creditChange$.next(res);
      });
  }

  collectCredit():Observable<Balance> {
    return this.creditChange$.asObservable();
  }

}
