<div class="main">

  <div class="switch">
    <div class="monthly">Pay Monthly </div>
    <mat-slide-toggle [(ngModel)]="isAnnual" [color]="'primary'" (toggleChange)="priceModelChange()">
      <div class="yearly">Pay Annualy </div>
    </mat-slide-toggle>
    <div class="discount">SAVE 20% </div>
  </div>
  <div class="types">
    @for (prod of ui_products; track prod.amount) {

    <div class="type">
      <mat-card>
        <div class="product">
          <div class="product-name"> {{ prod.label }} </div>
          <div *ngIf="prod.type=='TIER2_MONTHLY'" class="popular">POPULAR</div>
          <div *ngIf="prod.type.includes('ANNUAL')" class="discount">20% OFF</div>
        </div>
        <mat-card-header>
          <mat-card-title class="title"> ${{ priceCompute( prod.amount ) }} /mo </mat-card-title>
          <mat-card-subtitle class="subtitle"> {{ year_txt }} excl. tax</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>

          <button mat-raised-button color="primary" class="subscribe" (click)="subscribe(prod.type)" [disabled]="checkPurchasePossibility(prod)"> SUBSCRIBE NOW
            !</button>

          <div class="credits">
            <div class="credits-title"> {{ prod.monthly_credits }} credits/mo</div>
            <div class="credits-text"> {{ prod.monthly_credits }} images generated, or {{ prod.monthly_credits/2 }}
              upscaled images </div>
          </div>

          <mat-list role="list">
            <mat-list-item role="listitem"><mat-icon matListItemIcon>check</mat-icon>Nzym AI Engine</mat-list-item>
            <mat-list-item role="listitem"><mat-icon matListItemIcon>check</mat-icon>Image Prompt</mat-list-item>
            <mat-list-item role="listitem"><mat-icon matListItemIcon>check</mat-icon>Nzym AI image fixer</mat-list-item>
            <mat-list-item role="listitem"><mat-icon matListItemIcon>check</mat-icon>Face Swap</mat-list-item>
            <mat-list-item role="listitem"><mat-icon matListItemIcon>check</mat-icon>Zoom Out generation</mat-list-item>
            <mat-list-item role="listitem"><mat-icon matListItemIcon>check</mat-icon>Upscaler x2</mat-list-item>
            <mat-list-item role="listitem"><mat-icon matListItemIcon>check</mat-icon>Upscaler x4</mat-list-item>
            <mat-list-item role="listitem"><mat-icon matListItemIcon>check</mat-icon>Nzym Scenes
              influencers</mat-list-item>
            <mat-list-item role="listitem"><mat-icon matListItemIcon>check</mat-icon>{{ prod.number_of_avatar_creations
              }} Custom Avatar</mat-list-item>
            <mat-list-item role="listitem"><mat-icon matListItemIcon>check</mat-icon>High Resolution
              Generation</mat-list-item>
            <mat-list-item role="listitem"><mat-icon matListItemIcon>check</mat-icon>Packaged Downloads</mat-list-item>
          </mat-list>
        </mat-card-content>
      </mat-card>
    </div>
    }
  </div>
