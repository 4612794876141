import { Component } from '@angular/core';
import { AvatarService } from '../services/avatar.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AccountService } from '../services/account.service';
import { Avatar } from '../shared/entity/avatar';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialog } from '@angular/material/dialog';
import { DialogReadyComponent } from './dialog-ready/dialog-ready.component';

@Component({
  selector: 'app-avatar-wait-for-generation',
  standalone: true,
  imports: [RouterModule, MatButtonModule, MatCardModule, MatProgressBarModule],
  templateUrl: './avatar-wait-for-generation.component.html',
  styleUrl: './avatar-wait-for-generation.component.scss',
})
export class AvatarWaitForGenerationComponent {
  guid: string;
  avatar: Avatar;
  value: number = 0;
  picture: string;

  constructor(
    public dialog: MatDialog,
    private avatarService: AvatarService,
    private router: Router,
    private accountService: AccountService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {

      this.guid = params.get('avatar_guid');

      this.avatarService.get(params.get('avatar_guid')).subscribe((avatar) => {
        this.avatar = avatar;
        this.picture = avatar.data.marketing_profile_picture_uri;
      });

      this.download();
    });
  }

  async download() {
    while (this.value < 100) {
      this.checkAvatar();
      await sleep(2000);
      this.value++;
    }

    if (this.value >= 100) {
      const dialogRef = this.dialog.open(DialogReadyComponent, {
        data: { gender: this.avatar.data.gender },
        width: '310px',
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.router.navigate([
            '/avatar-profile/' + this.avatar.metadata.guid,
          ]);
        } else {
          //none
        }
      });
    }
  }

  checkAvatar() {
    this.avatarService.checkCompletion(this.guid).subscribe({

      next: (status) => {

        console.log(status);

        this.value  = status.completion;

    },
     error: (err) => {
      console.log("something got wrong with the server !");
      console.log(err);
     }

  });
  }

  move() {
    this.router.navigate(['home']);
  }
}
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
