import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCard, MatCardModule } from '@angular/material/card';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { Avatar } from '../../shared/entity/avatar';
import { AvatarService } from '../../services/avatar.service';
import { MatChipsModule } from '@angular/material/chips';

export interface DialogSelectAvatarData {}

@Component({
  selector: 'app-dialog-naming',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCard,
    MatProgressSpinnerModule,
    MatChipsModule
  ],
  templateUrl: './dialog-select-avatar.component.html',
  styleUrl: './dialog-select-avatar.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DialogSelectAvatarComponent {
  form: FormGroup;
  title: string;
  avatars: Avatar[];
  filteredAvatars: Avatar[];
  filter: string;

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DialogSelectAvatarComponent>,
    public avatarService: AvatarService
  ) {
    this.form = this.formBuilder.group({
      filter: ['']
    });
  }

  ngOnInit():void {
    this.title = 'SELECT AN AVATAR';

    this.avatarService.getAllActive(false).subscribe((res)=>{

      //console.log(res);
      this.avatars = res;
      this.filteredAvatars = this.avatars.slice();

    });
  }

  onChange(input) {

    let filter = input.target.value;

    let filteredArray:Avatar[] = [];

    this.avatars.forEach((avatar) => {

      if ((avatar.data.family_name.toUpperCase().indexOf(filter.toUpperCase()) >= 0)
        || (avatar.data.given_name.toUpperCase().indexOf(filter.toUpperCase()) >= 0)
       ) {

        filteredArray.push(avatar);
       }
    })
    this.filteredAvatars = filteredArray;


  }

  publicness(avatar: Avatar) {
    if (avatar.data.public_access)
      return "PUBLIC";
    else
      return "PRIVATE";
  }

  selectAvatar(avatar) {
    this.dialogRef.close(avatar);
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }
}
