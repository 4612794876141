import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Invoice } from '../shared/entity/invoice';


@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  apiBaseUrl : string;

  constructor(private http: HttpClient) {
    this.apiBaseUrl =  environment.apiUrl;
  }

  getAll(): Observable<Invoice[]>  {
    return this.http.get<Invoice[]>(`${this.apiBaseUrl}/v1/invoice`);
  }

}
