import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserListComponent } from './user-list/user-list.component';
import { ErrorComponent } from './error/error.component';
import { NoRoleComponent } from './no-role/no-role.component';
import { AuthGuard } from './shared/guard/auth.guard';
import { AvatarFaceGeneratorInitComponent } from './avatar-face-generator-init/avatar-face-generator-init.component';
import { AvatarFaceGeneratorRefComponent } from './avatar-face-generator-ref/avatar-face-generator-ref.component';
import { AvatarListComponent } from './avatar-list/avatar-list.component';
import { NzymGeneratorComponent } from './nzym-generator/nzym-generator.component';
import { InpaintEditorComponent } from './inpaint-editor/inpaint-editor.component';
import { FavoritesComponent } from './favorite/favorites.component';
import { AdminGuard } from './shared/guard/admin.guard';
import { SubscriptionComponent } from './subscription/subscription.component';
import { PayComponent } from './pay/pay.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { ErrorSubscriptionComponent } from './error-subscription/error-subscription.component';
import { ByeComponent } from './bye/bye.component';
import { AvatarGalleryComponent } from './avatar-gallery/avatar-gallery.component';
import { AvatarProfileComponent } from './avatar-profile/avatar-profile.component';
import { AvatarWaitForGenerationComponent } from './avatar-wait-for-generation/avatar-wait-for-generation.component';
import { NoCreditComponent } from './no-credit/no-credit.component';
import { BuySpotCreditsComponent } from './buy-spot-credits/buy-spot-credits.component';
import { PaySpotComponent } from './pay-spot/pay-spot.component';
import { SessionExpiredComponent } from './session-expired/session-expired.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { IngredientComponent } from './ingredient/ingredient.component';
import { IngredientListComponent } from './ingredient-list/ingredient-list.component';
import { AbuseListComponent } from './abuse-list/abuse-list.component';
import { UserImagesComponent } from './user-images/user-images.component';
import { AvatarStoriesListComponent } from './avatar-stories-list/avatar-stories-list.component';
import { PublicGalleryComponent } from './public-gallery/public-gallery.component';
import { CommunityComponent } from './community/community.component';
import { BanComponent } from './ban/ban.component';


const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard],runGuardsAndResolvers: 'always'  },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'ingredient-list', component: IngredientListComponent, canActivate: [AdminGuard],runGuardsAndResolvers: 'always' },
  { path: 'ingredient/:ingredient_guid', component: IngredientComponent, canActivate: [AdminGuard],runGuardsAndResolvers: 'always' },
  { path: 'abuse-list', component: AbuseListComponent, canActivate: [AdminGuard],runGuardsAndResolvers: 'always' },
  { path: 'user-list', component: UserListComponent, canActivate: [AdminGuard],runGuardsAndResolvers: 'always' },
  { path: 'user-detail/:user_guid', component: UserDetailComponent, canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },
  { path: 'user-images/:user_guid', component: UserImagesComponent, canActivate: [AdminGuard],runGuardsAndResolvers: 'always' },
  { path: 'invoices', component: InvoiceComponent, canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },
  { path: 'subscription', component: SubscriptionComponent, canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },
  { path: 'buy-spot-credits', component: BuySpotCreditsComponent, canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },
  { path: 'pay/:type', component: PayComponent, canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },
  { path: 'pay-spot/:guid', component: PaySpotComponent, canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },
  { path: 'avatar-list', component: AvatarListComponent, canActivate: [AdminGuard],runGuardsAndResolvers: 'always' },
  { path: 'avatar-stories-list', component: AvatarStoriesListComponent, canActivate: [AdminGuard],runGuardsAndResolvers: 'always' },
  { path: 'avatar-gallery', component: AvatarGalleryComponent, canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },
  { path: 'avatar-profile/:avatar_guid', component: AvatarProfileComponent, canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },
  { path: 'avatar-face-generator-ref/:avatar_guid', component: AvatarFaceGeneratorRefComponent, canActivate: [AdminGuard],runGuardsAndResolvers: 'always' },
  { path: 'avatar-wait-for-generation/:avatar_guid', component: AvatarWaitForGenerationComponent, canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },
  { path: 'avatar-face-generator-init', component: AvatarFaceGeneratorInitComponent, canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },
  { path: 'nzym-studio', component: NzymGeneratorComponent, canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },
  { path: 'inpaint/:img_guid', component: InpaintEditorComponent, canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },
  { path: 'favorites', component: FavoritesComponent, canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },
  { path: 'community', component: CommunityComponent, canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },
  { path: 'public-gallery', component: PublicGalleryComponent, canActivate: [AuthGuard],runGuardsAndResolvers: 'always' },
  { path: 'error', component: ErrorComponent},
  { path: 'error-subscription', component: ErrorSubscriptionComponent},
  { path: 'forbidden', component: ForbiddenComponent},
  { path: 'bye', component: ByeComponent },
  { path: 'ban', component: BanComponent },
  { path: 'no-credit', component: NoCreditComponent },
  { path: 'session-expired', component: SessionExpiredComponent },
  { path: 'email-verification', component: EmailVerificationComponent },
  { path: 'no-role-assigned', component: NoRoleComponent,runGuardsAndResolvers: 'always' },
  { path: '**', component: PageNotFoundComponent,runGuardsAndResolvers: 'always' }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
