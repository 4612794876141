import { ActionCode } from '../enum/action-code';
import { Picture } from './picture';
import { UpscaleRequest } from './upscale-request';
import { VaryRequest } from './vary-request';
import { ZoomRequest } from './zoom-request';

export class ActionMessage {

  img_guid: string;
  action_code: ActionCode;
  zoom_req?: ZoomRequest;
  upscale_req?: UpscaleRequest;
  vary_req?:VaryRequest;
  tiles?: any[];
}
