import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from '../../shared/entity/subscription';

export interface DialogData {
  sub: Subscription;
}

@Component({
  selector: 'app-dialog-future-sub',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './dialog-future-sub.component.html',
  styleUrl: './dialog-future-sub.component.scss',
})
export class DialogFutureSubComponent {
  sub: Subscription;

  constructor(
    public dialogRef: MatDialogRef<DialogFutureSubComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {
    this.sub = this.data.sub;
  }

  isActive() {

    if (this.sub.active)
      return 'ACTIVE';
    else
      return 'CANCELLED';

  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  submit() {
    this.dialogRef.close(true);
  }

  dateNextReload() {
    var utcSeconds = this.sub.next_date_cycle;
    var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(utcSeconds);

    return (
      d.getUTCFullYear() +
      '/' +
      d.getUTCMonth() +
      '/' +
      d.getUTCDate() +
      ' ' +
      d.getUTCHours() +
      ':' +
      d.getUTCMinutes() +
      ' (UTC Time)'
    );
  }

}
