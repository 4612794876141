import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatDialog } from '@angular/material/dialog';
import { RandomImageService } from '../services/random-image.service';
import { Picture } from '../shared/entity/picture';
import { AdminService } from '../services/admin.service';
import { ActivatedRoute } from '@angular/router';
import { NgFor, NgIf } from '@angular/common';
import { DialogImageActionComponent } from './dialog-image-action/dialog-image-action.component';

export interface Tile {
  pic: Picture;
  loaded: boolean;
  start?: number;
  sec?: number;
  decilesec?: number;
}

@Component({
  selector: 'app-user-images',
  standalone: true,
  imports: [ NgIf, NgFor,ScrollingModule
    ],
  templateUrl: './user-images.component.html',
  styleUrl: './user-images.component.scss'
})
export class UserImagesComponent {

  user_guid:string;
  tiles: Tile[];

  windowWidth: number;
  windowHeight: number;

  background_img : string;
  sourceNbColumns: number;
  displayColumn: boolean;

  loaded: boolean = false;

  sourceFlexColumns: Tile[][];

  //*********************
  //         INFINITE SCROLL

  contentHeight: number;
  scroll = (e) => this.onScroll(e);
  last_id: number; // for infinite scroll
  isLoading: boolean; // for infinite scroll


  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;


  constructor(
    public dialog: MatDialog,
    private adminService: AdminService,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private randomImageService : RandomImageService
  ) {}

  ngOnInit() {

    this.route.paramMap.subscribe((params) => {

    this.user_guid = params.get('user_guid');

    this.background_img = this.randomImageService.get();

    this.tiles = [];
    this.displayColumn = true;

    this.sourceNbColumns = 0;
    this.isLoading = false;

    //nb of pîctures preloaded
    let initLoad = 100;

    if (window.innerWidth > 2500) initLoad = 200;

    this.adminService.getAllPictures(this.user_guid, initLoad, null)
      .subscribe((pictures) => {
        pictures.forEach((pic) => {
          let tile = {
            pic: pic,
            loaded: true,
          };
          this.tiles.push(tile);
        });

        if (pictures && pictures.length > 0)
          this.last_id = pictures[pictures.length - 1].id;

        this.adaptTable();

        this.loaded = true;
      });

    document.addEventListener('scroll', this.scroll);
    });

  }

  ngOnDestroy() {
   document.removeEventListener('scroll', this.scroll);
  }

  onScroll(event) {
    let scrollHeight = event.target.scrollHeight;
    let scrollTop = event.target.scrollTop;
    let ratio = scrollTop / scrollHeight;

    if (ratio >= 0.2 && !this.isLoading) {
      //    console.log("Load Next !");
      if (window.innerWidth > 2500) this.loadItems(50);
      else this.loadItems(20);
    }
  }

  getRatioStyle(ratio) {
    if (ratio == 'RATIO_1_1') {
      return 'ratio-11';
    } else if (ratio == 'RATIO_3_5') {
      return 'ratio-35';
    } else if (ratio == 'RATIO_7_9') {
      return 'ratio-79';
    } else if (ratio == 'RATIO_5_3') {
      return 'ratio-53';
    } else if (ratio == 'RATIO_5_2') {
      return 'ratio-52';
    } else if (ratio == 'RATIO_2_3') {
      return 'ratio-23';
    } else if (ratio == 'RATIO_4_3') {
      return 'ratio-43';
    } else if (ratio == 'RATIO_4_5') {
      return 'ratio-45';
    } else if (ratio == 'RATIO_191_1') {
      return 'ratio-1911';
    }
    return 'ratio-11';
  }


  loadItems(step) {
    this.isLoading = true;
    this.adminService.getAllPictures(this.user_guid, step, this.last_id)
      .subscribe((pictures) => {
        pictures.forEach((pic) => {
          let tile = {
            pic: pic,
            loaded: true,
          };
          this.tiles.push(tile);
        });

        //no more pics
        if (pictures.length == 0) {
          return;
        }

        if (pictures && pictures.length > 0)
          this.last_id = pictures[pictures.length - 1].id;
        this.recalculateColumns();
        this.changeDetectorRef.detectChanges();
        this.isLoading = false;
      });
  }

  adaptTable() {
    this.windowHeight = window.innerHeight;
    this.windowWidth = window.innerWidth;

    let nbColumns = 0;

    if (this.windowWidth <= 1000) {
      nbColumns = 3;
    } else if (this.windowWidth <= 1280) {
      nbColumns = 4;
    } else if (this.windowWidth <= 1800) {
      nbColumns = 6;
    } else if (this.windowWidth <= 2300) {
      nbColumns = 8;
    } else if (this.windowWidth <= 3000) {
      nbColumns = 12;
    } else if (this.windowWidth <= 4000) {
      nbColumns = 17;
    } else if (this.windowWidth > 4000) {
      nbColumns = 23;
    }

    if (nbColumns != this.sourceNbColumns) {
      this.sourceNbColumns = nbColumns;

      //this.displayColumn = false;

      this.recalculateColumns();

      //this.displayColumn = true;
    }
  }

  recalculateColumns() {
    let arraysForColumn = [];
    let nbColumns = this.sourceNbColumns;

    for (let i = 0; i < nbColumns; i++) {
      arraysForColumn.push([]);
    }

    let nbTiles = this.tiles.length;
    let nbIterations = 0;

    if (nbTiles % nbColumns == 0) {
      nbIterations = nbTiles / nbColumns;
    } else {
      nbIterations = Math.floor(nbTiles / nbColumns) + 1;
    }

    for (let iter = 0; iter < nbIterations; iter++) {
      for (let c = 0; c < nbColumns; c++) {
        if (c + iter * nbColumns < this.tiles.length) {
          let rang = c + iter * nbColumns;
          arraysForColumn[c][iter] = this.tiles[c + iter * nbColumns];
        }
      }
    }

    this.sourceFlexColumns = arraysForColumn;
  }

  findPictureInTiles(img_guid: string): Picture {
    let foundPic = null;

    this.tiles.forEach((tile) => {
      if (tile.pic.guid == img_guid) {
        foundPic = tile.pic;
      }
    });

    return foundPic;
  }

  findIndexInTiles(img_guid: string) {
    let foundPic = null;

    let indexFound = 0;

    this.tiles.forEach((tile, index) => {
      if (tile.pic.guid == img_guid) {
        indexFound = index;
      }
    });

    return indexFound;
  }


  display(tile: Tile): void {
    let dataConfig;

    let indexTile = this.findIndexInTiles(tile.pic.guid);

    //horizontal
    if (
      tile.pic.img_ratio == 'RATIO_5_3' ||
      tile.pic.img_ratio == 'RATIO_5_2'
    ) {
      dataConfig = {
        id: 'popup',
        data: {
          name: 'IMAGE',
          index: indexTile,
          tiles: this.tiles,
        },
        maxWidth: '100%',
        maxHeight: '100%',
        autoFocus: false,
      };
    } else {
      //vertical

      if (tile.pic.img_ratio == 'RATIO_3_5') {
        dataConfig = {
          id: 'popup',
          data: {
            name: 'IMAGE',
            index: indexTile,
            tiles: this.tiles,
          },
          maxWidth: '100%',
          maxHeight: '100%',
          autoFocus: false,
          position: { margin: 'auto' },
          panelClass: 'custom-container',
        };
      } else {
        dataConfig = {
          id: 'popup',
          data: {
            name: 'IMAGE',
            index: indexTile,
            tiles: this.tiles,
          },
          maxWidth: '100%',
          maxHeight: '100%',
          autoFocus: false,
          panelClass: 'custom-container',
        };
      }
    }

    const dialogRef = this.dialog.open(DialogImageActionComponent, dataConfig);

    dialogRef.afterClosed().subscribe(() => {

    });
  }



}
