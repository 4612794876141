import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AsyncPipe, Location, NgFor, NgIf } from '@angular/common';
import {
  CSP_NONCE,
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NO_ERRORS_SCHEMA,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { PictureService } from '../services/picture.service';
import { ActionMessage } from '../shared/entity/action-message';
import { Avatar } from '../shared/entity/avatar';
import { Picture } from '../shared/entity/picture';
import { PictureRequest } from '../shared/entity/picture-request';
import { ActionCode } from '../shared/enum/action-code';
import { DialogImageActionComponent } from './dialog-image-action/dialog-image-action.component';
import { DialogSelectAvatarComponent } from './dialog-select-avatar/dialog-select-avatar.component';
import { CreditService } from '../services/credit.service';

import { Scene } from '../shared/entity/scene';
import { DialogSelectSceneComponent } from './dialog-select-scene/dialog-select-scene.component';
import { FileDragNDropDirective } from '../shared/directive/file-drag-n-drop.directive ';
import { ImagePrompt } from '../shared/entity/image-prompt';
import { ImagePromptType } from '../shared/enum/image-prompt-type';
import { AiCreativity } from '../shared/enum/ai-creativity';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AvatarService } from '../services/avatar.service';
import { DialogHairstyleComponent } from './dialog-hairstyle/dialog-hairstyle.component';
import { HairStyle } from '../shared/entity/hairstyle';
import { ScaleFactor } from '../shared/enum/scale-factor';
import { DialogNeedBiscuitComponent } from '../shared/component/dialog-need-biscuit/dialog-need-biscuit.component';
import { OperationsCostService } from '../services/operations-cost';
import { RandomImageService } from '../services/random-image.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PromptService } from '../services/prompt.service';
import { PromptTag } from '../shared/entity/prompt-tag';

export interface Tile {
  pic: Picture;
  loaded: boolean;
  //start?: number;
  //sec?: number;
  //decilesec?: number;
}

@Component({
  selector: 'app-nzym-generator',
  templateUrl: './nzym-generator.component.html',
  styleUrl: './nzym-generator.component.scss',
  //changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCardModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    AsyncPipe,
    MatAutocompleteModule,
    MatRadioModule,
    MatChipsModule,
    MatSliderModule,
    MatIconModule,
    MatExpansionModule,
    ScrollingModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    FileDragNDropDirective,
    DialogNeedBiscuitComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class NzymGeneratorComponent {
  windowWidth: number;
  windowHeight: number;

  title: string | undefined;
  subtitle: string | undefined;
  creativityOpenState: boolean;
  formatOpenState: boolean;
  stylesOpenState: boolean;
  numberOpenState: boolean;
  sceneOpenState: boolean;
  ipromptOpenState: boolean;
  form: FormGroup;
  tiles: Tile[];
  sourceFlexColumns: Tile[][];
  styles: string[];
  sourceNbColumns: number;
  displayColumn: boolean;
  background_img: string;

  //interval call to refresh pending pictures
  intervalId;

  //****************************
  //       PROMPT TAG

  promptTag1: PromptTag;
  promptTag2: PromptTag;
  promptTag3: PromptTag;

  //****************************

  //***************************
  //  CONTROL AVATAR ACCORDION PROGRAMMATICALLY
  isAvatarAddedExpanded: boolean = false;

  //*********************
  //         INFINITE SCROLL

  contentHeight: number;
  scroll = (e) => this.onScroll(e);
  last_id: number; // for infinite scroll
  isLoading: boolean; // for infinite scroll

  //*********************

  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;

  avatarAdded: Avatar | undefined;
  sceneAdded: Scene | undefined;
  loaded: boolean = false;
  tile: Tile;
  processed_tile: Tile;
  inprocess: boolean;
  generateLabel: string;
  gen_done: boolean;

  //UI only
  default_avatar_and_hairstyle_image = '/img/hairstyle/choose.webp';
  avatar_image: string;
  hairstyle_image: string;

  //IMAGE PROMPTS
  files: File[];
  imagePrompts: ImagePrompt[];

  //COSTS
  cost: number = 1;

  cost_simple: number;
  cost_iprompt: number;
  cost_zoomout: number;
  cost_vary: number;
  cost_upscale2: number;
  cost_upscale4: number;

  constructor(
    public dialog: MatDialog,
    private pictureService: PictureService,
    private formBuilder: FormBuilder,
    private location: Location,
    private changeDetectorRef: ChangeDetectorRef,
    private creditService: CreditService,
    private avatarService: AvatarService,
    private route: ActivatedRoute,
    private _snackbar: MatSnackBar,
    private opertationsCost: OperationsCostService,
    private randomImageService: RandomImageService,
    private promptService: PromptService
  ) {}

  ngOnDestroy() {
    //    console.log("Destroy !");
    document.removeEventListener('scroll', this.scroll, true);
    clearInterval(this.intervalId);
  }

  ngOnInit() {
    this.background_img = this.randomImageService.get();

    this.cost_simple = this.opertationsCost.cost_simple;
    this.cost_iprompt = this.opertationsCost.cost_iprompt;
    this.cost_zoomout = this.opertationsCost.cost_zoomout;
    this.cost_vary = this.opertationsCost.cost_vary;
    this.cost_upscale2 = this.opertationsCost.cost_upscale2;
    this.cost_upscale4 = this.opertationsCost.cost_upscale4;

    this.tiles = [];
    this.displayColumn = true;
    this.styles = ['PHOTOREALISM'];
    this.files = [];
    this.imagePrompts = [];

    this.avatar_image = this.default_avatar_and_hairstyle_image;
    this.hairstyle_image = this.default_avatar_and_hairstyle_image;

    this.form = this.formBuilder.group({
      prompt: ['', Validators.required],
      nb_of_images: ['1', Validators.required],
      ratio: ['RATIO_1_1', Validators.required],
      influence_level: ['0.50'],
      creativity: ['1'],
    });

    this.promptTag1 = this.promptService.getPrompt();
    this.promptTag2 = this.promptService.getPrompt();
    this.promptTag3 = this.promptService.getPrompt();

    while (
      this.promptTag1.prompt == this.promptTag2.prompt ||
      this.promptTag1.prompt == this.promptTag3.prompt ||
      this.promptTag2.prompt == this.promptTag3.prompt
    ) {
      this.promptTag3 = this.promptService.getPrompt();
    }

    //  console.log("INIT !");
    this.title = 'NZYM STUDIO';
    this.subtitle = 'SETTINGS';
    this.inprocess = false;
    this.gen_done = false;
    this.generateLabel = 'generate';

    this.creativityOpenState = false;
    this.formatOpenState = false;
    this.stylesOpenState = true;
    this.numberOpenState = false;
    this.sceneOpenState = false;
    this.ipromptOpenState = false;

    this.sourceNbColumns = 0;
    this.isLoading = false;
    //not initiated by default even if displayed
    this.form.controls.nb_of_images.setValue(4);

    //CHECK IF WE RECEIVED AN AVATAR AS ADDITION TO THE PROMPT (URL NAVIGATION FROM AVATAR PROFILE PAGE)
    this.route.paramMap.subscribe((params: ParamMap) => {
      let avatarGuid = this.route.snapshot.queryParamMap.get('aguid');

      if (avatarGuid) {
        this.avatarService.get(avatarGuid).subscribe((avatar) => {
          this.avatarAdded = avatar;
          this.avatar_image = avatar.data.marketing_profile_picture_thumb_uri;
          this.isAvatarAddedExpanded = true;
          this.form.controls.prompt.patchValue('a portrait of a person');
        });
      }
    });

    //nb of pîctures preloaded
    let initLoad = 100;

    if (window.innerWidth > 2500) initLoad = 200;

    this.pictureService
      .getPicturePerPage(initLoad, null)
      .subscribe((pictures) => {
        pictures.forEach((pic) => {
          let tile = {
            pic: pic,
            loaded: true,
          };

          if (pic.img_uri == null) {
            tile.loaded = false;
            pic.img_uri = '../../assets/img/1x1-gray.png';
          }

          this.tiles.push(tile);
        });

        if (pictures && pictures.length > 0)
          this.last_id = pictures[pictures.length - 1].id;

        this.adaptTable();

        this.loaded = true;

        //REFRESH PENDING PICTURES ONLY very 1.8s
        this.intervalId = setInterval(() => {
          //console.log('In !');
          let counter = 0;
          this.tiles.forEach((tile) => {
            if (tile.pic.thumb_uri == null) {
              counter++;
              //console.log('TRIG REFRESH ! ' + counter + ' ' + tile.pic.guid);

              if (tile.pic.guid != null) {
                this.pictureService.get(tile.pic.guid).subscribe((newPic) => {
                  if (newPic.img_uri != null) {
                    tile.pic = newPic;
                    tile.loaded = true;
                  }
                });
              }
            }
          });
        }, 1800);
      });

    document.addEventListener('scroll', this.scroll, true);

    this.refreshCost();
  }

  changePrompt(prompt: string) {
    this.form.controls.prompt.patchValue(prompt);
  }

  columnTrack(event) {}

  ngAfterViewInit() {}

  ngAfterContentInit() {}

  onScroll(event) {
    let scrollHeight = event.target.scrollHeight;
    let scrollTop = event.target.scrollTop;
    let ratio = scrollTop / scrollHeight;

    if (ratio >= 0.2 && !this.isLoading) {
      //    console.log("Load Next !");
      if (window.innerWidth > 2500) this.loadItems(50);
      else this.loadItems(20);
    }
  }

  loadItems(step) {
    this.isLoading = true;
    this.pictureService
      .getPicturePerPage(step, this.last_id)
      .subscribe((pictures) => {
        pictures.forEach((pic) => {
          let tile = {
            pic: pic,
            loaded: true,
          };
          this.tiles.push(tile);
        });

        //no more pics
        if (pictures.length == 0) {
          return;
        }

        if (pictures && pictures.length > 0)
          this.last_id = pictures[pictures.length - 1].id;
        this.recalculateColumns();
        this.changeDetectorRef.detectChanges();
        this.isLoading = false;
      });
  }

  adaptTable() {
    this.windowHeight = window.innerHeight;
    this.windowWidth = window.innerWidth;

    let nbColumns = 0;

    if (this.windowWidth <= 1000) {
      nbColumns = 3;
    } else if (this.windowWidth <= 1280) {
      nbColumns = 4;
    } else if (this.windowWidth <= 1800) {
      nbColumns = 6;
    } else if (this.windowWidth <= 2300) {
      nbColumns = 8;
    } else if (this.windowWidth <= 3000) {
      nbColumns = 12;
    } else if (this.windowWidth <= 4000) {
      nbColumns = 17;
    } else if (this.windowWidth > 4000) {
      nbColumns = 23;
    }

    if (nbColumns != this.sourceNbColumns) {
      this.sourceNbColumns = nbColumns;

      //this.displayColumn = false;

      this.recalculateColumns();

      //this.displayColumn = true;
    }
  }

  recalculateColumns() {
    let arraysForColumn = [];
    let nbColumns = this.sourceNbColumns;

    for (let i = 0; i < nbColumns; i++) {
      arraysForColumn.push([]);
    }

    let nbTiles = this.tiles.length;
    let nbIterations = 0;

    if (nbTiles % nbColumns == 0) {
      nbIterations = nbTiles / nbColumns;
    } else {
      nbIterations = Math.floor(nbTiles / nbColumns) + 1;
    }

    for (let iter = 0; iter < nbIterations; iter++) {
      for (let c = 0; c < nbColumns; c++) {
        if (c + iter * nbColumns < this.tiles.length) {
          let rang = c + iter * nbColumns;
          arraysForColumn[c][iter] = this.tiles[c + iter * nbColumns];
        }
      }
    }

    this.sourceFlexColumns = arraysForColumn;
  }

  changeFormatSelected(value) {
    //console.log(value);

    if (!value.source._selected) {
      value.source._selected = true;
      this.form.controls.ratio.patchValue(value.source._value);
    } else {
      this.form.controls.ratio.patchValue(value.source._value);
    }
  }

  changeStyleSelection(event) {
    if (event.source._selected) {
      this.styles.push(event.source._value);
    } else {
      let index = this.styles.indexOf(event.source._value);
      this.styles.splice(index, 1);
    }
  }

  iconFormat(ratio) {
    if (ratio == 'RATIO_1_1') {
      return 'crop_square';
    } else if (ratio == 'RATIO_3_5') {
      return 'crop_portrait';
    } else if (ratio == 'RATIO_7_9') {
      return 'crop_portrait';
    } else if (ratio == 'RATIO_5_3') {
      return 'crop_5_4';
    } else if (ratio == 'RATIO_5_2') {
      return 'crop_7_5';
    }
    return 'crop_square';
  }
/*
  chrono(tile) {
    //console.log('start chronometer !');

    tile.start = new Date().getTime();

    this.processed_tile = tile;

    // Update the chornometer every 1/100 second
    var x = setInterval(function () {
      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = now - tile.start;

      // Time calculations for days, hours, minutes and seconds
      tile.sec = Math.floor(distance / 1000);
      tile.decilesec = Math.floor((distance % 1000) / 10);

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        tile.start = new Date().getTime();
        tile.sec = 0;
        tile.decilesec = 0;

        //  console.log('stop chronometer !');
      }
    }, 100);
  }
*/
  //*******************************************
  //*******************************************
  //***********  TOOTLTIPS
  //*******************************************
  //*******************************************

  creativityToolTipMessage() {
    return "Low means that AI will follow strictly your prompt, Higher levels give AI more 'freedom'";
  }

  //*******************************************
  //*******************************************
  //***********  GENERATION
  //*******************************************
  //*******************************************

  generate() {
    if (this.form.valid) {
      //check credits !
      this.creditService.get().subscribe((info) => {
        let possible: boolean = info.balance >= this.cost;

        if (possible) {
          this.launchGeneration();
        } else {
          this.dialog.open(DialogNeedBiscuitComponent, {
            width: '600px',
            disableClose: true,
            autoFocus: false,
          });
        }
      });
    }
  }

  launchGeneration() {
    let picReq = new PictureRequest();
    picReq.raw_user_prompt = this.form.controls.prompt.value;
    picReq.img_ratio = this.form.controls.ratio.value;

    //if avatar added, he contains the hair style (if any chose)
    //it is not stored in base for the avatar but must be sent for the gen
    picReq.avatar_guid = this.avatarAdded?.metadata.guid;
    if (this.avatarAdded) picReq.hair_style = this.avatarAdded.data.hair_style;

    picReq.nb_images = 1;
    picReq.gen_type = 'FLUX_IMG_GENERATOR';
    picReq.styles = this.styles;
    picReq.scene_guid = this.sceneAdded?.guid;
    picReq.scene_influence_level = this.form.controls.influence_level.value;

    if (this.form.controls.creativity.value == 0)
      picReq.creativity = AiCreativity.LOW;
    else if (this.form.controls.creativity.value == 1)
      picReq.creativity = AiCreativity.MEDIUM;
    else if (this.form.controls.creativity.value == 2)
      picReq.creativity = AiCreativity.HIGH;
    else if (this.form.controls.creativity.value == 3)
      picReq.creativity = AiCreativity.CRAZY;
    else picReq.creativity = AiCreativity.MEDIUM;

    //preparation by managing the base 64 text
    //deep copy
    let iPrompts = JSON.parse(JSON.stringify(this.imagePrompts));

    for (let i = 0; i < iPrompts.length; i++) {
      iPrompts[i].base64 = iPrompts[i].base64
        .replace('data:', '')
        .replace(/^.+,/, '');
    }

    picReq.img_prompts = iPrompts;

    this.generateAndDisplayImages(
      picReq,
      this.imageNb(this.form.controls.nb_of_images.value)
    );
  }

  //*******************************************
  //*******************************************
  //***********  REGENERATION
  //*******************************************
  //*******************************************

  regenerate(img_guid: string) {
    //check credits !
    this.creditService.get().subscribe((info) => {
      //cost is one
      let possible: boolean = info.balance >= this.cost_simple;

      if (possible) {
        this.launchRegeneration(img_guid);
      } else {
        this.dialog.open(DialogNeedBiscuitComponent, {
          width: '600px',
          disableClose: true,
          autoFocus: false,
        });
      }
    });
  }

  launchRegeneration(img_guid: string) {
    let pic = this.findPictureInTiles(img_guid);

    let picReq = new PictureRequest();
    picReq.raw_user_prompt = pic.raw_user_prompt;
    picReq.img_ratio = pic.img_ratio;
    picReq.avatar_guid = pic.avatar_guid;
    picReq.hair_style = pic.hair_style;
    picReq.nb_images = 1;
    picReq.gen_type = 'SDXL_IMG_GENERATOR';
    picReq.creativity = pic.creativity;

    this.form.controls.prompt.setValue(pic.raw_user_prompt);
    this.form.controls.ratio.setValue(pic.img_ratio);

    //let array = [];
    //array.push(picReq);

    this.generateAndDisplayImages(picReq, 1);
  }

  //*******************************************
  //*******************************************
  //***********  ZOOMOUT
  //*******************************************
  //*******************************************

  generateZoomOut(params: ActionMessage) {
    this.creditService.get().subscribe((info) => {
      //cost is one
      let possible: boolean = info.balance >= this.cost_zoomout;

      if (possible) {
        this.launchZoomOut(params);
      } else {
        this.dialog.open(DialogNeedBiscuitComponent, {
          width: '600px',
          disableClose: true,
          autoFocus: false,
        });
      }
    });
  }

  launchZoomOut(params: ActionMessage) {
    this.virtualScroll.scrollToIndex(0);

    this.inprocess = true;
    let pic = this.findPictureInTiles(params.zoom_req.img_guid);

    this.addBlankCase(pic.img_ratio);

    this.pictureService.zoomOut(params).subscribe((picture) => {
      this.creditService.update();

     // this.sourceFlexColumns[0][0].start = 1000000000000000000000000000000;

      //assign the value coming from the server
      let tile = {
        pic: picture,
        loaded: false,
      };

      tile.pic.img_uri = '../../assets/img/1x1-gray.png';

      this.tiles[0] = tile;

      this.recalculateColumns();

      this.changeDetectorRef.detectChanges();

      this.inprocess = false;
    });
  }

  //*******************************************
  //*******************************************
  //***********  UPSCALE
  //*******************************************
  //*******************************************

  generateUpscale(params: ActionMessage) {
    this.creditService.get().subscribe((info) => {
      //cost is one
      let possible: boolean = false;

      if (params.upscale_req.factor == ScaleFactor.TWO)
        possible = info.balance >= this.cost_upscale2;
      else possible = info.balance >= this.cost_upscale4;

      if (possible) {
        this.launchUpscale(params);
      } else {
        this.dialog.open(DialogNeedBiscuitComponent, {
          width: '600px',
          disableClose: true,
          autoFocus: false,
        });
      }
    });
  }

  launchUpscale(params: ActionMessage) {
    this.virtualScroll.scrollToIndex(0);

    this.inprocess = true;
    let pic = this.findPictureInTiles(params.upscale_req.img_guid);

    this.addBlankCase(pic.img_ratio);

    this.pictureService.upscale(params).subscribe((picture) => {
      this.creditService.update();

      this._snackbar.open('Upscale done !', '', {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 1000,
        panelClass: ['green-snackbar'],
      });

      //this.sourceFlexColumns[0][0].start = 1000000000000000000000000000000;

      //assign the value coming from the server
      let tile = {
        pic: picture,
        loaded: true,
      };

      this.tiles[0] = tile;

      this.recalculateColumns();

      this.changeDetectorRef.detectChanges();

      this.inprocess = false;
    });
  }

  //*******************************************
  //*******************************************
  //***********  VARY
  //*******************************************
  //*******************************************

  generateVary(params: ActionMessage) {
    this.creditService.get().subscribe((info) => {
      //cost is one
      let possible: boolean = info.balance >= this.cost_vary;

      if (possible) {
        this.launchVary(params);
      } else {
        this.dialog.open(DialogNeedBiscuitComponent, {
          width: '600px',
          disableClose: true,
          autoFocus: false,
        });
      }
    });
  }

  launchVary(params: ActionMessage) {
    this.virtualScroll.scrollToIndex(0);

    this.inprocess = true;
    let pic = this.findPictureInTiles(params.vary_req.img_guid);

    this.addBlankCase(pic.img_ratio);

    this.pictureService.vary(params).subscribe((picture) => {
      this.creditService.update();

      this._snackbar.open('Variant done !', '', {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 1000,
        panelClass: ['green-snackbar'],
      });

      //this.sourceFlexColumns[0][0].start = 1000000000000000000000000000000;

      //assign the value coming from the server
      let tile = {
        pic: picture,
        loaded: true,
      };

      this.tiles[0] = tile;

      this.recalculateColumns();

      this.changeDetectorRef.detectChanges();

      this.inprocess = false;
    });
  }

  //input: Array of PictureRequest
  generateAndDisplayImages(pictureReq, nbImages) {
    this.virtualScroll.scrollToIndex(0);

    for (let i = 0; i < nbImages; i++) {
      //this.inprocess = true;
      //  let pictureReq = array[0];

      this.addBlankCase(pictureReq.img_ratio);

      this.pictureService.generate(pictureReq).subscribe((res) => {

        this.creditService.update();

        //this.sourceFlexColumns[0][i].start = 1000000000000000000000000000000;

        //assign the value coming from the server
        let tile = {
          pic: res[0],
          loaded: false,
        };

        tile.pic.img_uri = '../../assets/img/1x1-gray.png';

        //console.log('tile nouvellement créé pic.guid:' + tile.pic.guid);

        this.tiles[i] = tile;
        this.sourceFlexColumns[0][i] = tile;

        //console.log('tile une fois injecté dans tiles pic.guid:' + this.tiles[i].pic.guid);

        // console.log("tile ajoutée avec pic guid:"+this.tiles[i].pic.guid);
        //console.log("tile ajoutée avec pic uri :"+tile.pic.img_uri);
        //console.log("tile ajoutée avec pic ratio:"+tile.pic.img_ratio);

        this.recalculateColumns();

        //remove first element of array
        // array.shift();
        this.changeDetectorRef.detectChanges();
        //next image if any request left
        /*if (array.length > 0) {
          this.generateAndDisplayImages(array);
        } else {
          this.inprocess = false;
        }*/
      });

    }
  }

  addBlankCase(ratio) {
    //transparent pixel
    let tile = {
      pic: {
        img_uri: '../../assets/img/1x1-gray.png',
        img_ratio: ratio,
      },
      loaded: false,
     // start: new Date().getTime(),
     // sec: 0,
     // decilesec: 0,
    };

    this.tiles.unshift(tile);

    this.recalculateColumns();
    //  this.changeDetectorRef.detectChanges();
    //this.chrono(tile);
    //this.chrono(this.sourceFlexColumns[0].at(0));
  }

  getRatioStyle(ratio) {
    if (ratio == 'RATIO_1_1') {
      return 'ratio-11';
    } else if (ratio == 'RATIO_3_5') {
      return 'ratio-35';
    } else if (ratio == 'RATIO_7_9') {
      return 'ratio-79';
    } else if (ratio == 'RATIO_5_3') {
      return 'ratio-53';
    } else if (ratio == 'RATIO_5_2') {
      return 'ratio-52';
    } else if (ratio == 'RATIO_2_3') {
      return 'ratio-23';
    } else if (ratio == 'RATIO_4_3') {
      return 'ratio-43';
    } else if (ratio == 'RATIO_4_5') {
      return 'ratio-45';
    } else if (ratio == 'RATIO_191_1') {
      return 'ratio-1911';
    }
    return 'ratio-11';
  }

  //*******************************************
  //*******************************************
  //***********  AVATAR MANAGEMENT
  //*******************************************
  //*******************************************

  selectAvatar() {
    const dialogRef = this.dialog.open(DialogSelectAvatarComponent, {
      height: '80%',
      width: '80%',
      panelClass: 'custom-container',
    });

    dialogRef.afterClosed().subscribe((result: Avatar) => {
      //true means profile has been updated
      if (result) {
        this.avatarAdded = result;
        this.avatar_image = result.data.marketing_profile_picture_thumb_uri;
        this.hairstyle_image = this.default_avatar_and_hairstyle_image;
      }
    });
  }

  cancelAvatar() {
    this.avatarAdded = null;
    this.avatar_image = this.default_avatar_and_hairstyle_image;
    this.hairstyle_image = this.default_avatar_and_hairstyle_image;
  }

  changeAvatarAddButtonLabel() {
    if (this.avatarAdded) return 'CHANGE';
    else return 'ADD';
  }

  //*******************************************
  //*******************************************
  //***********  POSTURE MANAGEMENT
  //*******************************************
  //*******************************************

  selectScene() {
    const dialogRef = this.dialog.open(DialogSelectSceneComponent, {
      height: '80%',
      width: '80%',
      panelClass: 'custom-container',
    });

    dialogRef.afterClosed().subscribe((result: Scene) => {
      //true means profile has been updated
      if (result) {
        this.sceneAdded = result;
      }
    });
  }

  cancelScene() {
    this.sceneAdded = null;
  }

  changeSceneAddButtonLabel() {
    if (this.sceneAdded) return 'CHANGE';
    else return 'ADD';
  }

  //*******************************************
  //*******************************************
  //***********  UTILS
  //*******************************************
  //*******************************************

  display(tile: Tile): void {
    let dataConfig;

    let indexTile = this.findIndexInTiles(tile.pic.guid);

    //horizontal
    if (
      tile.pic.img_ratio == 'RATIO_5_3' ||
      tile.pic.img_ratio == 'RATIO_5_2'
    ) {
      dataConfig = {
        id: 'popup',
        data: {
          name: 'IMAGE',
          index: indexTile,
          tiles: this.tiles,
          inprocess: this.inprocess,
        },
        maxWidth: '100%',
        maxHeight: '100%',
        autoFocus: false,
        panelClass: 'custom-container',
      };
    } else {
      //vertical

      if (tile.pic.img_ratio == 'RATIO_3_5') {
        dataConfig = {
          id: 'popup',
          data: {
            name: 'IMAGE',
            index: indexTile,
            tiles: this.tiles,
            inprocess: this.inprocess,
          },
          maxWidth: '100%',
          maxHeight: '100%',
          autoFocus: false,
          position: { margin: 'auto' },
          panelClass: 'custom-container',
        };
      } else {
        dataConfig = {
          id: 'popup',
          data: {
            name: 'IMAGE',
            index: indexTile,
            tiles: this.tiles,
            inprocess: this.inprocess,
          },
          maxWidth: '100%',
          maxHeight: '100%',
          autoFocus: false,
          panelClass: 'custom-container',
        };
      }
    }

    const dialogRef = this.dialog.open(DialogImageActionComponent, dataConfig);

    dialogRef.afterClosed().subscribe((result: ActionMessage) => {
      if (result) {
        //whatever happens, the tiles may have been updated (deletion)
        //so we redraw the table

        this.tiles = result.tiles;
        this.recalculateColumns();
        this.changeDetectorRef.detectChanges();

        if (result.action_code == ActionCode.SAME_AGAIN) {
          //GENERATE 1 IMAGE WITH SAME SETTINGS
          this.regenerate(result.img_guid);
        } else if (result.action_code == ActionCode.ZOOM_OUT) {
          //GENERATE ZOOMOUT WITH SAME PARAMETERS
          this.generateZoomOut(result);
        } else if (result.action_code == ActionCode.UPSCALE) {
          this.generateUpscale(result);
        } else if (result.action_code == ActionCode.VARY) {
          this.generateVary(result);
        } else if (result.action_code == ActionCode.DELETE) {
          //this.removeFromTable(result.img_guid);
          //this.tiles = result.tiles;
          //this.recalculateColumns();
          //this.changeDetectorRef.detectChanges();
        }
      }
    });
  }

  findPictureInTiles(img_guid: string): Picture {
    let foundPic = null;

    this.tiles.forEach((tile) => {
      if (tile.pic.guid == img_guid) {
        foundPic = tile.pic;
      }
    });

    return foundPic;
  }

  findIndexInTiles(img_guid: string) {
    let foundPic = null;

    let indexFound = 0;

    this.tiles.forEach((tile, index) => {
      if (tile.pic.guid == img_guid) {
        indexFound = index;
      }
    });

    return indexFound;
  }

  removeFromTable(img_guid: string) {
    let foundPic = null;

    this.tiles.forEach((tile, index) => {
      if (tile.pic.guid == img_guid) {
        this.tiles.splice(index, 1);
      }
    });

    this.recalculateColumns();

    this.changeDetectorRef.detectChanges();
  }

  onChangeImageNumber(e) {
    this.refreshCost();
  }

  refreshCost() {
    let extra = 0;
    if (this.imagePrompts.length > 0) extra = 1;

    this.cost =
      this.imageNb(this.form.controls.nb_of_images.value) *
      (this.cost_simple + extra * this.cost_iprompt);
  }

  imageNb(value) {
    let result;

    if (value == 0) result = 1;
    else result = +value;

    return result;
  }

  influence(value) {
    return value * 100 + '%';
  }

  creativityTrad(value) {
    if (value == 0) return 'LOW';
    if (value == 1) return 'NORMAL';
    if (value == 2) return 'HIGH';
    if (value == 3) return 'CRAZY';

    return 'NORMAL';
  }

  adaptFormat(str: string) {
    if (str == 'RATIO_1_1') return '1024x1024';
    else if (str == 'RATIO_3_5') return '768x1280';
    else if (str == 'RATIO_7_9') return '896x1152';
    else if (str == 'RATIO_5_3') return '1280x768';
    else if (str == 'RATIO_5_2') return '1600x640';
    else if (str == 'RATIO_2_3') return '820x1230';
    else if (str == 'RATIO_4_3') return '1280x960';
    else if (str == 'RATIO_4_5') return '1080x1350';
    else if (str == 'RATIO_191_1') return '1412x740';
    return '';
  }

  cancel() {
    this.location.back();
  }

  //*****************************
  //*****************************
  //************* HAIRSTYLE

  openHairstyle() {
    const dialogRef = this.dialog.open(DialogHairstyleComponent, {
      data: { gender: this.avatarAdded.data.gender },
      width: '80%',
      maxWidth: '1000px',
      maxHeight: '800px',
    });

    dialogRef.afterClosed().subscribe((result: HairStyle) => {
      this.hairstyle_image = result.img_url;
      this.avatarAdded.data.hair_style = result.reference;
    });
  }

  //***********************************
  //***********************************
  //*************  IMAGE PROMPTS !

  getImagePromptMessage() {
    if (this.files.length > 0) {
      return this.files.length + ' IMAGES ';
    } else {
      return '';
    }
  }

  file2Base64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      let reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString() || '');
      reader.onerror = (error) => reject(error);
    });
  };

  onImageSwapFileChange(pFileList: File[]) {
    var self = this;

    let temp_files: File[] = Object.keys(pFileList).map(
      (key) => pFileList[key]
    );

    if (self.files.length == 0) {
      self.files = temp_files.slice(0, 2);

      self._snackbar.open('2 images added !', '', {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 1000,
        panelClass: ['green-snackbar'],
      });
    } else if (self.files.length == 1) {
      self.files.push(temp_files.slice(0, 1)[0]);

      self._snackbar.open('1 image added !', '', {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 1000,
        panelClass: ['green-snackbar'],
      });
    } else {
      self._snackbar.open('Max 2 images !', '', {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 1000,
        panelClass: ['red-snackbar'],
      });

      return;
    }

    let reader = new FileReader();

    reader.onloadend = (e) => {
      //let base64 = reader.result.toString();
      //console.log(base64);
      //console.log(base64.replace('data:', '').replace(/^.+,/, ''));
      // self.b64Files.push(base64);
    };

    for (let i = 0; i < this.files.length; i++) {
      //self.imagePrompts = [];

      self.file2Base64(this.files[i]).then((value) => {
        //if it does not exist yet, we initialise the new item
        if (!self.imagePrompts[i]) {
          let imagePrompt = new ImagePrompt();
          imagePrompt.base64 = value;
          imagePrompt.strength = 0.5;
          imagePrompt.type = ImagePromptType.IMAGE_PROMPT;
          self.imagePrompts.push(imagePrompt);
        } //else we keep the values
        this.refreshCost();
      });
    }

    self._snackbar.open('Successfully upload (max 2 images)!', '', {
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      duration: 1000,
      panelClass: ['green-snackbar'],
    });
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.imagePrompts.splice(index, 1);
    this.refreshCost();

    this._snackbar.open('Successfully removed!', '', {
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      duration: 1000,
      panelClass: ['green-snackbar'],
    });
  }

  setImagePromptType(index: number, promptType: string) {
    if (promptType == 'IMAGE_PROMPT')
      this.imagePrompts[index].type = ImagePromptType.IMAGE_PROMPT;
    else this.imagePrompts[index].type = ImagePromptType.FACE_SWAP;
  }

  setImagePromptStrength(index: number, e: number) {
    this.imagePrompts[index].strength = e;
  }

  displayEvent(e) {
    let pFileList: File[] = e.target.files;
    this.onImageSwapFileChange(pFileList);
  }
}
