import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Avatar } from '../shared/entity/avatar';
import { StableDiffusionDto } from '../shared/entity/stable-diffusion-dto';
import { References } from '../shared/entity/references';
import { MarketingImage } from '../shared/entity/marketing-image';
import { Picture } from '../shared/entity/picture';
import { Status } from '../shared/entity/status';

@Injectable({
  providedIn: 'root',
})
export class AvatarService {
  apiBaseUrl: string;

  constructor(private http: HttpClient) {
    this.apiBaseUrl = environment.apiUrl;
  }

  search(typing: string): Observable<Avatar[]> {
    return this.http.post<Avatar[]>(
      `${this.apiBaseUrl}/v1/avatar/search`,
      typing
    );
  }

  checkCompletion(guid: string): Observable<Status> {
    return this.http.get<Status>(`${this.apiBaseUrl}/v1/avatar/completion/marketing/`+guid);
  }

  getAll(): Observable<Avatar[]> {
    return this.http.get<Avatar[]>(`${this.apiBaseUrl}/v1/avatar`);
  }

  get(guid: string): Observable<Avatar> {
    return this.http.get<Avatar>(`${this.apiBaseUrl}/v1/avatar/` + guid);
  }

  getAvailableAvatars(amount: number) : Observable<Avatar[]> {
    return this.http.get<Avatar[]>(`${this.apiBaseUrl}/v1/avatar/available?nb=`+amount);
  }

  isOwnedByCurrentUser(avatarGuid: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${this.apiBaseUrl}/v1/avatar/${avatarGuid}/owned`
    );
  }

  initiate(avatar: Avatar): Observable<Picture[]> {
    //console.log("post to "+`${this.apiBaseUrl}/v1/avatar/initiate`);
    return this.http.post<Picture[]>(
      `${this.apiBaseUrl}/v1/avatar/initiate`,
      avatar
    );
  }

  generateDreamboothPicture(ref: References): Observable<StableDiffusionDto[]> {
    //console.log("post to "+`${this.apiBaseUrl}/v1/avatar/generate`);
    return this.http.post<StableDiffusionDto[]>(
      `${this.apiBaseUrl}/v1/avatar/generate`,
      ref
    );
  }

  generateActionPicture(ref: References): Observable<StableDiffusionDto[]> {
    //console.log("post to "+`${this.apiBaseUrl}/v1/avatar/generate`);
    return this.http.post<StableDiffusionDto[]>(
      `${this.apiBaseUrl}/v1/avatar/generate`,
      ref
    );
  }

  create(ref: References): Observable<Avatar> {
    return this.http.post<Avatar>(`${this.apiBaseUrl}/v1/avatar`, ref);
  }

  update(avatar: Avatar): Observable<Avatar> {
    return this.http.put<Avatar>(`${this.apiBaseUrl}/v1/avatar`, avatar);
  }

  delete(guid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiBaseUrl}/v1/avatar/` + guid);
  }

  active(guid: string): Observable<void> {
    return this.http.get<void>(`${this.apiBaseUrl}/v1/avatar/active/` + guid);
  }

  publicAccess(guid: string): Observable<void> {
    return this.http.get<void>(`${this.apiBaseUrl}/v1/avatar/access/` + guid);
  }

  getAllActive(withLoras: boolean): Observable<Avatar[]> {
    return this.http.get<Avatar[]>(
      `${this.apiBaseUrl}/v1/avatar/active?w=` + withLoras
    );
  }

  randomizeAvatarStory(guid: string): Observable<Avatar> {

    return this.http.get<Avatar>(`${this.apiBaseUrl}/v1/avatar/random-history/${guid}`);

  }

  createMarketingImage(image: MarketingImage): Observable<Avatar> {
    return this.http.post<Avatar>(
      `${this.apiBaseUrl}/v1/avatar/marketing`,
      image
    );
  }

  getOwners(guid: string): Observable<string[]> {
    return this.http.get<string[]>(
      `${this.apiBaseUrl}/v1/avatar/owners/` + guid
    );
  }

  backgroundGeneration(guid: string): Observable<void> {
    return this.http.get<void>(`${this.apiBaseUrl}/v1/avatar/bg/` + guid);
  }

  regenProfilePicture(guid: string): Observable<void> {
    return this.http.get<void>(
      `${this.apiBaseUrl}/v1/avatar/bg/profilePicture/` + guid
    );
  }

  regenScenes(guid: string): Observable<void> {
    return this.http.get<void>(
      `${this.apiBaseUrl}/v1/avatar/bg/marketing/` + guid
    );
  }

  regenDreamboothSinglePosture(ref: References): Observable<Picture> {
    //console.log(ref);

    return this.http.post<Picture>(
      `${this.apiBaseUrl}/v1/avatar/bg/dreambooth/posture/individual`,
      ref
    );
  }

  regenDreamboothAllPostures(guid: string): Observable<void> {
    return this.http.get<void>(
      `${this.apiBaseUrl}/v1/avatar/bg/dreambooth/` + guid
    );
  }

  buy(guid: string): Observable<void> {
    return this.http.get<void>(`${this.apiBaseUrl}/v1/avatar/buy/` + guid);
  }

  downloadAllDreamboothZip(guid):Observable<Blob> {
    return this.http.get(`${this.apiBaseUrl}/v1/avatar/${guid}/downloads`, { responseType: 'blob' });

  }

}
