import { Component, ViewChild } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { InvoiceService } from '../services/invoice.service';
import { Invoice } from '../shared/entity/invoice';

@Component({
  selector: 'app-invoice',
  standalone: true,
  imports: [ MatFormFieldModule, MatInputModule, MatTableModule, MatSortModule, MatPaginatorModule ],
  templateUrl: './invoice.component.html',
  styleUrl: './invoice.component.scss'
})
export class InvoiceComponent {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

//  private invoices: Invoice[];
  displayedColumns: string[] = ['invoice_date', 'label', 'period_start','period_end','charge_amount','paid'];

  dataSource: MatTableDataSource<Invoice>;

  constructor(
    private invoiceService: InvoiceService
  ) { }

  ngOnInit() {

    this.invoiceService.getAll().subscribe((invoices) => {

      this.dataSource = new MatTableDataSource(invoices);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    })
  }

  ngAfterViewInit() {

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
