import { CUSTOM_ELEMENTS_SCHEMA, Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardHeader, MatCardModule } from '@angular/material/card';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Avatar } from '../../shared/entity/avatar';
import { NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';

export interface DialogData {
  avatar: Avatar;
}

@Component({
  selector: 'app-edit-avatar',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCardModule,
    NgIf,
    MatSliderModule,
    MatSelectModule
  ],
  templateUrl: './dialog-edit-avatar.component.html',
  styleUrl: './dialog-edit-avatar.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DialogEditAvatarComponent {

  form: FormGroup;

  title: string;
  portrait_image: string;
  guid: string; //pictureGuid

  temp_avatar: Avatar;
  minAge: number;
  maxAge: number;
  bloodTypes: string[] = ['A', 'B', 'AB', 'O'];
  zodiacSigns: string[] = [
    'Capricorn',
    'Aquarius',
    'Pisces',
    'Aries',
    'Taurus',
    'Gemini',
    'Cancer',
    'Leo',
    'Virgo',
    'Libra',
    'Scorpio',
    'Sagittarius',
  ];
  tempers: string[] = [
    'short', // Easily angered
    'hotheaded', // Becoming angry quickly and intensely
    'irascible', // Easily angered
    'volatile', // Likely to erupt suddenly and violently
    'cantankerous', // Bad-tempered and argumentative, especially in an old person
    'sulky', // Silently bad-tempered
    'spirited', // Full of life and energy
    'passionate', // Having strong feelings and beliefs
    'feisty', // Having a lively and energetic spirit, especially when defending oneself
    'steady', // Not easily upset
    'even-tempered', // Not easily angered
    'mercurial', // Likely to change quickly
    'high-strung', // Easily excited or nervous

    // Additional adjectives
    'impetuous', // Acting or done without forethought
    'placid', // Calm and peaceful
    'stoic', // Showing little or no emotion
    'combustible', // Likely to become angry suddenly
    'choleric', // Easily angered and bad-tempered (used in old-fashioned personality typing)
    'phlegmatic', // Calm and unexcitable (used in old-fashioned personality typing)
    'forgiving', // Able to forgive others easily
    'resentful', // Feeling bitterness or indignation
    'vindictive', // Desiring revenge
    'assertive', // Stating one's needs or beliefs confidently
    'abrasive', // Harsh or unpleasant in manner
    'agreeable', // Willing to agree with others
    'relenting', // Giving way to pressure or persuasion
    'adamant', // Firmly refusing to change one's mind
    'impervious', // Not affected by something
  ];
  constructor(
    public dialogRef: MatDialogRef<DialogEditAvatarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {

    this.title = 'FINE TUNE YOUR AVATAR';
    this.portrait_image = this.data.avatar.data.marketing_profile_picture_thumb_uri;


    this.temp_avatar = this.data.avatar;

    this.minAge = Math.round(this.temp_avatar.data.age / 10) * 10;
    this.maxAge = this.minAge + 9;


    this.form = this.formBuilder.group({
      family_name: [this.temp_avatar.data.family_name, Validators.required],
      given_name: [this.temp_avatar.data.given_name, Validators.required],
      marketing_age: [this.minAge + 5, Validators.required],
      marketing_height: [this.temp_avatar.data.marketing_height, Validators.required],
      marketing_hair_color: [
        this.capitalizeFirstLetter(this.temp_avatar.data.hair_color),
        Validators.required,
      ],
      marketing_eye_color: [
        this.capitalizeFirstLetter(this.temp_avatar.data.eye_color),
        Validators.required,
      ],
      marketing_zodiac_sign: ['Aquarius', Validators.required],
      marketing_blood_type: ['A', Validators.required],
      marketing_temper: ['spirited', Validators.required],
      marketing_history: [
        "Jean wasn't born under the spotlight. He traded a predictable life for a one-way bus ticket to the city of dreams. With a worn suitcase full of ambition and a heart brimming with talent (be it singing, acting, or something else entirely), he left behind the familiar comfort for the exhilarating unknown, determined to turn his passion for stardom into reality.",
        Validators.required,
      ],
    });


    if (this.temp_avatar.data.on_sale)
      this.form.addControl( 'sales_price',  new FormControl(this.temp_avatar.data.sales_price, Validators.required));

  }

  formatAge(value) {
    return value;
  }

  formatSize(value) {
    return value / 100 + 'm';
  }

  onCancel(): void {

      this.dialogRef.close(null);
  }

  submit() {

    if (this.form.valid) {

      this.temp_avatar.data.family_name = this.form.controls.family_name.value;
      this.temp_avatar.data.given_name = this.form.controls.given_name.value;
      this.temp_avatar.data.marketing_age = this.form.controls.marketing_age.value;
      this.temp_avatar.data.marketing_height = this.form.controls.marketing_height.value;
      this.temp_avatar.data.marketing_hair_color = this.form.controls.marketing_hair_color.value;
      this.temp_avatar.data.marketing_eye_color = this.form.controls.marketing_eye_color.value;
      this.temp_avatar.data.marketing_zodiac_sign = this.form.controls.marketing_zodiac_sign.value;
      this.temp_avatar.data.marketing_blood_type = this.form.controls.marketing_blood_type.value;
      this.temp_avatar.data.marketing_temper = this.form.controls.marketing_temper.value;
      this.temp_avatar.data.marketing_history = this.form.controls.marketing_history.value;

      if (this.temp_avatar.data.on_sale)
        this.temp_avatar.data.sales_price = this.form.controls.sales_price.value;

      this.dialogRef.close(this.temp_avatar);

    }

}

capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);
}

}
