import { NgIf } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AvatarService } from '../services/avatar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Avatar } from '../shared/entity/avatar';
import { AccountService } from '../services/account.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogPurchaseAvatarComponent } from '../shared/component/dialog-purchase-avatar/dialog-purchase-avatar.component';
import { DialogImageDreamboothProfileComponent } from './dialog-image-dreambooth-profile/dialog-image-dreambooth-profile.component';

export interface Tile {
  imgThumb: string,
  imgFull: string,
  loaded: boolean;
  start?: number;
  sec?: number;
  decilesec?: number;
  action?: string;
}

@Component({
  selector: 'app-avatar-profile',
  standalone: true,
  imports: [
    MatIconModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatCardModule,
    MatButton,
    NgIf,
    DialogImageDreamboothProfileComponent
  ],
  templateUrl: './avatar-profile.component.html',
  styleUrl: './avatar-profile.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AvatarProfileComponent {
  avatar: Avatar;
  dreamboothAvailable: boolean = false;
  dreamboothTiles: Tile[] = [];
  admin : boolean = false;

  constructor(
    private avatarService: AvatarService,
    public accountService: AccountService,
    private router : Router,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {

    this.admin = this.accountService.isAdmin();

    this.route.paramMap.subscribe((params) => {
      this.avatarService.get(params.get('avatar_guid')).subscribe((avatar) => {
        this.avatar = avatar;
        //console.log(avatar);

        if (avatar.data.lora_pics) {
          // console.log(Object.keys(avatar.data.lora_pics));

          let keys = Object.keys(avatar.data.lora_pics);
          let count = 0;

          keys.forEach((key) => {
            this.addDreamboothCase(avatar.data.lora_pics[key].thumb_uri, avatar.data.lora_pics[key].img_uri, key);
            if (count == 10) {
              //only once !
              this.dreamboothAvailable = true;
            }
            count++;
          });
        }


      });
    });
  }

  purchase() {

    const dialogRef = this.dialog.open(DialogPurchaseAvatarComponent, {
      data: { avatar: this.avatar },
      height: '350px',
      width: '600px',
      closeOnNavigation: false,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((res: boolean) => {

      //console.log(res);

      if (res) {
        this.avatarService.get(this.avatar.metadata.guid).subscribe((av)=> {
          this.avatar = av;
        })
    }

    });

  }

  use() {

      this.router.navigate(['/nzym-studio'], {queryParams: {aguid:this.avatar.metadata.guid}});

  }

  addDreamboothCase(picThumnUri: string, picFullUri: string, pic_action: string) {
    let tile = {
      imgThumb: picThumnUri,
      imgFull: picFullUri,
      loaded: true,
      start: new Date().getTime(),
      sec: 0,
      decilesec: 0,
      action: pic_action,
    };
    this.dreamboothTiles.unshift(tile);
  }

  openDreamboothDialog(tile): void {
    const dialogRef = this.dialog.open(DialogImageDreamboothProfileComponent, {
      data: { name: 'IMAGE', pic: tile, guid: this.avatar.metadata.guid },
      height: '80%',
      width: '80%',
      maxWidth: '1000px',
      maxHeight: '600px',
    });

    dialogRef.afterClosed().subscribe((result: Tile) => {
      //this is a tile
      //we have to write the img in the tiles array, looking for the reference action

      //could be undefined
      if (result) {
        for (let i = 0; i < this.dreamboothTiles.length; i++) {
          if (this.dreamboothTiles[i].action == result.action) {
            this.dreamboothTiles[i].imgThumb = result.imgThumb;
            this.dreamboothTiles[i].imgFull = result.imgFull;
            break;
          }
        }
      }
    });
  }

  downloads() {

    this.avatarService.downloadAllDreamboothZip(this.avatar.metadata.guid).subscribe((blob) => {
      // Create a link element
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'nzym-dreambooth-'+this.avatar.data.given_name+'-'+this.avatar.data.family_name+'-'+ new Date().getTime() + '.zip'; // Set the download filename

      // Append link to the body, click it, and then remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

}
