import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipGrid, MatChipsModule } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PictureService } from '../services/picture.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatCardModule } from '@angular/material/card';
import { RandomImageService } from '../services/random-image.service';
import { NgIf } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { CommunityService } from '../services/community.service';
import { DialogCommunityWallComponent } from './dialog-community-image/dialog-community-wall-image.component';
import { CommunityPicture } from '../shared/entity/community-picture';

@Component({
  selector: 'app-favorites',
  standalone: true,
  imports: [
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatChipGrid,
    MatChipsModule,
    MatTooltipModule,
    MatCardModule,
    MatProgressSpinner,
    NgIf,
  ],
  templateUrl: './community.component.html',
  styleUrl: './community.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommunityComponent {
  items: CommunityPicture[];
  title: string;
  background_img: string;
  loaded: boolean;

  //*********************
  //         INFINITE SCROLL

  scroll = (e) => this.onScroll(e);
  last_id: number; // for infinite scroll
  isLoading: boolean; // for infinite scroll

  //*********************

  constructor(
    private communityService: CommunityService,
    private pictureService: PictureService,
    public dialog: MatDialog,
    private _snackbar: MatSnackBar,
    private randomImageService: RandomImageService
  ) {
    this.items = [];
  }

  ngOnInit() {

    //console.log("PROOOOOOOUT");

    this.loaded = false;

    this.background_img = this.randomImageService.get();

    //nb of pîctures preloaded
    let initLoad = 40;

    if (window.innerWidth > 2500)
      initLoad = 200;

    this.communityService
      .getPicturePerPage(initLoad, null)
      .subscribe((pictures) => {
        pictures.forEach((picture) => {
          let item: CommunityPicture = {};
          item = picture;
          this.items.push(item);
        });

        if (pictures && pictures.length > 0)
          this.last_id = pictures[pictures.length - 1].id;

        this.loaded = true;
      });

    this.isLoading = false;

    document.addEventListener('scroll', this.scroll, true);
  }

  ngOnDestroy() {
    document.removeEventListener('scroll', this.scroll, true);
  }

  onScroll(event) {
    //console.log('Scroll !');

    let scrollHeight = event.target.scrollHeight;
    let scrollTop = event.target.scrollTop;
    let ratio = scrollTop / scrollHeight;

    if (ratio >= 0.3 && !this.isLoading) {
      //console.log('Load Next !');
      if (window.innerWidth > 2500) this.loadItems(50);
      else this.loadItems(20);
    }
  }

  loadItems(step) {
    this.isLoading = true;
    this.communityService
      .getPicturePerPage(step, this.last_id)
      .subscribe((pictures) => {
        pictures.forEach((picture) => {
          let item: CommunityPicture = {};
          item = picture;
          this.items.push(item);
        });

        //no more pics
        if (pictures.length == 0) {
          return;
        }

        if (pictures && pictures.length > 0)
          this.last_id = pictures[pictures.length - 1].id;

        this.isLoading = false;
      });
  }

  open(index: number) {
    let dataConfig = {
      data: { name: 'IMAGE', images: this.items, index: index },
      minWidth: '400px',
      minHeight: '500px',
      maxWidth: '90vw',
      maxHeight: '97vh',
      autoFocus: false,
    };

    const dialogRef = this.dialog.open(
      DialogCommunityWallComponent,
      dataConfig
    );
  }

  singleDownload(index: number) {
    this.pictureService
      .downloadImage(this.items[index].img_uri)
      .subscribe((blob) => {
        // Create a link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'nzym-img-' + new Date().getTime() + '.jpg'; // Set the download filename

        // Append link to the body, click it, and then remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }
}
