import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardModule } from '@angular/material/card';

import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PictureService } from '../../services/picture.service';

import { Picture } from '../../shared/entity/picture';

import { NgIf } from '@angular/common';
import { Inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogCustomLightboxComponent } from '../custom-lightbox/dialog-custom-lightbox.component';
import { CreditService } from '../../services/credit.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Favorite } from '../../shared/entity/favorite';
import { FavoriteService } from '../../services/favorite.service';
import { ActionMessage } from '../../shared/entity/action-message';
import { ActionCode } from '../../shared/enum/action-code';
import { ImagePrompt } from '../../shared/entity/image-prompt';

export interface DialogInpaintData {}

@Component({
  selector: 'app-dialog-naming',
  standalone: true,
  imports: [
    MatButtonModule,
    MatCardModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCard,
    MatProgressSpinnerModule,
    MatIconModule,
    MatTooltipModule,
    NgIf,
  ],
  templateUrl: './dialog-inpaint.component.html',
  styleUrl: './dialog-inpaint.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DialogInpaintComponent {
  improve_mode: boolean;
  original_uri: string;
  add_prompt: string;
  source_pic: Picture;
  base64Mask: string;
  nb_pics: number;
  inpainted_pics: Picture[];
  loaded: boolean[];
  picsHeight: number;
  picsWidth: number;
  calcHeight: string;
  img_prompts: ImagePrompt[]; //actually only 1 in the array max

  @ViewChild('original', { static: false }) originalPic: ElementRef;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogInpaintComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public pictureService: PictureService,
    public creditService: CreditService,
    private changeDetectorRef: ChangeDetectorRef,
    private _snackbar: MatSnackBar,
    private favoriteService: FavoriteService
  ) {

    //console.log(data);

    this.img_prompts = data.img_prompts;
    this.improve_mode = data.improve_mode;
    this.add_prompt = data.add_prompt;
    this.source_pic = data.pic;
    this.base64Mask = data.mask;
    this.nb_pics = data.nb_pics;
    this.inpainted_pics = [];
    this.original_uri = data.pic.img_uri;
    this.loaded = [];

    //console.log('CALC=' + this.calcHeight);
  }

  ngOnInit(): void {
    for (let i = 0; i < this.nb_pics; i++) {
      let blank: Picture = new Picture();
      blank.id = i;
      blank.img_uri = '../../../assets/img/trans-picture.png';
      this.inpainted_pics.push(blank);
      this.loaded.push(false);
    }
    this.changeDetectorRef.detectChanges();
    let count = 0;

    this.generateInpaints(count);
  }

  onLoad() {
    this.picsHeight = this.originalPic.nativeElement.naturalHeight;
    this.picsWidth = this.originalPic.nativeElement.naturalWidth;

    this.calcHeight = this.originalPic.nativeElement.clientHeight + 'px';
  }

  generateInpaints(count: number) {

    //we deep copy to avoid the alteration of the UI which needs the data: jpg intro in the base64
    if (this.img_prompts && this.img_prompts.length>0) {

    this.img_prompts = JSON.parse(JSON.stringify(this.img_prompts));
    this.img_prompts[0].base64 = this.img_prompts[0].base64.replace('data:', '').replace(/^.+,/, '');

    }

    this.pictureService
      .inpaint(
        this.source_pic.guid,
        this.base64Mask,
        this.add_prompt,
        this.improve_mode,
        this.img_prompts
      )
      .subscribe((res) => {
        this.creditService.update();

        this._snackbar.open('Fix applied !', '', {
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
          duration: 1000,
          panelClass: ['green-snackbar'],
        });

        //one by one
        this.inpainted_pics[count].img_uri = res.img_uri;
        this.inpainted_pics[count].favorite = res.favorite;
        this.inpainted_pics[count].guid = res.guid;
        this.loaded[count] = true;

        count++;

        if (count < this.nb_pics) {
          this.generateInpaints(count);
        }
      });
  }

  openLightbox(index) {
    index++;

    const dialogRef = this.dialog.open(DialogCustomLightboxComponent, {
      data: {
        original: this.source_pic,
        pics: this.inpainted_pics,
        start_index: index,
      },
      width: this.picsWidth * 0.7 + 'px',
      maxWidth: '1024px',
      maxHeight: '1200px',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(() => {});
  }

  onCancel(): void {
    let msg = new ActionMessage();

    msg.action_code = ActionCode.NONE;

     this.dialogRef.close(msg);
  }

  favorite(index: number) {
    let pic = this.inpainted_pics[index];

    let fav: Favorite = new Favorite();
    fav.img_guid = pic.guid;

    let isAlreadyFav = pic.favorite;

    if (isAlreadyFav) {
      //Case remove from fav
      this.favoriteService.remove(fav).subscribe((result) => {
        if (result.done) {
          pic.favorite = false;

          this._snackbar.open('Removed from favourites !', '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 1000,
            panelClass: ['green-snackbar'],
          });
        } else {
          this._snackbar.open('Not removed, it was not a fav !', '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 1000,
            panelClass: ['red-snackbar'],
          });
        }
      });
    } else {
      //Case add to Favorite
      this.favoriteService.add(fav).subscribe((result) => {
        if (result.done) {
          pic.favorite = true;

          this._snackbar.open('Added to favourites !', '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 1000,
            panelClass: ['green-snackbar'],
          });
        } else {
          this._snackbar.open('Already a fav !', '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 1000,
            panelClass: ['red-snackbar'],
          });
        }
      });
    }
  }

  fix(index: number) {

    let msg = new ActionMessage();

    let pic = this.inpainted_pics[index];

    msg.img_guid = pic.guid;
    msg.action_code = ActionCode.FIX;

    //CLOSE AND ASK MAIN PAGE TO GENERATE A NEW ONE WITH THE SAME PROMPT
    this.dialogRef.close(msg);

  }

}
