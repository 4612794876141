import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Subscription } from '../shared/entity/subscription';
import { OrderSubscription } from '../shared/entity/order-subscription';


@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  apiBaseUrl : string;

  constructor(private http: HttpClient) {
    this.apiBaseUrl =  environment.apiUrl;
  }

  get(): Observable<Subscription>  {
    return this.http.get<Subscription>(`${this.apiBaseUrl}/v1/subscription`);
  }

  adminGet(guid: string): Observable<Subscription>  {
    return this.http.get<Subscription>(`${this.apiBaseUrl}/v1/subscription/admin/`+guid);
  }

  getRemaining(): Observable<Subscription>  {
    return this.http.get<Subscription>(`${this.apiBaseUrl}/v1/subscription/remaining`);
  }

  //only for admin
  getRemainingForUser(guid:string): Observable<Subscription>  {
    return this.http.get<Subscription>(`${this.apiBaseUrl}/v1/subscription/remaining/${guid}`);
  }

  getFuture(): Observable<Subscription>  {
    return this.http.get<Subscription>(`${this.apiBaseUrl}/v1/subscription/future`);
  }

  getAllProducts() : Observable<Subscription[]> {
    return this.http.get<Subscription[]>(`${this.apiBaseUrl}/v1/subscription/product/all`);
  }

  getProduct(type) : Observable<Subscription> {
    return this.http.get<Subscription>(`${this.apiBaseUrl}/v1/subscription/product/`+type);
  }

  buy(type) : Observable<OrderSubscription> {
    return this.http.get<OrderSubscription>(`${this.apiBaseUrl}/v1/subscription/buy?type=`+type);
  }

  cancelSub() : Observable<void>  {
    return this.http.get<void>(`${this.apiBaseUrl}/v1/subscription/cancel`);
  }

}
