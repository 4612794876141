<div class="main">
  <div class="image-description">
    <div class="text-title">
      <h3> <mat-icon>edit_note</mat-icon> PROMPT </h3>
    </div>
    <div class="text"> {{ data.prompt }}</div>
  </div>
  <div class="image-size">
    <div class="text-title">
      <h3> <mat-icon>photo_size_select_large</mat-icon>  REAL SIZE (pixels)</h3>
    </div>
    <div class="text"> {{ data.realx }} x {{ data.realy }} </div>
  </div>
  <div class="image-source">
    <div class="text-title">
      <h3> <mat-icon>network_intelligence_update</mat-icon>  SOURCE </h3>
    </div>
    <div class="text"> {{ data.source }} </div>
  </div>
</div>

