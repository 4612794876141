<div class="container-pop"#containerpop>
   <img class="image" src="{{ inpaintedPics[index].img_uri }}" (click)="onCancel()">

  <div class="text">
    {{ text[index] }}
  </div>
</div>

<div [class]=" (index  != 0) ? 'overlay-left' : 'not-visible'" (click)="goLeft()"><mat-icon>arrow_back</mat-icon></div>
<div [class]=" (index  != 2) ? 'overlay-right' : 'not-visible'" (click)="goRight()"><mat-icon>arrow_forward</mat-icon></div>
