<div *ngIf="!loaded" class="spinner-container"><mat-spinner></mat-spinner></div>

<div *ngIf="loaded" class="cards">
  <mat-card class="mat-elevation-z0 single-card settings-card">
    <mat-cart-header class="mat-card-header form-box-title">
      <mat-card-title><span class="card-title"> {{ title.toUpperCase() }} </span></mat-card-title>
      <mat-card-title><span class="card-subtitle"> {{ subtitle }} </span></mat-card-title>
    </mat-cart-header>
    <mat-card-content>

      <form [formGroup]="form">
        <div class="container">

          <div class="container-element">
            <div class="full-form">

              <mat-label class="setting">
                <div class="section-title">
                  <div class="text"><mat-icon class="icon">wc</mat-icon></div>
                  <div class="text">GENDER</div>
                </div>
              </mat-label>
              <mat-radio-group aria-label="Select an option" formControlName="gender">
                <mat-radio-button labelPosition="before" value="male" [checked]='true'
                  (change)="changeGenderSelected($event)">MALE</mat-radio-button>
                <mat-radio-button value="female" (change)="changeGenderSelected($event)">FEMALE</mat-radio-button>
              </mat-radio-group>

              <div class="separator"></div>


              <mat-label class="setting">
                <div class="section-title">
                  <div class="text"><mat-icon class="icon">globe</mat-icon></div>
                  <div class="text">ETHNICITY</div>
                </div>
              </mat-label>

              <mat-chip-listbox aria-label="Ethnicity" [selectable]="true" [multiple]="false">
                <mat-chip-option value="caucasian" (selectionChange)="changeEthnicitySelected($event)"> CAUCASIAN
                </mat-chip-option>
                <mat-chip-option value="black" (selectionChange)="changeEthnicitySelected($event)"> AFRO
                </mat-chip-option>
                <mat-chip-option value="asian" (selectionChange)="changeEthnicitySelected($event)"> ASIAN
                </mat-chip-option>
                <mat-chip-option value="latino" (selectionChange)="changeEthnicitySelected($event)"> LATINO
                </mat-chip-option>
              </mat-chip-listbox>

              <div class="separator"></div>

              <mat-label class="setting">
                <div class="section-title">
                  <div class="text"><mat-icon class="icon">flag</mat-icon></div>
                  <div class="text">NATIONALITY </div>
                </div>
              </mat-label>
              <mat-form-field>
                <mat-label>Nationality</mat-label>
                <input type="text" matInput formControlName="nationality"
                  [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                  @for (nationality of filteredOptions | async ; track nationality) {
                  <mat-option [value]="nationality">{{nationality}}</mat-option>
                  }
                </mat-autocomplete>
              </mat-form-field>

              <div class="separator"></div>

              <mat-label class="setting">
                <div class="section-title">
                  <div class="text"><mat-icon class="icon">style</mat-icon></div>
                  <div class="text">HAIR COLOR </div>
                </div>
              </mat-label>
              <div class="hair-color svg-choose-box">
                <div (click)="changeHairColorSelected('blond')" class="round svg blond">
                  <div class="svg-content" [class]=" isHairBlond() ? 'visible':'not-visible'">
                    <svg viewBox="-2 -3 30 30" focusable="false" aria-hidden="true">
                      <path class="svg" fill="none" stroke="currentColor" d="M1.73,12.91 8.1,19.28 22.79,4.59"></path>
                    </svg>
                  </div>
                </div>
                <div (click)="changeHairColorSelected('brown')" class="round svg brown">
                  <div class="svg-content" [class]=" isHairBrown() ? 'visible':'not-visible'">
                    <svg viewBox="-2 -3 30 30" focusable="false" aria-hidden="true">
                      <path class="svg" fill="none" stroke="currentColor" d="M1.73,12.91 8.1,19.28 22.79,4.59"></path>
                    </svg>
                  </div>
                </div>
                <div (click)="changeHairColorSelected('black')" class="round svg black">
                  <div class="svg-content" [class]=" isHairBlack() ? 'visible':'not-visible'">
                    <svg viewBox="-2 -3 30 30" focusable="false" aria-hidden="true">
                      <path class="svg" fill="none" stroke="currentColor" d="M1.73,12.91 8.1,19.28 22.79,4.59"></path>
                    </svg>
                  </div>
                </div>
                <div (click)="changeHairColorSelected('red')" class="round svg red">
                  <div class="svg-content" [class]=" isHairRed() ? 'visible':'not-visible'">
                    <svg viewBox="-2 -3 30 30" focusable="false" aria-hidden="true">
                      <path class="svg" fill="none" stroke="currentColor" d="M1.73,12.91 8.1,19.28 22.79,4.59"></path>
                    </svg>
                  </div>
                </div>
                <div (click)="changeHairColorSelected('blue')" class="round svg blue">
                  <div class="svg-content" [class]=" isHairBlue() ? 'visible':'not-visible'">
                    <svg viewBox="-2 -3 30 30" focusable="false" aria-hidden="true">
                      <path class="svg" fill="none" stroke="currentColor" d="M1.73,12.91 8.1,19.28 22.79,4.59"></path>
                    </svg>
                  </div>
                </div>
                <div (click)="changeHairColorSelected('purple')" class="round svg purple">
                  <div class="svg-content" [class]=" isHairPurple() ? 'visible':'not-visible'">
                    <svg viewBox="-2 -3 30 30" focusable="false" aria-hidden="true">
                      <path class="svg" fill="none" stroke="currentColor" d="M1.73,12.91 8.1,19.28 22.79,4.59"></path>
                    </svg>
                  </div>
                </div>
                <div (click)="changeHairColorSelected('white')" class="round svg white">
                  <div class="svg-content" [class]=" isHairWhite() ? 'visible':'not-visible'">
                    <svg viewBox="-2 -3 30 30" focusable="false" aria-hidden="true">
                      <path class="svg" fill="none" stroke="currentColor" d="M1.73,12.91 8.1,19.28 22.79,4.59"></path>
                    </svg>
                  </div>
                </div>
              </div>

              <div class="separator"></div>

              <mat-label class="setting">
                <div class="section-title">
                  <div class="text"><mat-icon class="icon">face</mat-icon></div>
                  <div class="text">DEFAULT HAIR STYLE </div>
                </div>
              </mat-label>
              <div class="hairstyle-container">
                <div class="hairstyle-image"> <img src="{{ hairstyle_image }}" class="format"></div>
                <div class="hairstyle-action"><button mat-raised-button color="primary" (click)="openHairstyle()">HAIRSTYLE PICKER</button></div>
              </div>

           <!--   <mat-chip-listbox aria-label="Hair Style" [selectable]="true" [multiple]="false">
                <mat-chip-option value="long" (selectionChange)="changeHairStyleSelected($event)"> Long
                </mat-chip-option>
                <mat-chip-option value="medium" (selectionChange)="changeHairStyleSelected($event)"> Medium
                </mat-chip-option>
                <mat-chip-option value="short" (selectionChange)="changeHairStyleSelected($event)"> Short
                </mat-chip-option>
                <mat-chip-option value="spiky" (selectionChange)="changeHairStyleSelected($event)"> Spiky
                </mat-chip-option>
              </mat-chip-listbox>
-->
              <div class="separator"></div>


              <mat-label class="setting">
                <div class="section-title">
                  <div class="text"><mat-icon class="icon">eye_tracking</mat-icon></div>
                  <div class="text">EYE COLOR </div>
                </div>
              </mat-label>
              <div class="eye-color svg-choose-box">
                <div (click)="changeEyeColorSelected('blue')" class="round svg blue">
                  <div class="svg-content" [class]=" isEyeBlue() ? 'visible':'not-visible'">
                    <svg viewBox="-2 -3 30 30" focusable="false" aria-hidden="true">
                      <path class="svg" fill="none" stroke="currentColor" d="M1.73,12.91 8.1,19.28 22.79,4.59"></path>
                    </svg>
                  </div>
                </div>
                <div (click)="changeEyeColorSelected('brown')" class="round svg brown">
                  <div class="svg-content" [class]=" isEyeBrown() ? 'visible':'not-visible'">
                    <svg viewBox="-2 -3 30 30" focusable="false" aria-hidden="true">
                      <path class="svg" fill="none" stroke="currentColor" d="M1.73,12.91 8.1,19.28 22.79,4.59"></path>
                    </svg>
                  </div>
                </div>
                <div (click)="changeEyeColorSelected('green')" class="round svg green">
                  <div class="svg-content" [class]=" isEyeGreen() ? 'visible':'not-visible'">
                    <svg viewBox="-2 -3 30 30" focusable="false" aria-hidden="true">
                      <path class="svg" fill="none" stroke="currentColor" d="M1.73,12.91 8.1,19.28 22.79,4.59"></path>
                    </svg>
                  </div>
                </div>


              </div>

              <div class="separator"></div>

              <mat-label class="setting">
                <div class="section-title">
                  <div class="text"><mat-icon class="icon">schedule</mat-icon></div>
                  <div class="text">AGE RANGE </div>
                </div>
              </mat-label>
              <mat-slider min="20" max="50" step="10" [displayWith]="formatAge" discrete class="avatar-age">
                <input matSliderThumb formControlName="age">
              </mat-slider>

              <div class="button-container">
                <div class="button-container-element">
                  <div class="inside-button">

                    <button mat-raised-button color="primary" type="submit" class="button"
                      [disabled]="(!form.valid || inprocess)" (click)="initiate()">


                      <div class="button-text">
                        <span class="action-text">
                          {{ this.generateLabel }}
                          </span>
                        <div class="amount">
                        <div class="cost">{{ cost_preview }} </div>
                        <div class="container-icon">
                          <mat-icon class="icon"> award_star</mat-icon>
                        </div>
                        </div>
                      </div>

                    </button>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </form>
    </mat-card-content>
  </mat-card>

  <mat-card class="mat-elevation-z0 single-card pictures-card">


    <mat-card-content class="pictures">
      <div class="pics-container">

        <div class="genbox">
          <div class="full-overlay">
            <div class="genbox-overlay background" [class]="!inprocess ? 'not-visible' : 'spinner-container'">
              <mat-spinner></mat-spinner>
            </div>
            <!--  <div class="genbox-overlay" [class]="!inprocess ? 'not-visible' : 'chrono-visible'">{{ processed_tile?.sec}}.{{
              processed_tile?.decilesec}} s </div>-->
            <div class="keep">
              <button mat-raised-button color="accent" class="button" [disabled]="!gen_done || inprocess"
                (click)="openDialog()"> KEEP {{ pronoun }} & CREATE AVATAR !</button>
            </div>
          </div>
          <img width="100%" src='{{ this.tile.img }}'>
        </div>
        <div class="carroussel">



          <div class="thumb">
            <img class="thumb-img" src='{{thumbs[0].img}}' (click)="setMain(0)">
            <div class="thumb-proposal"> PROPOSAL #1</div>
          </div>
          <div class="thumb">
            <img class="thumb-img" src='{{thumbs[1].img}}' (click)="setMain(1)">
            <div class="thumb-proposal"> PROPOSAL #2</div>
          </div>
          <div class="thumb">
            <img class="thumb-img" src='{{thumbs[2].img}}' (click)="setMain(2)">
            <div class="thumb-proposal"> PROPOSAL #3</div>
          </div>
          <div class="thumb">
            <img class="thumb-img" src='{{thumbs[3].img}}' (click)="setMain(3)">
            <div class="thumb-proposal"> PROPOSAL #4</div>
          </div>
        </div>

      </div>
    </mat-card-content>
  </mat-card>

</div>
