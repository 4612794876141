<div class="dialog">
  <div [class]="horizontal_format ? 'img-container-horizontal' : 'img-container-vertical'">
    <div [class]="horizontal_format ? 'image-display-horizontal' : 'image-display-vertical'">
      <div [class]="img_overlay ? 'overlay' : 'not-visible'" #overlayEdit>

        <div class="image-features">
          <div class="section ">
            <!--            <div class="section-head">ACTION</div>-->
            <div class="section-body">
              <div class="button">
                <button mat-button color="white" class="nzym-icon-button" (click)="download()" matTooltip="[D] Download"
                  [matTooltipPosition]="'right'">
                  <mat-icon>download</mat-icon></button>
              </div>
            </div>
          </div>
        </div>


      </div>

      <div [class]="img_overlay ? 'user-overlay ' : 'not-visible'" #overlayEdit>

      </div>

      <div [class]="img_overlay ? 'prompt-overlay ' : 'not-visible'" #overlayEdit>

        <div class="bottom-description">
          <div class="image-creator">
            <img class="image-profile" src="{{ pics[index].creator_img_uri }}" />
            <div class="image-creator"> {{ pics[index].creator_nickname }}</div>
          </div>

          <div class="image-information">
            <div class="image-prompt"> PROMPT: " {{ pics[index].raw_user_prompt }} "</div>
            <!--<div class="setting"> {{ pics[index].creativity }}</div>-->
            <div [class]="pics[index].avatar_name ? 'avatar': 'not-visible'"> With avatar {{ pics[index].avatar_name }}
            </div>
            <div [class]="pics[index].scene_name ? 'scene': 'not-visible'"> With scene {{ pics[index].scene_name }}
            </div>
          </div>
        </div>

      </div>

      <img class="full-image" src="{{ pics[index].img_uri }}" #imgEdit (load)="onLoadImage()">
    </div>

  </div>

