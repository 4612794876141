import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OperationsCostService {

  public cost_simple:number = 1;
  public cost_iprompt:number = 1;
  public cost_inpaint: number = 2;
  public cost_zoomout:number = 2;
  public cost_vary:number = 1;
  public cost_upscale2:number = 8;
  public cost_upscale4:number = 16;
  public cost_full_avatar_generation:number = 50;

  constructor() {}

}
