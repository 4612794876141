<mat-card class="mat-elevation-z0 single-card settings-card">
  <mat-cart-header class="mat-card-header form-box-title">
    <mat-card-title> {{ title }} </mat-card-title>
  </mat-cart-header>
  <mat-card-content>

    <div class="container">
      <div class="container-element">
        <form [formGroup]="form" class="form-naming">
          <mat-form-field class="form-element">
            <input matInput type="string" placeholder="Filter (Optional)" (input)="onChange($event)"
              formControlName="filter">
          </mat-form-field>[
        </form>
      </div>
    </div>


    <div class="container">
      @for (scene of filteredScenes; track scene.guid) {
      <div class="container-element" (click)="selectScene(scene)">
        <div class="scene-box">
          <img src="{{ scene.thumb_uri}}" class="scene-box-pic">

            <div class="scene-creds">
            {{ scene.name}}
          </div>

        </div>
      </div>
      }
    </div>


  </mat-card-content>
</mat-card>

