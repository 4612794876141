import { CUSTOM_ELEMENTS_SCHEMA, Component, Inject } from '@angular/core';
import { Picture } from '../shared/entity/picture';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipGrid, MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { AvatarService } from '../services/avatar.service';
import { Avatar } from '../shared/entity/avatar';
import { NgIf } from '@angular/common';
import { Router } from '@angular/router';
import { AccountService } from '../services/account.service';
import { DialogPurchaseAvatarComponent } from '../shared/component/dialog-purchase-avatar/dialog-purchase-avatar.component';
import { MatDialog } from '@angular/material/dialog';
import { RandomImageService } from '../services/random-image.service';

interface Item {
  pic?: Picture;
  selected?: boolean;
}

@Component({
  selector: 'app-favorites',
  standalone: true,
  imports: [
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatChipGrid,
    MatChipsModule,
    MatTooltipModule,
    MatCardModule,
    NgIf,
  ],
  templateUrl: './avatar-gallery.component.html',
  styleUrl: './avatar-gallery.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AvatarGalleryComponent {
  avatars: Avatar[];
  filteredAvatarsText: Avatar[];
  filteredAvatarsFinal: Avatar[];
  title: string;
  filterField: string = '';

  filterFreeToggle: boolean;
  filterPrivateToggle: boolean;
  filterSaleToggle: boolean;
  filterPurchasedToggle: boolean;

  background_img: string;

  constructor(
    private avatarService: AvatarService,
    public accountService: AccountService,
    public dialog: MatDialog,
    private randomImageService: RandomImageService,
    private router: Router
  ) {}

  ngOnInit() {

    this.background_img = this.randomImageService.get();

    this.filterFreeToggle = true;
    this.filterPrivateToggle = true;
    this.filterSaleToggle = true;
    this.filterPurchasedToggle = true;

    this.avatars = [];

    this.avatarService.getAll().subscribe((avatars) => {
      this.avatars = avatars;
      //console.log(avatars);
      //this.filteredAvatars = this.avatars.slice();
      this.filteredAvatarsText = [];
      this.filteredAvatarsFinal = [];

      this.filter('e');
    });
  }

  filter(e) {
    //console.log('filter call !');

    this.filteredAvatarsText = [];
    this.filteredAvatarsFinal = [];

    if (this.filterField.trim().length > 2) {
      //tokenize
      let tokens: string[] = this.filterField.split(' ');

      tokens.forEach((token) => {
        if (token != '' && token.length > 2) {
          for (let i = 0; i < this.avatars.length; i++) {
            let name =
              this.avatars[i].data.family_name.toLowerCase() +
              ' ' +
              this.avatars[i].data.given_name.toLowerCase();

            if (name.indexOf(token.toLowerCase()) >= 0) {
              if (!this.alreadyFiltered(this.avatars[i])) {
                this.filteredAvatarsText.push(this.avatars[i]);
              }
            }
          }
        }
      });
    } else {
      this.filteredAvatarsText = this.avatars;
    }

    if (this.filterFreeToggle) {
      for (let i = 0; i < this.filteredAvatarsText.length; i++) {
        if (this.filteredAvatarsText[i].data.public_access) {
          this.filteredAvatarsFinal.push(this.filteredAvatarsText[i]);
        }
      }
    }

    if (this.filterPrivateToggle) {
      for (let i = 0; i < this.filteredAvatarsText.length; i++) {
        if (this.accountService.isAdmin()) {
          if ( !this.filteredAvatarsText[i].data.public_access && !this.filteredAvatarsText[i].data.on_sale )
            this.filteredAvatarsFinal.push(this.filteredAvatarsText[i]);
        } else {
          if (
            !this.filteredAvatarsText[i].data.public_access &&
            this.filteredAvatarsText[i].data.link_origin == 'CREATION'
          ) {
            this.filteredAvatarsFinal.push(this.filteredAvatarsText[i]);
          }
        }
      }
    }

    if (this.filterSaleToggle) {
      for (let i = 0; i < this.filteredAvatarsText.length; i++) {
        if (
          this.filteredAvatarsText[i].data.on_sale &&
          !this.filteredAvatarsText[i].data.is_owned_by_user
        ) {
          this.filteredAvatarsFinal.push(this.filteredAvatarsText[i]);
        }
      }
    }

    if (this.filterPurchasedToggle) {
      for (let i = 0; i < this.filteredAvatarsText.length; i++) {
        if (
          this.filteredAvatarsText[i].data.is_owned_by_user &&
          this.filteredAvatarsText[i].data.link_origin == 'PURCHASE'
        ) {
          this.filteredAvatarsFinal.push(this.filteredAvatarsText[i]);
        }
      }
    }

    if (this.filteredAvatarsFinal.length > 0) {
      this.filteredAvatarsFinal.sort((a, b) => {
        const nameA = a.data.family_name.toUpperCase();
        const nameB = b.data.family_name.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal, here
        return 0;
      });
    }
  }

  alreadyFiltered(avatar: Avatar) {
    let already = false;

    this.filteredAvatarsText.forEach((f) => {
      if (f.id === avatar.id) {
        already = true;
      }
    });

    return already;
  }

  filterFree() {
    this.filterFreeToggle = !this.filterFreeToggle;
    this.filter('e');
  }

  filterPrivate() {
    this.filterPrivateToggle = !this.filterPrivateToggle;
    this.filter('e');
  }

  filterSale() {
    this.filterSaleToggle = !this.filterSaleToggle;
    this.filter('e');
  }

  filterPurchased() {
    this.filterPurchasedToggle = !this.filterPurchasedToggle;
    this.filter('e');
  }

  avatarStatus(avatar: Avatar) {
    if (avatar.data.public_access) {
      return 'FREE USE';
    } else {
      if (avatar.data.on_sale && !avatar.data.is_owned_by_user) {
        return 'ON SALE';
      } else {
        if (avatar.data.link_origin == 'PURCHASE') return 'PURCHASED';
        else {
        if (this.accountService.isAdmin()) {
            return 'CUSTOM CREATION';
        } else
            return 'YOUR CREATION';
      }
      }
    }
  }

  buy(event, index: number) {

    event.stopPropagation();

      const dialogRef = this.dialog.open(DialogPurchaseAvatarComponent, {
        data: { avatar: this.filteredAvatarsFinal[index] },
        height: '350px',
        width: '600px',
        closeOnNavigation: false,
        disableClose: true
      });

      dialogRef.afterClosed().subscribe((res: boolean) => {

        //console.log(res);

        if (res) {
          this.router.navigate(['/avatar-profile/'+this.filteredAvatarsFinal[index].metadata.guid]);
      }

      });


  }

  openProfile(index: number) {
    this.router.navigate([
      'avatar-profile',
      this.filteredAvatarsFinal[index].metadata.guid,
    ]);
  }
}
