<mat-card class="mat-elevation-z0 single-card settings-card">
  <mat-cart-header class="mat-card-header">
    <mat-card-title class="title"> INPAINT RESULTS </mat-card-title>
  </mat-cart-header>
  <mat-card-content>


    <div class="container-inpaint">

        <div class="image-container" (click)='openLightbox(-1)'>
          <div [class]="source_pic.favorite ? 'overlay-fav' : 'not-visible'"><mat-icon>favorite</mat-icon> </div>
            <img class="image" src="{{ original_uri }}" #original (load)="onLoad()">
            <div class="image-label">
              ORIGINAL

      </div>
        </div>


      @for (pic of inpainted_pics; track pic.id) {


        <div class="image-container" >
          <div [class]="!loaded[$index] ? 'overlay-spinner' : 'not-visible'">
            <mat-spinner [diameter]="50"></mat-spinner>
            </div>
          <div [class]="pic.favorite ? 'overlay-fav' : 'not-visible'"><mat-icon>favorite</mat-icon> </div>
          <img class="image" src="{{ pic.img_uri}}"  (click)='openLightbox($index)' [style]=" { height: calcHeight}">
            <div class="image-label"> PROPOSAL {{ $index+1 }}</div>

            <div class="actions">
              <button mat-button [disabled]="!loaded[$index]" class="nzym-icon-button" matTooltip="Add/remote favorite" [matTooltipPosition]="'above'" (click)="openLightbox($index)"><mat-icon>visibility</mat-icon></button>
              <button mat-button [disabled]="!loaded[$index]" class="nzym-icon-button" matTooltip="Add/remote favorite" [matTooltipPosition]="'above'" (click)="favorite($index)"><mat-icon>favorite</mat-icon></button>
              <button mat-button [disabled]="!loaded[$index]" class="nzym-icon-button" matTooltip="Continue fix from this picture" [matTooltipPosition]="'above'" (click)="fix($index)"><mat-icon>sync</mat-icon></button>
             <!-- <button mat-button [disabled]="!loaded[$index]" class="nzym-icon-button" matTooltip="Zoom out this picture" [matTooltipPosition]="'above'" (click)="zoomOut($index)"><mat-icon>zoom_out_map</mat-icon></button>-->
            </div>
        </div>


    }
    </div>


  </mat-card-content>
</mat-card>
