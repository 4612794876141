export class AvatarData {

  gender:string;
  nationality : string;
  artist_name:string;
  family_name:string;
  given_name:string;
  ethnicity: string;
  hair_color: string;
  hair_style: string;
  eye_color: string;
  age: number;
  nb_image: number;
  active: boolean;
  has_been_sold_at_least_once: boolean;
  on_sale: boolean;
  sold_out : boolean;
  exclusive : boolean;
  max_sales : number;
  is_owned_by_user: boolean;
  link_origin: string;

  sales_price: number;
  action: string;
  //img_uri: string;
  img_guid: string;
  public_access: boolean;
  ref_pics: any;
  lora_pics: any;

  in_creation: boolean;
  in_regeneration: boolean;

  marketing_age: number;
  marketing_eye_color: string;
  marketing_hair_color: string;
  marketing_height: number;
  marketing_blood_type: string;
  marketing_temper: string;
  marketing_history: string;
  marketing_zodiac_sign: string;
  marketing_story_guid: string;

  marketing_profile_picture_uri: string;
  marketing_main_scene_uri: string;
  marketing_scene_one_uri: string;
  marketing_scene_two_uri: string;
  marketing_scene_three_uri: string;
  marketing_scene_four_uri: string;
  marketing_portrait_one_uri: string;
  marketing_portrait_two_uri: string;
  marketing_portrait_three_uri: string;
  marketing_portrait_four_uri: string;

  marketing_profile_picture_thumb_uri: string;
  marketing_main_scene_thumb_uri: string;
  marketing_scene_one_thumb_uri: string;
  marketing_scene_two_thumb_uri: string;
  marketing_scene_three_thumb_uri: string;
  marketing_scene_four_thumb_uri: string;
  marketing_portrait_one_thumb_uri: string;
  marketing_portrait_two_thumb_uri: string;
  marketing_portrait_three_thumb_uri: string;
  marketing_portrait_four_thumb_uri: string;

  AvatarData() {
  }

}


