<div *ngIf="!loaded" class="spinner-container"><mat-spinner ></mat-spinner></div>

<div *ngIf="loaded">
<div class="cards">
  <mat-card class="mat-elevation-z0 single-card">
    <mat-cart-header class="mat-card-header">
      <mat-card-title><span class="card-title">#ADMINS </span></mat-card-title>
    <mat-card-title><span class="card-subtitle">  </span></mat-card-title>
    </mat-cart-header>
    <mat-card-content class="metric">
      <p>
        {{ totalAdmins }}
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card class="mat-elevation-z0 single-card">
    <mat-cart-header class="mat-card-header">
      <mat-card-title><span class="card-title">#USERS</span></mat-card-title>
      <mat-card-title><span class="card-subtitle"></span></mat-card-title>
    </mat-cart-header>
    <mat-card-content class="metric">
      <p>
        {{ totalUsers }}
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card class="mat-elevation-z0 single-card">
    <mat-cart-header class="mat-card-header">
      <mat-card-title><span class="card-title">#ACTIVE </span></mat-card-title>
      <mat-card-title><span class="card-subtitle"></span></mat-card-title>
    </mat-cart-header>
    <mat-card-content class="metric">
      <p>
        {{ nbActive }}
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card class="mat-elevation-z0 single-card">
    <mat-cart-header class="mat-card-header">
      <mat-card-title><span class="card-title">#INACTIVE</span></mat-card-title>
      <mat-card-title><span class="card-subtitle"></span></mat-card-title>
    </mat-cart-header>
    <mat-card-content class="metric">
      <p>
        {{ nbInactive }}
      </p>
    </mat-card-content>
  </mat-card>

</div>

<div class="filter">
  <mat-form-field class="searchfield">
    <input matInput type="text" (keyup)="doFilter($event)" placeholder="">
    <mat-label>Search & Filter </mat-label>
    <mat-icon matSuffix>filter_list</mat-icon>
    <mat-hint>Filter applies to all columns</mat-hint>
  </mat-form-field>
</div>

</div>


<table [hidden]="!loaded" mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)" matSortActive="email" matSortDirection="asc"
  class="mat-elevation-z8">

   <!-- picture Column -->
   <ng-container matColumnDef="picture">
    <th mat-header-cell *matHeaderCellDef >
      PICTURE
    </th>
    <td mat-cell *matCellDef="let element">
      <img src="{{element.picture }}" class="profile-picture" referrerpolicy="no-referrer">
    </td>
  </ng-container>

  <!-- email Column -->
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by email">
      ID / LOGIN
    </th>
    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
  </ng-container>

  <!-- fullname Column -->
  <ng-container matColumnDef="fullname">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by fullname">
      FULL NAME
    </th>
    <td mat-cell *matCellDef="let element"> {{element.given_name}} {{element.family_name}} </td>
  </ng-container>

  <!-- given_name Column -->
  <ng-container matColumnDef="role">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by role">
      ROLE
    </th>
    <td mat-cell *matCellDef="let element"> {{element.role.replaceAll('_',' ')}} </td>
  </ng-container>



  <!-- created_at Column -->
  <ng-container matColumnDef="created_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by created_at">
      CREATION DATE
    </th>
    <td mat-cell *matCellDef="let element"> {{element.created_at}} </td>
  </ng-container>

  <!-- last_login Column -->
  <ng-container matColumnDef="last_login">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by last_login">
      LAST SEEN
    </th>
    <td mat-cell *matCellDef="let element"> {{element.last_login}} </td>
  </ng-container>

  <!-- last_login Column -->
  <ng-container matColumnDef="nb_of_avatars">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number of avatars">
      NB AVATARS
    </th>
    <td mat-cell *matCellDef="let element"> {{element.nb_of_avatars }} </td>
  </ng-container>

  <!-- logins_count Column -->
  <ng-container matColumnDef="logins_count">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by logins_count">
      LOGIN COUNT
    </th>
    <td mat-cell *matCellDef="let element"> {{element.logins_count}} </td>
  </ng-container>

  <!-- action Column  -->
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>
      <span>ACTIONS</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="icons">
        <button mat-icon-button class="action-button" (click)="seePictures(element.user_guid)"><mat-icon [color]="'primary'" alt="IMAGES">photo_library </mat-icon></button>
        <button mat-icon-button class="action-button" [routerLink]="['/user-detail',element.user_guid]" ><mat-icon [color]="'primary'" alt="EDIT">search </mat-icon></button>
        <button mat-icon-button class="action-button" aria-label="delete user" (click)="delete(element.user_guid)"><mat-icon [color]="'primary'" alt="DELETE">delete</mat-icon></button>
      </div>
    </td>
  </ng-container>

  <!-- fab Column  -->
  <ng-container matColumnDef="add">
    <th mat-header-cell *matHeaderCellDef>
      <div class="fab">
        <button mat-mini-fab color="accent" aria-label="Add lab" (click)="new()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<div [hidden]="!loaded">
  <mat-paginator [pageSize]="25" [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons
    aria-label="Select page of samples" class="paginator">
  </mat-paginator>
</div>
