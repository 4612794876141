<div class="container">
  <div class="message">


    <div class="sections">
      <div class="section">
        <div class="section-title">  YOU COMMITED TO A FUTURE SUBSCRIPTION</div>
        <div class="section-content">
          <div class="attribute">
            <div class="profile-attribute">TYPE </div>
            <div class="attribute-value">{{ sub?.label }}</div>
          </div>
          <div class="attribute">
            <div class="profile-attribute">ENGAGEMENT </div>
            <div class="attribute-value">{{ sub?.recurrence }}</div>
          </div>
          <div class="attribute">
            <div class="profile-attribute">MONTHLY CREDITS INCLUDED </div>
            <div class="attribute-value">{{ sub?.monthly_credits }} </div>
          </div>
          <div class="attribute">
            <div class="profile-attribute"> STARTS ON...  </div>
            <div class="attribute-value">{{ dateNextReload() }} </div>
          </div>
          <div class="attribute">
            <div class="profile-attribute"> AUTOMATIC RENEWAL </div>
            <div class="attribute-value"> {{ isActive() }}</div>
          </div>
        </div>
      </div>



  </div>
  <div class="actions">
    <button mat-raised-button color="primary" class="ok" (click)="submit()"> OK </button>
  </div>
</div>
