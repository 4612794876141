import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { CustomAuthService } from '../services/custom-auth.service';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
  standalone: true,
  imports: [ MatButtonModule ]
})
export class EmailVerificationComponent {

  constructor(
    private router: Router,
    private customAuthService : CustomAuthService
  ) {
  }

    relog() {

      this.router.navigate(['login']);


    }

}
