import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { CookieService } from 'ngx-cookie-service';
import { AccountService } from './services/account.service';
import { UserService } from './services/user.service';
import { User } from './shared/entity/user';
import { Consent } from './shared/entity/consent';
import { DialogConsentComponent } from './home/dialog-consent/dialog-consent.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GoogleConsent } from './shared/entity/consent-google';
import { environment } from '../environments/environment';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Platform';
  //pictureUrl: string;

  menuItems = [];
  admin: boolean = false;

  displayIfAuth = false;

  constructor(
    private router: Router,
    private matIconReg: MatIconRegistry,
    private cookieService: CookieService,
    public accountService: AccountService,
    private userService: UserService,
    public dialog: MatDialog
  ) {}

  refreshCredit: boolean;

  listenerRefreshCredit(event) {
    //console.log(event);
    this.refreshCredit = false;
  }

  ngOnInit() {
    this.refreshCredit = false;

    //no need to verify the JWT as it is done by the Server !
    //we will get errors if the JWT is a fraud or expired

    this.matIconReg.setDefaultFontSetClass('material-symbols-outlined');

    if (this.accountService.isAuthenticated()) {

        this.displayIfAuth = true;
        this.setMenu();
        this.admin = this.accountService.isAdmin();

    } else {
      let jwt = this.cookieService.get('jwt');

      if (jwt) {
        localStorage.setItem('id_token', jwt);
        this.accountService.setIdToken(jwt);

        this.userService.getPerEmail(this.accountService.email).subscribe({
          next: (user: User) => {
            this.accountService.picture = user.data.picture;
            this.accountService.guid = user.metadata.guid;
            this.accountService.consent = user.data.consent;
            this.displayIfAuth = true;

            //console.log(user);

            if (user.data.banned == true) {
              this.router.navigate(['ban']);
            } else {
              if (user.data.email_verified == 'true') {
                this.setMenu();
                this.manageConsent();
              } else {
                this.router.navigate(['email-verification']);
              }
            }
          },
          error: (error) => {
            this.accountService.logout(false);
          },
        });
      } else {
        localStorage.setItem('id_token', null);

        if (sessionStorage.getItem('bye') == 'true') {
          this.router.navigate(['bye']);
        } else {
          // console.log("Redirect to login !");
          // window.location.href = this.auth.getSignInUrl();
        }
      }
    }
  }

  ngOnChange() {
    if (this.refreshCredit) {
      console.log('APP RefreshCredit=' + this.refreshCredit);
    }
  }

  isSupport(rank: number) {
    if (rank == 5) return true;
    return false;
  }

  profile() {
    this.router.navigate(['user-detail', this.accountService.guid]);
  }

  setMenu() {
    this.menuItems = [
      {
        label: 'CREATE AVATAR',
        icon: 'fingerprint',
        link: 'avatar-face-generator-init',
        rank: 1,
        visible: true,
      },
      {
        label: 'Avatar Gallery',
        icon: 'guardian',
        link: 'avatar-gallery',
        rank: 2,
        visible: true,
      },
      {
        label: 'NZYM Studio',
        icon: 'color_lens',
        link: 'nzym-studio',
        rank: 3,
        visible: true,
      },
      {
        label: 'Your Portfolio',
        icon: 'favorite',
        link: 'favorites',
        rank: 4,
        visible: true,
      },
      {
        label: 'Community Wall',
        icon: 'communities',
        link: 'community',
        rank: 5,
        visible: true,
      },
      {
        label: 'Doc & Tips',
        iconUrl: '../assets/img/gitbook.png',
        linkUrl: 'https://doc.nzym.io',
        rank: 6,
        visible: true,
      },
      {
        label: 'Tutorials',
        iconUrl: '../assets/img/youtube.png',
        linkUrl: 'https://www.youtube.com/@NZym_AI',
        rank: 7,
        visible: true,
      },
      {
        label: 'Discord',
        iconUrl: '../assets/img/discord.png',
        linkUrl: 'https://discord.gg/B7KzCJBsJs',
        rank: 8,
        visible: true,
      },
      {
        label: 'Instagram',
        iconUrl: '../assets/img/instagram.png',
        linkUrl: 'https://www.instagram.com/nzym.io/',
        rank: 9,
        visible: true,
      },
      {
        label: 'X - News',
        iconUrl: '../assets/img/twitter.png',
        linkUrl: 'https://x.com/Nzym_AI',
        rank: 10,
        visible: true,
      },
      {
        label: 'ADMIN: users',
        icon: 'settings',
        link: 'user-list',
        rank: 11,
        visible: this.accountService.isAdmin(),
      },
      {
        label: 'ADMIN: Avatars',
        icon: 'settings',
        link: 'avatar-list',
        rank: 12,
        visible: this.accountService.isAdmin(),
      },
      {
        label: 'ADMIN: Avatar Stories',
        icon: 'settings',
        link: 'avatar-stories-list',
        rank: 13,
        visible: this.accountService.isAdmin(),
      },
      {
        label: 'ADMIN: Ingredients',
        icon: 'settings',
        link: 'ingredient-list',
        rank: 14,
        visible: this.accountService.isAdmin(),
      },
      {
        label: 'ADMIN: Abuse',
        icon: 'settings',
        link: 'abuse-list',
        rank: 15,
        visible: this.accountService.isAdmin(),
      },
    ];
  }

  manageConsent() {
    //FIRST CHECK IF WE HAVE DATA CONSENT IN BASE

    if (this.accountService.consent.google.ad_personalization!='granted' && this.accountService.consent.google.ad_personalization!='denied') {
      //IF NOT, CHECK IF WE HAVE 1st PARTY COOKIES FROM THE WEBSITE

      let ad_personalization = this.cookieService.get('ad_personalization');
      let ad_storage = this.cookieService.get('ad_storage');
      let ad_user_data = this.cookieService.get('ad_user_data');
      let analytics_storage = this.cookieService.get('analytics_storage');

      if (ad_personalization == 'granted' || ad_personalization == 'denied' ) {
        //IF YES, TAKE IT, STORE IN BASE AND UPDATE GTAG

        let consent = new Consent();
        let google = new GoogleConsent();

        google.ad_personalization = ad_personalization;
        google.ad_storage = ad_storage;
        google.ad_user_data = ad_user_data;
        google.analytics_storage = analytics_storage;
        consent.google = google;

        this.userService.updateConsent(consent).subscribe(() => {
          //console.log('consent updated !');
          this.accountService.setConsent(consent);

          gtag('config', environment.gaId);
          gtag('consent', 'update', consent.google);
          gtag('event', 'first_login_with_consent_from_website', {});
        });
      } else {
        //IF NOT, OPEN CONSENT POPUP

        const dialogRef = this.dialog.open(DialogConsentComponent, {
          height: '150px',
          width: '500px',
          closeOnNavigation: false,
          disableClose: true,
          position: {
            bottom: '0',
          },
        });

        dialogRef.afterClosed().subscribe((consent: Consent) => {
          //console.log(consent);

          this.userService.updateConsent(consent).subscribe(() => {
            //console.log('consent updated !');

            this.accountService.setConsent(consent);

            this.cookieService.set(
              'ad_personalization',
              this.accountService.consent.google.ad_personalization,
              { domain: 'nzym.io', path: '/' }
            );
            this.cookieService.set(
              'ad_storage',
              this.accountService.consent.google.ad_storage,
              { domain: 'nzym.io', path: '/' }
            );
            this.cookieService.set(
              'ad_user_data',
              this.accountService.consent.google.ad_user_data,
              { domain: 'nzym.io', path: '/' }
            );
            this.cookieService.set(
              'analytics_storage',
              this.accountService.consent.google.analytics_storage,
              { domain: 'nzym.io', path: '/' }
            );

            gtag('config', environment.gaId);
            gtag('consent', 'update', consent.google);
            gtag('event', 'first_login_with_consent_from_app', {});
          });
        });
      }
    } else {
      // IF SO, IT IS THE REFERENCE ONE
      //SETUP 1ST PARTY COOKIES AND UPDATE  GTAG
      this.cookieService.set(
        'ad_personalization',
        this.accountService.consent.google.ad_personalization,
        { domain: 'nzym.io', path: '/' }
      );
      this.cookieService.set(
        'ad_storage',
        this.accountService.consent.google.ad_storage,
        { domain: 'nzym.io', path: '/' }
      );
      this.cookieService.set(
        'ad_user_data',
        this.accountService.consent.google.ad_user_data,
        { domain: 'nzym.io', path: '/' }
      );
      this.cookieService.set(
        'analytics_storage',
        this.accountService.consent.google.analytics_storage,
        { domain: 'nzym.io', path: '/' }
      );

      gtag('config', environment.gaId);
      gtag('consent', 'update', this.accountService.consent.google);
      gtag('event', 'login', {});
    }
  }


}
