<mat-card class="mat-elevation-z0 single-card settings-card">
  <mat-cart-header class="mat-card-header form-box-title">
    <mat-card-title> {{ title }} </mat-card-title>
  </mat-cart-header>
  <mat-card-content>
    <div class="container">

      @for (pic of pics; track pic; let idx=$index) {
      <div class="container-element" (click)="selectImage(idx)">

        <div class="image-box">
          <img src="{{ pic }}" class="image">
        </div>
      </div>
      }

    </div>


  </mat-card-content>
</mat-card>
