import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SpotBuyService } from '../services/spot-buy.service';
import { Router } from '@angular/router';
import { SpotOffer } from '../shared/entity/spot-offer';

@Component({
  selector: 'app-buy-spot-credits',
  standalone: true,
  imports: [MatButtonModule, MatIconModule],
  templateUrl: './buy-spot-credits.component.html',
  styleUrl: './buy-spot-credits.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BuySpotCreditsComponent {

  spotOffers : SpotOffer[];

  constructor(
    private router: Router,
    private spotBuyService: SpotBuyService
  ) {}

  ngOnInit() {

    this.spotBuyService.getAllSpotOffers().subscribe((spots) => {

    //  console.log(spots);
      this.spotOffers = spots;

    });

  }

  purchase(guid:string) {

    //console.log(guid);
    this.router.navigate(['pay-spot/'+guid]);
  }

}
