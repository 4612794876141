export class ZoomRequest {

  img_guid?: string;

  top :boolean;
  right :boolean;
  bottom :boolean;
  left :boolean;
  distance_top: number;
  distance_right :number;
  distance_bottom :number;
  distance_left :number;
  mixing_prompt_toggle: boolean;
  mixing_prompt: string;

  constructor() {
    this.top = true;
    this.right = true;
    this.bottom = true;
    this.left = true;
    this.distance_top = 0;
    this.distance_right = 0;
    this.distance_bottom = 0;
    this.distance_left = 0;
    this.mixing_prompt_toggle = false;
    this.mixing_prompt = "";
  }

}
