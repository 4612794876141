<div class="main">

<div class="container" *ngIf="loaded">

  <div class="illustration">
    <img class="image" src="../../../../assets/img/purchase_avatar.jpg">
  </div>
  <div class="explanation">
    <div class="title"> WANT TO PURCHASE {{ avatar?.data.given_name }} {{ avatar?.data.family_name }} ?</div>

    <div class="text" *ngIf="!transactionPossible"> You are running out of gas ! </div>
    <div class="text" *ngIf="!transactionPossible"> You do not have enough credits to purchase {{ avatar?.data.given_name }} </div>
    <div class="text" *ngIf="!transactionPossible && !hideSpots"> Please recharge your account, by getting spot credits or upgrading your subscription </div>
    <div class="text" *ngIf="!transactionPossible && hideSpots"> Please recharge your credits, by upgrading your subscription </div>

    <div class="text" *ngIf="transactionPossible"> You are one click away to make {{ avatar?.data.given_name }} join your custom avatar pool </div>
    <div class="text" *ngIf="transactionPossible"> {{ avatar?.data.given_name }} will cost you {{ avatar?.data.sales_price }} credits </div>

  </div>

</div>
<div class="actions" *ngIf="!transactionPossible">
  <div class="action"  *ngIf="!hideSpots">
    <button mat-raised-button color="primary" class="spot" (click)="buySpotCredits()">BUY SPOT CREDITS</button>
  </div>
  <div class="action">
    <button mat-raised-button color="primary"  class="upgrade" (click)="upgradeSub()">UPGRADE SUBSCRIPTION</button>
  </div>
  <div class="action">
    <button mat-raised-button color="accent" class="cancel" (click)="onCancel()">CANCEL</button>
  </div>
</div>
<div class="actions" *ngIf="transactionPossible">
  <div class="action">
    <button mat-raised-button color="primary"  class="upgrade" (click)="purchase()">CONFIRM</button>
  </div>
  <div class="action">
    <button mat-raised-button color="accent" class="cancel" (click)="onCancel()">CANCEL</button>
  </div>
</div>

</div>
