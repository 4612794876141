import { CUSTOM_ELEMENTS_SCHEMA, Component, Inject } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AvatarService } from '../../services/avatar.service';
import { Avatar } from '../../shared/entity/avatar';

export interface DialogData {
  avatar: Avatar;
}

@Component({
  selector: 'app-dialog-inpaint-avatar',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCard,
    MatProgressSpinnerModule,
    MatChipsModule
  ],
  templateUrl: './dialog-inpaint-select-avatar-image.component.html',
  styleUrl: './dialog-inpaint-select-avatar-image.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DialogInpaintAvatarImageSelectionComponent {

  title: string;
  pics:any[];

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<DialogInpaintAvatarImageSelectionComponent>,
    public avatarService: AvatarService
  ) {

    this.pics = [];
  }

  ngOnInit():void {
    this.title = 'SELECT A REFERENCE IMAGE TO INPAINT/FACE SWAP';

    //console.log(this.data.avatar.data);

    for (let i in this.data.avatar.data.lora_pics) {
      this.pics.push( this.data.avatar.data.lora_pics[i].thumb_uri);
    }

    }

   selectImage(index: number) {

    this.dialogRef.close(this.pics[index]);

   }
}
