import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCard } from '@angular/material/card';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { ZoomRequest } from '../../../shared/entity/zoom-request';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OperationsCostService } from '../../../services/operations-cost';

export interface DialogData {
    img_uri: string;
}

@Component({
  selector: 'app-dialog-custom-zoom',
  standalone: true,
  imports: [

    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatIconModule,
    MatDialogClose,
    MatCard,
    MatTooltipModule

  ],
  templateUrl: './dialog-custom-zoom.component.html',
  styleUrl: './dialog-custom-zoom.component.scss',
})
export class DialogCustomZoomComponent {

  setting: ZoomRequest;
  top_value: number = 0;
  left_value: number = 0;
  bottom_value: number = 0;
  right_value: number = 0;
  pic: string;
  expandTopToggle: boolean = true;
  expandBottomToggle: boolean = true;
  expandLeftToggle: boolean = true;
  expandRightToggle: boolean = true;

  cost_zoomout: number;

  constructor(
    public dialogRef: MatDialogRef<DialogCustomZoomComponent>,
    public dialog: MatDialog,
    private operationsCost: OperationsCostService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {

    this.cost_zoomout = this.operationsCost.cost_zoomout;


    this.pic = this.data.img_uri;
    this.setting = new ZoomRequest();

    this.setting.top = true;
    this.setting.right= true;
    this.setting.bottom= true;
    this.setting.left= true;

    this.setting.distance_top = 0;
    this.setting.distance_right= 0;
    this.setting.distance_bottom = 0;
    this.setting.distance_left = 0;

  }

  onSubmit() {

    this.setting.top = this.expandTopToggle;
    this.setting.right= this.expandRightToggle;
    this.setting.bottom= this.expandBottomToggle;
    this.setting.left= this.expandLeftToggle;

    //no effect on the api for the moment , April 2024 (and actually bad lines effects if activated !)
    this.setting.distance_top = this.top_value;
    this.setting.distance_right= this.right_value;
    this.setting.distance_bottom = this.bottom_value;
    this.setting.distance_left = this.left_value;

    this.dialogRef.close(this.setting);

  }


  expandTop() {
    this.expandTopToggle = !this.expandTopToggle;
  }

  expandBottom() {
    this.expandBottomToggle = !this.expandBottomToggle;
  }

  expandLeft() {
    this.expandLeftToggle = !this.expandLeftToggle;
  }

  expandRight() {
    this.expandRightToggle = !this.expandRightToggle;
  }

  onCancel(): void {
      this.dialogRef.close(null);
  }

}
