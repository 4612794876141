import { CUSTOM_ELEMENTS_SCHEMA, Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HairStyleService } from '../../services/hairstyle.service';
import { HairStyle } from '../../shared/entity/hairstyle';


export interface DialogData {
  gender: string;
}

@Component({
  selector: 'app-dialog-naming',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCard,
    MatProgressSpinnerModule,
    MatChipsModule
  ],
  templateUrl: './dialog-hairstyle.component.html',
  styleUrl: './dialog-hairstyle.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DialogHairstyleComponent {
  form: FormGroup;
  title: string;
  hairstyles: HairStyle[];
  gender: string;

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DialogHairstyleComponent>,
    public hairstyleService: HairStyleService
  ) {
    this.form = this.formBuilder.group({
      filter: ['']
    });
  }

  ngOnInit():void {

    this.title = 'SELECT A HAIRSTYLE FOR THIS CREATION';
    this.gender = this.data.gender;
    this.hairstyles = [];

    this.hairstyleService.getAll().subscribe((res)=>{

       res.forEach( (style) => {

          if (style.gender.toUpperCase()==this.gender.toUpperCase() || style.gender.toUpperCase()=='UNISEX') {
            this.hairstyles.push(style);
          }

      });

    });

  }


 selectHairstyle(hairstyle) {
    this.dialogRef.close(hairstyle);
  }

  onCancel(): void {
    this.dialogRef.close(null);
  }
}
