<div class="main">

<div class="container" *ngIf="loaded">

  <div class="illustration">
    <img class="image" src="../../../../assets/img/no_credits.jpg">
  </div>
  <div class="explanation">
    <div class="title"> NOT ENOUGH CREDITS !</div>
    <div class="text"> You are running out of gas ! </div>
    <div class="text" *ngIf="!hideSpot"> Continue to fuel your creativity and generate new images immediatly, either by getting spot credits or upgrade your subscription hereunder </div>
    <div class="text" *ngIf="hideSpot"> Continue to fuel your creativity and generate new images immediatly, by getting a subscription hereunder </div>
  </div>

</div>
<div class="actions">
  <div class="action"  *ngIf="!hideSpot">
    <button mat-raised-button color="primary" class="spot" (click)="buySpotCredits()">BUY SPOT CREDITS</button>
  </div>
  <div class="action">
    <button mat-raised-button color="primary"  class="upgrade" (click)="upgradeSub()">UPGRADE SUBSCRIPTION</button>
  </div>
  <div class="action">
    <button mat-raised-button color="accent" class="cancel" (click)="onCancel()">CANCEL</button>
  </div>
</div>

</div>
