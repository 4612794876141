<div class="maincontainer">
  <div class="card">
    <h1 class="errorcode"> SORRY ! </h1>
    <div class="errortext"> You can not modify your subscription !</div>
    <div class="errortext"> You already have a pending modification</div>
    <div class="errortext"> But of course, our support will be glad to help you, if need be !</div>
    <div class="actions">
      <button mat-raised-button color="primary" (click)="home()">HOME</button>
      <a mat-raised-button color="warn" href="mailto:support@nzym.io" > CONTACT SUPPORT</a>
    </div>
  </div>
</div>
