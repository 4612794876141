import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  text: string;
}

@Component({
  selector: 'app-dialog-warning-sold',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './dialog-warning-sold.component.html',
  styleUrl: './dialog-warning-sold.component.scss',
})
export class DialogWarningSoldComponent {
  text: string;

  constructor(
    public dialogRef: MatDialogRef<DialogWarningSoldComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {
    this.text = this.data.text;
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  submit() {
    this.dialogRef.close(true);
  }
}
