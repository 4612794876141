import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCard } from '@angular/material/card';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PictureService } from '../../services/picture.service';
import { Picture } from '../../shared/entity/picture';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgIf } from '@angular/common';


interface Item {
  pic: Picture;
  selected: boolean;
  tags: string[];
}


export interface DialogData {
  name: string;
  index: number;
  items: Item[];
}

@Component({
  selector: 'app-dialog-home-action',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatCard,
    MatProgressSpinnerModule,
    MatIconModule,
    MatTooltipModule,
    NgIf,
  ],
  templateUrl: './dialog-favorite.component.html',
  styleUrl: './dialog-favorite.component.scss',
})
export class DialogFavoriteComponent {

  @ViewChild('imgEdit', { static: true }) imgEdit: ElementRef;
  @ViewChild('overlayEdit', { static: true }) overlayEdit: ElementRef;


  img_overlay: boolean;

  horizontal_format: boolean; // setup the style
  realx: number;
  realy: number;
  index: number;
  items:Item[];

  keyPressed = (e) => {

    if (e.isComposing || e.code === 'ArrowLeft') {
      this.goLeft();
    }
    if (e.isComposing || e.code === 'ArrowRight') {
      this.goRight();
    }
    if (e.isComposing || e.code === 'KeyD') {
      this.download();
    }

    e.stopPropagation();
  }


  mouseMove = (e) => {
    if (this.img_overlay == false) this.img_overlay = true;
  };

  mouseOut = (e) => {
    if (this.img_overlay == true) this.img_overlay = false;
  };

  imgTouchStart = (e) => {
   //if (this.img_overlay == false) this.img_overlay = true;
   this.img_overlay = true;
   //e.stopPropagation();
   //e.preventDefault();
  };

  overlayTouchStart = (e) => {
    //if (this.img_overlay == false) this.img_overlay = true;
   // this.img_overlay = false;
    //e.stopPropagation();
    e.preventDefault();
   };

  constructor(
    public dialogRef: MatDialogRef<DialogFavoriteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    private pictureService: PictureService,
    private _snackbar: MatSnackBar
  ) {}

  ngOnDestroy() {
     document.removeEventListener('keydown', this.keyPressed);
  }

  ngAfterViewInit() {
    var _this = this;
    document.addEventListener(
      'keydown', _this.keyPressed
    );
  }


  ngOnInit() {

    this.horizontal_format = false;

    this.index = this.data.index;
    this.items = this.data.items;


    //init overlay
    this.img_overlay = true;

    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this.onCancel();
      }
    });

    this.dialogRef.backdropClick().subscribe((event) => {
      this.onCancel();
    });
  }

  onLoadImage() {
    this.realx = this.imgEdit.nativeElement.naturalWidth;
    this.realy = this.imgEdit.nativeElement.naturalHeight;

    //this.calcLeft = (this.imgEdit.nativeElement.clientWidth - 24*2)+'px';
  }



  onCancel(): void {
    this.dialogRef.close(null);
  }

  download(): void {

    this.pictureService.downloadImage(this.items[this.index].pic.img_uri).subscribe((blob) => {
      // Create a link element
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.type = "image/jpeg";
      link.download = 'nzym-img-' + (new Date().getTime()) + '.jpg'; // Set the download filename

      // Append link to the body, click it, and then remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }



  goLeft() {
    if (this.index > 0)  {
      this.index--;

    }
  }

  goRight() {
    if (this.index < this.items.length-1) {
      this.index++;

    }

  }

}
