<div class="picture-container">
  <img src='../../assets/img/role.png' class="picture">
</div>
<h1 class="title"> PENDING ACCOUNT CONFIGURATION</h1>
<div class="paragraph">
<p>
  Your account has been created but your administrator has to give you the rights to access.
</p>
<p>  It should happen shortly !
</p>
</div>
