import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
} from '@angular/router';

import { Observable } from 'rxjs';
import { AccountService } from '../../services/account.service';


@Injectable({
  providedIn: 'root',
})
export class AdminGuard {

  constructor(public accountService: AccountService, public router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | UrlTree | boolean {

    if (this.accountService.isAuthenticated()) {

      if ( this.accountService.hasRoleAssigned()) {

        if (this.accountService.isAdmin())
          return true;
        else
          return false;
      } else {
        //no role assigned
        //console.log('Access Denied, Role is Required to Access This Page !');
        this.router.navigate(['no-role-assigned']);
      }
    } else {
      this.router.navigate(['home']);
    }

    return false;
  }
}
