<div class="container">
  <div class="message">
    {{ text }}
  </div>
  <div class="price">
    <form [formGroup]="form">
      <mat-form-field class="form-element">
        <mat-label>PRICE (credits)</mat-label>
        <input matInput type="number" formControlName="sales_price">
      </mat-form-field>

      <mat-form-field class="form-element ">
        <mat-label>Maximum Sales</mat-label>
        <input matInput type="number" formControlName="max_sales">
      </mat-form-field>
      <div class="form-element toggle">
        <!--<input matInput type="number" formControlName="exclusive">-->
        <mat-slide-toggle labelPosition="before" formControlName="exclusive"> Mark as Exclusive Sale </mat-slide-toggle>
      </div>
    </form>

  </div>
  <div class="actions">
    <button mat-raised-button color="primary" class="ok" (click)="submit()"> OK </button>
    <button mat-raised-button color="warn" class="cancel" (click)="onCancel()"> CANCEL </button>
  </div>
</div>
