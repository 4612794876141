<mat-card class="mat-elevation-z0 single-card fav-card">
  <mat-cart-header class="mat-card-header form-box-title">
    <mat-card-title><span class="card-title"> YOUR PORTFOLIO - FAVORITES & KEEPERS </span></mat-card-title>
    <mat-card-title><span class="card-subtitle"> TAG, CLASSIFY, EXPORT </span></mat-card-title>
  </mat-cart-header>

  <mat-card-content class="setting-content" [style.background]="'url('+background_img+')'">

    <div class="commands">

      <mat-form-field class="filter">
        <mat-label>Filter through tags</mat-label>
        <input matInput placeholder="Sport Car Model..." [(ngModel)]="filterField" (keyup)="filter($event)">
      </mat-form-field>

      <div class="buttons">
        <button mat-button class="button" (click)="selectAll()"><mat-icon>checklist</mat-icon>Select all</button>
        <button mat-button class="button" (click)="deselectAll()"><mat-icon>disabled_by_default</mat-icon>Reset
          filters</button>
        <button mat-button class="button" (click)="downloads()"><mat-icon>download</mat-icon> Download selected</button>
        <button mat-button class="button" (click)="labels()"><mat-icon>comment_bank </mat-icon>Add global tags </button>
      </div>
    </div>

    <div class="spinner" *ngIf="!loaded" >  <mat-spinner [diameter]="100"></mat-spinner></div>

    <div class="gallery" *ngIf="loaded" >
      <cdk-virtual-scroll-viewport itemSize="40" class="gallery-viewport" >

        <div class="gallery-content">
          <!-- ***-->
          @for (item of filteredItems ; track item.pic.guid; let idx = $index) {
          <div class="image">
            <img src="{{ item.pic.thumb_uri || item.pic.img_uri }}" (click)="open(idx)" >
            <div class="overlay-select">
              <mat-checkbox [(ngModel)]="item.selected">
              </mat-checkbox>
            </div>
            <div class="overlay-actions">
              <mat-icon matTooltip="Download" (click)="singleDownload(idx)"
                [matTooltipPosition]="'right'">download</mat-icon>
              <mat-icon matTooltip="De-fav" (click)="unfav(idx)" [matTooltipPosition]="'right'">heart_minus</mat-icon>
              <mat-icon matTooltip="Edit Tags" (click)="singleTagEdit(idx)"
                [matTooltipPosition]="'right'">edit_document</mat-icon>
            </div>
            <div class="tags">
              <mat-chip-set>
                @for (tag of item.pic.tags; track tag+'_'+$index; let imgIdx = $index) {
                @if (imgIdx < 5) { <mat-chip class="chip" (click)="addChip(tag)">{{tag}}</mat-chip>
                  }
                  }
              </mat-chip-set>
            </div>
          </div>
          }
          <!-- ***-->
        </div>
      </cdk-virtual-scroll-viewport>
    </div>

  </mat-card-content>
</mat-card>
