import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  gender: string;
}

@Component({
  selector: 'app-dialog-cancel-sub',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './dialog-cancel-sub.component.html',
  styleUrl: './dialog-cancel-sub.component.scss',
})
export class DialogCancelSubComponent {
  text: string;
  text2: string;

  constructor(
    public dialogRef: MatDialogRef<DialogCancelSubComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  ngOnInit() {

   this.text = "DO YOU REALLY WANT TO QUIT NZYM ?";
   this.text2 = "If so, we will stop your subscriptions at the end of your current engagement";

  }

  onCancel(): void {
    this.dialogRef.close(false);

  }

  submit() {
    this.dialogRef.close(true);
  }
}
