import { NgIf } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  NO_ERRORS_SCHEMA,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialog } from '@angular/material/dialog';
import { PictureService } from '../services/picture.service';
import { Picture } from '../shared/entity/picture';
import { DialogInpaintComponent } from './dialog-inpaint/dialog-inpaint.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionMessage } from '../shared/entity/action-message';
import { ActionCode } from '../shared/enum/action-code';
import { ImagePrompt } from '../shared/entity/image-prompt';
import { ImagePromptType } from '../shared/enum/image-prompt-type';
import { FileDragNDropDirective } from '../shared/directive/file-drag-n-drop.directive ';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DialogInpaintSelectAvatarComponent } from './dialog-inpaint-select-avatar/dialog-inpaint-select-avatar.component';
import { OperationsCostService } from '../services/operations-cost';
import { DialogNeedBiscuitComponent } from '../shared/component/dialog-need-biscuit/dialog-need-biscuit.component';
import { CreditService } from '../services/credit.service';

@Component({
  selector: 'app-inpaint-editor',
  standalone: true,
  imports: [
    NgIf,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCardModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatChipsModule,
    MatSliderModule,
    MatIconModule,
    MatExpansionModule,
    MatButtonToggleModule,
    FileDragNDropDirective,
  ],
  templateUrl: './inpaint-editor.component.html',
  styleUrl: './inpaint-editor.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class InpaintEditorComponent {
  @ViewChild('background', { static: false })
  bgCanvas: ElementRef<HTMLCanvasElement>;

  @ViewChild('mask', { static: false })
  maskCanvas: ElementRef<HTMLCanvasElement>;

  @ViewChild('editor_container', { static: false })
  div_editor: ElementRef;

  prompt: string;
  sourcePic: Picture;

  brushOpenState: boolean;
  modeOpenState: boolean;
  zoomOpenState: boolean;
  toolOpenState: boolean;
  numberOpenState: boolean;
  swapOpenState: boolean;

  loaded: boolean;
  back_ctx: CanvasRenderingContext2D;
  edit_ctx: CanvasRenderingContext2D;
  flag: boolean;
  prevX: number;
  currX: number;
  prevY: number;
  currY: number;
  dot_flag: boolean;
  image_has_been_touched: boolean;

  penColor: string;
  lineWidth: number;
  img_tmp: any;
  mask_image: any;

  //  w: number;
  //  h: number;

  nb_of_images: number;
  zoom_level: number;
  mode: string;

  cost: number;

  mouseMove = (e) => {
    this.findxy('mousemove', e);
    e.stopPropagation();
    //e.preventDefault();
  };

  mouseDown = (e) => {
    this.findxy('mousedown', e);
    e.stopPropagation();
    //e.preventDefault();
  };

  mouseUp = (e) => {
    this.findxy('mouseup', e);
    e.stopPropagation();
    //e.preventDefault();
  };

  mouseOut = (e) => {
    this.findxy('mouseout', e);
    e.stopPropagation();
    //e.preventDefault();
  };

  touchStart = (e) => {
    this.touchFindxy('touchstart', e);
    //e.stopPropagation();
    e.preventDefault();
  };

  touchEnd = (e) => {
    this.touchFindxy('touchend', e);
    e.stopPropagation();
    //e.preventDefault();
  };

  touchMove = (e) => {
    this.touchFindxy('touchmove', e);
    e.stopPropagation();
    //e.preventDefault();
  };

  touchCancel = (e) => {
    this.touchFindxy('touchcancel', e);
    e.stopPropagation();
    //e.preventDefault();
  };

  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private pictureService: PictureService,
    private router: Router,
    private _snackbar: MatSnackBar,
    private operationsCost: OperationsCostService,
    private creditService : CreditService
  ) {}

  ngOnDestroy() {
    this.maskCanvas.nativeElement.removeEventListener(
      'mousemove',
      this.mouseMove
    );
    this.maskCanvas.nativeElement.removeEventListener(
      'mousedown',
      this.mouseDown
    );
    this.maskCanvas.nativeElement.removeEventListener(
      'mouseup',
      this.mouseUp
    );
    this.maskCanvas.nativeElement.removeEventListener(
      'mouseout',
      this.mouseOut
    );
    this.maskCanvas.nativeElement.removeEventListener(
      'touchstart',
      this.touchStart
    );
    this.maskCanvas.nativeElement.removeEventListener(
      'touchend',
      this.touchEnd
    );
    this.maskCanvas.nativeElement.removeEventListener(
      'touchcancel',
      this.touchCancel
    );
    this.maskCanvas.nativeElement.removeEventListener(
      'touchmove',
      this.touchMove
    );
  }

  ngOnInit() {
    //init attributes
    this.files = [];
    this.img_prompts = [];

    //get from the previous page
    this.sourcePic = new Picture();
    this.prompt = '';

    this.image_has_been_touched = false;
    this.flag = false;
    this.prevX = 0;
    this.currX = 0;
    this.prevY = 0;
    this.currY = 0;
    this.dot_flag = false;
    this.penColor = 'white';
    this.lineWidth = 40;

    this.brushOpenState = true;
    this.modeOpenState = true;
    this.zoomOpenState = true;
    this.toolOpenState = true;
    this.numberOpenState = true;
    this.swapOpenState = true;

    this.loaded = true;

    this.nb_of_images = 2;
    this.zoom_level = 0.75;
    this.mode = 'IMPROVE';

    this.cost = this.nb_of_images * this.operationsCost.cost_inpaint;

    let img_guid = this.activatedRoute.snapshot.params['img_guid'];

    this.pictureService.get(img_guid).subscribe((img) => {
      this.sourcePic = img;

      this.img_tmp = new Image();

      this.img_tmp.src = this.sourcePic.img_uri;

      var _this = this;

      this.img_tmp.onload = () => {
        //prepare the mask image
        this.mask_image = new Image(this.img_tmp.width, this.img_tmp.height);
        let image_ratio = _this.img_tmp.width / _this.img_tmp.height;
        let canvas_width = window.innerWidth - 420; // setting column size
        let canvas_height = canvas_width / image_ratio;

        //by default we maximise the width
        let ratioW = 0.99;
        let ratioH = this.adjustH(canvas_height, 0.01, 1);

        let ratio = ratioW * ratioH;

        //console.log("img Width="+_this.img_tmp.width);
        //console.log("canvas_width="+canvas_width);
        //console.log('ratioW=' + ratioW);
        //console.log('ratioH=' + ratioH);
        //console.log('ratio=' + ratioH * ratioW);

        this.bgCanvas.nativeElement.height = canvas_height * ratio;
        this.bgCanvas.nativeElement.width = canvas_width * ratio;
        this.maskCanvas.nativeElement.height = canvas_height * ratio;
        this.maskCanvas.nativeElement.width = canvas_width * ratio;

        this.div_editor.nativeElement.height = canvas_height * ratio;

        //console.log('wRatio='+wRatio);
        //console.log('hRatio='+hRatio);

        this.zoom_level = ratio;

        this.back_ctx.drawImage(
          _this.img_tmp,
          0,
          0,
          _this.img_tmp.width,
          _this.img_tmp.height,
          //centerShift_x,
          //centerShift_y,
          0,
          0,
          canvas_width * ratio,
          canvas_height * ratio
          //_this.img_tmp.width * ratioW,
          //_this.img_tmp.height  * ratioW*ratioH
        );

        this.edit_ctx.fillStyle = 'black';
        this.edit_ctx.fillRect(
          0,
          0,
          this.maskCanvas.nativeElement.width,
          this.maskCanvas.nativeElement.height
        );
      };
    });
  }

  adjustW(imgWidth, canvas_width, step, count) {
    let imgWidth_adjusted = canvas_width * (1 - count * step);

    if (canvas_width < imgWidth_adjusted) {
      count++;
      return this.adjustW(imgWidth_adjusted, canvas_width, step, count);
    }

    //console.log(count);

    let result = 1;

    for (let i = 1; i < count + 1; i++) {
      result = result * (1 - i * step);
    }

    return result;

    //return 1 - count * step;
  }

  adjustH(canvas_height, step, count) {
    let maxHeight = window.innerHeight - 80; //margin

    let canvas_height_adjusted = canvas_height * (1 - count * step);

    if (maxHeight < canvas_height_adjusted) {
      count++;
      return this.adjustH(canvas_height_adjusted, step, count);
    }

    let result = 1;

    for (let i = 1; i < count + 1; i++) {
      result = result * (1 - i * step);
    }

    return result;
  }

  ngAfterViewInit() {
    this.back_ctx = this.bgCanvas.nativeElement.getContext('2d', {
      willReadFrequently: true,
    });
    this.edit_ctx = this.maskCanvas.nativeElement.getContext('2d', {
      willReadFrequently: true,
    });

    //this.w = this.maskCanvas.nativeElement.width;
    //this.h = this.maskCanvas.nativeElement.height;

    //  this.back_ctx.scale(0.75, 0.75);
    //  this.edit_ctx.scale(0.75, 0.75);

    //  var rect = this.maskCanvas.nativeElement.getBoundingClientRect();

    var _this = this;

    this.maskCanvas.nativeElement.addEventListener(
      'mousemove',
      _this.mouseMove
    );
    this.maskCanvas.nativeElement.addEventListener(
      'mousedown',
      _this.mouseDown
    );
    this.maskCanvas.nativeElement.addEventListener(
      'mouseup',
      _this.mouseUp
    );
    this.maskCanvas.nativeElement.addEventListener(
      'mouseout',
      _this.mouseOut
    );
    this.maskCanvas.nativeElement.addEventListener(
      'touchstart',
      _this.touchStart
    );
    this.maskCanvas.nativeElement.addEventListener(
      'touchend',
      _this.touchEnd
    );
    this.maskCanvas.nativeElement.addEventListener(
      'touchcancel',
      _this.touchCancel
    );
    this.maskCanvas.nativeElement.addEventListener(
      'touchmove',
      _this.touchMove
    );
  }

  activePen(value) {
    value.source._selected = true;
    this.penColor = 'white';
  }

  activeEraser(value) {
    value.source._selected = true;
    this.penColor = 'black';
  }

  touchFindxy(res: string, e: TouchEvent) {
    if (res == 'touchstart') {
      this.image_has_been_touched = true;

      this.prevX = this.currX;
      this.prevY = this.currY;
      //this.currX = e.clientX - this.maskCanvas.nativeElement.offsetLeft ; // margin
      //this.currY = e.clientY - this.maskCanvas.nativeElement.offsetTop; // topbarmenu + margin

      //console.log(this.maskCanvas.nativeElement.getBoundingClientRect());
      this.currX =
        e.touches[0].clientX -
        this.maskCanvas.nativeElement.getBoundingClientRect().left;
      this.currY =
        e.touches[0].clientY -
        this.maskCanvas.nativeElement.getBoundingClientRect().top;

      /*let rect = this.maskCanvas.nativeElement.getBoundingClientRect();
      let x = e.clientX - rect.left;
      let y = e.clientY - rect.top;
*/

      this.flag = true;
      this.dot_flag = true;

      if (this.dot_flag) {
        this.edit_ctx.beginPath();
        this.edit_ctx.fillStyle = this.penColor;
        this.edit_ctx.fillRect(this.currX, this.currY, 2, 2); // topbarmenu
        this.edit_ctx.lineCap = 'round';
        this.edit_ctx.lineJoin = 'round';
        this.edit_ctx.closePath();
        this.dot_flag = false;
      }
    }
    if (res == 'touchend' || res == 'touchcancel') {
      this.flag = false;
      this.mask_image = this.edit_ctx.getImageData(
        0,
        0,
        this.mask_image.height,
        this.mask_image.width
      );
    }
    if (res == 'touchmove') {
      if (this.flag) {
        this.prevX = this.currX;
        this.prevY = this.currY;
        //this.currX = e.clientX - this.maskCanvas.nativeElement.offsetLeft; // margin
        //this.currY = e.clientY - this.maskCanvas.nativeElement.offsetTop ; // topbarmenu + margin

        this.currX =
          e.changedTouches[0].clientX -
          this.maskCanvas.nativeElement.getBoundingClientRect().left;
        this.currY =
          e.changedTouches[0].clientY -
          this.maskCanvas.nativeElement.getBoundingClientRect().top;

        this.draw();
      }
    }
  }

  findxy(res: string, e: MouseEvent) {
    if (res == 'mousedown') {
      this.image_has_been_touched = true;

      this.prevX = this.currX;
      this.prevY = this.currY;
      //this.currX = e.clientX - this.maskCanvas.nativeElement.offsetLeft ; // margin
      //this.currY = e.clientY - this.maskCanvas.nativeElement.offsetTop; // topbarmenu + margin

      //console.log(this.maskCanvas.nativeElement.getBoundingClientRect());
      this.currX =
        e.clientX - this.maskCanvas.nativeElement.getBoundingClientRect().left;
      this.currY =
        e.clientY - this.maskCanvas.nativeElement.getBoundingClientRect().top;

      /*let rect = this.maskCanvas.nativeElement.getBoundingClientRect();
      let x = e.clientX - rect.left;
      let y = e.clientY - rect.top;
*/

      this.flag = true;
      this.dot_flag = true;

      if (this.dot_flag) {
        this.edit_ctx.beginPath();
        this.edit_ctx.fillStyle = this.penColor;
        this.edit_ctx.fillRect(this.currX, this.currY, 2, 2); // topbarmenu
        this.edit_ctx.lineCap = 'round';
        this.edit_ctx.lineJoin = 'round';
        this.edit_ctx.closePath();
        this.dot_flag = false;
      }
    }
    if (res == 'mouseup' || res == 'mouseout') {
      this.flag = false;
      this.mask_image = this.edit_ctx.getImageData(
        0,
        0,
        this.mask_image.height,
        this.mask_image.width
      );
    }
    if (res == 'mousemove') {
      if (this.flag) {
        this.prevX = this.currX;
        this.prevY = this.currY;
        //this.currX = e.clientX - this.maskCanvas.nativeElement.offsetLeft; // margin
        //this.currY = e.clientY - this.maskCanvas.nativeElement.offsetTop ; // topbarmenu + margin

        this.currX =
          e.clientX -
          this.maskCanvas.nativeElement.getBoundingClientRect().left;
        this.currY =
          e.clientY - this.maskCanvas.nativeElement.getBoundingClientRect().top;

        this.draw();
      }
    }
  }

  draw() {
    this.edit_ctx.beginPath();
    this.edit_ctx.moveTo(this.prevX, this.prevY);
    this.edit_ctx.lineTo(this.currX, this.currY);
    this.edit_ctx.strokeStyle = this.penColor;
    this.edit_ctx.lineWidth = this.lineWidth;
    this.edit_ctx.stroke();
    this.edit_ctx.closePath();
  }

  zoomDraw() {
    let image_ratio = this.img_tmp.width / this.img_tmp.height;

    let canvas_width = window.innerWidth - 420;
    let canvas_height = canvas_width / image_ratio;

    this.bgCanvas.nativeElement.height = canvas_height * this.zoom_level;
    this.bgCanvas.nativeElement.width = canvas_width * this.zoom_level;
    this.maskCanvas.nativeElement.height = canvas_height * this.zoom_level;
    this.maskCanvas.nativeElement.width = canvas_width * this.zoom_level;

    var hRatio = this.bgCanvas.nativeElement.width / this.img_tmp.width;
    var vRatio = this.bgCanvas.nativeElement.height / this.img_tmp.height;

    var ratio = Math.max(hRatio, vRatio);

    //    var ratio = this.zoom_level;

    var centerShift_x =
      (this.bgCanvas.nativeElement.width - this.img_tmp.width * ratio) / 2;
    var centerShift_y =
      (this.bgCanvas.nativeElement.height - this.img_tmp.height * ratio) / 2;

    var centerShift_x2 =
      (this.maskCanvas.nativeElement.width - this.mask_image.width * ratio) / 2;
    var centerShift_y2 =
      (this.maskCanvas.nativeElement.height - this.mask_image.height * ratio) /
      2;

    //    this.back_ctx.drawImage(this.img_tmp,0,0, this.img_tmp.width*ratio, this.img_tmp.height*ratio);

    this.back_ctx.drawImage(
      this.img_tmp,
      0,
      0,
      this.img_tmp.width,
      this.img_tmp.height,
      centerShift_x,
      centerShift_y,
      this.img_tmp.width * ratio,
      this.img_tmp.height * ratio
    );

    this.edit_ctx.drawImage(
      this.maskCanvas.nativeElement,
      0,
      0,
      this.mask_image.width * ratio,
      this.mask_image.height,
      centerShift_x2,
      centerShift_y2,
      this.mask_image.width * ratio,
      this.mask_image.height * ratio
    );
  }

  save() {
    //document.getElementById("canvasimg").style.border = "2px solid";
    let dataURL = this.maskCanvas.nativeElement.toDataURL();

    //console.log(dataURL);
    //this.maskCanvas.nativeElement. = dataURL;
    //document.getElementById("canvasimg").style.display = "inline";
  }

  inpaint() {


    //console.log(base64Mask);

    this.creditService.get().subscribe((info) => {
      let possible: boolean = info.balance >= this.cost;

      if (possible) {
        this.inpaintGeneration();
      } else {
        this.dialog.open(DialogNeedBiscuitComponent, {
          width: '600px',
          disableClose: true,
          autoFocus: false,
        });
      }
    });

  }

  inpaintGeneration() {

    //WARNING THE MASK IS SCALED - SERVER WILL NEED TO CHECK AND RESIZE THE MASK TO THE SOURCE PIC SIZE
    //base64 image of the mask
    let base64Mask = this.maskCanvas.nativeElement.toDataURL();

    //3 images will be displayed, with max-width 350px
    //the dialog will be then maxof ( 350 * 3 + some margins) --> 1100 px MAX
    //width will be the ratio * original pic height + margin

    let originalRatio = this.img_tmp.width / this.img_tmp.height;
    let maxImgHeight = 350 / originalRatio + 140;

    const dialogRef = this.dialog.open(DialogInpaintComponent, {
      data: {
        pic: this.sourcePic,
        mask: base64Mask,
        nb_pics: this.nb_of_images,
        add_prompt: this.prompt,
        improve_mode: this.mode == 'IMPROVE' ? true : false,
        img_prompts: this.img_prompts,
      },
      width: '90%',
      maxWidth: '1100px',
      maxHeight: maxImgHeight + 'px',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: ActionMessage) => {
      if (result) {
        if (result.action_code == ActionCode.FIX) {
          /* this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          };
          this.router.navigate(['/inpaint/', result.img_guid], {
            queryParams: { refresh: 1 },
            skipLocationChange: true,
          });*/

          window.location.href = './inpaint/' + result.img_guid;
        }
      }
    });
  }

  onPromptChange(event) {
    this.prompt = event.target.value;
  }

  brushSize(value) {
    if (value == 0) return '1';
    else return value;
  }

  imageNb(value) {
    if (value == 0) {
      this.nb_of_images = 1;
      return '1';
    } else return value;
  }

  zoomLevel(value) {
    return Math.round(value * 100) + '%';
  }

  changeModeSelected(value) {
    if (!value.source._selected) {
      value.source._selected = true;
      this.mode = value.source._value;
    } else {
      this.mode = value.source._value;
    }
  }

  adaptMode() {
    if (this.mode == 'IMPROVE') {
      return 'FIX (CHARACTER)';
    } else {
      return 'REPLACE (OBJECTS)';
    }
  }

  //********************************************
  //*********** AVATAR IMAGE MANAGEMENT

  selectAvatarImage() {
    let self = this;

    const dialogRef = this.dialog.open(DialogInpaintSelectAvatarComponent, {
      height: '80%',
      width: '80%',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.toDataUrl(result, function (myBase64) {
          if (!self.img_prompts[0]) {
            let imagePrompt = new ImagePrompt();
            imagePrompt.base64 = myBase64;
            imagePrompt.strength = 0.5;
            imagePrompt.type = ImagePromptType.FACE_SWAP;
            self.img_prompts.push(imagePrompt);
          }
        });
      }
    });
  }

  toDataUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  //***********************************
  //***********************************
  //*************  IMAGE PROMPTS !
  //IMAGE PROMPTS

  files: File[];
  img_prompts: ImagePrompt[];

  getImagePromptMessage() {
    if (this.files.length > 0) {
      return this.files.length + ' IMAGES ';
    } else {
      return '';
    }
  }

  file2Base64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      let reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString() || '');
      reader.onerror = (error) => reject(error);
    });
  };

  onImageSwapFileChange(pFileList: File[]) {
    var self = this;

    let temp_files: File[] = Object.keys(pFileList).map(
      (key) => pFileList[key]
    );

    if (self.files.length == 0) {
      self.files = temp_files.slice(0, 1);

      self._snackbar.open('1 image added !', '', {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 1000,
        panelClass: ['green-snackbar'],
      });
    } else {
      self._snackbar.open('Max 1 image !', '', {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 1000,
        panelClass: ['red-snackbar'],
      });

      return;
    }

    let reader = new FileReader();

    reader.onloadend = (e) => {
      //let base64 = reader.result.toString();
      //console.log(base64);
      //console.log(base64.replace('data:', '').replace(/^.+,/, ''));
      // self.b64Files.push(base64);
    };

    for (let i = 0; i < this.files.length; i++) {
      //self.img_prompts = [];

      self.file2Base64(this.files[i]).then((value) => {
        //if it does not exist yet, we initialise the new item
        if (!self.img_prompts[i]) {
          let imagePrompt = new ImagePrompt();
          imagePrompt.base64 = value;
          imagePrompt.strength = 0.5;
          imagePrompt.type = ImagePromptType.IMAGE_PROMPT;
          imagePrompt.temp_id = i;
          self.img_prompts.push(imagePrompt);
        } //else we keep the values
      });
    }

    self._snackbar.open('Successfully upload (max 2 images)!', '', {
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      duration: 1000,
      panelClass: ['green-snackbar'],
    });
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.img_prompts.splice(index, 1);

    this._snackbar.open('Successfully removed!', '', {
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      duration: 1000,
      panelClass: ['green-snackbar'],
    });
  }

  setFaceSwapStrenght(index: number, e: number) {
    this.img_prompts[index].strength = e;
  }

  influence(value) {
    return value * 100 + '%';
  }

  displayEvent(e) {
    let pFileList: File[] = e.target.files;
    this.onImageSwapFileChange(pFileList);
  }
}
