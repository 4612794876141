import { ImagePromptType } from "../enum/image-prompt-type";

export class ImagePrompt {

    base64: string;
    type: ImagePromptType;
    strength: number;

    temp_id: number; // for loop tracking in ui

}
